import Vue from "vue";
import Vuex from "vuex";

Vue.use(Vuex);

import { getScores } from "../firebase";

import availableKinases from "@/refs/availableKinases";
import availableTranscriptionFactors from "@/refs/availableTranscriptionFactors";
import categoryDetails from "@/refs/categoryDetails";
import normalizeFn from "./normalizeFn";
import publishedKinaseLists from "@/refs/publishedKinaseLists";
import sampleMeta from "@/refs/sampleMeta";
import sortFn from "./sortFn";

let k_label_v_value = {};
let heatmapShow = {};
categoryDetails.forEach((category) => {
  k_label_v_value[category.title] = Object.fromEntries(
    category.values.map((el) => [el.label, el.numericValue])
  );
  heatmapShow[category.title] = Object.fromEntries(
    category.values.map((el) => [el.label, true])
  );
});

let sampleMetaNumerical = [];
let k_sample_v_tumor = {};

sampleMeta.forEach((sample, i) => {
  let sampleMetaNumericalObj = {
    ...sample,
  };
  k_sample_v_tumor[sample.caseId] = sample.Tumor;
  Object.entries(k_label_v_value).forEach(([label, value]) => {
    sampleMetaNumericalObj[`${label}_num`] = parseInt(value[sample[label]]);
  });
  sampleMetaNumerical.push(sampleMetaNumericalObj);
});

export default new Vuex.Store({
  state: {
    activeTabBoxplot: 1,
    activeTabDownload: 0,
    activeTabHeatmap: 1,
    availableKinases,
    availableTranscriptionFactors,
    boxplotCategory: "Tumor",
    categoryDetails,
    clustergramRowOrder: {
      rows: [],
      source: null,
    },
    clustergramsSynchronized: false,
    clustergramsSyncSource: "",
    clustergramRowOrders: [],
    heatmapCombinedData: deepClone(sampleMetaNumerical),
    heatmapShow: deepClone(heatmapShow),
    heatmapSortTracks: ["Tumor"],
    heatmapView: "KEA3/Chea3 scores",
    combinedData: deepClone(sampleMetaNumerical),
    kea3Scores: [],
    k_label_v_value,
    kinaseTracks: [],
    inputKinases: [],
    inputKinasesFound: [],
    inputKinasesNotFound: [],
    inputKinasesSubmitted: ["BUB1B", "BUB1", "CDC7", "HASPIN"],
    inputKinaseSubstrateView: "",
    inputTranscriptionFactors: [],
    inputTranscriptionFactorsFound: [],
    inputTranscriptionFactorsNotFound: [],
    inputTranscriptionFactorsSubmitted: [
      "ZNF695",
      "ZNF93",
      "ZNF888",
      "HMGA2",
      "ETV4",
      "ZNF878",
    ],
    normalizeIndependent: false,
    publishedKinaseLists,
    sampleMeta,
    sampleMetaNumerical,
    scatterplotCancer: "pancan",
    scatterplotCategory: "Immune_subtype",
    scatterplotInputKinase: "AAK1",
    scatterplotShownTables: ["kea_up_chea_up"],
    scatterplotThreshold: {
      direction: "greaterThan",
      value: "0.3",
    },
    scatterplotThresholdRange: [-1, 0],
  },
  mutations: {
    RESET_SORT_TRACKS(state) {
      state.heatmapSortTracks = ["Tumor"];
    },
    SET_CLUSTERGRAM_ROW_ORDER(state, clustergramRowOrder) {
      state.clustergramRowOrder = clustergramRowOrder;
    },
    SET_CLUSTERGRAM_SYNCHRONIZED(state, clustergramsSynchronized) {
      state.clustergramsSynchronized = clustergramsSynchronized;
    },
    SET_COMBINED_DATA(state, { combinedData }) {
      state.combinedData = combinedData;
    },
    SET_HEATMAP_LOCK_TRACKS(state, { lockTracks }) {
      state.heatmapSortTracks = lockTracks;
    },
    SET_INPUT_KINASES(state, { inputKinases, found, notFound }) {
      state.inputKinases = inputKinases;
      state.inputKinasesFound = found;
      state.inputKinasesNotFound = notFound;
    },
    SET_INPUT_KINASE_SUBSTRATE_VIEW(state, { inputKinaseSubstrateView }) {
      state.inputKinaseSubstrateView = inputKinaseSubstrateView;
    },
    SET_INPUT_KINASES_SUBMITTED(state, { kinases }) {
      state.inputKinasesSubmitted = kinases;
    },
    SET_INPUT_TRANSCRIPTION_FACTORS(
      state,
      { inputTranscriptionFactors, found, notFound }
    ) {
      state.inputTranscriptionFactors = inputTranscriptionFactors;
      state.inputTranscriptionFactorsFound = found;
      state.inputTranscriptionFactorsNotFound = notFound;
    },
    SET_INPUT_TRANSCRIPTION_FACTORS_SUBMITTED(state, { transcriptionFactors }) {
      state.inputTranscriptionFactorsSubmitted = transcriptionFactors;
    },
    SET_HEATMAP_COMBINED_DATA(state, { combinedData }) {
      state.heatmapCombinedData = combinedData;
    },
    SET_HEATMAP_VIEW(state, { heatmapView }) {
      state.heatmapView = heatmapView;
    },
    SET_KEA3_SCORES(state, { kea3Scores }) {
      state.kea3Scores = kea3Scores;
    },
    SET_KINASE_TRACKS(state, { kinaseTracks }) {
      state.kinaseTracks = kinaseTracks;
    },
    SET_SCATTERPLOT_CANCER(state, { scatterplotCancer }) {
      state.scatterplotCancer = scatterplotCancer;
    },
    SET_SCATTERPLOT_CATEGORY(state, { scatterplotCategory }) {
      state.scatterplotCategory = scatterplotCategory;
    },
    SET_SCATTERPLOT_INPUT_KINASE(state, { inputKinase }) {
      state.scatterplotInputKinase = inputKinase;
    },
    SET_SCATTERPLOT_THRESHOLD(state, { scatterplotThreshold }) {
      state.scatterplotThreshold = scatterplotThreshold;
    },
    SET_SCATTERPLOT_THRESHOLD_RANGE(state, { scatterplotThresholdRange }) {
      state.scatterplotThresholdRange = scatterplotThresholdRange;
    },
    SET_SCATTERPLOT_SHOWN_TABLES(state, { selection }) {
      state.scatterplotShownTables = selection;
    },
    TOGGLE_LEGEND_ELEMENT(state, { category, label }) {
      let update = deepClone(state.heatmapShow);
      update[category][label] = !update[category][label];
      state.heatmapShow = update;
    },
    UPDATE_ACTIVE_TAB(state, { key, tab }) {
      state[key] = tab;
    },
    UPDATE_BOXPLOT_CATEGORY(state, { category }) {
      state.boxplotCategory = category;
    },
  },
  actions: {
    combineData(store) {
      const scores = deepClone(store.state.kea3Scores);
      let combinedData = deepClone(store.state.combinedData);
      // let tumors = store.state.tumors
      // const k_sample_v_tumor = store.state.k_sample_v_tumor

      // const res = normalizeFn({
      //   scores,
      //   combinedData,
      //   tumors,
      //   k_sample_v_tumor
      // })

      // store.commit('SET_KINASE_TRACKS', { kinaseTracks: res.kinaseTracks })
    },
    async getScores(store, { kinases }) {
      const asyncPromises = kinases.map((kinase) => {
        return getScores({ kinase });
      });

      const scores = await Promise.all(asyncPromises);

      store.commit("SET_KEA3_SCORES", {
        kea3Scores: scores,
      });

      store.dispatch("combineData");
    },
    resetSortTracks(store) {
      store.commit("RESET_SORT_TRACKS");
    },
    setHeatmapCombinedData(store, { combinedData }) {
      store.commit("SET_HEATMAP_COMBINED_DATA", { combinedData });
    },
    setHeatmapLockTracks(store, { lockTracks }) {
      store.commit("SET_HEATMAP_LOCK_TRACKS", { lockTracks });
    },
    setHeatmapView(store, { heatmapView }) {
      store.commit("SET_HEATMAP_VIEW", { heatmapView });
    },
    setClustergramRowOrder(store, clustergramRowOrder) {
      store.commit("SET_CLUSTERGRAM_ROW_ORDER", clustergramRowOrder);
    },
    setClustergramsSynchronized(store, clustergramsSynchronized) {
      store.commit("SET_CLUSTERGRAM_SYNCHRONIZED", clustergramsSynchronized);
    },
    setInputKinases(store, { inputKinases }) {
      const found = inputKinases.filter(
        (kinase) => kinase in store.state.availableKinases
      );
      const notFound = inputKinases.filter(
        (kinase) => !(kinase in store.state.availableKinases)
      );
      store.commit("SET_INPUT_KINASES", { inputKinases, found, notFound });
    },
    setInputKinaseSubstrateView(store, { inputKinaseSubstrateView }) {
      store.commit("SET_INPUT_KINASE_SUBSTRATE_VIEW", {
        inputKinaseSubstrateView,
      });
    },
    setInputKinasesSubmitted(store, { kinases }) {
      store.commit("SET_INPUT_KINASES_SUBMITTED", { kinases });
    },
    setInputTranscriptionFactors(store, { inputTranscriptionFactors }) {
      const found = inputTranscriptionFactors.filter(
        (transcriptionFactor) =>
          transcriptionFactor in store.state.availableTranscriptionFactors
      );
      const notFound = inputTranscriptionFactors.filter(
        (transcriptionFactor) =>
          !(transcriptionFactor in store.state.availableTranscriptionFactors)
      );
      store.commit("SET_INPUT_TRANSCRIPTION_FACTORS", {
        inputTranscriptionFactors,
        found,
        notFound,
      });
    },
    setInputTranscriptionFactorsSubmitted(store, { transcriptionFactors }) {
      store.commit("SET_INPUT_TRANSCRIPTION_FACTORS_SUBMITTED", {
        transcriptionFactors,
      });
    },
    setScatterplotCancer(store, { scatterplotCancer }) {
      store.commit("SET_SCATTERPLOT_CANCER", { scatterplotCancer });
    },
    setScatterplotCategory(store, { scatterplotCategory }) {
      store.commit("SET_SCATTERPLOT_CATEGORY", { scatterplotCategory });
    },
    setScatterplotInputKinase(store, { inputKinase }) {
      store.commit("SET_SCATTERPLOT_INPUT_KINASE", { inputKinase });
    },
    setScatterplotShownTables(store, { selection }) {
      store.commit("SET_SCATTERPLOT_SHOWN_TABLES", { selection });
    },
    setScatterplotThreshold(store, { scatterplotThreshold }) {
      store.commit("SET_SCATTERPLOT_THRESHOLD", { scatterplotThreshold });
    },
    setScatterplotThresholdRange(store, { scatterplotThresholdRange }) {
      store.commit("SET_SCATTERPLOT_THRESHOLD_RANGE", {
        scatterplotThresholdRange,
      });
    },
    toggleLegendElement(store, { category, label }) {
      store.commit("TOGGLE_LEGEND_ELEMENT", { category, label });
    },
    updateActiveTab(store, { key, tab }) {
      store.commit("UPDATE_ACTIVE_TAB", { key, tab });
    },
    updateBoxplotCategory(store, { category }) {
      store.commit("UPDATE_BOXPLOT_CATEGORY", { category });
    },
  },
});

function deepClone(o) {
  return JSON.parse(JSON.stringify(o));
}
