import firebase from "firebase/app";
import 'firebase/database'
import { last } from "lodash";
import range from 'lodash/range'

const env = process.env

const firebaseConfig = {
    apiKey: env.VUE_APP_API_KEY,
    authDomain: env.VUE_APP_AUTH_DOMAIN,
    // databaseURL: env.VUE_APP_DB_URL,
    databaseURL: 'https://protrack-pancan-default-rtdb.firebaseio.com',
    projectId: env.VUE_APP_PROJECT_ID,
    storageBucket: env.VUE_APP_STORAGE_BUCKET,
    messagingSenderId: env.VUE_APP_MESSAGING_SENDER_ID,
    appId: env.VUE_APP_APP_ID,
    measurementId: env.VUE_APP_MEASUREMENT_ID,
}

firebase.initializeApp(firebaseConfig)  


export const getItems = async({ options, total, symbol, correlation }) => {
    let startIdx, endIdx

    const { sortBy, sortDesc, page, itemsPerPage } = options
    let dataSet = sortBy[0]
    if (!dataSet) {
        dataSet = 'pancan'
    }
    const descending = sortDesc[0]

    if (descending) {
        startIdx = total + 1 - itemsPerPage * page
        endIdx = total + itemsPerPage - itemsPerPage * page
    } else {
        startIdx = itemsPerPage * page - itemsPerPage
        endIdx = itemsPerPage * page - 1
    }

    const path = `prokap/scatterplot_associations/${dataSet}/${symbol}/${correlation}`
    const ref = firebase.database().ref(path).orderByKey().startAt(startIdx.toString()).endAt(endIdx.toString())
    
    const series = await ref.once('value')
        .then((snapshot) => {
            const res = snapshot.val()

            let resArr = []
            for (let i = startIdx; i < endIdx; i++) {
                resArr.push(res[i])
            }
            if (descending) {
                resArr.reverse()
            }
            return resArr
        })

    return series
}


// const getScatterplotItemsByCancer = ({ cancer, kinase, correlation}) => {
//     return new Promise((resolve, reject) => {
//         const path = `prokap/scatterplot_associations_test/${correlation}/${cancer}/${kinase}`
//         // const path = `prokap/scatterplot_association_indexes/${kinase}/${symbol}/${correlation}`
//         return firebase.database().ref(path)
//     })
//   }

export const getRowNames = async({ kinase, correlation, options, sortCancer }) => {
    let startIdx, endIdx

    const { sortDesc, page, itemsPerPage } = options
    const descending = sortDesc[0]

    const pathDataSetSortKey = `prokap/scatterplot_associations/${correlation}/sortOrder/${sortCancer}/${kinase}`

    const refLast = firebase.database().ref(pathDataSetSortKey).orderByKey().limitToLast(1)	
    let lastIdx = await refLast.once('value')
        .then((snapshot) => {
            let res = snapshot.val()
            return res
        })

    let totalItems = parseInt(Object.keys(lastIdx)[0])

    if (descending) {
        startIdx = totalItems - itemsPerPage * page
        endIdx = totalItems + itemsPerPage - itemsPerPage * page - 1
    } else {
        startIdx = itemsPerPage * page - itemsPerPage
        endIdx = itemsPerPage * page
    }

    const ref = firebase.database().ref(pathDataSetSortKey).orderByKey().startAt(startIdx.toString()).endAt(endIdx.toString())
    const series = await ref.once('value')
        .then((snapshot) => {
            let res = snapshot.val()
            let range_arr = range(startIdx, endIdx)
            let resArr = range_arr.map(i => res[i])

            if (descending) {
                resArr.reverse()
            }
            return resArr
        })

    return series
}

export const getRowValues = async({ rowNames, kinase, correlation }) => {
    let promiseArray = rowNames.map((rowName) => {
        const path = `prokap/scatterplot_associations/${correlation}/data/${kinase}/${rowName}`

        return firebase.database().ref(path).once('value')
    })

    const series = Promise.all(promiseArray)
        .then(function(results) {
            if (!results) { return }
            return results.map(function(snapshot, i) {
                return {'symbol': rowNames[i], ...snapshot.val()}
            })
        })
    
    return series
}


export const getScatterplotIndexesPhospho = async({ symbol }) => {
    const path = `prokap/scatterplot_association_indexes_phospho/${symbol}`

    const ref = firebase.database().ref(path)

    const res = await ref.once('value')
        .then((snapshot) => {
            const res = snapshot.val()
            return res

        })

    return res
}



export const getScores = async ({ kinase }) => {
    if (kinase.length === 0) {
        return []
    }

    const path = `prokap/kinaseScores/${kinase}`

    const ref = firebase.database().ref(path)
    const series = await ref.once('value')
        .then((snapshot) => {
            return snapshot.val()
        })
    return series
}


export const getTFScores = async ({ transcriptionFactor }) => {
    if (transcriptionFactor.length === 0) {
        return []
    }

    const path = `prokap/TFScores/${transcriptionFactor}`

    const ref = firebase.database().ref(path)
    const series = await ref.once('value')
        .then((snapshot) => {
            return snapshot.val()
        })
    
    return series
}

export const getPhosphoAbundances = async ({ phosphosite }) => {
    if (phosphosite.length === 0) {
        return []
    }

    const path = `prokap/phosphoAbundance/${phosphosite}`

    const ref = firebase.database().ref(path)
    const series = await ref.once('value')
        .then((snapshot) => {
            return snapshot.val()
        })
    
    return series
}

export const getAllScores = async () => {
    const path = `prokap/kinaseScores`

    const ref = firebase.database().ref(path)
    const series = await ref.once('value')
        .then((snapshot) => {
            return snapshot.val()
        })
    
    return series
}

export const getProteinAbundanceFC = async ({ kinase }) => {
    if (kinase.length === 0) {
        return []
    }

    const path = `prokap/proteinAbundanceFC/${kinase}`

    const ref = firebase.database().ref(path)
    const series = await ref.once('value')
        .then((snapshot) => {
            return snapshot.val()
        })
    
    return series
}

export const getAllProteinAbundanceFC = async () => {
    const path = `prokap/proteinAbundanceFC`

    const ref = firebase.database().ref(path)
    const series = await ref.once('value')
        .then((snapshot) => {
            return snapshot.val()
        })
    
    return series
}

export const getSubstrateFC = async ({ kinase }) => {
    if (kinase.length === 0) {
        return []
    }

    const path = `prokap/substrateFC/${kinase}`

    const ref = firebase.database().ref(path)
    const series = await ref.once('value')
        .then((snapshot) => {
            return snapshot.val()
        })
    
    return series
}

export const getSmallMoleculeSuiteData = async ({ kinase }) => {
    if (kinase.length === 0) {
        return []
    }

    const path = `prokap/smallMoleculeSuite/${kinase}`

    const ref = firebase.database().ref(path)
    const series = await ref.once('value')
        .then((snapshot) => {
            return snapshot.val()
        })
    
    return series
}

export const getDKKdata = async ({ kinase }) => {
    if (kinase.length === 0) {
        return []
    }

    const path = `prokap/DKK/${kinase}`

    const ref = firebase.database().ref(path)
    const series = await ref.once('value')
        .then((snapshot) => {
            return snapshot.val()
        })
    
    return series
}
