<template>
  <div class="footer-affiliations">
    <img src="../assets/logo_combined.png" style="height: 100px;">
    <div>
        <div style="margin: 0 auto; text-align: center; font-size: 1.1em; margin-top: 12px;"><b>Affiliations</b></div>
        <div class="footer-affiliations-links">
            <div 
                v-for="affiliation in affiliatons" 
                :key="affiliation.label"
                class="footer-affiliations-link"
            >
                <a :href="affiliation.link" target="_blank">{{ affiliation.label }}</a>
            </div>
            <v-btn
                class="ml-6"
                href="https://github.com/WangLab-MSSM/prokap/"
                target="_blank"
            >
            <v-icon
                dark
                left
            >
                mdi-github
            </v-icon>
                Source code
            </v-btn>
        </div>
    </div>
  </div>
</template>

<script>
export default {
    name: 'footer-affiliations',

    data: () => ({
        affiliatons: [
            { label: 'NCI Clinical Proteomic Tumor Analysis Consortium (CPTAC)', link: 'https://proteomics.cancer.gov/programs/cptac' },
            { label: 'Icahn School of Medicine at Mount Sinai', link: 'http://icahn.mssm.edu/' },
            { label: 'Pei Wang Lab', link: 'https://labs.icahn.mssm.edu/pei-wang-lab/' },
            { label: "The Ma'ayan Lab", link: 'http://icahn.mssm.edu/research/labs/maayan-laboratory' },
        ],
    }),
}
</script>

<style>
.footer-affiliations {
    width: 100%;
    background: white;
    display: flex;
    flex-direction: row;
    border-top: solid 5px #ebebeb;
}

.footer-affiliations-links {
    width: 100%;
    min-width: 800px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.footer-affiliations-link {
    margin: 6px 20px;
    font-size: 1.05em;
}
</style>