<template>
  <landing-input />
</template>

<script>
import LandingInput from '../components/LandingInput.vue';

export default {
  name: "HomeView",

  components: {
    LandingInput,
  },
};
</script>
