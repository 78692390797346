<template>
  <div class="landing-input-footer">
    <!-- <div class="use-case-cards-title"><b>Use cases:</b></div> -->
    <div class="landing-input-footer-left">
      <!-- <div class="use-case-cards">
        <landing-input-use-case-card 
          v-for="useCase, i in useCases"
          :key="`useCase-${i}`"
          :useCase="useCase"
          :cardIdx="i"
        />
      </div> -->
    </div>
    <!-- <div class="landing-input-footer-right">

    </div> -->
  </div>
</template>

<script>
// import LandingInputUseCaseCard from './LandingInputUseCaseCard.vue';

export default {
  // components: { LandingInputUseCaseCard },
  name: "LandingInputFooter",

  data: () => ({
    useCases: [
      {
        header: 'I have a list of kinases, and I want to...',
        sublinks: [
          { 
            route: '/visualizationHeatmap', 
            description: 'compare their inferred activity across cancer types',
            workflow: [
              {
                method: 'setInputKinases',
                args: {
                  inputKinases: [
                    'BUB1B',
                    'BUB1',
                    'CDC7',
                    'HASPIN',
                  ]
                },
              },
              {
                method: 'setInputKinasesSubmitted',
                args: {
                  kinases: [
                    'BUB1B',
                    'BUB1',
                    'CDC7',
                    'HASPIN',
                  ],
                }
              },
              {
                method: 'setInputTranscriptionFactors',
                args: {
                  inputTranscriptionFactors: [
                    'ZNF695',
                    'ZNF93',
                    'ZNF888',
                    'HMGA2',
                    'ETV4',
                    'ZNF878',
                  ],
                },
              },
              {
                method: 'setInputTranscriptionFactorsSubmitted',
                args: {
                  transcriptionFactors: [
                    'ZNF695',
                    'ZNF93',
                    'ZNF888',
                    'HMGA2',
                    'ETV4',
                    'ZNF878',
                  ],
                }
              },
              // {
              //   method: 'getScores',
              //   args: { 
              //     kinases: [
              //       'CDK4',
              //     ]
              //  },
              // },
              // {
              //   method: 'sortSamples',
              //   args: {
              //     tracksToLock: ['Tumor']
              //   }
              // }
            ],
          },
          { 
            route: '/downloadPage', 
            description: 'download their inferred activity scores for multiple cancer types',
            workflow: [],
          },
        ],
      },
      // {
      //   header: 'I have a single kinase, and I want to...',
      //   sublinks: [
      //     { 
      //       route: '/visualizationHeatmap', 
      //       description: 'visualize its inferred activity, protein abundance, and the protein abundances of its associated substrates across cancers',
      //       workflow: [
      //         {
      //           method: 'setSubstrateView',
      //           args: {
      //             substrateView: true,
      //           },
      //         },
      //         {
      //           method: 'setSubstrateKinase',
      //           args: {
      //             substrateKinase: 'CDK4',
      //           },
      //         },
      //       ],
      //     },
      //     { 
      //       route: '/kinaseInfo', 
      //       description: 'learn more about it',
      //       workflow: [],
      //     }
      //   ],
      // },
      // {
      //   header: 'I have a gene and I want to...',
      //   sublinks: [
      //     { 
      //       route: '/visualizationBoxplot', 
      //       description: 'see if its mutation status is associated with kinase activity scores',
      //       workflow: 'viewMutationOnBoxplot',
      //     }
      //   ],
      // },
    ],
  }),
  
};
</script>

<style scoped>
.landing-input-footer {
  margin: 8px;
  font-size: 0.8em;
}

.landing-input-footer-left {
  width: 90%;
  display: flex;
  flex-direction: row;
  justify-content: space-between ;
}

.use-case-cards-title {
  font-size: 1.4em;
  margin-top: -6px;
  margin-bottom: -6px;
}

.use-case-cards {
  display: flex;
  flex-direction: row;
}

</style>
