<template>
  <div class="app-bar-links">      
    <v-menu 
        offset-y
        v-for="link in links"
        :key="link.header"
    >
        <template v-slot:activator="{ on, attrs }">
            <div :class="{underlined: !link.icon}">
                <v-btn
                    plain
                    color="blue-grey darken-4"
                    dark
                    v-bind="attrs"
                    v-on="on"
                    @click="goToRoute(link.route)"
                >
                    <v-icon dark v-if="link.icon">
                        {{ link.icon }}
                    </v-icon>
                    <div v-else>
                        {{ link.header }}
                    </div>
                </v-btn>
            </div>
        </template>

        <v-list v-if="link.sublinks">
          <v-list-item
            v-for="(item, index) in link.sublinks"
            :key="index"
          >
            <v-hover
                v-slot="{ hover }"
            >
                <!-- <router-link :to="`/${item.route}`" class="sublink-button"> -->
                    <v-btn
                        block
                        :elevation="hover ? 2 : 0"
                        class="mx-auto"
                        @click="goToRoute(item.route)"
                    >
                        {{ item.title }}
                    </v-btn>
                <!-- </router-link> -->
            </v-hover>
          </v-list-item>
        </v-list>
      </v-menu>
  </div>
</template>

<script>
export default {
    name: "app-bar-links",
    data: () => ({
        links: [
             { 
                header: 'Home',
                sublinks: null,
                icon: 'mdi-home-variant-outline',
                route: '/',
            },
            { 
                header: 'Visualize',
                sublinks: [
                    { 
                        title: 'Heatmap',
                        route: '/visualizationHeatmap',
                    },
                    { 
                        title: 'Boxplot',
                        route: '/visualizationBoxplot',
                    },
                    { 
                        title: 'Scatterplot',
                        route: '/visualizationScatterplot',
                    },
                ],
                icon: null,
                route: null,
            },
            // { 
            //     header: 'Kinase info',
            //     sublinks: null,
            //     icon: null,
            //     route: '/kinaseInfo',
            // },
            { 
                header: 'Custom downloads',
                sublinks: null,
                icon: null,
                route: '/downloadPage',
            },
        ],
    }),
    methods: {
        goToRoute(route) {
            if (route && this.$route.path !== route) {
                this.$router.push(route)
            }

        }
    }
}
</script>

<style>
.app-bar-links {
    width: 100%;
    display: flex;
    justify-content: center;
}

.sublink-button {
    width: 100%;
    text-decoration: none;
}

.underlined {
    border-bottom: 1px dotted #37474F; 
    margin-right: 15px;
}
</style>