<template>
    <div :class="calculateClass()">
    <div class="found-card">
      <v-card
        elevation="0"
      >
        <v-tabs
          v-model="tab"
          color="grey"
        >
          <v-tab
            v-for="item in items"
            :key="item.ID"
            :id="item.ID"
          >
            {{ item.label }} ({{ item.kinases.length  }})
          </v-tab>
          <v-spacer v-if="items" />
        </v-tabs>

        <v-tabs-items v-model="tab">

          <v-tab-item
            v-for="item in items"
            :key="item.ID"
          >
            <v-card flat>
              <div class="item-content">
                <div class="item-content-text">
                  <ul>
                    <li v-for="kinase in item.kinases" :key="kinase">{{ kinase }}</li>
                  </ul>
                </div>

              </div>
            </v-card>
          </v-tab-item>
        </v-tabs-items>

        </v-card>
      </div>

      <v-btn
        color="blue-grey lighten-3"
        rounded
        expanded
        elevation="2"
        class="mt-5"
        @click="submitKinases"
      >
        Explore found kinases
        <v-icon
        right
        dark
      >
        mdi-play
      </v-icon>
      </v-btn>
  </div>
</template>

<script>
export default {
  name: "LandingInputRight",

  data: () => ({
    tab: null,
  }),

  props: [ 'onHeatmapPage' ],

  computed: {
    found() { return this.$store.state.inputKinasesFound },
    notFound() { return this.$store.state.inputKinasesNotFound },
    items() {
      if (!this.found || !this.notFound) { return null }

      return [
        {
          label: 'Found', 
          ID: 'found-kinases', 
          kinases: this.found, 
        },
        {
          label: 'Not found', 
          ID: 'not-found-kinases', 
          kinases: this.notFound, 
        },
      ]
    },
    inputKinases() { return this.$store.state.inputKinases },
  },

  watch: {
    items() { 
      if (this.notFound.length >= 1) {
        this.tab = 1 
      }
    },
  },

  methods: {
    calculateClass() {
      let classLabel = this.inputKinases.length > 0 ? 
      'landing-input-right half-width' : 
      'landing-input-right hidden'

      if (this.onHeatmapPage) {
        classLabel = `${classLabel} shrunken`
      }

      return classLabel
    },
    submitKinases() {
      this.$store.dispatch('getScores', { 
        kinases: this.found,
      })

      this.$store.dispatch('setInputKinasesSubmitted', { 
        kinases: this.found,
      })
      
      if (this.$route.path === '/') {
        this.$router.push('visualizationHeatmap')
      } 
      // else {
      //   this.$store.dispatch('updateActiveTab', { key: 'activeTabHeatmap', tab: 1 })
      // }

    },
  },
  
};
</script>

<style scoped>
.landing-input-right {
  margin: 10px;
  height: 38vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.found-card {
  height: 32vh;
  overflow: scroll;
  border: solid 1px #ebebeb;
}

.hidden {
  width: 0;
  display: none;
}

.half-width {
  width: 40%;
}

#found-kinases {
    color: #0A6A56 !important;
}

#not-found-kinases {
    color: #A82B11 !important;
}

.item-content {
  padding: 10px;
}

.item-content-text {
  display: flex;
  flex-direction: column;
  overflow: scroll;
}

.item-content ul {
  list-style: none;
}

.shrunken {
  height: 30vh;
}
</style>
