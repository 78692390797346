<template>
  <div class="landing-input">
    <v-container 
      class="mx-auto"
    >
      <v-banner
        outlined
        shaped
      >
        <div class="banner">
            <div  class="banner-content">
              <landing-input-header/>

              <div class="input-container">
                <landing-input-left />
                <landing-input-right />
              </div>
          </div>
        </div>
      </v-banner>
      <landing-input-footer />
    </v-container>
  </div>
</template>

<script>
import LandingInputHeader from './LandingInputHeader.vue';
import LandingInputLeft from './LandingInputLeft.vue';
import LandingInputRight from './LandingInputRight.vue';
import LandingInputFooter from './LandingInputFooter.vue';

export default {
  components: {
    LandingInputHeader,
    LandingInputLeft,
    LandingInputRight,
    LandingInputFooter,
},
  
  name: "LandingInput",
};
</script>

<style scoped>
.landing-input {   
  margin-top: 1vh;
  min-width: 1000px;
}

.banner {
  height: 50vh;
  display: flex;
  justify-content: center;
  min-width: 1000px;
}

.banner-content {
  height: 50%;
  padding: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.input-container {
  min-width: 800px;
  min-height: 300px;
  margin: 10px;
  width: 100%;
  justify-content: center;
  display: flex;
  flex-direction: row;
}

.input-right {
  width: 40%;
  margin: 10px;
  display: flex;
  flex-direction: column;
  background: yellow;
}
</style>
