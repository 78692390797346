export default [
  {
    pmid: "00000",
    title: "Example kinase list",
    kinases: [
      "CSNK2A1",
      "CHEK2",
      "CSNK2A2",
      "CDK2",
      "AURKB",
      "ATM",
      "CHEK1",
      "PLK1",
    ],
    paperTitle: "This is an example kinase list.",
  },
  {
    pmid: "33079988",
    title: "Dark kinases",
    kinases: [
      "CSNK2A1",
      "CHEK2",
      "CSNK2A2",
      "CDK2",
      "AURKB",
      "ATM",
      "CHEK1",
      "PLK1",
      "ATR",
      "CDK1",
      "CDK9",
      "SRPK2",
      "CDC7",
      "CDK6",
      "CDK4",
      "AURKA",
      "SRPK1",
      "CLK1",
      "BUB1",
      "FYN",
      "SRPK3",
      "BUB1B",
      "CLK2",
      "MAPK1",
      "CSNK2A3",
      "DYRK1A",
      "AKT1",
      "GSK3B",
      "CDK7",
      "MAPK3",
      "PRKDC",
      "CDK13",
      "LCK",
      "NEK6",
      "ABL1",
      "HIPK2",
      "CDK12",
      "LYN",
      "BTK",
      "VRK1",
      "MAPK14",
      "FGR",
      "PLK4",
      "CHUK",
      "IKBKE",
      "NTRK1",
      "CSNK1A1",
      "CDK11A",
      "SRC",
      "CLK3",
      "HIPK1",
      "PRKCI",
      "TTK",
      "EGFR",
      "CDK8",
      "SYK",
      "CDC42BPB",
      "TBK1",
      "PTK2",
      "HCK",
      "EIF2AK2",
      "SCYL2",
      "PRKAA1",
      "CSNK1E",
      "NEK9",
      "TNK2",
    ],
    paperTitle: "The Dark Kinase Knowledgebase... (Berginski et. al, 2021)",
  },
];
