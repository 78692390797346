import Vue from "vue";
import VueRouter from "vue-router";
import HomeView from "../views/HomeView.vue";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "home",
    component: () =>
      import(/* webpackChunkName: "visualization" */ "../views/HomeView.vue"),
  },
  {
    path: "/downloadPage",
    name: "downloadPage",
    component: () =>
      import(
        /* webpackChunkName: "visualization" */ "../views/DownloadPage.vue"
      ),
  },
  {
    path: "/visualizationHeatmap",
    name: "visualizationHeatmap",
    component: () =>
      import(
        /* webpackChunkName: "visualization" */ "../views/VisualizationHeatmap.vue"
      ),
  },
  {
    path: "/visualizationBoxplot",
    name: "visualizationBoxplot",
    component: () =>
      import(
        /* webpackChunkName: "visualization" */ "../views/VisualizationBoxplot.vue"
      ),
  },
  {
    path: "/visualizationScatterplot",
    name: "visualizationScatterplot",
    component: () =>
      import(
        /* webpackChunkName: "visualization" */ "../views/VisualizationScatterplot.vue"
      ),
  },
  // {
  //   path: "/kinaseInfo",
  //   name: "kinaseInfo",
  //   component: () =>
  //     import(/* webpackChunkName: "visualization" */ "../views/KinaseInfo.vue"),
  // },
];

const router = new VueRouter({
  base: process.env.BASE_URL,
  routes,
});

export default router;
