export default [
  {
    Tumor: "LSCC",
    Sex: "Female",
    Age: "55-63 (25%-50%)",
    Participant_Country: "Poland",
    Grade: "G2",
    Stage: "III",
    BMI: "25-30",
    Alcohol_consumption: "non-drinker",
    Tobacco_use: "past-smoker",
    Secondhand_smoke_exposure: "No",
    Overall_survival_in_days: ">=1016 (>=75%)",
    Recurrence_status: "No",
    Survival_status: "Dead",
    caseId: "C3N-02288",
    Immune_subtype: "Fibroblast/TGFBeta",
  },
  {
    Tumor: "HGSC",
    Sex: "Female",
    Age: "<55 (25%)",
    Participant_Country: "not_reported",
    Grade: "G3",
    Stage: "III",
    BMI: "not_reported",
    Alcohol_consumption: "not_reported",
    Tobacco_use: "not_reported",
    Secondhand_smoke_exposure: "not_reported",
    Overall_survival_in_days: "<348 (25%)",
    Recurrence_status: "No",
    Survival_status: "Dead",
    caseId: "02OV044",
    Immune_subtype: "CD8-/IFNG-",
  },
  {
    Tumor: "GBM",
    Sex: "Male",
    Age: "55-63 (25%-50%)",
    Participant_Country: "Russia",
    Grade: "not_reported",
    Stage: "not_reported",
    BMI: "18.5-25",
    Alcohol_consumption: "non-drinker",
    Tobacco_use: "current smoker",
    Secondhand_smoke_exposure: "Yes",
    Overall_survival_in_days: "<348 (25%)",
    Recurrence_status: "No",
    Survival_status: "Alive",
    caseId: "C3L-01043",
    Immune_subtype: "CD8+/IFNG+",
  },
  {
    Tumor: "UCEC",
    Sex: "Female",
    Age: ">=70 (>=75%)",
    Participant_Country: "United States",
    Grade: "G3",
    Stage: "IV",
    BMI: ">=30",
    Alcohol_consumption: "non-drinker",
    Tobacco_use: "non-smoker",
    Secondhand_smoke_exposure: "not_reported",
    Overall_survival_in_days: "<348 (25%)",
    Recurrence_status: "No",
    Survival_status: "Alive",
    caseId: "C3L-01307",
    Immune_subtype: "Fibroblast/TGFBeta",
  },
  {
    Tumor: "HNSCC",
    Sex: "Male",
    Age: "63-70 (50%-75%)",
    Participant_Country: "Poland",
    Grade: "G2",
    Stage: "II",
    BMI: "25-30",
    Alcohol_consumption: "not_reported",
    Tobacco_use: "past-smoker",
    Secondhand_smoke_exposure: "not_reported",
    Overall_survival_in_days: "609-1016 (50%-75%)",
    Recurrence_status: "No",
    Survival_status: "Alive",
    caseId: "C3N-00828",
    Immune_subtype: "CD8-/IFNG+",
  },
  {
    Tumor: "ccRCC",
    Sex: "Male",
    Age: "<55 (25%)",
    Participant_Country: "United States",
    Grade: "G3",
    Stage: "I",
    BMI: ">=30",
    Alcohol_consumption: "non-drinker",
    Tobacco_use: "not_reported",
    Secondhand_smoke_exposure: "not_reported",
    Overall_survival_in_days: ">=1016 (>=75%)",
    Recurrence_status: "No",
    Survival_status: "Dead",
    caseId: "C3L-00917",
    Immune_subtype: "CD8+/IFNG+",
  },
  {
    Tumor: "HNSCC",
    Sex: "Male",
    Age: "63-70 (50%-75%)",
    Participant_Country: "Poland",
    Grade: "G2",
    Stage: "II",
    BMI: "18.5-25",
    Alcohol_consumption: "drinker",
    Tobacco_use: "current smoker",
    Secondhand_smoke_exposure: "No",
    Overall_survival_in_days: "<348 (25%)",
    Recurrence_status: "No",
    Survival_status: "Alive",
    caseId: "C3N-03027",
    Immune_subtype: "CD8-/IFNG+",
  },
  {
    Tumor: "PDAC",
    Sex: "Male",
    Age: ">=70 (>=75%)",
    Participant_Country: "Poland",
    Grade: "G3",
    Stage: "IV",
    BMI: "<18.5",
    Alcohol_consumption: "drinker",
    Tobacco_use: "current smoker",
    Secondhand_smoke_exposure: "not_reported",
    Overall_survival_in_days: "<348 (25%)",
    Recurrence_status: "Yes",
    Survival_status: "Alive",
    caseId: "C3N-04282",
    Immune_subtype: "CD8-/IFNG-",
  },
  {
    Tumor: "GBM",
    Sex: "Male",
    Age: "63-70 (50%-75%)",
    Participant_Country: "Russia",
    Grade: "not_reported",
    Stage: "not_reported",
    BMI: "25-30",
    Alcohol_consumption: "non-drinker",
    Tobacco_use: "non-smoker",
    Secondhand_smoke_exposure: "No",
    Overall_survival_in_days: "609-1016 (50%-75%)",
    Recurrence_status: "Yes",
    Survival_status: "Dead",
    caseId: "C3L-01061",
    Immune_subtype: "CD8-/IFNG-",
  },
  {
    Tumor: "HGSC",
    Sex: "Female",
    Age: "55-63 (25%-50%)",
    Participant_Country: "not_reported",
    Grade: "G3",
    Stage: "III",
    BMI: "not_reported",
    Alcohol_consumption: "not_reported",
    Tobacco_use: "not_reported",
    Secondhand_smoke_exposure: "not_reported",
    Overall_survival_in_days: ">=1016 (>=75%)",
    Recurrence_status: "Yes",
    Survival_status: "Alive",
    caseId: "04OV048",
    Immune_subtype: "CD8+/IFNG+",
  },
  {
    Tumor: "BRCA",
    Sex: "Female",
    Age: "63-70 (50%-75%)",
    Participant_Country: "not_reported",
    Grade: "not_reported",
    Stage: "III",
    BMI: "not_reported",
    Alcohol_consumption: "not_reported",
    Tobacco_use: "not_reported",
    Secondhand_smoke_exposure: "not_reported",
    Overall_survival_in_days: "348-609 (25%-50%)",
    Recurrence_status: "No",
    Survival_status: "Dead",
    caseId: "18BR019",
    Immune_subtype: "CD8-/IFNG-",
  },
  {
    Tumor: "BRCA",
    Sex: "Female",
    Age: "<55 (25%)",
    Participant_Country: "not_reported",
    Grade: "not_reported",
    Stage: "II",
    BMI: "not_reported",
    Alcohol_consumption: "not_reported",
    Tobacco_use: "not_reported",
    Secondhand_smoke_exposure: "not_reported",
    Overall_survival_in_days: "348-609 (25%-50%)",
    Recurrence_status: "No",
    Survival_status: "Dead",
    caseId: "11BR010",
    Immune_subtype: "Fibroblast/TGFBeta",
  },
  {
    Tumor: "PDAC",
    Sex: "Male",
    Age: "63-70 (50%-75%)",
    Participant_Country: "United States",
    Grade: "G2",
    Stage: "II",
    BMI: "18.5-25",
    Alcohol_consumption: "drinker",
    Tobacco_use: "non-smoker",
    Secondhand_smoke_exposure: "No",
    Overall_survival_in_days: "609-1016 (50%-75%)",
    Recurrence_status: "No",
    Survival_status: "Alive",
    caseId: "C3L-00277",
    Immune_subtype: "Fibroblast/TGFBeta",
  },
  {
    Tumor: "HNSCC",
    Sex: "Male",
    Age: "55-63 (25%-50%)",
    Participant_Country: "Poland",
    Grade: "G2",
    Stage: "IV",
    BMI: "18.5-25",
    Alcohol_consumption: "drinker",
    Tobacco_use: "past-smoker",
    Secondhand_smoke_exposure: "not_reported",
    Overall_survival_in_days: "609-1016 (50%-75%)",
    Recurrence_status: "Yes",
    Survival_status: "Dead",
    caseId: "C3N-01858",
    Immune_subtype: "CD8-/IFNG+",
  },
  {
    Tumor: "BRCA",
    Sex: "Female",
    Age: ">=70 (>=75%)",
    Participant_Country: "not_reported",
    Grade: "not_reported",
    Stage: "I",
    BMI: "not_reported",
    Alcohol_consumption: "not_reported",
    Tobacco_use: "not_reported",
    Secondhand_smoke_exposure: "not_reported",
    Overall_survival_in_days: "348-609 (25%-50%)",
    Recurrence_status: "No",
    Survival_status: "Dead",
    caseId: "18BR017",
    Immune_subtype: "Fibroblast/TGFBeta",
  },
  {
    Tumor: "BRCA",
    Sex: "Female",
    Age: ">=70 (>=75%)",
    Participant_Country: "not_reported",
    Grade: "not_reported",
    Stage: "II",
    BMI: "not_reported",
    Alcohol_consumption: "not_reported",
    Tobacco_use: "not_reported",
    Secondhand_smoke_exposure: "not_reported",
    Overall_survival_in_days: "348-609 (25%-50%)",
    Recurrence_status: "No",
    Survival_status: "Dead",
    caseId: "11BR025",
    Immune_subtype: "CD8-/IFNG-",
  },
  {
    Tumor: "COAD",
    Sex: "Female",
    Age: "<55 (25%)",
    Participant_Country: "not_reported",
    Grade: "not_reported",
    Stage: "II",
    BMI: "not_reported",
    Alcohol_consumption: "not_reported",
    Tobacco_use: "not_reported",
    Secondhand_smoke_exposure: "not_reported",
    Overall_survival_in_days: "348-609 (25%-50%)",
    Recurrence_status: "No",
    Survival_status: "Dead",
    caseId: "05CO029",
    Immune_subtype: "CD8-/IFNG+",
  },
  {
    Tumor: "LSCC",
    Sex: "Male",
    Age: "63-70 (50%-75%)",
    Participant_Country: "United States",
    Grade: "G3",
    Stage: "I",
    BMI: "18.5-25",
    Alcohol_consumption: "drinker",
    Tobacco_use: "past-smoker",
    Secondhand_smoke_exposure: "No",
    Overall_survival_in_days: ">=1016 (>=75%)",
    Recurrence_status: "Yes",
    Survival_status: "Dead",
    caseId: "C3L-04071",
    Immune_subtype: "CD8+/IFNG+",
  },
  {
    Tumor: "HGSC",
    Sex: "Female",
    Age: "55-63 (25%-50%)",
    Participant_Country: "not_reported",
    Grade: "G2",
    Stage: "I",
    BMI: "not_reported",
    Alcohol_consumption: "not_reported",
    Tobacco_use: "not_reported",
    Secondhand_smoke_exposure: "not_reported",
    Overall_survival_in_days: "348-609 (25%-50%)",
    Recurrence_status: "No",
    Survival_status: "Dead",
    caseId: "18OV001",
    Immune_subtype: "CD8-/IFNG-",
  },
  {
    Tumor: "UCEC",
    Sex: "Female",
    Age: "63-70 (50%-75%)",
    Participant_Country: "Ukraine",
    Grade: "G3",
    Stage: "I",
    BMI: ">=30",
    Alcohol_consumption: "non-drinker",
    Tobacco_use: "non-smoker",
    Secondhand_smoke_exposure: "not_reported",
    Overall_survival_in_days: "609-1016 (50%-75%)",
    Recurrence_status: "No",
    Survival_status: "Dead",
    caseId: "C3N-01825",
    Immune_subtype: "Fibroblast/TGFBeta",
  },
  {
    Tumor: "LSCC",
    Sex: "Male",
    Age: "63-70 (50%-75%)",
    Participant_Country: "United States",
    Grade: "G2",
    Stage: "IV",
    BMI: ">=30",
    Alcohol_consumption: "past-drinker",
    Tobacco_use: "past-smoker",
    Secondhand_smoke_exposure: "not_reported",
    Overall_survival_in_days: "<348 (25%)",
    Recurrence_status: "No",
    Survival_status: "Dead",
    caseId: "C3L-03272",
    Immune_subtype: "CD8-/IFNG+",
  },
  {
    Tumor: "GBM",
    Sex: "Male",
    Age: "<55 (25%)",
    Participant_Country: "United States",
    Grade: "not_reported",
    Stage: "not_reported",
    BMI: ">=30",
    Alcohol_consumption: "not_reported",
    Tobacco_use: "not_reported",
    Secondhand_smoke_exposure: "not_reported",
    Overall_survival_in_days: "609-1016 (50%-75%)",
    Recurrence_status: "Yes",
    Survival_status: "Alive",
    caseId: "C3L-02708",
    Immune_subtype: "CD8-/IFNG-",
  },
  {
    Tumor: "HNSCC",
    Sex: "Male",
    Age: "63-70 (50%-75%)",
    Participant_Country: "Poland",
    Grade: "G2",
    Stage: "II",
    BMI: "18.5-25",
    Alcohol_consumption: "past-drinker",
    Tobacco_use: "past-smoker",
    Secondhand_smoke_exposure: "not_reported",
    Overall_survival_in_days: "<348 (25%)",
    Recurrence_status: "No",
    Survival_status: "Alive",
    caseId: "C3N-00519",
    Immune_subtype: "Fibroblast/TGFBeta",
  },
  {
    Tumor: "HNSCC",
    Sex: "Male",
    Age: "55-63 (25%-50%)",
    Participant_Country: "Ukraine",
    Grade: "G2",
    Stage: "II",
    BMI: ">=30",
    Alcohol_consumption: "non-drinker",
    Tobacco_use: "non-smoker",
    Secondhand_smoke_exposure: "not_reported",
    Overall_survival_in_days: "609-1016 (50%-75%)",
    Recurrence_status: "Yes",
    Survival_status: "Dead",
    caseId: "C3N-03620",
    Immune_subtype: "CD8+/IFNG+",
  },
  {
    Tumor: "ccRCC",
    Sex: "Female",
    Age: "63-70 (50%-75%)",
    Participant_Country: "Poland",
    Grade: "G3",
    Stage: "III",
    BMI: ">=30",
    Alcohol_consumption: "drinker",
    Tobacco_use: "past-smoker",
    Secondhand_smoke_exposure: "not_reported",
    Overall_survival_in_days: ">=1016 (>=75%)",
    Recurrence_status: "No",
    Survival_status: "Alive",
    caseId: "C3N-00437",
    Immune_subtype: "CD8+/IFNG+",
  },
  {
    Tumor: "HGSC",
    Sex: "Female",
    Age: "63-70 (50%-75%)",
    Participant_Country: "not_reported",
    Grade: "G3",
    Stage: "III",
    BMI: "not_reported",
    Alcohol_consumption: "not_reported",
    Tobacco_use: "not_reported",
    Secondhand_smoke_exposure: "not_reported",
    Overall_survival_in_days: "<348 (25%)",
    Recurrence_status: "No",
    Survival_status: "Dead",
    caseId: "26OV011",
    Immune_subtype: "Fibroblast/TGFBeta",
  },
  {
    Tumor: "HGSC",
    Sex: "Female",
    Age: "55-63 (25%-50%)",
    Participant_Country: "not_reported",
    Grade: "G3",
    Stage: "III",
    BMI: "not_reported",
    Alcohol_consumption: "not_reported",
    Tobacco_use: "not_reported",
    Secondhand_smoke_exposure: "not_reported",
    Overall_survival_in_days: ">=1016 (>=75%)",
    Recurrence_status: "No",
    Survival_status: "Dead",
    caseId: "04OV049",
    Immune_subtype: "Fibroblast/TGFBeta",
  },
  {
    Tumor: "LSCC",
    Sex: "Male",
    Age: "55-63 (25%-50%)",
    Participant_Country: "China",
    Grade: "G2",
    Stage: "I",
    BMI: "25-30",
    Alcohol_consumption: "drinker",
    Tobacco_use: "current smoker",
    Secondhand_smoke_exposure: "not_reported",
    Overall_survival_in_days: "609-1016 (50%-75%)",
    Recurrence_status: "No",
    Survival_status: "Dead",
    caseId: "C3N-03204",
    Immune_subtype: "CD8-/IFNG-",
  },
  {
    Tumor: "ccRCC",
    Sex: "Male",
    Age: "<55 (25%)",
    Participant_Country: "Poland",
    Grade: "G2",
    Stage: "III",
    BMI: ">=30",
    Alcohol_consumption: "past-drinker",
    Tobacco_use: "past-smoker",
    Secondhand_smoke_exposure: "not_reported",
    Overall_survival_in_days: ">=1016 (>=75%)",
    Recurrence_status: "No",
    Survival_status: "Dead",
    caseId: "C3N-01649",
    Immune_subtype: "ccRCC/Endothelial",
  },
  {
    Tumor: "COAD",
    Sex: "Male",
    Age: "<55 (25%)",
    Participant_Country: "not_reported",
    Grade: "not_reported",
    Stage: "II",
    BMI: "not_reported",
    Alcohol_consumption: "not_reported",
    Tobacco_use: "not_reported",
    Secondhand_smoke_exposure: "not_reported",
    Overall_survival_in_days: "348-609 (25%-50%)",
    Recurrence_status: "No",
    Survival_status: "Dead",
    caseId: "11CO033",
    Immune_subtype: "Eosinophils/Endothelial",
  },
  {
    Tumor: "PDAC",
    Sex: "Female",
    Age: ">=70 (>=75%)",
    Participant_Country: "Poland",
    Grade: "G2",
    Stage: "III",
    BMI: ">=30",
    Alcohol_consumption: "non-drinker",
    Tobacco_use: "past-smoker",
    Secondhand_smoke_exposure: "No",
    Overall_survival_in_days: "609-1016 (50%-75%)",
    Recurrence_status: "Yes",
    Survival_status: "Alive",
    caseId: "C3N-01383",
    Immune_subtype: "Eosinophils/Endothelial",
  },
  {
    Tumor: "UCEC",
    Sex: "Female",
    Age: "63-70 (50%-75%)",
    Participant_Country: "United States",
    Grade: "G1",
    Stage: "I",
    BMI: ">=30",
    Alcohol_consumption: "not_reported",
    Tobacco_use: "past-smoker",
    Secondhand_smoke_exposure: "not_reported",
    Overall_survival_in_days: "609-1016 (50%-75%)",
    Recurrence_status: "No",
    Survival_status: "Dead",
    caseId: "C3L-00006",
    Immune_subtype: "CD8-/IFNG-",
  },
  {
    Tumor: "BRCA",
    Sex: "Female",
    Age: "<55 (25%)",
    Participant_Country: "not_reported",
    Grade: "not_reported",
    Stage: "II",
    BMI: "not_reported",
    Alcohol_consumption: "not_reported",
    Tobacco_use: "not_reported",
    Secondhand_smoke_exposure: "not_reported",
    Overall_survival_in_days: "348-609 (25%-50%)",
    Recurrence_status: "No",
    Survival_status: "Dead",
    caseId: "11BR036",
    Immune_subtype: "CD8-/IFNG-",
  },
  {
    Tumor: "LSCC",
    Sex: "Male",
    Age: ">=70 (>=75%)",
    Participant_Country: "United States",
    Grade: "G2",
    Stage: "I",
    BMI: "18.5-25",
    Alcohol_consumption: "not_reported",
    Tobacco_use: "past-smoker",
    Secondhand_smoke_exposure: "not_reported",
    Overall_survival_in_days: ">=1016 (>=75%)",
    Recurrence_status: "No",
    Survival_status: "Dead",
    caseId: "C3L-02546",
    Immune_subtype: "Eosinophils/Endothelial",
  },
  {
    Tumor: "LUAD",
    Sex: "Male",
    Age: "<55 (25%)",
    Participant_Country: "Vietnam",
    Grade: "G2",
    Stage: "I",
    BMI: "18.5-25",
    Alcohol_consumption: "drinker",
    Tobacco_use: "non-smoker",
    Secondhand_smoke_exposure: "No",
    Overall_survival_in_days: "348-609 (25%-50%)",
    Recurrence_status: "Yes",
    Survival_status: "Dead",
    caseId: "C3N-00574",
    Immune_subtype: "Fibroblast/TGFBeta",
  },
  {
    Tumor: "UCEC",
    Sex: "Female",
    Age: ">=70 (>=75%)",
    Participant_Country: "United States",
    Grade: "G3",
    Stage: "II",
    BMI: "18.5-25",
    Alcohol_consumption: "non-drinker",
    Tobacco_use: "non-smoker",
    Secondhand_smoke_exposure: "not_reported",
    Overall_survival_in_days: "609-1016 (50%-75%)",
    Recurrence_status: "Yes",
    Survival_status: "Dead",
    caseId: "C3N-01802",
    Immune_subtype: "Fibroblast/TGFBeta",
  },
  {
    Tumor: "HNSCC",
    Sex: "Male",
    Age: "<55 (25%)",
    Participant_Country: "Ukraine",
    Grade: "G2",
    Stage: "II",
    BMI: "25-30",
    Alcohol_consumption: "non-drinker",
    Tobacco_use: "non-smoker",
    Secondhand_smoke_exposure: "not_reported",
    Overall_survival_in_days: "609-1016 (50%-75%)",
    Recurrence_status: "Yes",
    Survival_status: "Dead",
    caseId: "C3N-02714",
    Immune_subtype: "CD8+/IFNG+",
  },
  {
    Tumor: "PDAC",
    Sex: "Male",
    Age: ">=70 (>=75%)",
    Participant_Country: "Canada",
    Grade: "G2",
    Stage: "II",
    BMI: "18.5-25",
    Alcohol_consumption: "drinker",
    Tobacco_use: "current smoker",
    Secondhand_smoke_exposure: "not_reported",
    Overall_survival_in_days: "<348 (25%)",
    Recurrence_status: "Yes",
    Survival_status: "Alive",
    caseId: "C3L-02610",
    Immune_subtype: "CD8-/IFNG-",
  },
  {
    Tumor: "COAD",
    Sex: "Female",
    Age: "not_reported",
    Participant_Country: "not_reported",
    Grade: "not_reported",
    Stage: "III",
    BMI: "not_reported",
    Alcohol_consumption: "not_reported",
    Tobacco_use: "not_reported",
    Secondhand_smoke_exposure: "not_reported",
    Overall_survival_in_days: "348-609 (25%-50%)",
    Recurrence_status: "No",
    Survival_status: "Dead",
    caseId: "05CO002",
    Immune_subtype: "CD8-/IFNG-",
  },
  {
    Tumor: "GBM",
    Sex: "Female",
    Age: "<55 (25%)",
    Participant_Country: "China",
    Grade: "not_reported",
    Stage: "not_reported",
    BMI: "18.5-25",
    Alcohol_consumption: "non-drinker",
    Tobacco_use: "non-smoker",
    Secondhand_smoke_exposure: "No",
    Overall_survival_in_days: "609-1016 (50%-75%)",
    Recurrence_status: "Yes",
    Survival_status: "Alive",
    caseId: "C3N-03186",
    Immune_subtype: "Brain/Neuro",
  },
  {
    Tumor: "PDAC",
    Sex: "Male",
    Age: "63-70 (50%-75%)",
    Participant_Country: "China",
    Grade: "G2",
    Stage: "II",
    BMI: "18.5-25",
    Alcohol_consumption: "non-drinker",
    Tobacco_use: "non-smoker",
    Secondhand_smoke_exposure: "not_reported",
    Overall_survival_in_days: "609-1016 (50%-75%)",
    Recurrence_status: "No",
    Survival_status: "Dead",
    caseId: "C3N-03086",
    Immune_subtype: "CD8-/IFNG-",
  },
  {
    Tumor: "HNSCC",
    Sex: "Male",
    Age: "<55 (25%)",
    Participant_Country: "China",
    Grade: "G1",
    Stage: "IV",
    BMI: "18.5-25",
    Alcohol_consumption: "non-drinker",
    Tobacco_use: "non-smoker",
    Secondhand_smoke_exposure: "not_reported",
    Overall_survival_in_days: "<348 (25%)",
    Recurrence_status: "Yes",
    Survival_status: "Dead",
    caseId: "C3N-03849",
    Immune_subtype: "CD8-/IFNG-",
  },
  {
    Tumor: "BRCA",
    Sex: "Female",
    Age: "63-70 (50%-75%)",
    Participant_Country: "not_reported",
    Grade: "not_reported",
    Stage: "II",
    BMI: "not_reported",
    Alcohol_consumption: "not_reported",
    Tobacco_use: "not_reported",
    Secondhand_smoke_exposure: "not_reported",
    Overall_survival_in_days: "348-609 (25%-50%)",
    Recurrence_status: "No",
    Survival_status: "Dead",
    caseId: "05BR001",
    Immune_subtype: "CD8+/IFNG+",
  },
  {
    Tumor: "GBM",
    Sex: "Female",
    Age: ">=70 (>=75%)",
    Participant_Country: "Poland",
    Grade: "not_reported",
    Stage: "not_reported",
    BMI: "25-30",
    Alcohol_consumption: "non-drinker",
    Tobacco_use: "current smoker",
    Secondhand_smoke_exposure: "No",
    Overall_survival_in_days: "<348 (25%)",
    Recurrence_status: "No",
    Survival_status: "Alive",
    caseId: "C3N-00662",
    Immune_subtype: "CD8-/IFNG-",
  },
  {
    Tumor: "UCEC",
    Sex: "Female",
    Age: "<55 (25%)",
    Participant_Country: "Ukraine",
    Grade: "G1",
    Stage: "I",
    BMI: ">=30",
    Alcohol_consumption: "non-drinker",
    Tobacco_use: "non-smoker",
    Secondhand_smoke_exposure: "not_reported",
    Overall_survival_in_days: "609-1016 (50%-75%)",
    Recurrence_status: "No",
    Survival_status: "Dead",
    caseId: "C3N-00326",
    Immune_subtype: "CD8-/IFNG-",
  },
  {
    Tumor: "PDAC",
    Sex: "Male",
    Age: "<55 (25%)",
    Participant_Country: "China",
    Grade: "G2",
    Stage: "II",
    BMI: "18.5-25",
    Alcohol_consumption: "non-drinker",
    Tobacco_use: "non-smoker",
    Secondhand_smoke_exposure: "not_reported",
    Overall_survival_in_days: "<348 (25%)",
    Recurrence_status: "Yes",
    Survival_status: "Alive",
    caseId: "C3N-04119",
    Immune_subtype: "Fibroblast/TGFBeta",
  },
  {
    Tumor: "ccRCC",
    Sex: "Male",
    Age: ">=70 (>=75%)",
    Participant_Country: "United States",
    Grade: "G2",
    Stage: "III",
    BMI: ">=30",
    Alcohol_consumption: "non-drinker",
    Tobacco_use: "past-smoker",
    Secondhand_smoke_exposure: "not_reported",
    Overall_survival_in_days: "609-1016 (50%-75%)",
    Recurrence_status: "No",
    Survival_status: "Alive",
    caseId: "C3L-00088",
    Immune_subtype: "ccRCC/Endothelial",
  },
  {
    Tumor: "HNSCC",
    Sex: "Male",
    Age: "55-63 (25%-50%)",
    Participant_Country: "not_reported",
    Grade: "G2",
    Stage: "IV",
    BMI: ">=30",
    Alcohol_consumption: "drinker",
    Tobacco_use: "current smoker",
    Secondhand_smoke_exposure: "not_reported",
    Overall_survival_in_days: "<348 (25%)",
    Recurrence_status: "No",
    Survival_status: "Dead",
    caseId: "C3L-04849",
    Immune_subtype: "CD8-/IFNG-",
  },
  {
    Tumor: "UCEC",
    Sex: "Female",
    Age: "63-70 (50%-75%)",
    Participant_Country: "United States",
    Grade: "G2",
    Stage: "I",
    BMI: ">=30",
    Alcohol_consumption: "not_reported",
    Tobacco_use: "non-smoker",
    Secondhand_smoke_exposure: "not_reported",
    Overall_survival_in_days: ">=1016 (>=75%)",
    Recurrence_status: "Yes",
    Survival_status: "Dead",
    caseId: "C3L-01275",
    Immune_subtype: "Fibroblast/TGFBeta",
  },
  {
    Tumor: "BRCA",
    Sex: "Female",
    Age: "55-63 (25%-50%)",
    Participant_Country: "not_reported",
    Grade: "not_reported",
    Stage: "II",
    BMI: "not_reported",
    Alcohol_consumption: "not_reported",
    Tobacco_use: "not_reported",
    Secondhand_smoke_exposure: "not_reported",
    Overall_survival_in_days: "not_reported",
    Recurrence_status: "No",
    Survival_status: "not_reported",
    caseId: "01BR042",
    Immune_subtype: "CD8+/IFNG+",
  },
  {
    Tumor: "UCEC",
    Sex: "Female",
    Age: "63-70 (50%-75%)",
    Participant_Country: "Ukraine",
    Grade: "G2",
    Stage: "I",
    BMI: ">=30",
    Alcohol_consumption: "non-drinker",
    Tobacco_use: "non-smoker",
    Secondhand_smoke_exposure: "not_reported",
    Overall_survival_in_days: "609-1016 (50%-75%)",
    Recurrence_status: "No",
    Survival_status: "Dead",
    caseId: "C3N-00848",
    Immune_subtype: "CD8-/IFNG-",
  },
  {
    Tumor: "LSCC",
    Sex: "Male",
    Age: "63-70 (50%-75%)",
    Participant_Country: "Vietnam",
    Grade: "G3",
    Stage: "I",
    BMI: "<18.5",
    Alcohol_consumption: "non-drinker",
    Tobacco_use: "non-smoker",
    Secondhand_smoke_exposure: "No",
    Overall_survival_in_days: "348-609 (25%-50%)",
    Recurrence_status: "Yes",
    Survival_status: "Dead",
    caseId: "C3N-01411",
    Immune_subtype: "CD8-/IFNG-",
  },
  {
    Tumor: "ccRCC",
    Sex: "Male",
    Age: ">=70 (>=75%)",
    Participant_Country: "United States",
    Grade: "G3",
    Stage: "II",
    BMI: ">=30",
    Alcohol_consumption: "drinker",
    Tobacco_use: "past-smoker",
    Secondhand_smoke_exposure: "not_reported",
    Overall_survival_in_days: ">=1016 (>=75%)",
    Recurrence_status: "No",
    Survival_status: "Dead",
    caseId: "C3L-00360",
    Immune_subtype: "CD8+/IFNG+",
  },
  {
    Tumor: "COAD",
    Sex: "Female",
    Age: ">=70 (>=75%)",
    Participant_Country: "not_reported",
    Grade: "not_reported",
    Stage: "III",
    BMI: "not_reported",
    Alcohol_consumption: "not_reported",
    Tobacco_use: "not_reported",
    Secondhand_smoke_exposure: "not_reported",
    Overall_survival_in_days: "<348 (25%)",
    Recurrence_status: "No",
    Survival_status: "Dead",
    caseId: "06CO002",
    Immune_subtype: "Fibroblast/TGFBeta",
  },
  {
    Tumor: "UCEC",
    Sex: "Female",
    Age: "55-63 (25%-50%)",
    Participant_Country: "Ukraine",
    Grade: "G1",
    Stage: "I",
    BMI: ">=30",
    Alcohol_consumption: "non-drinker",
    Tobacco_use: "non-smoker",
    Secondhand_smoke_exposure: "not_reported",
    Overall_survival_in_days: "609-1016 (50%-75%)",
    Recurrence_status: "No",
    Survival_status: "Dead",
    caseId: "C3N-01217",
    Immune_subtype: "Fibroblast/TGFBeta",
  },
  {
    Tumor: "BRCA",
    Sex: "Female",
    Age: "55-63 (25%-50%)",
    Participant_Country: "not_reported",
    Grade: "not_reported",
    Stage: "II",
    BMI: "not_reported",
    Alcohol_consumption: "not_reported",
    Tobacco_use: "not_reported",
    Secondhand_smoke_exposure: "not_reported",
    Overall_survival_in_days: "348-609 (25%-50%)",
    Recurrence_status: "No",
    Survival_status: "Dead",
    caseId: "11BR023",
    Immune_subtype: "CD8+/IFNG+",
  },
  {
    Tumor: "LSCC",
    Sex: "Male",
    Age: ">=70 (>=75%)",
    Participant_Country: "Bulgaria",
    Grade: "G2",
    Stage: "III",
    BMI: "25-30",
    Alcohol_consumption: "non-drinker",
    Tobacco_use: "current smoker",
    Secondhand_smoke_exposure: "not_reported",
    Overall_survival_in_days: ">=1016 (>=75%)",
    Recurrence_status: "No",
    Survival_status: "Alive",
    caseId: "C3L-02646",
    Immune_subtype: "Fibroblast/TGFBeta",
  },
  {
    Tumor: "LSCC",
    Sex: "Male",
    Age: ">=70 (>=75%)",
    Participant_Country: "Bulgaria",
    Grade: "G3",
    Stage: "II",
    BMI: "25-30",
    Alcohol_consumption: "drinker",
    Tobacco_use: "current smoker",
    Secondhand_smoke_exposure: "Yes",
    Overall_survival_in_days: "609-1016 (50%-75%)",
    Recurrence_status: "No",
    Survival_status: "Dead",
    caseId: "C3L-03963",
    Immune_subtype: "CD8+/IFNG+",
  },
  {
    Tumor: "LSCC",
    Sex: "Male",
    Age: ">=70 (>=75%)",
    Participant_Country: "Bulgaria",
    Grade: "G3",
    Stage: "II",
    BMI: "25-30",
    Alcohol_consumption: "drinker",
    Tobacco_use: "past-smoker",
    Secondhand_smoke_exposure: "No",
    Overall_survival_in_days: "<348 (25%)",
    Recurrence_status: "No",
    Survival_status: "Alive",
    caseId: "C3L-02648",
    Immune_subtype: "Eosinophils/Endothelial",
  },
  {
    Tumor: "UCEC",
    Sex: "Female",
    Age: "55-63 (25%-50%)",
    Participant_Country: "United States",
    Grade: "G1",
    Stage: "I",
    BMI: ">=30",
    Alcohol_consumption: "drinker",
    Tobacco_use: "not_reported",
    Secondhand_smoke_exposure: "not_reported",
    Overall_survival_in_days: ">=1016 (>=75%)",
    Recurrence_status: "No",
    Survival_status: "Dead",
    caseId: "C3L-01312",
    Immune_subtype: "Fibroblast/TGFBeta",
  },
  {
    Tumor: "BRCA",
    Sex: "Female",
    Age: ">=70 (>=75%)",
    Participant_Country: "not_reported",
    Grade: "not_reported",
    Stage: "III",
    BMI: "not_reported",
    Alcohol_consumption: "not_reported",
    Tobacco_use: "not_reported",
    Secondhand_smoke_exposure: "not_reported",
    Overall_survival_in_days: "<348 (25%)",
    Recurrence_status: "No",
    Survival_status: "Dead",
    caseId: "20BR008",
    Immune_subtype: "Fibroblast/TGFBeta",
  },
  {
    Tumor: "LSCC",
    Sex: "Male",
    Age: "63-70 (50%-75%)",
    Participant_Country: "Bulgaria",
    Grade: "G2",
    Stage: "II",
    BMI: "25-30",
    Alcohol_consumption: "drinker",
    Tobacco_use: "current smoker",
    Secondhand_smoke_exposure: "Yes",
    Overall_survival_in_days: ">=1016 (>=75%)",
    Recurrence_status: "No",
    Survival_status: "Dead",
    caseId: "C3L-02127",
    Immune_subtype: "CD8-/IFNG-",
  },
  {
    Tumor: "ccRCC",
    Sex: "Male",
    Age: ">=70 (>=75%)",
    Participant_Country: "Poland",
    Grade: "G2",
    Stage: "III",
    BMI: "25-30",
    Alcohol_consumption: "drinker",
    Tobacco_use: "past-smoker",
    Secondhand_smoke_exposure: "No",
    Overall_survival_in_days: ">=1016 (>=75%)",
    Recurrence_status: "No",
    Survival_status: "Alive",
    caseId: "C3N-00310",
    Immune_subtype: "ccRCC/Endothelial",
  },
  {
    Tumor: "HGSC",
    Sex: "Female",
    Age: "55-63 (25%-50%)",
    Participant_Country: "not_reported",
    Grade: "G3",
    Stage: "III",
    BMI: "not_reported",
    Alcohol_consumption: "not_reported",
    Tobacco_use: "not_reported",
    Secondhand_smoke_exposure: "not_reported",
    Overall_survival_in_days: "609-1016 (50%-75%)",
    Recurrence_status: "No",
    Survival_status: "Alive",
    caseId: "04OV055",
    Immune_subtype: "CD8-/IFNG+",
  },
  {
    Tumor: "LUAD",
    Sex: "Male",
    Age: "55-63 (25%-50%)",
    Participant_Country: "Vietnam",
    Grade: "G2",
    Stage: "I",
    BMI: "18.5-25",
    Alcohol_consumption: "drinker",
    Tobacco_use: "current smoker",
    Secondhand_smoke_exposure: "No",
    Overall_survival_in_days: "348-609 (25%-50%)",
    Recurrence_status: "No",
    Survival_status: "Dead",
    caseId: "C3N-00580",
    Immune_subtype: "CD8-/IFNG-",
  },
  {
    Tumor: "LUAD",
    Sex: "Female",
    Age: ">=70 (>=75%)",
    Participant_Country: "China",
    Grade: "G3",
    Stage: "II",
    BMI: "18.5-25",
    Alcohol_consumption: "non-drinker",
    Tobacco_use: "non-smoker",
    Secondhand_smoke_exposure: "not_reported",
    Overall_survival_in_days: "609-1016 (50%-75%)",
    Recurrence_status: "No",
    Survival_status: "Alive",
    caseId: "C3N-02529",
    Immune_subtype: "CD8+/IFNG+",
  },
  {
    Tumor: "LSCC",
    Sex: "Male",
    Age: "55-63 (25%-50%)",
    Participant_Country: "Vietnam",
    Grade: "G2",
    Stage: "II",
    BMI: "18.5-25",
    Alcohol_consumption: "drinker",
    Tobacco_use: "current smoker",
    Secondhand_smoke_exposure: "No",
    Overall_survival_in_days: "348-609 (25%-50%)",
    Recurrence_status: "No",
    Survival_status: "Dead",
    caseId: "C3N-01017",
    Immune_subtype: "Eosinophils/Endothelial",
  },
  {
    Tumor: "LUAD",
    Sex: "Male",
    Age: ">=70 (>=75%)",
    Participant_Country: "Vietnam",
    Grade: "G3",
    Stage: "I",
    BMI: "18.5-25",
    Alcohol_consumption: "non-drinker",
    Tobacco_use: "non-smoker",
    Secondhand_smoke_exposure: "No",
    Overall_survival_in_days: "348-609 (25%-50%)",
    Recurrence_status: "No",
    Survival_status: "Dead",
    caseId: "C3N-02089",
    Immune_subtype: "CD8-/IFNG+",
  },
  {
    Tumor: "PDAC",
    Sex: "Male",
    Age: "63-70 (50%-75%)",
    Participant_Country: "Poland",
    Grade: "G2",
    Stage: "IV",
    BMI: "18.5-25",
    Alcohol_consumption: "drinker",
    Tobacco_use: "past-smoker",
    Secondhand_smoke_exposure: "not_reported",
    Overall_survival_in_days: "348-609 (25%-50%)",
    Recurrence_status: "No",
    Survival_status: "Alive",
    caseId: "C3N-01380",
    Immune_subtype: "Eosinophils/Endothelial",
  },
  {
    Tumor: "UCEC",
    Sex: "Female",
    Age: "55-63 (25%-50%)",
    Participant_Country: "United States",
    Grade: "G1",
    Stage: "I",
    BMI: "25-30",
    Alcohol_consumption: "non-drinker",
    Tobacco_use: "non-smoker",
    Secondhand_smoke_exposure: "not_reported",
    Overall_survival_in_days: ">=1016 (>=75%)",
    Recurrence_status: "No",
    Survival_status: "Dead",
    caseId: "C3L-00156",
    Immune_subtype: "CD8-/IFNG+",
  },
  {
    Tumor: "HGSC",
    Sex: "Female",
    Age: "<55 (25%)",
    Participant_Country: "not_reported",
    Grade: "G3",
    Stage: "III",
    BMI: "not_reported",
    Alcohol_consumption: "not_reported",
    Tobacco_use: "not_reported",
    Secondhand_smoke_exposure: "not_reported",
    Overall_survival_in_days: "<348 (25%)",
    Recurrence_status: "No",
    Survival_status: "Dead",
    caseId: "04OV045",
    Immune_subtype: "Fibroblast/TGFBeta",
  },
  {
    Tumor: "BRCA",
    Sex: "Female",
    Age: "<55 (25%)",
    Participant_Country: "not_reported",
    Grade: "not_reported",
    Stage: "II",
    BMI: "not_reported",
    Alcohol_consumption: "not_reported",
    Tobacco_use: "not_reported",
    Secondhand_smoke_exposure: "not_reported",
    Overall_survival_in_days: "348-609 (25%-50%)",
    Recurrence_status: "No",
    Survival_status: "Dead",
    caseId: "11BR004",
    Immune_subtype: "CD8+/IFNG+",
  },
  {
    Tumor: "BRCA",
    Sex: "Female",
    Age: ">=70 (>=75%)",
    Participant_Country: "not_reported",
    Grade: "not_reported",
    Stage: "II",
    BMI: "not_reported",
    Alcohol_consumption: "not_reported",
    Tobacco_use: "not_reported",
    Secondhand_smoke_exposure: "not_reported",
    Overall_survival_in_days: "348-609 (25%-50%)",
    Recurrence_status: "No",
    Survival_status: "Dead",
    caseId: "03BR004",
    Immune_subtype: "CD8-/IFNG-",
  },
  {
    Tumor: "LSCC",
    Sex: "Male",
    Age: "63-70 (50%-75%)",
    Participant_Country: "China",
    Grade: "G2",
    Stage: "I",
    BMI: "18.5-25",
    Alcohol_consumption: "not_reported",
    Tobacco_use: "past-smoker",
    Secondhand_smoke_exposure: "not_reported",
    Overall_survival_in_days: "348-609 (25%-50%)",
    Recurrence_status: "Yes",
    Survival_status: "Alive",
    caseId: "C3N-02575",
    Immune_subtype: "CD8-/IFNG-",
  },
  {
    Tumor: "BRCA",
    Sex: "Female",
    Age: "<55 (25%)",
    Participant_Country: "not_reported",
    Grade: "not_reported",
    Stage: "II",
    BMI: "not_reported",
    Alcohol_consumption: "not_reported",
    Tobacco_use: "not_reported",
    Secondhand_smoke_exposure: "not_reported",
    Overall_survival_in_days: "348-609 (25%-50%)",
    Recurrence_status: "No",
    Survival_status: "Dead",
    caseId: "11BR060",
    Immune_subtype: "Fibroblast/TGFBeta",
  },
  {
    Tumor: "LSCC",
    Sex: "Male",
    Age: "63-70 (50%-75%)",
    Participant_Country: "Bulgaria",
    Grade: "G1",
    Stage: "II",
    BMI: "25-30",
    Alcohol_consumption: "non-drinker",
    Tobacco_use: "non-smoker",
    Secondhand_smoke_exposure: "No",
    Overall_survival_in_days: ">=1016 (>=75%)",
    Recurrence_status: "No",
    Survival_status: "Dead",
    caseId: "C3L-02168",
    Immune_subtype: "Eosinophils/Endothelial",
  },
  {
    Tumor: "PDAC",
    Sex: "Male",
    Age: "55-63 (25%-50%)",
    Participant_Country: "Poland",
    Grade: "G2",
    Stage: "III",
    BMI: "18.5-25",
    Alcohol_consumption: "drinker",
    Tobacco_use: "current smoker",
    Secondhand_smoke_exposure: "No",
    Overall_survival_in_days: "609-1016 (50%-75%)",
    Recurrence_status: "Yes",
    Survival_status: "Dead",
    caseId: "C3N-03665",
    Immune_subtype: "CD8-/IFNG+",
  },
  {
    Tumor: "ccRCC",
    Sex: "Male",
    Age: ">=70 (>=75%)",
    Participant_Country: "Vietnam",
    Grade: "G3",
    Stage: "IV",
    BMI: "18.5-25",
    Alcohol_consumption: "non-drinker",
    Tobacco_use: "non-smoker",
    Secondhand_smoke_exposure: "No",
    Overall_survival_in_days: "348-609 (25%-50%)",
    Recurrence_status: "No",
    Survival_status: "Dead",
    caseId: "C3N-00577",
    Immune_subtype: "CD8-/IFNG-",
  },
  {
    Tumor: "BRCA",
    Sex: "Female",
    Age: "<55 (25%)",
    Participant_Country: "not_reported",
    Grade: "not_reported",
    Stage: "II",
    BMI: "not_reported",
    Alcohol_consumption: "not_reported",
    Tobacco_use: "not_reported",
    Secondhand_smoke_exposure: "not_reported",
    Overall_survival_in_days: "348-609 (25%-50%)",
    Recurrence_status: "No",
    Survival_status: "Dead",
    caseId: "11BR020",
    Immune_subtype: "CD8-/IFNG+",
  },
  {
    Tumor: "LUAD",
    Sex: "Female",
    Age: "55-63 (25%-50%)",
    Participant_Country: "United States",
    Grade: "G3",
    Stage: "II",
    BMI: "18.5-25",
    Alcohol_consumption: "drinker",
    Tobacco_use: "non-smoker",
    Secondhand_smoke_exposure: "Yes",
    Overall_survival_in_days: ">=1016 (>=75%)",
    Recurrence_status: "No",
    Survival_status: "Dead",
    caseId: "C3L-00001",
    Immune_subtype: "CD8-/IFNG+",
  },
  {
    Tumor: "LSCC",
    Sex: "Male",
    Age: "55-63 (25%-50%)",
    Participant_Country: "Russia",
    Grade: "G2",
    Stage: "II",
    BMI: "18.5-25",
    Alcohol_consumption: "non-drinker",
    Tobacco_use: "past-smoker",
    Secondhand_smoke_exposure: "Yes",
    Overall_survival_in_days: ">=1016 (>=75%)",
    Recurrence_status: "No",
    Survival_status: "Dead",
    caseId: "C3L-00965",
    Immune_subtype: "CD8-/IFNG-",
  },
  {
    Tumor: "UCEC",
    Sex: "Female",
    Age: "55-63 (25%-50%)",
    Participant_Country: "United States",
    Grade: "G3",
    Stage: "I",
    BMI: ">=30",
    Alcohol_consumption: "drinker",
    Tobacco_use: "past-smoker",
    Secondhand_smoke_exposure: "not_reported",
    Overall_survival_in_days: ">=1016 (>=75%)",
    Recurrence_status: "No",
    Survival_status: "Dead",
    caseId: "C3L-01246",
    Immune_subtype: "CD8-/IFNG-",
  },
  {
    Tumor: "ccRCC",
    Sex: "Male",
    Age: "<55 (25%)",
    Participant_Country: "United States",
    Grade: "G2",
    Stage: "I",
    BMI: ">=30",
    Alcohol_consumption: "non-drinker",
    Tobacco_use: "past-smoker",
    Secondhand_smoke_exposure: "not_reported",
    Overall_survival_in_days: ">=1016 (>=75%)",
    Recurrence_status: "No",
    Survival_status: "Dead",
    caseId: "C3L-00907",
    Immune_subtype: "Fibroblast/TGFBeta",
  },
  {
    Tumor: "GBM",
    Sex: "Female",
    Age: "55-63 (25%-50%)",
    Participant_Country: "United States",
    Grade: "not_reported",
    Stage: "not_reported",
    BMI: "18.5-25",
    Alcohol_consumption: "drinker",
    Tobacco_use: "past-smoker",
    Secondhand_smoke_exposure: "not_reported",
    Overall_survival_in_days: "<348 (25%)",
    Recurrence_status: "No",
    Survival_status: "Alive",
    caseId: "C3L-00365",
    Immune_subtype: "CD8-/IFNG+",
  },
  {
    Tumor: "ccRCC",
    Sex: "Male",
    Age: "<55 (25%)",
    Participant_Country: "Vietnam",
    Grade: "G2",
    Stage: "III",
    BMI: "18.5-25",
    Alcohol_consumption: "non-drinker",
    Tobacco_use: "non-smoker",
    Secondhand_smoke_exposure: "No",
    Overall_survival_in_days: "609-1016 (50%-75%)",
    Recurrence_status: "No",
    Survival_status: "Dead",
    caseId: "C3N-00177",
    Immune_subtype: "CD8+/IFNG+",
  },
  {
    Tumor: "BRCA",
    Sex: "Female",
    Age: ">=70 (>=75%)",
    Participant_Country: "not_reported",
    Grade: "not_reported",
    Stage: "II",
    BMI: "not_reported",
    Alcohol_consumption: "not_reported",
    Tobacco_use: "not_reported",
    Secondhand_smoke_exposure: "not_reported",
    Overall_survival_in_days: "<348 (25%)",
    Recurrence_status: "No",
    Survival_status: "Dead",
    caseId: "05BR016",
    Immune_subtype: "CD8-/IFNG-",
  },
  {
    Tumor: "LSCC",
    Sex: "Male",
    Age: "63-70 (50%-75%)",
    Participant_Country: "Vietnam",
    Grade: "G3",
    Stage: "I",
    BMI: "18.5-25",
    Alcohol_consumption: "non-drinker",
    Tobacco_use: "non-smoker",
    Secondhand_smoke_exposure: "No",
    Overall_survival_in_days: "348-609 (25%-50%)",
    Recurrence_status: "No",
    Survival_status: "Dead",
    caseId: "C3N-01020",
    Immune_subtype: "CD8+/IFNG+",
  },
  {
    Tumor: "HNSCC",
    Sex: "Female",
    Age: "<55 (25%)",
    Participant_Country: "Poland",
    Grade: "G2",
    Stage: "IV",
    BMI: "18.5-25",
    Alcohol_consumption: "not_reported",
    Tobacco_use: "not_reported",
    Secondhand_smoke_exposure: "not_reported",
    Overall_survival_in_days: "348-609 (25%-50%)",
    Recurrence_status: "No",
    Survival_status: "Dead",
    caseId: "C3N-01948",
    Immune_subtype: "CD8+/IFNG+",
  },
  {
    Tumor: "COAD",
    Sex: "Male",
    Age: "<55 (25%)",
    Participant_Country: "not_reported",
    Grade: "not_reported",
    Stage: "III",
    BMI: "not_reported",
    Alcohol_consumption: "not_reported",
    Tobacco_use: "not_reported",
    Secondhand_smoke_exposure: "not_reported",
    Overall_survival_in_days: "<348 (25%)",
    Recurrence_status: "No",
    Survival_status: "Dead",
    caseId: "09CO013",
    Immune_subtype: "CD8-/IFNG-",
  },
  {
    Tumor: "COAD",
    Sex: "Male",
    Age: ">=70 (>=75%)",
    Participant_Country: "not_reported",
    Grade: "not_reported",
    Stage: "II",
    BMI: "not_reported",
    Alcohol_consumption: "not_reported",
    Tobacco_use: "not_reported",
    Secondhand_smoke_exposure: "not_reported",
    Overall_survival_in_days: "<348 (25%)",
    Recurrence_status: "No",
    Survival_status: "Dead",
    caseId: "05CO049",
    Immune_subtype: "CD8-/IFNG+",
  },
  {
    Tumor: "BRCA",
    Sex: "Female",
    Age: "<55 (25%)",
    Participant_Country: "not_reported",
    Grade: "not_reported",
    Stage: "II",
    BMI: "not_reported",
    Alcohol_consumption: "not_reported",
    Tobacco_use: "not_reported",
    Secondhand_smoke_exposure: "not_reported",
    Overall_survival_in_days: "348-609 (25%-50%)",
    Recurrence_status: "No",
    Survival_status: "Dead",
    caseId: "03BR013",
    Immune_subtype: "Fibroblast/TGFBeta",
  },
  {
    Tumor: "UCEC",
    Sex: "Female",
    Age: ">=70 (>=75%)",
    Participant_Country: "Poland",
    Grade: "G3",
    Stage: "IV",
    BMI: ">=30",
    Alcohol_consumption: "non-drinker",
    Tobacco_use: "non-smoker",
    Secondhand_smoke_exposure: "No",
    Overall_survival_in_days: "609-1016 (50%-75%)",
    Recurrence_status: "Yes",
    Survival_status: "Alive",
    caseId: "C3N-01003",
    Immune_subtype: "Fibroblast/TGFBeta",
  },
  {
    Tumor: "UCEC",
    Sex: "Female",
    Age: "55-63 (25%-50%)",
    Participant_Country: "Poland",
    Grade: "G2",
    Stage: "I",
    BMI: "25-30",
    Alcohol_consumption: "non-drinker",
    Tobacco_use: "non-smoker",
    Secondhand_smoke_exposure: "No",
    Overall_survival_in_days: ">=1016 (>=75%)",
    Recurrence_status: "No",
    Survival_status: "Dead",
    caseId: "C3N-00880",
    Immune_subtype: "CD8-/IFNG-",
  },
  {
    Tumor: "COAD",
    Sex: "Female",
    Age: ">=70 (>=75%)",
    Participant_Country: "not_reported",
    Grade: "not_reported",
    Stage: "III",
    BMI: "not_reported",
    Alcohol_consumption: "not_reported",
    Tobacco_use: "not_reported",
    Secondhand_smoke_exposure: "not_reported",
    Overall_survival_in_days: "348-609 (25%-50%)",
    Recurrence_status: "No",
    Survival_status: "Dead",
    caseId: "14CO003",
    Immune_subtype: "CD8-/IFNG+",
  },
  {
    Tumor: "GBM",
    Sex: "Female",
    Age: "63-70 (50%-75%)",
    Participant_Country: "United States",
    Grade: "not_reported",
    Stage: "not_reported",
    BMI: "18.5-25",
    Alcohol_consumption: "not_reported",
    Tobacco_use: "not_reported",
    Secondhand_smoke_exposure: "not_reported",
    Overall_survival_in_days: "348-609 (25%-50%)",
    Recurrence_status: "Yes",
    Survival_status: "Alive",
    caseId: "C3L-03748",
    Immune_subtype: "Fibroblast/TGFBeta",
  },
  {
    Tumor: "LUAD",
    Sex: "Female",
    Age: "<55 (25%)",
    Participant_Country: "United States",
    Grade: "G3",
    Stage: "II",
    BMI: ">=30",
    Alcohol_consumption: "drinker",
    Tobacco_use: "non-smoker",
    Secondhand_smoke_exposure: "not_reported",
    Overall_survival_in_days: ">=1016 (>=75%)",
    Recurrence_status: "No",
    Survival_status: "Dead",
    caseId: "C3L-00422",
    Immune_subtype: "Eosinophils/Endothelial",
  },
  {
    Tumor: "ccRCC",
    Sex: "Male",
    Age: "63-70 (50%-75%)",
    Participant_Country: "Poland",
    Grade: "G3",
    Stage: "III",
    BMI: ">=30",
    Alcohol_consumption: "not_reported",
    Tobacco_use: "not_reported",
    Secondhand_smoke_exposure: "not_reported",
    Overall_survival_in_days: ">=1016 (>=75%)",
    Recurrence_status: "Yes",
    Survival_status: "Dead",
    caseId: "C3N-00312",
    Immune_subtype: "CD8-/IFNG-",
  },
  {
    Tumor: "BRCA",
    Sex: "Female",
    Age: "<55 (25%)",
    Participant_Country: "not_reported",
    Grade: "not_reported",
    Stage: "II",
    BMI: "not_reported",
    Alcohol_consumption: "not_reported",
    Tobacco_use: "not_reported",
    Secondhand_smoke_exposure: "not_reported",
    Overall_survival_in_days: "348-609 (25%-50%)",
    Recurrence_status: "No",
    Survival_status: "Dead",
    caseId: "11BR028",
    Immune_subtype: "CD8-/IFNG-",
  },
  {
    Tumor: "UCEC",
    Sex: "Female",
    Age: "63-70 (50%-75%)",
    Participant_Country: "Ukraine",
    Grade: "G2",
    Stage: "I",
    BMI: "25-30",
    Alcohol_consumption: "non-drinker",
    Tobacco_use: "non-smoker",
    Secondhand_smoke_exposure: "not_reported",
    Overall_survival_in_days: "<348 (25%)",
    Recurrence_status: "No",
    Survival_status: "Alive",
    caseId: "C3N-01520",
    Immune_subtype: "CD8-/IFNG-",
  },
  {
    Tumor: "PDAC",
    Sex: "Female",
    Age: "63-70 (50%-75%)",
    Participant_Country: "Poland",
    Grade: "G2",
    Stage: "not_reported",
    BMI: "25-30",
    Alcohol_consumption: "drinker",
    Tobacco_use: "non-smoker",
    Secondhand_smoke_exposure: "No",
    Overall_survival_in_days: "<348 (25%)",
    Recurrence_status: "No",
    Survival_status: "Alive",
    caseId: "C3N-03426",
    Immune_subtype: "CD8+/IFNG+",
  },
  {
    Tumor: "HNSCC",
    Sex: "Male",
    Age: "55-63 (25%-50%)",
    Participant_Country: "Ukraine",
    Grade: "G2",
    Stage: "IV",
    BMI: "18.5-25",
    Alcohol_consumption: "drinker",
    Tobacco_use: "past-smoker",
    Secondhand_smoke_exposure: "not_reported",
    Overall_survival_in_days: "609-1016 (50%-75%)",
    Recurrence_status: "No",
    Survival_status: "Dead",
    caseId: "C3N-02333",
    Immune_subtype: "CD8+/IFNG+",
  },
  {
    Tumor: "PDAC",
    Sex: "Female",
    Age: ">=70 (>=75%)",
    Participant_Country: "Canada",
    Grade: "G3",
    Stage: "II",
    BMI: ">=30",
    Alcohol_consumption: "non-drinker",
    Tobacco_use: "non-smoker",
    Secondhand_smoke_exposure: "not_reported",
    Overall_survival_in_days: "<348 (25%)",
    Recurrence_status: "No",
    Survival_status: "Alive",
    caseId: "C3L-04848",
    Immune_subtype: "CD8+/IFNG+",
  },
  {
    Tumor: "ccRCC",
    Sex: "Male",
    Age: "55-63 (25%-50%)",
    Participant_Country: "United States",
    Grade: "G2",
    Stage: "I",
    BMI: ">=30",
    Alcohol_consumption: "not_reported",
    Tobacco_use: "not_reported",
    Secondhand_smoke_exposure: "not_reported",
    Overall_survival_in_days: "348-609 (25%-50%)",
    Recurrence_status: "No",
    Survival_status: "Dead",
    caseId: "C3L-01553",
    Immune_subtype: "ccRCC/Endothelial",
  },
  {
    Tumor: "HGSC",
    Sex: "Female",
    Age: ">=70 (>=75%)",
    Participant_Country: "not_reported",
    Grade: "G3",
    Stage: "III",
    BMI: "not_reported",
    Alcohol_consumption: "not_reported",
    Tobacco_use: "not_reported",
    Secondhand_smoke_exposure: "not_reported",
    Overall_survival_in_days: "348-609 (25%-50%)",
    Recurrence_status: "No",
    Survival_status: "Dead",
    caseId: "02OV005",
    Immune_subtype: "Fibroblast/TGFBeta",
  },
  {
    Tumor: "UCEC",
    Sex: "Female",
    Age: "63-70 (50%-75%)",
    Participant_Country: "United States",
    Grade: "G2",
    Stage: "I",
    BMI: ">=30",
    Alcohol_consumption: "non-drinker",
    Tobacco_use: "non-smoker",
    Secondhand_smoke_exposure: "not_reported",
    Overall_survival_in_days: ">=1016 (>=75%)",
    Recurrence_status: "No",
    Survival_status: "Dead",
    caseId: "C3L-01249",
    Immune_subtype: "Fibroblast/TGFBeta",
  },
  {
    Tumor: "UCEC",
    Sex: "Female",
    Age: "63-70 (50%-75%)",
    Participant_Country: "United States",
    Grade: "G2",
    Stage: "I",
    BMI: ">=30",
    Alcohol_consumption: "not_reported",
    Tobacco_use: "non-smoker",
    Secondhand_smoke_exposure: "not_reported",
    Overall_survival_in_days: ">=1016 (>=75%)",
    Recurrence_status: "No",
    Survival_status: "Dead",
    caseId: "C3L-00932",
    Immune_subtype: "Fibroblast/TGFBeta",
  },
  {
    Tumor: "GBM",
    Sex: "Male",
    Age: "63-70 (50%-75%)",
    Participant_Country: "Poland",
    Grade: "not_reported",
    Stage: "not_reported",
    BMI: "25-30",
    Alcohol_consumption: "non-drinker",
    Tobacco_use: "non-smoker",
    Secondhand_smoke_exposure: "No",
    Overall_survival_in_days: "<348 (25%)",
    Recurrence_status: "Yes",
    Survival_status: "Alive",
    caseId: "C3N-01368",
    Immune_subtype: "CD8-/IFNG-",
  },
  {
    Tumor: "GBM",
    Sex: "Male",
    Age: "63-70 (50%-75%)",
    Participant_Country: "China",
    Grade: "not_reported",
    Stage: "not_reported",
    BMI: "18.5-25",
    Alcohol_consumption: "drinker",
    Tobacco_use: "current smoker",
    Secondhand_smoke_exposure: "No",
    Overall_survival_in_days: "348-609 (25%-50%)",
    Recurrence_status: "Yes",
    Survival_status: "Alive",
    caseId: "C3N-02785",
    Immune_subtype: "Brain/Neuro",
  },
  {
    Tumor: "PDAC",
    Sex: "Female",
    Age: "55-63 (25%-50%)",
    Participant_Country: "Poland",
    Grade: "G2",
    Stage: "III",
    BMI: "18.5-25",
    Alcohol_consumption: "non-drinker",
    Tobacco_use: "non-smoker",
    Secondhand_smoke_exposure: "No",
    Overall_survival_in_days: "<348 (25%)",
    Recurrence_status: "No",
    Survival_status: "Alive",
    caseId: "C3N-01165",
    Immune_subtype: "Eosinophils/Endothelial",
  },
  {
    Tumor: "LUAD",
    Sex: "Male",
    Age: "55-63 (25%-50%)",
    Participant_Country: "Vietnam",
    Grade: "G2",
    Stage: "II",
    BMI: "<18.5",
    Alcohol_consumption: "drinker",
    Tobacco_use: "current smoker",
    Secondhand_smoke_exposure: "No",
    Overall_survival_in_days: "348-609 (25%-50%)",
    Recurrence_status: "No",
    Survival_status: "Dead",
    caseId: "C3N-00547",
    Immune_subtype: "CD8-/IFNG+",
  },
  {
    Tumor: "LSCC",
    Sex: "Male",
    Age: ">=70 (>=75%)",
    Participant_Country: "United States",
    Grade: "G3",
    Stage: "II",
    BMI: "18.5-25",
    Alcohol_consumption: "not_reported",
    Tobacco_use: "current smoker",
    Secondhand_smoke_exposure: "not_reported",
    Overall_survival_in_days: "609-1016 (50%-75%)",
    Recurrence_status: "Yes",
    Survival_status: "Alive",
    caseId: "C3L-00904",
    Immune_subtype: "Eosinophils/Endothelial",
  },
  {
    Tumor: "ccRCC",
    Sex: "Male",
    Age: "63-70 (50%-75%)",
    Participant_Country: "Poland",
    Grade: "G2",
    Stage: "III",
    BMI: ">=30",
    Alcohol_consumption: "drinker",
    Tobacco_use: "past-smoker",
    Secondhand_smoke_exposure: "not_reported",
    Overall_survival_in_days: ">=1016 (>=75%)",
    Recurrence_status: "No",
    Survival_status: "Dead",
    caseId: "C3N-00320",
    Immune_subtype: "ccRCC/Endothelial",
  },
  {
    Tumor: "UCEC",
    Sex: "Female",
    Age: "63-70 (50%-75%)",
    Participant_Country: "United States",
    Grade: "G3",
    Stage: "IV",
    BMI: "25-30",
    Alcohol_consumption: "non-drinker",
    Tobacco_use: "non-smoker",
    Secondhand_smoke_exposure: "not_reported",
    Overall_survival_in_days: ">=1016 (>=75%)",
    Recurrence_status: "No",
    Survival_status: "Dead",
    caseId: "C3L-01925",
    Immune_subtype: "CD8-/IFNG-",
  },
  {
    Tumor: "UCEC",
    Sex: "Female",
    Age: "63-70 (50%-75%)",
    Participant_Country: "Ukraine",
    Grade: "G2",
    Stage: "II",
    BMI: ">=30",
    Alcohol_consumption: "non-drinker",
    Tobacco_use: "non-smoker",
    Secondhand_smoke_exposure: "not_reported",
    Overall_survival_in_days: "609-1016 (50%-75%)",
    Recurrence_status: "No",
    Survival_status: "Dead",
    caseId: "C3N-00324",
    Immune_subtype: "CD8-/IFNG+",
  },
  {
    Tumor: "UCEC",
    Sex: "Female",
    Age: "63-70 (50%-75%)",
    Participant_Country: "United States",
    Grade: "G1",
    Stage: "I",
    BMI: ">=30",
    Alcohol_consumption: "non-drinker",
    Tobacco_use: "past-smoker",
    Secondhand_smoke_exposure: "not_reported",
    Overall_survival_in_days: ">=1016 (>=75%)",
    Recurrence_status: "No",
    Survival_status: "Dead",
    caseId: "C3L-00143",
    Immune_subtype: "CD8+/IFNG+",
  },
  {
    Tumor: "LSCC",
    Sex: "Male",
    Age: "63-70 (50%-75%)",
    Participant_Country: "Ukraine",
    Grade: "G2",
    Stage: "II",
    BMI: "25-30",
    Alcohol_consumption: "drinker",
    Tobacco_use: "non-smoker",
    Secondhand_smoke_exposure: "not_reported",
    Overall_survival_in_days: "<348 (25%)",
    Recurrence_status: "No",
    Survival_status: "Dead",
    caseId: "C3N-03662",
    Immune_subtype: "CD8-/IFNG-",
  },
  {
    Tumor: "ccRCC",
    Sex: "Male",
    Age: ">=70 (>=75%)",
    Participant_Country: "United States",
    Grade: "G3",
    Stage: "III",
    BMI: "18.5-25",
    Alcohol_consumption: "non-drinker",
    Tobacco_use: "past-smoker",
    Secondhand_smoke_exposure: "not_reported",
    Overall_survival_in_days: "348-609 (25%-50%)",
    Recurrence_status: "No",
    Survival_status: "Dead",
    caseId: "C3L-00004",
    Immune_subtype: "CD8-/IFNG+",
  },
  {
    Tumor: "HNSCC",
    Sex: "Male",
    Age: "63-70 (50%-75%)",
    Participant_Country: "Ukraine",
    Grade: "G2",
    Stage: "III",
    BMI: "25-30",
    Alcohol_consumption: "non-drinker",
    Tobacco_use: "non-smoker",
    Secondhand_smoke_exposure: "not_reported",
    Overall_survival_in_days: "609-1016 (50%-75%)",
    Recurrence_status: "No",
    Survival_status: "Dead",
    caseId: "C3N-03490",
    Immune_subtype: "CD8-/IFNG-",
  },
  {
    Tumor: "ccRCC",
    Sex: "Male",
    Age: "<55 (25%)",
    Participant_Country: "United States",
    Grade: "G2",
    Stage: "II",
    BMI: ">=30",
    Alcohol_consumption: "non-drinker",
    Tobacco_use: "non-smoker",
    Secondhand_smoke_exposure: "not_reported",
    Overall_survival_in_days: ">=1016 (>=75%)",
    Recurrence_status: "Yes",
    Survival_status: "Alive",
    caseId: "C3L-00581",
    Immune_subtype: "Fibroblast/TGFBeta",
  },
  {
    Tumor: "PDAC",
    Sex: "Female",
    Age: "55-63 (25%-50%)",
    Participant_Country: "Canada",
    Grade: "G2",
    Stage: "II",
    BMI: ">=30",
    Alcohol_consumption: "not_reported",
    Tobacco_use: "non-smoker",
    Secondhand_smoke_exposure: "not_reported",
    Overall_survival_in_days: ">=1016 (>=75%)",
    Recurrence_status: "No",
    Survival_status: "Dead",
    caseId: "C3L-03123",
    Immune_subtype: "CD8+/IFNG+",
  },
  {
    Tumor: "ccRCC",
    Sex: "Male",
    Age: "63-70 (50%-75%)",
    Participant_Country: "Poland",
    Grade: "G3",
    Stage: "III",
    BMI: "18.5-25",
    Alcohol_consumption: "drinker",
    Tobacco_use: "current smoker",
    Secondhand_smoke_exposure: "No",
    Overall_survival_in_days: ">=1016 (>=75%)",
    Recurrence_status: "No",
    Survival_status: "Dead",
    caseId: "C3N-01646",
    Immune_subtype: "Fibroblast/TGFBeta",
  },
  {
    Tumor: "GBM",
    Sex: "Female",
    Age: "<55 (25%)",
    Participant_Country: "China",
    Grade: "not_reported",
    Stage: "not_reported",
    BMI: "18.5-25",
    Alcohol_consumption: "non-drinker",
    Tobacco_use: "non-smoker",
    Secondhand_smoke_exposure: "not_reported",
    Overall_survival_in_days: "<348 (25%)",
    Recurrence_status: "No",
    Survival_status: "Alive",
    caseId: "C3N-03088",
    Immune_subtype: "Brain/Neuro",
  },
  {
    Tumor: "LSCC",
    Sex: "Male",
    Age: ">=70 (>=75%)",
    Participant_Country: "United States",
    Grade: "G3",
    Stage: "I",
    BMI: ">=30",
    Alcohol_consumption: "not_reported",
    Tobacco_use: "past-smoker",
    Secondhand_smoke_exposure: "not_reported",
    Overall_survival_in_days: ">=1016 (>=75%)",
    Recurrence_status: "No",
    Survival_status: "Dead",
    caseId: "C3L-00603",
    Immune_subtype: "CD8-/IFNG-",
  },
  {
    Tumor: "HGSC",
    Sex: "Female",
    Age: ">=70 (>=75%)",
    Participant_Country: "not_reported",
    Grade: "G3",
    Stage: "III",
    BMI: "not_reported",
    Alcohol_consumption: "not_reported",
    Tobacco_use: "not_reported",
    Secondhand_smoke_exposure: "not_reported",
    Overall_survival_in_days: "<348 (25%)",
    Recurrence_status: "No",
    Survival_status: "Dead",
    caseId: "01OV030",
    Immune_subtype: "ccRCC/Endothelial",
  },
  {
    Tumor: "UCEC",
    Sex: "Female",
    Age: ">=70 (>=75%)",
    Participant_Country: "Poland",
    Grade: "G3",
    Stage: "I",
    BMI: ">=30",
    Alcohol_consumption: "non-drinker",
    Tobacco_use: "non-smoker",
    Secondhand_smoke_exposure: "No",
    Overall_survival_in_days: "348-609 (25%-50%)",
    Recurrence_status: "Yes",
    Survival_status: "Alive",
    caseId: "C3N-01349",
    Immune_subtype: "CD8-/IFNG+",
  },
  {
    Tumor: "LUAD",
    Sex: "Male",
    Age: "55-63 (25%-50%)",
    Participant_Country: "Vietnam",
    Grade: "G2",
    Stage: "I",
    BMI: "18.5-25",
    Alcohol_consumption: "drinker",
    Tobacco_use: "current smoker",
    Secondhand_smoke_exposure: "No",
    Overall_survival_in_days: "348-609 (25%-50%)",
    Recurrence_status: "No",
    Survival_status: "Dead",
    caseId: "C3N-00169",
    Immune_subtype: "Fibroblast/TGFBeta",
  },
  {
    Tumor: "HGSC",
    Sex: "Female",
    Age: ">=70 (>=75%)",
    Participant_Country: "not_reported",
    Grade: "G3",
    Stage: "III",
    BMI: "not_reported",
    Alcohol_consumption: "not_reported",
    Tobacco_use: "not_reported",
    Secondhand_smoke_exposure: "not_reported",
    Overall_survival_in_days: "348-609 (25%-50%)",
    Recurrence_status: "No",
    Survival_status: "Dead",
    caseId: "26OV013",
    Immune_subtype: "CD8-/IFNG-",
  },
  {
    Tumor: "LSCC",
    Sex: "Male",
    Age: "63-70 (50%-75%)",
    Participant_Country: "Poland",
    Grade: "G3",
    Stage: "III",
    BMI: "18.5-25",
    Alcohol_consumption: "drinker",
    Tobacco_use: "past-smoker",
    Secondhand_smoke_exposure: "not_reported",
    Overall_survival_in_days: ">=1016 (>=75%)",
    Recurrence_status: "No",
    Survival_status: "Dead",
    caseId: "C3N-03441",
    Immune_subtype: "Eosinophils/Endothelial",
  },
  {
    Tumor: "COAD",
    Sex: "Male",
    Age: "<55 (25%)",
    Participant_Country: "not_reported",
    Grade: "not_reported",
    Stage: "IV",
    BMI: "not_reported",
    Alcohol_consumption: "not_reported",
    Tobacco_use: "not_reported",
    Secondhand_smoke_exposure: "not_reported",
    Overall_survival_in_days: "<348 (25%)",
    Recurrence_status: "No",
    Survival_status: "Dead",
    caseId: "21CO007",
    Immune_subtype: "CD8-/IFNG-",
  },
  {
    Tumor: "BRCA",
    Sex: "Female",
    Age: "55-63 (25%-50%)",
    Participant_Country: "not_reported",
    Grade: "not_reported",
    Stage: "II",
    BMI: "not_reported",
    Alcohol_consumption: "not_reported",
    Tobacco_use: "not_reported",
    Secondhand_smoke_exposure: "not_reported",
    Overall_survival_in_days: "348-609 (25%-50%)",
    Recurrence_status: "No",
    Survival_status: "Dead",
    caseId: "18BR006",
    Immune_subtype: "Fibroblast/TGFBeta",
  },
  {
    Tumor: "UCEC",
    Sex: "Female",
    Age: "<55 (25%)",
    Participant_Country: "United States",
    Grade: "G2",
    Stage: "I",
    BMI: ">=30",
    Alcohol_consumption: "non-drinker",
    Tobacco_use: "past-smoker",
    Secondhand_smoke_exposure: "not_reported",
    Overall_survival_in_days: ">=1016 (>=75%)",
    Recurrence_status: "No",
    Survival_status: "Dead",
    caseId: "C3L-00032",
    Immune_subtype: "Fibroblast/TGFBeta",
  },
  {
    Tumor: "UCEC",
    Sex: "Female",
    Age: "55-63 (25%-50%)",
    Participant_Country: "Ukraine",
    Grade: "G1",
    Stage: "I",
    BMI: ">=30",
    Alcohol_consumption: "non-drinker",
    Tobacco_use: "non-smoker",
    Secondhand_smoke_exposure: "not_reported",
    Overall_survival_in_days: "609-1016 (50%-75%)",
    Recurrence_status: "No",
    Survival_status: "Dead",
    caseId: "C3N-00383",
    Immune_subtype: "CD8-/IFNG-",
  },
  {
    Tumor: "BRCA",
    Sex: "Female",
    Age: "<55 (25%)",
    Participant_Country: "not_reported",
    Grade: "not_reported",
    Stage: "III",
    BMI: "not_reported",
    Alcohol_consumption: "not_reported",
    Tobacco_use: "not_reported",
    Secondhand_smoke_exposure: "not_reported",
    Overall_survival_in_days: "348-609 (25%-50%)",
    Recurrence_status: "No",
    Survival_status: "Dead",
    caseId: "11BR018",
    Immune_subtype: "Fibroblast/TGFBeta",
  },
  {
    Tumor: "LUAD",
    Sex: "Male",
    Age: "55-63 (25%-50%)",
    Participant_Country: "China",
    Grade: "G2",
    Stage: "II",
    BMI: "25-30",
    Alcohol_consumption: "drinker",
    Tobacco_use: "past-smoker",
    Secondhand_smoke_exposure: "not_reported",
    Overall_survival_in_days: ">=1016 (>=75%)",
    Recurrence_status: "Yes",
    Survival_status: "Dead",
    caseId: "C3N-02380",
    Immune_subtype: "CD8-/IFNG-",
  },
  {
    Tumor: "ccRCC",
    Sex: "Female",
    Age: "63-70 (50%-75%)",
    Participant_Country: "United States",
    Grade: "G1",
    Stage: "I",
    BMI: ">=30",
    Alcohol_consumption: "non-drinker",
    Tobacco_use: "current smoker",
    Secondhand_smoke_exposure: "not_reported",
    Overall_survival_in_days: ">=1016 (>=75%)",
    Recurrence_status: "Yes",
    Survival_status: "Dead",
    caseId: "C3L-01288",
    Immune_subtype: "ccRCC/Endothelial",
  },
  {
    Tumor: "HNSCC",
    Sex: "Female",
    Age: "<55 (25%)",
    Participant_Country: "Poland",
    Grade: "G1",
    Stage: "IV",
    BMI: "18.5-25",
    Alcohol_consumption: "drinker",
    Tobacco_use: "current smoker",
    Secondhand_smoke_exposure: "Yes",
    Overall_survival_in_days: ">=1016 (>=75%)",
    Recurrence_status: "No",
    Survival_status: "Dead",
    caseId: "C3N-03782",
    Immune_subtype: "CD8-/IFNG-",
  },
  {
    Tumor: "COAD",
    Sex: "Female",
    Age: "63-70 (50%-75%)",
    Participant_Country: "not_reported",
    Grade: "not_reported",
    Stage: "II",
    BMI: "not_reported",
    Alcohol_consumption: "not_reported",
    Tobacco_use: "not_reported",
    Secondhand_smoke_exposure: "not_reported",
    Overall_survival_in_days: "348-609 (25%-50%)",
    Recurrence_status: "No",
    Survival_status: "Dead",
    caseId: "05CO044",
    Immune_subtype: "Fibroblast/TGFBeta",
  },
  {
    Tumor: "GBM",
    Sex: "Male",
    Age: ">=70 (>=75%)",
    Participant_Country: "Bulgaria",
    Grade: "not_reported",
    Stage: "not_reported",
    BMI: "18.5-25",
    Alcohol_consumption: "non-drinker",
    Tobacco_use: "non-smoker",
    Secondhand_smoke_exposure: "No",
    Overall_survival_in_days: "609-1016 (50%-75%)",
    Recurrence_status: "No",
    Survival_status: "Alive",
    caseId: "C3L-02642",
    Immune_subtype: "Brain/Neuro",
  },
  {
    Tumor: "BRCA",
    Sex: "Female",
    Age: ">=70 (>=75%)",
    Participant_Country: "not_reported",
    Grade: "not_reported",
    Stage: "II",
    BMI: "not_reported",
    Alcohol_consumption: "not_reported",
    Tobacco_use: "not_reported",
    Secondhand_smoke_exposure: "not_reported",
    Overall_survival_in_days: "<348 (25%)",
    Recurrence_status: "No",
    Survival_status: "Dead",
    caseId: "20BR001",
    Immune_subtype: "CD8-/IFNG-",
  },
  {
    Tumor: "LSCC",
    Sex: "Male",
    Age: "55-63 (25%-50%)",
    Participant_Country: "China",
    Grade: "G2",
    Stage: "III",
    BMI: "18.5-25",
    Alcohol_consumption: "drinker",
    Tobacco_use: "current smoker",
    Secondhand_smoke_exposure: "not_reported",
    Overall_survival_in_days: "609-1016 (50%-75%)",
    Recurrence_status: "No",
    Survival_status: "Dead",
    caseId: "C3N-04127",
    Immune_subtype: "Eosinophils/Endothelial",
  },
  {
    Tumor: "COAD",
    Sex: "Male",
    Age: "63-70 (50%-75%)",
    Participant_Country: "not_reported",
    Grade: "not_reported",
    Stage: "III",
    BMI: "not_reported",
    Alcohol_consumption: "not_reported",
    Tobacco_use: "not_reported",
    Secondhand_smoke_exposure: "not_reported",
    Overall_survival_in_days: "348-609 (25%-50%)",
    Recurrence_status: "No",
    Survival_status: "Dead",
    caseId: "09CO015",
    Immune_subtype: "CD8-/IFNG-",
  },
  {
    Tumor: "BRCA",
    Sex: "Female",
    Age: "55-63 (25%-50%)",
    Participant_Country: "not_reported",
    Grade: "not_reported",
    Stage: "II",
    BMI: "not_reported",
    Alcohol_consumption: "not_reported",
    Tobacco_use: "not_reported",
    Secondhand_smoke_exposure: "not_reported",
    Overall_survival_in_days: "<348 (25%)",
    Recurrence_status: "No",
    Survival_status: "Dead",
    caseId: "03BR005",
    Immune_subtype: "Fibroblast/TGFBeta",
  },
  {
    Tumor: "COAD",
    Sex: "Female",
    Age: ">=70 (>=75%)",
    Participant_Country: "not_reported",
    Grade: "not_reported",
    Stage: "II",
    BMI: "not_reported",
    Alcohol_consumption: "not_reported",
    Tobacco_use: "not_reported",
    Secondhand_smoke_exposure: "not_reported",
    Overall_survival_in_days: "348-609 (25%-50%)",
    Recurrence_status: "No",
    Survival_status: "Dead",
    caseId: "05CO020",
    Immune_subtype: "CD8-/IFNG-",
  },
  {
    Tumor: "HNSCC",
    Sex: "Male",
    Age: "<55 (25%)",
    Participant_Country: "China",
    Grade: "G2",
    Stage: "III",
    BMI: "18.5-25",
    Alcohol_consumption: "non-drinker",
    Tobacco_use: "non-smoker",
    Secondhand_smoke_exposure: "not_reported",
    Overall_survival_in_days: "348-609 (25%-50%)",
    Recurrence_status: "No",
    Survival_status: "Dead",
    caseId: "C3N-02730",
    Immune_subtype: "CD8+/IFNG+",
  },
  {
    Tumor: "PDAC",
    Sex: "Male",
    Age: "63-70 (50%-75%)",
    Participant_Country: "not_reported",
    Grade: "G2",
    Stage: "I",
    BMI: "18.5-25",
    Alcohol_consumption: "past-drinker",
    Tobacco_use: "non-smoker",
    Secondhand_smoke_exposure: "not_reported",
    Overall_survival_in_days: "<348 (25%)",
    Recurrence_status: "No",
    Survival_status: "Dead",
    caseId: "C3L-04027",
    Immune_subtype: "Eosinophils/Endothelial",
  },
  {
    Tumor: "GBM",
    Sex: "Male",
    Age: "55-63 (25%-50%)",
    Participant_Country: "United States",
    Grade: "not_reported",
    Stage: "not_reported",
    BMI: ">=30",
    Alcohol_consumption: "drinker",
    Tobacco_use: "past-smoker",
    Secondhand_smoke_exposure: "not_reported",
    Overall_survival_in_days: "<348 (25%)",
    Recurrence_status: "Yes",
    Survival_status: "Alive",
    caseId: "C3L-00104",
    Immune_subtype: "Fibroblast/TGFBeta",
  },
  {
    Tumor: "PDAC",
    Sex: "Female",
    Age: "<55 (25%)",
    Participant_Country: "Poland",
    Grade: "G2",
    Stage: "not_reported",
    BMI: "<18.5",
    Alcohol_consumption: "non-drinker",
    Tobacco_use: "current smoker",
    Secondhand_smoke_exposure: "No",
    Overall_survival_in_days: "<348 (25%)",
    Recurrence_status: "No",
    Survival_status: "Alive",
    caseId: "C3N-03430",
    Immune_subtype: "CD8-/IFNG+",
  },
  {
    Tumor: "COAD",
    Sex: "Male",
    Age: "55-63 (25%-50%)",
    Participant_Country: "not_reported",
    Grade: "not_reported",
    Stage: "III",
    BMI: "not_reported",
    Alcohol_consumption: "not_reported",
    Tobacco_use: "not_reported",
    Secondhand_smoke_exposure: "not_reported",
    Overall_survival_in_days: "348-609 (25%-50%)",
    Recurrence_status: "No",
    Survival_status: "Dead",
    caseId: "11CO032",
    Immune_subtype: "Fibroblast/TGFBeta",
  },
  {
    Tumor: "GBM",
    Sex: "Male",
    Age: "63-70 (50%-75%)",
    Participant_Country: "Poland",
    Grade: "not_reported",
    Stage: "not_reported",
    BMI: ">=30",
    Alcohol_consumption: "non-drinker",
    Tobacco_use: "non-smoker",
    Secondhand_smoke_exposure: "No",
    Overall_survival_in_days: "609-1016 (50%-75%)",
    Recurrence_status: "Yes",
    Survival_status: "Alive",
    caseId: "C3N-01196",
    Immune_subtype: "CD8-/IFNG-",
  },
  {
    Tumor: "GBM",
    Sex: "Female",
    Age: "63-70 (50%-75%)",
    Participant_Country: "China",
    Grade: "not_reported",
    Stage: "not_reported",
    BMI: "18.5-25",
    Alcohol_consumption: "non-drinker",
    Tobacco_use: "non-smoker",
    Secondhand_smoke_exposure: "not_reported",
    Overall_survival_in_days: "<348 (25%)",
    Recurrence_status: "No",
    Survival_status: "Dead",
    caseId: "C3N-03070",
    Immune_subtype: "Brain/Neuro",
  },
  {
    Tumor: "GBM",
    Sex: "Female",
    Age: "55-63 (25%-50%)",
    Participant_Country: "United States",
    Grade: "not_reported",
    Stage: "not_reported",
    BMI: ">=30",
    Alcohol_consumption: "non-drinker",
    Tobacco_use: "non-smoker",
    Secondhand_smoke_exposure: "Yes",
    Overall_survival_in_days: "<348 (25%)",
    Recurrence_status: "No",
    Survival_status: "Alive",
    caseId: "C3L-03681",
    Immune_subtype: "Fibroblast/TGFBeta",
  },
  {
    Tumor: "HNSCC",
    Sex: "Male",
    Age: "63-70 (50%-75%)",
    Participant_Country: "Poland",
    Grade: "G3",
    Stage: "IV",
    BMI: "25-30",
    Alcohol_consumption: "drinker",
    Tobacco_use: "current smoker",
    Secondhand_smoke_exposure: "No",
    Overall_survival_in_days: "609-1016 (50%-75%)",
    Recurrence_status: "Yes",
    Survival_status: "Dead",
    caseId: "C3N-01944",
    Immune_subtype: "Fibroblast/TGFBeta",
  },
  {
    Tumor: "COAD",
    Sex: "Male",
    Age: "55-63 (25%-50%)",
    Participant_Country: "not_reported",
    Grade: "not_reported",
    Stage: "III",
    BMI: "not_reported",
    Alcohol_consumption: "not_reported",
    Tobacco_use: "not_reported",
    Secondhand_smoke_exposure: "not_reported",
    Overall_survival_in_days: "348-609 (25%-50%)",
    Recurrence_status: "No",
    Survival_status: "Dead",
    caseId: "16CO011",
    Immune_subtype: "CD8-/IFNG-",
  },
  {
    Tumor: "HGSC",
    Sex: "Female",
    Age: "<55 (25%)",
    Participant_Country: "not_reported",
    Grade: "G3",
    Stage: "III",
    BMI: "not_reported",
    Alcohol_consumption: "not_reported",
    Tobacco_use: "not_reported",
    Secondhand_smoke_exposure: "not_reported",
    Overall_survival_in_days: "348-609 (25%-50%)",
    Recurrence_status: "No",
    Survival_status: "Dead",
    caseId: "02OV008",
    Immune_subtype: "CD8-/IFNG-",
  },
  {
    Tumor: "BRCA",
    Sex: "Female",
    Age: "<55 (25%)",
    Participant_Country: "not_reported",
    Grade: "not_reported",
    Stage: "III",
    BMI: "not_reported",
    Alcohol_consumption: "not_reported",
    Tobacco_use: "not_reported",
    Secondhand_smoke_exposure: "not_reported",
    Overall_survival_in_days: "348-609 (25%-50%)",
    Recurrence_status: "No",
    Survival_status: "Dead",
    caseId: "18BR010",
    Immune_subtype: "Fibroblast/TGFBeta",
  },
  {
    Tumor: "BRCA",
    Sex: "Female",
    Age: ">=70 (>=75%)",
    Participant_Country: "not_reported",
    Grade: "not_reported",
    Stage: "II",
    BMI: "not_reported",
    Alcohol_consumption: "not_reported",
    Tobacco_use: "not_reported",
    Secondhand_smoke_exposure: "not_reported",
    Overall_survival_in_days: "348-609 (25%-50%)",
    Recurrence_status: "No",
    Survival_status: "Dead",
    caseId: "18BR004",
    Immune_subtype: "CD8-/IFNG-",
  },
  {
    Tumor: "HGSC",
    Sex: "Female",
    Age: "55-63 (25%-50%)",
    Participant_Country: "not_reported",
    Grade: "G3",
    Stage: "III",
    BMI: "not_reported",
    Alcohol_consumption: "not_reported",
    Tobacco_use: "not_reported",
    Secondhand_smoke_exposure: "not_reported",
    Overall_survival_in_days: "<348 (25%)",
    Recurrence_status: "No",
    Survival_status: "Dead",
    caseId: "02OV032",
    Immune_subtype: "CD8+/IFNG+",
  },
  {
    Tumor: "HNSCC",
    Sex: "Male",
    Age: ">=70 (>=75%)",
    Participant_Country: "Ukraine",
    Grade: "G1",
    Stage: "I",
    BMI: ">=30",
    Alcohol_consumption: "drinker",
    Tobacco_use: "current smoker",
    Secondhand_smoke_exposure: "not_reported",
    Overall_survival_in_days: "<348 (25%)",
    Recurrence_status: "No",
    Survival_status: "Dead",
    caseId: "C3N-02716",
    Immune_subtype: "Fibroblast/TGFBeta",
  },
  {
    Tumor: "COAD",
    Sex: "Female",
    Age: "<55 (25%)",
    Participant_Country: "not_reported",
    Grade: "not_reported",
    Stage: "II",
    BMI: "not_reported",
    Alcohol_consumption: "not_reported",
    Tobacco_use: "not_reported",
    Secondhand_smoke_exposure: "not_reported",
    Overall_survival_in_days: "348-609 (25%-50%)",
    Recurrence_status: "No",
    Survival_status: "Dead",
    caseId: "11CO031",
    Immune_subtype: "CD8+/IFNG+",
  },
  {
    Tumor: "HGSC",
    Sex: "Female",
    Age: "<55 (25%)",
    Participant_Country: "not_reported",
    Grade: "G3",
    Stage: "IV",
    BMI: "not_reported",
    Alcohol_consumption: "not_reported",
    Tobacco_use: "not_reported",
    Secondhand_smoke_exposure: "not_reported",
    Overall_survival_in_days: "348-609 (25%-50%)",
    Recurrence_status: "No",
    Survival_status: "Dead",
    caseId: "04OV023",
    Immune_subtype: "Fibroblast/TGFBeta",
  },
  {
    Tumor: "COAD",
    Sex: "Male",
    Age: "<55 (25%)",
    Participant_Country: "not_reported",
    Grade: "not_reported",
    Stage: "III",
    BMI: "not_reported",
    Alcohol_consumption: "not_reported",
    Tobacco_use: "not_reported",
    Secondhand_smoke_exposure: "not_reported",
    Overall_survival_in_days: "348-609 (25%-50%)",
    Recurrence_status: "No",
    Survival_status: "Dead",
    caseId: "11CO036",
    Immune_subtype: "CD8+/IFNG+",
  },
  {
    Tumor: "GBM",
    Sex: "Female",
    Age: "55-63 (25%-50%)",
    Participant_Country: "United States",
    Grade: "not_reported",
    Stage: "not_reported",
    BMI: ">=30",
    Alcohol_consumption: "past-drinker",
    Tobacco_use: "current smoker",
    Secondhand_smoke_exposure: "Yes",
    Overall_survival_in_days: "<348 (25%)",
    Recurrence_status: "Yes",
    Survival_status: "Alive",
    caseId: "C3L-03407",
    Immune_subtype: "CD8+/IFNG+",
  },
  {
    Tumor: "LUAD",
    Sex: "Male",
    Age: "55-63 (25%-50%)",
    Participant_Country: "Vietnam",
    Grade: "G3",
    Stage: "III",
    BMI: "<18.5",
    Alcohol_consumption: "drinker",
    Tobacco_use: "current smoker",
    Secondhand_smoke_exposure: "No",
    Overall_survival_in_days: "<348 (25%)",
    Recurrence_status: "No",
    Survival_status: "Alive",
    caseId: "C3N-00180",
    Immune_subtype: "CD8-/IFNG-",
  },
  {
    Tumor: "GBM",
    Sex: "Male",
    Age: "<55 (25%)",
    Participant_Country: "United States",
    Grade: "not_reported",
    Stage: "not_reported",
    BMI: "25-30",
    Alcohol_consumption: "not_reported",
    Tobacco_use: "not_reported",
    Secondhand_smoke_exposure: "not_reported",
    Overall_survival_in_days: "348-609 (25%-50%)",
    Recurrence_status: "Yes",
    Survival_status: "Alive",
    caseId: "C3L-00674",
    Immune_subtype: "Brain/Neuro",
  },
  {
    Tumor: "GBM",
    Sex: "Male",
    Age: ">=70 (>=75%)",
    Participant_Country: "Russia",
    Grade: "not_reported",
    Stage: "not_reported",
    BMI: "18.5-25",
    Alcohol_consumption: "not_reported",
    Tobacco_use: "not_reported",
    Secondhand_smoke_exposure: "not_reported",
    Overall_survival_in_days: "348-609 (25%-50%)",
    Recurrence_status: "Yes",
    Survival_status: "Dead",
    caseId: "C3L-01040",
    Immune_subtype: "CD8-/IFNG-",
  },
  {
    Tumor: "BRCA",
    Sex: "Female",
    Age: "63-70 (50%-75%)",
    Participant_Country: "not_reported",
    Grade: "not_reported",
    Stage: "III",
    BMI: "not_reported",
    Alcohol_consumption: "not_reported",
    Tobacco_use: "not_reported",
    Secondhand_smoke_exposure: "not_reported",
    Overall_survival_in_days: "348-609 (25%-50%)",
    Recurrence_status: "No",
    Survival_status: "Dead",
    caseId: "01BR018",
    Immune_subtype: "CD8+/IFNG+",
  },
  {
    Tumor: "LSCC",
    Sex: "Male",
    Age: "55-63 (25%-50%)",
    Participant_Country: "Bulgaria",
    Grade: "G2",
    Stage: "II",
    BMI: "25-30",
    Alcohol_consumption: "drinker",
    Tobacco_use: "current smoker",
    Secondhand_smoke_exposure: "not_reported",
    Overall_survival_in_days: "609-1016 (50%-75%)",
    Recurrence_status: "No",
    Survival_status: "Dead",
    caseId: "C3L-02163",
    Immune_subtype: "CD8-/IFNG-",
  },
  {
    Tumor: "GBM",
    Sex: "Male",
    Age: "63-70 (50%-75%)",
    Participant_Country: "Russia",
    Grade: "not_reported",
    Stage: "not_reported",
    BMI: "25-30",
    Alcohol_consumption: "not_reported",
    Tobacco_use: "not_reported",
    Secondhand_smoke_exposure: "not_reported",
    Overall_survival_in_days: "348-609 (25%-50%)",
    Recurrence_status: "Yes",
    Survival_status: "Alive",
    caseId: "C3L-03387",
    Immune_subtype: "Brain/Neuro",
  },
  {
    Tumor: "PDAC",
    Sex: "Male",
    Age: "63-70 (50%-75%)",
    Participant_Country: "United States",
    Grade: "G2",
    Stage: "II",
    BMI: "25-30",
    Alcohol_consumption: "drinker",
    Tobacco_use: "past-smoker",
    Secondhand_smoke_exposure: "Yes",
    Overall_survival_in_days: "348-609 (25%-50%)",
    Recurrence_status: "No",
    Survival_status: "Dead",
    caseId: "C3L-00017",
    Immune_subtype: "Fibroblast/TGFBeta",
  },
  {
    Tumor: "ccRCC",
    Sex: "Male",
    Age: "<55 (25%)",
    Participant_Country: "United States",
    Grade: "G2",
    Stage: "I",
    BMI: "25-30",
    Alcohol_consumption: "drinker",
    Tobacco_use: "not_reported",
    Secondhand_smoke_exposure: "not_reported",
    Overall_survival_in_days: "<348 (25%)",
    Recurrence_status: "No",
    Survival_status: "Dead",
    caseId: "C3L-00800",
    Immune_subtype: "CD8+/IFNG+",
  },
  {
    Tumor: "LSCC",
    Sex: "Male",
    Age: ">=70 (>=75%)",
    Participant_Country: "United States",
    Grade: "G2",
    Stage: "III",
    BMI: "25-30",
    Alcohol_consumption: "drinker",
    Tobacco_use: "current smoker",
    Secondhand_smoke_exposure: "not_reported",
    Overall_survival_in_days: ">=1016 (>=75%)",
    Recurrence_status: "No",
    Survival_status: "Dead",
    caseId: "C3L-00927",
    Immune_subtype: "CD8-/IFNG-",
  },
  {
    Tumor: "COAD",
    Sex: "Male",
    Age: ">=70 (>=75%)",
    Participant_Country: "not_reported",
    Grade: "not_reported",
    Stage: "II",
    BMI: "not_reported",
    Alcohol_consumption: "not_reported",
    Tobacco_use: "not_reported",
    Secondhand_smoke_exposure: "not_reported",
    Overall_survival_in_days: "<348 (25%)",
    Recurrence_status: "No",
    Survival_status: "Alive",
    caseId: "05CO039",
    Immune_subtype: "CD8-/IFNG+",
  },
  {
    Tumor: "UCEC",
    Sex: "Female",
    Age: "55-63 (25%-50%)",
    Participant_Country: "Ukraine",
    Grade: "G3",
    Stage: "I",
    BMI: ">=30",
    Alcohol_consumption: "non-drinker",
    Tobacco_use: "non-smoker",
    Secondhand_smoke_exposure: "not_reported",
    Overall_survival_in_days: "609-1016 (50%-75%)",
    Recurrence_status: "No",
    Survival_status: "Dead",
    caseId: "C3N-00328",
    Immune_subtype: "CD8+/IFNG+",
  },
  {
    Tumor: "HGSC",
    Sex: "Female",
    Age: "55-63 (25%-50%)",
    Participant_Country: "not_reported",
    Grade: "not_reported",
    Stage: "III",
    BMI: "not_reported",
    Alcohol_consumption: "not_reported",
    Tobacco_use: "not_reported",
    Secondhand_smoke_exposure: "not_reported",
    Overall_survival_in_days: "<348 (25%)",
    Recurrence_status: "No",
    Survival_status: "Dead",
    caseId: "17OV015",
    Immune_subtype: "Fibroblast/TGFBeta",
  },
  {
    Tumor: "UCEC",
    Sex: "Female",
    Age: ">=70 (>=75%)",
    Participant_Country: "United States",
    Grade: "G3",
    Stage: "III",
    BMI: ">=30",
    Alcohol_consumption: "non-drinker",
    Tobacco_use: "past-smoker",
    Secondhand_smoke_exposure: "not_reported",
    Overall_survival_in_days: "348-609 (25%-50%)",
    Recurrence_status: "Yes",
    Survival_status: "Alive",
    caseId: "C3N-00200",
    Immune_subtype: "Fibroblast/TGFBeta",
  },
  {
    Tumor: "UCEC",
    Sex: "Female",
    Age: "63-70 (50%-75%)",
    Participant_Country: "United States",
    Grade: "G3",
    Stage: "I",
    BMI: ">=30",
    Alcohol_consumption: "non-drinker",
    Tobacco_use: "non-smoker",
    Secondhand_smoke_exposure: "not_reported",
    Overall_survival_in_days: ">=1016 (>=75%)",
    Recurrence_status: "No",
    Survival_status: "Dead",
    caseId: "C3L-01282",
    Immune_subtype: "Fibroblast/TGFBeta",
  },
  {
    Tumor: "HGSC",
    Sex: "Female",
    Age: "55-63 (25%-50%)",
    Participant_Country: "not_reported",
    Grade: "G3",
    Stage: "III",
    BMI: "not_reported",
    Alcohol_consumption: "not_reported",
    Tobacco_use: "not_reported",
    Secondhand_smoke_exposure: "not_reported",
    Overall_survival_in_days: "348-609 (25%-50%)",
    Recurrence_status: "Yes",
    Survival_status: "Dead",
    caseId: "04OV018",
    Immune_subtype: "Fibroblast/TGFBeta",
  },
  {
    Tumor: "HNSCC",
    Sex: "Male",
    Age: "55-63 (25%-50%)",
    Participant_Country: "Ukraine",
    Grade: "G2",
    Stage: "IV",
    BMI: "18.5-25",
    Alcohol_consumption: "non-drinker",
    Tobacco_use: "non-smoker",
    Secondhand_smoke_exposure: "not_reported",
    Overall_survival_in_days: "609-1016 (50%-75%)",
    Recurrence_status: "No",
    Survival_status: "Dead",
    caseId: "C3N-03664",
    Immune_subtype: "CD8-/IFNG-",
  },
  {
    Tumor: "LUAD",
    Sex: "Male",
    Age: "<55 (25%)",
    Participant_Country: "Vietnam",
    Grade: "G2",
    Stage: "I",
    BMI: "18.5-25",
    Alcohol_consumption: "drinker",
    Tobacco_use: "current smoker",
    Secondhand_smoke_exposure: "No",
    Overall_survival_in_days: "<348 (25%)",
    Recurrence_status: "No",
    Survival_status: "Dead",
    caseId: "C3N-01071",
    Immune_subtype: "CD8-/IFNG+",
  },
  {
    Tumor: "HGSC",
    Sex: "Female",
    Age: "55-63 (25%-50%)",
    Participant_Country: "not_reported",
    Grade: "G3",
    Stage: "III",
    BMI: "not_reported",
    Alcohol_consumption: "not_reported",
    Tobacco_use: "not_reported",
    Secondhand_smoke_exposure: "not_reported",
    Overall_survival_in_days: "<348 (25%)",
    Recurrence_status: "No",
    Survival_status: "Dead",
    caseId: "04OV021",
    Immune_subtype: "Fibroblast/TGFBeta",
  },
  {
    Tumor: "LUAD",
    Sex: "Female",
    Age: "63-70 (50%-75%)",
    Participant_Country: "China",
    Grade: "G2",
    Stage: "I",
    BMI: "25-30",
    Alcohol_consumption: "non-drinker",
    Tobacco_use: "non-smoker",
    Secondhand_smoke_exposure: "not_reported",
    Overall_survival_in_days: ">=1016 (>=75%)",
    Recurrence_status: "No",
    Survival_status: "Dead",
    caseId: "C3N-02433",
    Immune_subtype: "Eosinophils/Endothelial",
  },
  {
    Tumor: "LSCC",
    Sex: "Male",
    Age: "63-70 (50%-75%)",
    Participant_Country: "Poland",
    Grade: "G2",
    Stage: "II",
    BMI: "25-30",
    Alcohol_consumption: "not_reported",
    Tobacco_use: "current smoker",
    Secondhand_smoke_exposure: "not_reported",
    Overall_survival_in_days: ">=1016 (>=75%)",
    Recurrence_status: "No",
    Survival_status: "Dead",
    caseId: "C3N-02284",
    Immune_subtype: "CD8-/IFNG-",
  },
  {
    Tumor: "HGSC",
    Sex: "Female",
    Age: "63-70 (50%-75%)",
    Participant_Country: "not_reported",
    Grade: "G3",
    Stage: "III",
    BMI: "not_reported",
    Alcohol_consumption: "not_reported",
    Tobacco_use: "not_reported",
    Secondhand_smoke_exposure: "not_reported",
    Overall_survival_in_days: "348-609 (25%-50%)",
    Recurrence_status: "No",
    Survival_status: "Dead",
    caseId: "01OV047",
    Immune_subtype: "Fibroblast/TGFBeta",
  },
  {
    Tumor: "BRCA",
    Sex: "Female",
    Age: "<55 (25%)",
    Participant_Country: "not_reported",
    Grade: "not_reported",
    Stage: "II",
    BMI: "not_reported",
    Alcohol_consumption: "not_reported",
    Tobacco_use: "not_reported",
    Secondhand_smoke_exposure: "not_reported",
    Overall_survival_in_days: "348-609 (25%-50%)",
    Recurrence_status: "No",
    Survival_status: "Dead",
    caseId: "11BR003",
    Immune_subtype: "CD8+/IFNG+",
  },
  {
    Tumor: "BRCA",
    Sex: "Female",
    Age: "63-70 (50%-75%)",
    Participant_Country: "not_reported",
    Grade: "not_reported",
    Stage: "II",
    BMI: "not_reported",
    Alcohol_consumption: "not_reported",
    Tobacco_use: "not_reported",
    Secondhand_smoke_exposure: "not_reported",
    Overall_survival_in_days: "348-609 (25%-50%)",
    Recurrence_status: "No",
    Survival_status: "Dead",
    caseId: "11BR080",
    Immune_subtype: "CD8+/IFNG+",
  },
  {
    Tumor: "ccRCC",
    Sex: "Female",
    Age: "63-70 (50%-75%)",
    Participant_Country: "Ukraine",
    Grade: "G3",
    Stage: "IV",
    BMI: "25-30",
    Alcohol_consumption: "non-drinker",
    Tobacco_use: "not_reported",
    Secondhand_smoke_exposure: "not_reported",
    Overall_survival_in_days: "not_reported",
    Recurrence_status: "No",
    Survival_status: "Dead",
    caseId: "C3N-00149",
    Immune_subtype: "CD8+/IFNG+",
  },
  {
    Tumor: "PDAC",
    Sex: "Male",
    Age: "55-63 (25%-50%)",
    Participant_Country: "Poland",
    Grade: "G1",
    Stage: "II",
    BMI: "25-30",
    Alcohol_consumption: "drinker",
    Tobacco_use: "non-smoker",
    Secondhand_smoke_exposure: "not_reported",
    Overall_survival_in_days: ">=1016 (>=75%)",
    Recurrence_status: "Yes",
    Survival_status: "Alive",
    caseId: "C3L-00599",
    Immune_subtype: "CD8-/IFNG-",
  },
  {
    Tumor: "BRCA",
    Sex: "Female",
    Age: ">=70 (>=75%)",
    Participant_Country: "not_reported",
    Grade: "not_reported",
    Stage: "II",
    BMI: "not_reported",
    Alcohol_consumption: "not_reported",
    Tobacco_use: "not_reported",
    Secondhand_smoke_exposure: "not_reported",
    Overall_survival_in_days: "348-609 (25%-50%)",
    Recurrence_status: "No",
    Survival_status: "not_reported",
    caseId: "09BR004",
    Immune_subtype: "CD8-/IFNG+",
  },
  {
    Tumor: "GBM",
    Sex: "Male",
    Age: "55-63 (25%-50%)",
    Participant_Country: "Russia",
    Grade: "not_reported",
    Stage: "not_reported",
    BMI: ">=30",
    Alcohol_consumption: "non-drinker",
    Tobacco_use: "non-smoker",
    Secondhand_smoke_exposure: "No",
    Overall_survival_in_days: "348-609 (25%-50%)",
    Recurrence_status: "Yes",
    Survival_status: "Alive",
    caseId: "C3L-01146",
    Immune_subtype: "Brain/Neuro",
  },
  {
    Tumor: "BRCA",
    Sex: "Female",
    Age: "55-63 (25%-50%)",
    Participant_Country: "not_reported",
    Grade: "not_reported",
    Stage: "not_reported",
    BMI: "not_reported",
    Alcohol_consumption: "not_reported",
    Tobacco_use: "not_reported",
    Secondhand_smoke_exposure: "not_reported",
    Overall_survival_in_days: "not_reported",
    Recurrence_status: "No",
    Survival_status: "not_reported",
    caseId: "09BR001",
    Immune_subtype: "not_reported",
  },
  {
    Tumor: "LSCC",
    Sex: "Male",
    Age: "63-70 (50%-75%)",
    Participant_Country: "China",
    Grade: "G3",
    Stage: "II",
    BMI: "18.5-25",
    Alcohol_consumption: "drinker",
    Tobacco_use: "current smoker",
    Secondhand_smoke_exposure: "not_reported",
    Overall_survival_in_days: "<348 (25%)",
    Recurrence_status: "No",
    Survival_status: "Dead",
    caseId: "C3N-03076",
    Immune_subtype: "Eosinophils/Endothelial",
  },
  {
    Tumor: "PDAC",
    Sex: "Male",
    Age: "<55 (25%)",
    Participant_Country: "Poland",
    Grade: "G3",
    Stage: "IV",
    BMI: "25-30",
    Alcohol_consumption: "non-drinker",
    Tobacco_use: "non-smoker",
    Secondhand_smoke_exposure: "No",
    Overall_survival_in_days: "<348 (25%)",
    Recurrence_status: "No",
    Survival_status: "Alive",
    caseId: "C3N-01716",
    Immune_subtype: "Eosinophils/Endothelial",
  },
  {
    Tumor: "HNSCC",
    Sex: "Male",
    Age: "55-63 (25%-50%)",
    Participant_Country: "Poland",
    Grade: "G2",
    Stage: "III",
    BMI: ">=30",
    Alcohol_consumption: "not_reported",
    Tobacco_use: "not_reported",
    Secondhand_smoke_exposure: "not_reported",
    Overall_survival_in_days: ">=1016 (>=75%)",
    Recurrence_status: "No",
    Survival_status: "Dead",
    caseId: "C3N-01620",
    Immune_subtype: "Fibroblast/TGFBeta",
  },
  {
    Tumor: "COAD",
    Sex: "Female",
    Age: "<55 (25%)",
    Participant_Country: "not_reported",
    Grade: "not_reported",
    Stage: "II",
    BMI: "not_reported",
    Alcohol_consumption: "not_reported",
    Tobacco_use: "not_reported",
    Secondhand_smoke_exposure: "not_reported",
    Overall_survival_in_days: "348-609 (25%-50%)",
    Recurrence_status: "No",
    Survival_status: "Dead",
    caseId: "01CO008",
    Immune_subtype: "CD8-/IFNG-",
  },
  {
    Tumor: "PDAC",
    Sex: "Female",
    Age: ">=70 (>=75%)",
    Participant_Country: "United States",
    Grade: "G1",
    Stage: "II",
    BMI: "18.5-25",
    Alcohol_consumption: "drinker",
    Tobacco_use: "past-smoker",
    Secondhand_smoke_exposure: "not_reported",
    Overall_survival_in_days: "<348 (25%)",
    Recurrence_status: "No",
    Survival_status: "Alive",
    caseId: "C3L-02604",
    Immune_subtype: "CD8-/IFNG-",
  },
  {
    Tumor: "HNSCC",
    Sex: "Male",
    Age: ">=70 (>=75%)",
    Participant_Country: "Poland",
    Grade: "G2",
    Stage: "IV",
    BMI: "25-30",
    Alcohol_consumption: "drinker",
    Tobacco_use: "past-smoker",
    Secondhand_smoke_exposure: "No",
    Overall_survival_in_days: ">=1016 (>=75%)",
    Recurrence_status: "No",
    Survival_status: "Alive",
    caseId: "C3N-03012",
    Immune_subtype: "CD8-/IFNG-",
  },
  {
    Tumor: "LSCC",
    Sex: "Male",
    Age: ">=70 (>=75%)",
    Participant_Country: "Bulgaria",
    Grade: "G2",
    Stage: "II",
    BMI: "25-30",
    Alcohol_consumption: "drinker",
    Tobacco_use: "past-smoker",
    Secondhand_smoke_exposure: "Yes",
    Overall_survival_in_days: "<348 (25%)",
    Recurrence_status: "No",
    Survival_status: "Alive",
    caseId: "C3L-00445",
    Immune_subtype: "CD8-/IFNG-",
  },
  {
    Tumor: "HGSC",
    Sex: "Female",
    Age: "55-63 (25%-50%)",
    Participant_Country: "not_reported",
    Grade: "G3",
    Stage: "IV",
    BMI: "not_reported",
    Alcohol_consumption: "not_reported",
    Tobacco_use: "not_reported",
    Secondhand_smoke_exposure: "not_reported",
    Overall_survival_in_days: "609-1016 (50%-75%)",
    Recurrence_status: "No",
    Survival_status: "Dead",
    caseId: "04OV051",
    Immune_subtype: "CD8-/IFNG+",
  },
  {
    Tumor: "LUAD",
    Sex: "Female",
    Age: ">=70 (>=75%)",
    Participant_Country: "United States",
    Grade: "G2",
    Stage: "I",
    BMI: "18.5-25",
    Alcohol_consumption: "drinker",
    Tobacco_use: "past-smoker",
    Secondhand_smoke_exposure: "Yes",
    Overall_survival_in_days: ">=1016 (>=75%)",
    Recurrence_status: "No",
    Survival_status: "Dead",
    caseId: "C3L-01682",
    Immune_subtype: "Eosinophils/Endothelial",
  },
  {
    Tumor: "LSCC",
    Sex: "Male",
    Age: ">=70 (>=75%)",
    Participant_Country: "Ukraine",
    Grade: "G2",
    Stage: "III",
    BMI: "18.5-25",
    Alcohol_consumption: "non-drinker",
    Tobacco_use: "non-smoker",
    Secondhand_smoke_exposure: "Yes",
    Overall_survival_in_days: "<348 (25%)",
    Recurrence_status: "No",
    Survival_status: "Alive",
    caseId: "C3L-00993",
    Immune_subtype: "CD8-/IFNG-",
  },
  {
    Tumor: "COAD",
    Sex: "Male",
    Age: ">=70 (>=75%)",
    Participant_Country: "not_reported",
    Grade: "not_reported",
    Stage: "IV",
    BMI: "not_reported",
    Alcohol_consumption: "not_reported",
    Tobacco_use: "not_reported",
    Secondhand_smoke_exposure: "not_reported",
    Overall_survival_in_days: "348-609 (25%-50%)",
    Recurrence_status: "No",
    Survival_status: "Dead",
    caseId: "11CO062",
    Immune_subtype: "Fibroblast/TGFBeta",
  },
  {
    Tumor: "ccRCC",
    Sex: "Female",
    Age: "55-63 (25%-50%)",
    Participant_Country: "Poland",
    Grade: "G2",
    Stage: "III",
    BMI: "18.5-25",
    Alcohol_consumption: "drinker",
    Tobacco_use: "past-smoker",
    Secondhand_smoke_exposure: "not_reported",
    Overall_survival_in_days: ">=1016 (>=75%)",
    Recurrence_status: "No",
    Survival_status: "Dead",
    caseId: "C3N-01178",
    Immune_subtype: "CD8+/IFNG+",
  },
  {
    Tumor: "LSCC",
    Sex: "Male",
    Age: "55-63 (25%-50%)",
    Participant_Country: "Ukraine",
    Grade: "G2",
    Stage: "not_reported",
    BMI: "not_reported",
    Alcohol_consumption: "not_reported",
    Tobacco_use: "not_reported",
    Secondhand_smoke_exposure: "not_reported",
    Overall_survival_in_days: "not_reported",
    Recurrence_status: "No",
    Survival_status: "not_reported",
    caseId: "C3N-03092",
    Immune_subtype: "CD8+/IFNG+",
  },
  {
    Tumor: "HNSCC",
    Sex: "Male",
    Age: "<55 (25%)",
    Participant_Country: "Ukraine",
    Grade: "G2",
    Stage: "IV",
    BMI: "25-30",
    Alcohol_consumption: "drinker",
    Tobacco_use: "current smoker",
    Secondhand_smoke_exposure: "not_reported",
    Overall_survival_in_days: "609-1016 (50%-75%)",
    Recurrence_status: "No",
    Survival_status: "Dead",
    caseId: "C3N-02727",
    Immune_subtype: "Fibroblast/TGFBeta",
  },
  {
    Tumor: "LUAD",
    Sex: "Male",
    Age: "63-70 (50%-75%)",
    Participant_Country: "Vietnam",
    Grade: "G2",
    Stage: "II",
    BMI: "<18.5",
    Alcohol_consumption: "non-drinker",
    Tobacco_use: "non-smoker",
    Secondhand_smoke_exposure: "No",
    Overall_survival_in_days: "609-1016 (50%-75%)",
    Recurrence_status: "Yes",
    Survival_status: "Dead",
    caseId: "C3N-00167",
    Immune_subtype: "Eosinophils/Endothelial",
  },
  {
    Tumor: "COAD",
    Sex: "Female",
    Age: ">=70 (>=75%)",
    Participant_Country: "not_reported",
    Grade: "not_reported",
    Stage: "II",
    BMI: "not_reported",
    Alcohol_consumption: "not_reported",
    Tobacco_use: "not_reported",
    Secondhand_smoke_exposure: "not_reported",
    Overall_survival_in_days: "<348 (25%)",
    Recurrence_status: "No",
    Survival_status: "Alive",
    caseId: "05CO015",
    Immune_subtype: "CD8+/IFNG+",
  },
  {
    Tumor: "PDAC",
    Sex: "Female",
    Age: "<55 (25%)",
    Participant_Country: "Canada",
    Grade: "G3",
    Stage: "II",
    BMI: "25-30",
    Alcohol_consumption: "drinker",
    Tobacco_use: "non-smoker",
    Secondhand_smoke_exposure: "not_reported",
    Overall_survival_in_days: "<348 (25%)",
    Recurrence_status: "Yes",
    Survival_status: "Alive",
    caseId: "C3L-02613",
    Immune_subtype: "CD8-/IFNG+",
  },
  {
    Tumor: "GBM",
    Sex: "Male",
    Age: "<55 (25%)",
    Participant_Country: "China",
    Grade: "not_reported",
    Stage: "not_reported",
    BMI: "25-30",
    Alcohol_consumption: "non-drinker",
    Tobacco_use: "current smoker",
    Secondhand_smoke_exposure: "No",
    Overall_survival_in_days: "348-609 (25%-50%)",
    Recurrence_status: "Yes",
    Survival_status: "Alive",
    caseId: "C3N-02784",
    Immune_subtype: "CD8-/IFNG+",
  },
  {
    Tumor: "UCEC",
    Sex: "Female",
    Age: "55-63 (25%-50%)",
    Participant_Country: "United States",
    Grade: "G1",
    Stage: "I",
    BMI: "25-30",
    Alcohol_consumption: "drinker",
    Tobacco_use: "non-smoker",
    Secondhand_smoke_exposure: "not_reported",
    Overall_survival_in_days: ">=1016 (>=75%)",
    Recurrence_status: "No",
    Survival_status: "Dead",
    caseId: "C3L-00601",
    Immune_subtype: "CD8-/IFNG-",
  },
  {
    Tumor: "LUAD",
    Sex: "Male",
    Age: "<55 (25%)",
    Participant_Country: "Vietnam",
    Grade: "G3",
    Stage: "II",
    BMI: "18.5-25",
    Alcohol_consumption: "drinker",
    Tobacco_use: "current smoker",
    Secondhand_smoke_exposure: "No",
    Overall_survival_in_days: "348-609 (25%-50%)",
    Recurrence_status: "Yes",
    Survival_status: "Dead",
    caseId: "C3N-02155",
    Immune_subtype: "CD8-/IFNG-",
  },
  {
    Tumor: "UCEC",
    Sex: "Female",
    Age: "<55 (25%)",
    Participant_Country: "United States",
    Grade: "G1",
    Stage: "I",
    BMI: ">=30",
    Alcohol_consumption: "not_reported",
    Tobacco_use: "non-smoker",
    Secondhand_smoke_exposure: "not_reported",
    Overall_survival_in_days: "609-1016 (50%-75%)",
    Recurrence_status: "No",
    Survival_status: "Dead",
    caseId: "C3L-01248",
    Immune_subtype: "Fibroblast/TGFBeta",
  },
  {
    Tumor: "BRCA",
    Sex: "Female",
    Age: "<55 (25%)",
    Participant_Country: "not_reported",
    Grade: "not_reported",
    Stage: "II",
    BMI: "not_reported",
    Alcohol_consumption: "not_reported",
    Tobacco_use: "not_reported",
    Secondhand_smoke_exposure: "not_reported",
    Overall_survival_in_days: "348-609 (25%-50%)",
    Recurrence_status: "No",
    Survival_status: "Dead",
    caseId: "11BR056",
    Immune_subtype: "Fibroblast/TGFBeta",
  },
  {
    Tumor: "GBM",
    Sex: "Female",
    Age: ">=70 (>=75%)",
    Participant_Country: "United States",
    Grade: "not_reported",
    Stage: "not_reported",
    BMI: "25-30",
    Alcohol_consumption: "non-drinker",
    Tobacco_use: "non-smoker",
    Secondhand_smoke_exposure: "not_reported",
    Overall_survival_in_days: "<348 (25%)",
    Recurrence_status: "No",
    Survival_status: "Alive",
    caseId: "C3L-03728",
    Immune_subtype: "Brain/Neuro",
  },
  {
    Tumor: "UCEC",
    Sex: "Female",
    Age: "55-63 (25%-50%)",
    Participant_Country: "Ukraine",
    Grade: "G3",
    Stage: "III",
    BMI: "25-30",
    Alcohol_consumption: "non-drinker",
    Tobacco_use: "non-smoker",
    Secondhand_smoke_exposure: "not_reported",
    Overall_survival_in_days: "609-1016 (50%-75%)",
    Recurrence_status: "No",
    Survival_status: "Dead",
    caseId: "C3N-00340",
    Immune_subtype: "CD8-/IFNG+",
  },
  {
    Tumor: "GBM",
    Sex: "Male",
    Age: "<55 (25%)",
    Participant_Country: "Poland",
    Grade: "not_reported",
    Stage: "not_reported",
    BMI: ">=30",
    Alcohol_consumption: "past-drinker",
    Tobacco_use: "current smoker",
    Secondhand_smoke_exposure: "No",
    Overall_survival_in_days: "348-609 (25%-50%)",
    Recurrence_status: "Yes",
    Survival_status: "Alive",
    caseId: "C3N-01851",
    Immune_subtype: "CD8+/IFNG+",
  },
  {
    Tumor: "GBM",
    Sex: "Female",
    Age: "63-70 (50%-75%)",
    Participant_Country: "United States",
    Grade: "not_reported",
    Stage: "not_reported",
    BMI: "18.5-25",
    Alcohol_consumption: "not_reported",
    Tobacco_use: "current smoker",
    Secondhand_smoke_exposure: "not_reported",
    Overall_survival_in_days: "<348 (25%)",
    Recurrence_status: "No",
    Survival_status: "Alive",
    caseId: "C3L-00677",
    Immune_subtype: "CD8-/IFNG-",
  },
  {
    Tumor: "PDAC",
    Sex: "Male",
    Age: "55-63 (25%-50%)",
    Participant_Country: "Poland",
    Grade: "G2",
    Stage: "II",
    BMI: ">=30",
    Alcohol_consumption: "drinker",
    Tobacco_use: "current smoker",
    Secondhand_smoke_exposure: "Yes",
    Overall_survival_in_days: "609-1016 (50%-75%)",
    Recurrence_status: "Yes",
    Survival_status: "Alive",
    caseId: "C3N-00514",
    Immune_subtype: "Eosinophils/Endothelial",
  },
  {
    Tumor: "HNSCC",
    Sex: "Female",
    Age: "<55 (25%)",
    Participant_Country: "Poland",
    Grade: "G2",
    Stage: "III",
    BMI: "18.5-25",
    Alcohol_consumption: "not_reported",
    Tobacco_use: "not_reported",
    Secondhand_smoke_exposure: "not_reported",
    Overall_survival_in_days: ">=1016 (>=75%)",
    Recurrence_status: "No",
    Survival_status: "Dead",
    caseId: "C3N-00295",
    Immune_subtype: "Fibroblast/TGFBeta",
  },
  {
    Tumor: "UCEC",
    Sex: "Female",
    Age: ">=70 (>=75%)",
    Participant_Country: "United States",
    Grade: "G2",
    Stage: "I",
    BMI: ">=30",
    Alcohol_consumption: "drinker",
    Tobacco_use: "non-smoker",
    Secondhand_smoke_exposure: "not_reported",
    Overall_survival_in_days: ">=1016 (>=75%)",
    Recurrence_status: "No",
    Survival_status: "Dead",
    caseId: "C3L-00605",
    Immune_subtype: "CD8-/IFNG-",
  },
  {
    Tumor: "GBM",
    Sex: "Female",
    Age: "<55 (25%)",
    Participant_Country: "Poland",
    Grade: "not_reported",
    Stage: "not_reported",
    BMI: "18.5-25",
    Alcohol_consumption: "non-drinker",
    Tobacco_use: "non-smoker",
    Secondhand_smoke_exposure: "No",
    Overall_survival_in_days: "609-1016 (50%-75%)",
    Recurrence_status: "Yes",
    Survival_status: "Dead",
    caseId: "C3N-01364",
    Immune_subtype: "CD8+/IFNG+",
  },
  {
    Tumor: "GBM",
    Sex: "Female",
    Age: ">=70 (>=75%)",
    Participant_Country: "China",
    Grade: "not_reported",
    Stage: "not_reported",
    BMI: "25-30",
    Alcohol_consumption: "non-drinker",
    Tobacco_use: "non-smoker",
    Secondhand_smoke_exposure: "No",
    Overall_survival_in_days: "609-1016 (50%-75%)",
    Recurrence_status: "Yes",
    Survival_status: "Alive",
    caseId: "C3N-01518",
    Immune_subtype: "CD8+/IFNG+",
  },
  {
    Tumor: "BRCA",
    Sex: "Female",
    Age: ">=70 (>=75%)",
    Participant_Country: "not_reported",
    Grade: "not_reported",
    Stage: "I",
    BMI: "not_reported",
    Alcohol_consumption: "not_reported",
    Tobacco_use: "not_reported",
    Secondhand_smoke_exposure: "not_reported",
    Overall_survival_in_days: "348-609 (25%-50%)",
    Recurrence_status: "No",
    Survival_status: "Dead",
    caseId: "18BR016",
    Immune_subtype: "Fibroblast/TGFBeta",
  },
  {
    Tumor: "HNSCC",
    Sex: "Female",
    Age: "63-70 (50%-75%)",
    Participant_Country: "Poland",
    Grade: "G2",
    Stage: "IV",
    BMI: "18.5-25",
    Alcohol_consumption: "drinker",
    Tobacco_use: "current smoker",
    Secondhand_smoke_exposure: "No",
    Overall_survival_in_days: "<348 (25%)",
    Recurrence_status: "No",
    Survival_status: "Alive",
    caseId: "C3N-03008",
    Immune_subtype: "Fibroblast/TGFBeta",
  },
  {
    Tumor: "COAD",
    Sex: "Female",
    Age: "55-63 (25%-50%)",
    Participant_Country: "not_reported",
    Grade: "not_reported",
    Stage: "II",
    BMI: "not_reported",
    Alcohol_consumption: "not_reported",
    Tobacco_use: "not_reported",
    Secondhand_smoke_exposure: "not_reported",
    Overall_survival_in_days: "348-609 (25%-50%)",
    Recurrence_status: "No",
    Survival_status: "Dead",
    caseId: "11CO053",
    Immune_subtype: "CD8-/IFNG-",
  },
  {
    Tumor: "HGSC",
    Sex: "Female",
    Age: ">=70 (>=75%)",
    Participant_Country: "not_reported",
    Grade: "G3",
    Stage: "III",
    BMI: "not_reported",
    Alcohol_consumption: "not_reported",
    Tobacco_use: "not_reported",
    Secondhand_smoke_exposure: "not_reported",
    Overall_survival_in_days: ">=1016 (>=75%)",
    Recurrence_status: "Yes",
    Survival_status: "Alive",
    caseId: "04OV058",
    Immune_subtype: "Fibroblast/TGFBeta",
  },
  {
    Tumor: "LSCC",
    Sex: "Male",
    Age: "<55 (25%)",
    Participant_Country: "Bulgaria",
    Grade: "G2",
    Stage: "III",
    BMI: ">=30",
    Alcohol_consumption: "drinker",
    Tobacco_use: "current smoker",
    Secondhand_smoke_exposure: "Yes",
    Overall_survival_in_days: ">=1016 (>=75%)",
    Recurrence_status: "No",
    Survival_status: "Dead",
    caseId: "C3L-02650",
    Immune_subtype: "Eosinophils/Endothelial",
  },
  {
    Tumor: "BRCA",
    Sex: "Female",
    Age: "63-70 (50%-75%)",
    Participant_Country: "not_reported",
    Grade: "not_reported",
    Stage: "II",
    BMI: "not_reported",
    Alcohol_consumption: "not_reported",
    Tobacco_use: "not_reported",
    Secondhand_smoke_exposure: "not_reported",
    Overall_survival_in_days: "348-609 (25%-50%)",
    Recurrence_status: "No",
    Survival_status: "Dead",
    caseId: "11BR013",
    Immune_subtype: "CD8-/IFNG-",
  },
  {
    Tumor: "LUAD",
    Sex: "Female",
    Age: "63-70 (50%-75%)",
    Participant_Country: "United States",
    Grade: "G2",
    Stage: "I",
    BMI: "18.5-25",
    Alcohol_consumption: "non-drinker",
    Tobacco_use: "non-smoker",
    Secondhand_smoke_exposure: "not_reported",
    Overall_survival_in_days: "<348 (25%)",
    Recurrence_status: "No",
    Survival_status: "Dead",
    caseId: "C3N-00223",
    Immune_subtype: "Eosinophils/Endothelial",
  },
  {
    Tumor: "PDAC",
    Sex: "Male",
    Age: ">=70 (>=75%)",
    Participant_Country: "United States",
    Grade: "G2",
    Stage: "III",
    BMI: "25-30",
    Alcohol_consumption: "non-drinker",
    Tobacco_use: "past-smoker",
    Secondhand_smoke_exposure: "not_reported",
    Overall_survival_in_days: ">=1016 (>=75%)",
    Recurrence_status: "Yes",
    Survival_status: "Alive",
    caseId: "C3N-00249",
    Immune_subtype: "CD8-/IFNG-",
  },
  {
    Tumor: "GBM",
    Sex: "Male",
    Age: "55-63 (25%-50%)",
    Participant_Country: "Russia",
    Grade: "not_reported",
    Stage: "not_reported",
    BMI: "18.5-25",
    Alcohol_consumption: "non-drinker",
    Tobacco_use: "non-smoker",
    Secondhand_smoke_exposure: "not_reported",
    Overall_survival_in_days: "<348 (25%)",
    Recurrence_status: "Yes",
    Survival_status: "Alive",
    caseId: "C3L-01156",
    Immune_subtype: "Brain/Neuro",
  },
  {
    Tumor: "PDAC",
    Sex: "Female",
    Age: "55-63 (25%-50%)",
    Participant_Country: "United States",
    Grade: "G1",
    Stage: "II",
    BMI: ">=30",
    Alcohol_consumption: "non-drinker",
    Tobacco_use: "past-smoker",
    Secondhand_smoke_exposure: "not_reported",
    Overall_survival_in_days: "609-1016 (50%-75%)",
    Recurrence_status: "Yes",
    Survival_status: "Alive",
    caseId: "C3L-00928",
    Immune_subtype: "Eosinophils/Endothelial",
  },
  {
    Tumor: "UCEC",
    Sex: "Female",
    Age: "<55 (25%)",
    Participant_Country: "Ukraine",
    Grade: "G1",
    Stage: "I",
    BMI: "25-30",
    Alcohol_consumption: "non-drinker",
    Tobacco_use: "non-smoker",
    Secondhand_smoke_exposure: "not_reported",
    Overall_survival_in_days: "609-1016 (50%-75%)",
    Recurrence_status: "No",
    Survival_status: "Dead",
    caseId: "C3N-00386",
    Immune_subtype: "CD8-/IFNG-",
  },
  {
    Tumor: "BRCA",
    Sex: "Female",
    Age: "63-70 (50%-75%)",
    Participant_Country: "not_reported",
    Grade: "not_reported",
    Stage: "III",
    BMI: "not_reported",
    Alcohol_consumption: "not_reported",
    Tobacco_use: "not_reported",
    Secondhand_smoke_exposure: "not_reported",
    Overall_survival_in_days: "348-609 (25%-50%)",
    Recurrence_status: "No",
    Survival_status: "Dead",
    caseId: "11BR074",
    Immune_subtype: "Fibroblast/TGFBeta",
  },
  {
    Tumor: "LUAD",
    Sex: "Female",
    Age: "63-70 (50%-75%)",
    Participant_Country: "United States",
    Grade: "G2",
    Stage: "I",
    BMI: "18.5-25",
    Alcohol_consumption: "non-drinker",
    Tobacco_use: "current smoker",
    Secondhand_smoke_exposure: "not_reported",
    Overall_survival_in_days: "not_reported",
    Recurrence_status: "No",
    Survival_status: "not_reported",
    caseId: "C3N-00704",
    Immune_subtype: "CD8-/IFNG+",
  },
  {
    Tumor: "COAD",
    Sex: "Female",
    Age: ">=70 (>=75%)",
    Participant_Country: "not_reported",
    Grade: "not_reported",
    Stage: "III",
    BMI: "not_reported",
    Alcohol_consumption: "not_reported",
    Tobacco_use: "not_reported",
    Secondhand_smoke_exposure: "not_reported",
    Overall_survival_in_days: "348-609 (25%-50%)",
    Recurrence_status: "No",
    Survival_status: "Dead",
    caseId: "11CO079",
    Immune_subtype: "CD8-/IFNG-",
  },
  {
    Tumor: "HNSCC",
    Sex: "Male",
    Age: "55-63 (25%-50%)",
    Participant_Country: "Russia",
    Grade: "G1",
    Stage: "III",
    BMI: ">=30",
    Alcohol_consumption: "non-drinker",
    Tobacco_use: "past-smoker",
    Secondhand_smoke_exposure: "Yes",
    Overall_survival_in_days: ">=1016 (>=75%)",
    Recurrence_status: "Yes",
    Survival_status: "Alive",
    caseId: "C3L-00977",
    Immune_subtype: "CD8-/IFNG+",
  },
  {
    Tumor: "PDAC",
    Sex: "Male",
    Age: ">=70 (>=75%)",
    Participant_Country: "Canada",
    Grade: "G2",
    Stage: "II",
    BMI: "18.5-25",
    Alcohol_consumption: "past-drinker",
    Tobacco_use: "past-smoker",
    Secondhand_smoke_exposure: "not_reported",
    Overall_survival_in_days: "609-1016 (50%-75%)",
    Recurrence_status: "Yes",
    Survival_status: "Alive",
    caseId: "C3L-02115",
    Immune_subtype: "CD8-/IFNG-",
  },
  {
    Tumor: "GBM",
    Sex: "Female",
    Age: "<55 (25%)",
    Participant_Country: "China",
    Grade: "not_reported",
    Stage: "not_reported",
    BMI: "25-30",
    Alcohol_consumption: "non-drinker",
    Tobacco_use: "non-smoker",
    Secondhand_smoke_exposure: "No",
    Overall_survival_in_days: "348-609 (25%-50%)",
    Recurrence_status: "Yes",
    Survival_status: "Dead",
    caseId: "C3N-02181",
    Immune_subtype: "Eosinophils/Endothelial",
  },
  {
    Tumor: "HGSC",
    Sex: "Female",
    Age: "55-63 (25%-50%)",
    Participant_Country: "not_reported",
    Grade: "G3",
    Stage: "III",
    BMI: "not_reported",
    Alcohol_consumption: "not_reported",
    Tobacco_use: "not_reported",
    Secondhand_smoke_exposure: "not_reported",
    Overall_survival_in_days: "<348 (25%)",
    Recurrence_status: "No",
    Survival_status: "Alive",
    caseId: "17OV028",
    Immune_subtype: "CD8-/IFNG-",
  },
  {
    Tumor: "BRCA",
    Sex: "Female",
    Age: "<55 (25%)",
    Participant_Country: "not_reported",
    Grade: "not_reported",
    Stage: "II",
    BMI: "not_reported",
    Alcohol_consumption: "not_reported",
    Tobacco_use: "not_reported",
    Secondhand_smoke_exposure: "not_reported",
    Overall_survival_in_days: "<348 (25%)",
    Recurrence_status: "No",
    Survival_status: "Dead",
    caseId: "22BR005",
    Immune_subtype: "CD8-/IFNG-",
  },
  {
    Tumor: "LUAD",
    Sex: "Male",
    Age: "55-63 (25%-50%)",
    Participant_Country: "China",
    Grade: "G3",
    Stage: "III",
    BMI: "25-30",
    Alcohol_consumption: "not_reported",
    Tobacco_use: "current smoker",
    Secondhand_smoke_exposure: "not_reported",
    Overall_survival_in_days: "348-609 (25%-50%)",
    Recurrence_status: "No",
    Survival_status: "Dead",
    caseId: "C3N-02003",
    Immune_subtype: "CD8+/IFNG+",
  },
  {
    Tumor: "PDAC",
    Sex: "Male",
    Age: "<55 (25%)",
    Participant_Country: "United States",
    Grade: "G2",
    Stage: "II",
    BMI: ">=30",
    Alcohol_consumption: "non-drinker",
    Tobacco_use: "non-smoker",
    Secondhand_smoke_exposure: "not_reported",
    Overall_survival_in_days: ">=1016 (>=75%)",
    Recurrence_status: "Yes",
    Survival_status: "Dead",
    caseId: "C3N-00198",
    Immune_subtype: "Eosinophils/Endothelial",
  },
  {
    Tumor: "UCEC",
    Sex: "Female",
    Age: "<55 (25%)",
    Participant_Country: "United States",
    Grade: "G1",
    Stage: "I",
    BMI: ">=30",
    Alcohol_consumption: "not_reported",
    Tobacco_use: "non-smoker",
    Secondhand_smoke_exposure: "not_reported",
    Overall_survival_in_days: ">=1016 (>=75%)",
    Recurrence_status: "No",
    Survival_status: "Dead",
    caseId: "C3L-01311",
    Immune_subtype: "CD8-/IFNG+",
  },
  {
    Tumor: "GBM",
    Sex: "Male",
    Age: "<55 (25%)",
    Participant_Country: "China",
    Grade: "not_reported",
    Stage: "not_reported",
    BMI: "18.5-25",
    Alcohol_consumption: "non-drinker",
    Tobacco_use: "non-smoker",
    Secondhand_smoke_exposure: "No",
    Overall_survival_in_days: "348-609 (25%-50%)",
    Recurrence_status: "No",
    Survival_status: "Alive",
    caseId: "C3N-01798",
    Immune_subtype: "Brain/Neuro",
  },
  {
    Tumor: "HNSCC",
    Sex: "Male",
    Age: "63-70 (50%-75%)",
    Participant_Country: "Ukraine",
    Grade: "G1",
    Stage: "I",
    BMI: "25-30",
    Alcohol_consumption: "drinker",
    Tobacco_use: "current smoker",
    Secondhand_smoke_exposure: "not_reported",
    Overall_survival_in_days: "609-1016 (50%-75%)",
    Recurrence_status: "Yes",
    Survival_status: "Dead",
    caseId: "C3N-00846",
    Immune_subtype: "CD8+/IFNG+",
  },
  {
    Tumor: "LSCC",
    Sex: "Male",
    Age: "55-63 (25%-50%)",
    Participant_Country: "Bulgaria",
    Grade: "G3",
    Stage: "II",
    BMI: "25-30",
    Alcohol_consumption: "drinker",
    Tobacco_use: "current smoker",
    Secondhand_smoke_exposure: "Yes",
    Overall_survival_in_days: "<348 (25%)",
    Recurrence_status: "No",
    Survival_status: "Dead",
    caseId: "C3L-04013",
    Immune_subtype: "CD8-/IFNG-",
  },
  {
    Tumor: "COAD",
    Sex: "Female",
    Age: "63-70 (50%-75%)",
    Participant_Country: "not_reported",
    Grade: "not_reported",
    Stage: "III",
    BMI: "not_reported",
    Alcohol_consumption: "not_reported",
    Tobacco_use: "not_reported",
    Secondhand_smoke_exposure: "not_reported",
    Overall_survival_in_days: "<348 (25%)",
    Recurrence_status: "No",
    Survival_status: "Dead",
    caseId: "09CO005",
    Immune_subtype: "CD8-/IFNG-",
  },
  {
    Tumor: "LUAD",
    Sex: "Male",
    Age: "63-70 (50%-75%)",
    Participant_Country: "China",
    Grade: "G3",
    Stage: "I",
    BMI: "25-30",
    Alcohol_consumption: "non-drinker",
    Tobacco_use: "current smoker",
    Secondhand_smoke_exposure: "not_reported",
    Overall_survival_in_days: "609-1016 (50%-75%)",
    Recurrence_status: "No",
    Survival_status: "Dead",
    caseId: "C3N-02423",
    Immune_subtype: "CD8+/IFNG+",
  },
  {
    Tumor: "COAD",
    Sex: "Female",
    Age: "<55 (25%)",
    Participant_Country: "not_reported",
    Grade: "not_reported",
    Stage: "III",
    BMI: "not_reported",
    Alcohol_consumption: "not_reported",
    Tobacco_use: "not_reported",
    Secondhand_smoke_exposure: "not_reported",
    Overall_survival_in_days: "<348 (25%)",
    Recurrence_status: "No",
    Survival_status: "Dead",
    caseId: "09CO018",
    Immune_subtype: "CD8-/IFNG+",
  },
  {
    Tumor: "ccRCC",
    Sex: "Female",
    Age: ">=70 (>=75%)",
    Participant_Country: "United States",
    Grade: "G2",
    Stage: "I",
    BMI: "25-30",
    Alcohol_consumption: "non-drinker",
    Tobacco_use: "non-smoker",
    Secondhand_smoke_exposure: "not_reported",
    Overall_survival_in_days: "<348 (25%)",
    Recurrence_status: "No",
    Survival_status: "Dead",
    caseId: "C3L-01352",
    Immune_subtype: "ccRCC/Endothelial",
  },
  {
    Tumor: "GBM",
    Sex: "Female",
    Age: ">=70 (>=75%)",
    Participant_Country: "United States",
    Grade: "not_reported",
    Stage: "not_reported",
    BMI: ">=30",
    Alcohol_consumption: "non-drinker",
    Tobacco_use: "non-smoker",
    Secondhand_smoke_exposure: "not_reported",
    Overall_survival_in_days: "609-1016 (50%-75%)",
    Recurrence_status: "Yes",
    Survival_status: "Alive",
    caseId: "C3L-03727",
    Immune_subtype: "Fibroblast/TGFBeta",
  },
  {
    Tumor: "HNSCC",
    Sex: "Male",
    Age: "55-63 (25%-50%)",
    Participant_Country: "Poland",
    Grade: "G2",
    Stage: "III",
    BMI: "25-30",
    Alcohol_consumption: "not_reported",
    Tobacco_use: "not_reported",
    Secondhand_smoke_exposure: "not_reported",
    Overall_survival_in_days: ">=1016 (>=75%)",
    Recurrence_status: "No",
    Survival_status: "Dead",
    caseId: "C3N-00498",
    Immune_subtype: "CD8-/IFNG-",
  },
  {
    Tumor: "UCEC",
    Sex: "Female",
    Age: "55-63 (25%-50%)",
    Participant_Country: "United States",
    Grade: "G1",
    Stage: "I",
    BMI: "25-30",
    Alcohol_consumption: "drinker",
    Tobacco_use: "non-smoker",
    Secondhand_smoke_exposure: "not_reported",
    Overall_survival_in_days: "609-1016 (50%-75%)",
    Recurrence_status: "No",
    Survival_status: "Dead",
    caseId: "C3L-00137",
    Immune_subtype: "CD8-/IFNG-",
  },
  {
    Tumor: "HGSC",
    Sex: "Female",
    Age: "63-70 (50%-75%)",
    Participant_Country: "not_reported",
    Grade: "G2",
    Stage: "III",
    BMI: "not_reported",
    Alcohol_consumption: "not_reported",
    Tobacco_use: "not_reported",
    Secondhand_smoke_exposure: "not_reported",
    Overall_survival_in_days: "not_reported",
    Recurrence_status: "No",
    Survival_status: "not_reported",
    caseId: "11OV002",
    Immune_subtype: "CD8+/IFNG+",
  },
  {
    Tumor: "ccRCC",
    Sex: "Male",
    Age: "<55 (25%)",
    Participant_Country: "United States",
    Grade: "G3",
    Stage: "I",
    BMI: "25-30",
    Alcohol_consumption: "drinker",
    Tobacco_use: "past-smoker",
    Secondhand_smoke_exposure: "not_reported",
    Overall_survival_in_days: ">=1016 (>=75%)",
    Recurrence_status: "No",
    Survival_status: "Dead",
    caseId: "C3L-01836",
    Immune_subtype: "ccRCC/Endothelial",
  },
  {
    Tumor: "LSCC",
    Sex: "Male",
    Age: "55-63 (25%-50%)",
    Participant_Country: "United States",
    Grade: "G2",
    Stage: "II",
    BMI: "18.5-25",
    Alcohol_consumption: "non-drinker",
    Tobacco_use: "current smoker",
    Secondhand_smoke_exposure: "not_reported",
    Overall_survival_in_days: ">=1016 (>=75%)",
    Recurrence_status: "Yes",
    Survival_status: "Dead",
    caseId: "C3L-02951",
    Immune_subtype: "Eosinophils/Endothelial",
  },
  {
    Tumor: "GBM",
    Sex: "Male",
    Age: "55-63 (25%-50%)",
    Participant_Country: "China",
    Grade: "not_reported",
    Stage: "not_reported",
    BMI: "18.5-25",
    Alcohol_consumption: "non-drinker",
    Tobacco_use: "non-smoker",
    Secondhand_smoke_exposure: "No",
    Overall_survival_in_days: "348-609 (25%-50%)",
    Recurrence_status: "Yes",
    Survival_status: "Alive",
    caseId: "C3N-02188",
    Immune_subtype: "CD8-/IFNG+",
  },
  {
    Tumor: "UCEC",
    Sex: "Female",
    Age: ">=70 (>=75%)",
    Participant_Country: "United States",
    Grade: "G1",
    Stage: "I",
    BMI: "18.5-25",
    Alcohol_consumption: "drinker",
    Tobacco_use: "current smoker",
    Secondhand_smoke_exposure: "not_reported",
    Overall_survival_in_days: "348-609 (25%-50%)",
    Recurrence_status: "No",
    Survival_status: "Dead",
    caseId: "C3N-00866",
    Immune_subtype: "CD8-/IFNG-",
  },
  {
    Tumor: "HGSC",
    Sex: "Female",
    Age: ">=70 (>=75%)",
    Participant_Country: "not_reported",
    Grade: "G3",
    Stage: "III",
    BMI: "not_reported",
    Alcohol_consumption: "not_reported",
    Tobacco_use: "not_reported",
    Secondhand_smoke_exposure: "not_reported",
    Overall_survival_in_days: "348-609 (25%-50%)",
    Recurrence_status: "No",
    Survival_status: "Dead",
    caseId: "02OV015",
    Immune_subtype: "CD8-/IFNG-",
  },
  {
    Tumor: "LUAD",
    Sex: "Female",
    Age: "<55 (25%)",
    Participant_Country: "Vietnam",
    Grade: "G2",
    Stage: "I",
    BMI: "18.5-25",
    Alcohol_consumption: "non-drinker",
    Tobacco_use: "non-smoker",
    Secondhand_smoke_exposure: "No",
    Overall_survival_in_days: "348-609 (25%-50%)",
    Recurrence_status: "No",
    Survival_status: "Dead",
    caseId: "C3N-00559",
    Immune_subtype: "Eosinophils/Endothelial",
  },
  {
    Tumor: "GBM",
    Sex: "Male",
    Age: ">=70 (>=75%)",
    Participant_Country: "United States",
    Grade: "not_reported",
    Stage: "not_reported",
    BMI: "25-30",
    Alcohol_consumption: "drinker",
    Tobacco_use: "not_reported",
    Secondhand_smoke_exposure: "not_reported",
    Overall_survival_in_days: "609-1016 (50%-75%)",
    Recurrence_status: "No",
    Survival_status: "Alive",
    caseId: "C3L-01327",
    Immune_subtype: "CD8-/IFNG-",
  },
  {
    Tumor: "GBM",
    Sex: "Male",
    Age: ">=70 (>=75%)",
    Participant_Country: "United States",
    Grade: "not_reported",
    Stage: "not_reported",
    BMI: "18.5-25",
    Alcohol_consumption: "not_reported",
    Tobacco_use: "not_reported",
    Secondhand_smoke_exposure: "not_reported",
    Overall_survival_in_days: ">=1016 (>=75%)",
    Recurrence_status: "No",
    Survival_status: "Dead",
    caseId: "C3L-03744",
    Immune_subtype: "Fibroblast/TGFBeta",
  },
  {
    Tumor: "ccRCC",
    Sex: "Female",
    Age: ">=70 (>=75%)",
    Participant_Country: "United States",
    Grade: "G3",
    Stage: "I",
    BMI: ">=30",
    Alcohol_consumption: "non-drinker",
    Tobacco_use: "past-smoker",
    Secondhand_smoke_exposure: "not_reported",
    Overall_survival_in_days: ">=1016 (>=75%)",
    Recurrence_status: "No",
    Survival_status: "Dead",
    caseId: "C3L-00910",
    Immune_subtype: "ccRCC/Endothelial",
  },
  {
    Tumor: "GBM",
    Sex: "Male",
    Age: "<55 (25%)",
    Participant_Country: "Russia",
    Grade: "not_reported",
    Stage: "not_reported",
    BMI: "25-30",
    Alcohol_consumption: "non-drinker",
    Tobacco_use: "non-smoker",
    Secondhand_smoke_exposure: "No",
    Overall_survival_in_days: "<348 (25%)",
    Recurrence_status: "No",
    Survival_status: "Alive",
    caseId: "C3L-01049",
    Immune_subtype: "CD8-/IFNG+",
  },
  {
    Tumor: "BRCA",
    Sex: "Female",
    Age: "<55 (25%)",
    Participant_Country: "not_reported",
    Grade: "not_reported",
    Stage: "III",
    BMI: "not_reported",
    Alcohol_consumption: "not_reported",
    Tobacco_use: "not_reported",
    Secondhand_smoke_exposure: "not_reported",
    Overall_survival_in_days: "348-609 (25%-50%)",
    Recurrence_status: "No",
    Survival_status: "Dead",
    caseId: "11BR049",
    Immune_subtype: "CD8-/IFNG+",
  },
  {
    Tumor: "BRCA",
    Sex: "Female",
    Age: ">=70 (>=75%)",
    Participant_Country: "not_reported",
    Grade: "not_reported",
    Stage: "III",
    BMI: "not_reported",
    Alcohol_consumption: "not_reported",
    Tobacco_use: "not_reported",
    Secondhand_smoke_exposure: "not_reported",
    Overall_survival_in_days: "348-609 (25%-50%)",
    Recurrence_status: "No",
    Survival_status: "Dead",
    caseId: "01BR030",
    Immune_subtype: "CD8+/IFNG+",
  },
  {
    Tumor: "BRCA",
    Sex: "Female",
    Age: "55-63 (25%-50%)",
    Participant_Country: "not_reported",
    Grade: "not_reported",
    Stage: "II",
    BMI: "not_reported",
    Alcohol_consumption: "not_reported",
    Tobacco_use: "not_reported",
    Secondhand_smoke_exposure: "not_reported",
    Overall_survival_in_days: "<348 (25%)",
    Recurrence_status: "No",
    Survival_status: "Dead",
    caseId: "05BR029",
    Immune_subtype: "CD8-/IFNG+",
  },
  {
    Tumor: "COAD",
    Sex: "Female",
    Age: "63-70 (50%-75%)",
    Participant_Country: "not_reported",
    Grade: "not_reported",
    Stage: "III",
    BMI: "not_reported",
    Alcohol_consumption: "not_reported",
    Tobacco_use: "not_reported",
    Secondhand_smoke_exposure: "not_reported",
    Overall_survival_in_days: "<348 (25%)",
    Recurrence_status: "Yes",
    Survival_status: "Alive",
    caseId: "11CO052",
    Immune_subtype: "CD8+/IFNG+",
  },
  {
    Tumor: "ccRCC",
    Sex: "Male",
    Age: ">=70 (>=75%)",
    Participant_Country: "Ukraine",
    Grade: "G2",
    Stage: "I",
    BMI: ">=30",
    Alcohol_consumption: "drinker",
    Tobacco_use: "non-smoker",
    Secondhand_smoke_exposure: "not_reported",
    Overall_survival_in_days: "609-1016 (50%-75%)",
    Recurrence_status: "No",
    Survival_status: "Dead",
    caseId: "C3N-01522",
    Immune_subtype: "CD8-/IFNG-",
  },
  {
    Tumor: "UCEC",
    Sex: "Female",
    Age: "63-70 (50%-75%)",
    Participant_Country: "United States",
    Grade: "G1",
    Stage: "I",
    BMI: ">=30",
    Alcohol_consumption: "past-drinker",
    Tobacco_use: "past-smoker",
    Secondhand_smoke_exposure: "not_reported",
    Overall_survival_in_days: ">=1016 (>=75%)",
    Recurrence_status: "Yes",
    Survival_status: "Alive",
    caseId: "C3L-00918",
    Immune_subtype: "Fibroblast/TGFBeta",
  },
  {
    Tumor: "LUAD",
    Sex: "Female",
    Age: "55-63 (25%-50%)",
    Participant_Country: "United States",
    Grade: "G3",
    Stage: "II",
    BMI: "18.5-25",
    Alcohol_consumption: "non-drinker",
    Tobacco_use: "past-smoker",
    Secondhand_smoke_exposure: "Yes",
    Overall_survival_in_days: "609-1016 (50%-75%)",
    Recurrence_status: "No",
    Survival_status: "Alive",
    caseId: "C3L-02365",
    Immune_subtype: "CD8-/IFNG-",
  },
  {
    Tumor: "BRCA",
    Sex: "Female",
    Age: "<55 (25%)",
    Participant_Country: "not_reported",
    Grade: "not_reported",
    Stage: "II",
    BMI: "not_reported",
    Alcohol_consumption: "not_reported",
    Tobacco_use: "not_reported",
    Secondhand_smoke_exposure: "not_reported",
    Overall_survival_in_days: "348-609 (25%-50%)",
    Recurrence_status: "No",
    Survival_status: "Dead",
    caseId: "11BR031",
    Immune_subtype: "Fibroblast/TGFBeta",
  },
  {
    Tumor: "PDAC",
    Sex: "Male",
    Age: "55-63 (25%-50%)",
    Participant_Country: "not_reported",
    Grade: "G2",
    Stage: "I",
    BMI: "25-30",
    Alcohol_consumption: "drinker",
    Tobacco_use: "non-smoker",
    Secondhand_smoke_exposure: "not_reported",
    Overall_survival_in_days: "609-1016 (50%-75%)",
    Recurrence_status: "Yes",
    Survival_status: "Alive",
    caseId: "C3L-03635",
    Immune_subtype: "Fibroblast/TGFBeta",
  },
  {
    Tumor: "LUAD",
    Sex: "Male",
    Age: "55-63 (25%-50%)",
    Participant_Country: "Vietnam",
    Grade: "G3",
    Stage: "III",
    BMI: "<18.5",
    Alcohol_consumption: "non-drinker",
    Tobacco_use: "non-smoker",
    Secondhand_smoke_exposure: "No",
    Overall_survival_in_days: "348-609 (25%-50%)",
    Recurrence_status: "No",
    Survival_status: "Dead",
    caseId: "C3N-01023",
    Immune_subtype: "CD8-/IFNG+",
  },
  {
    Tumor: "PDAC",
    Sex: "Female",
    Age: "55-63 (25%-50%)",
    Participant_Country: "United States",
    Grade: "G3",
    Stage: "III",
    BMI: ">=30",
    Alcohol_consumption: "drinker",
    Tobacco_use: "past-smoker",
    Secondhand_smoke_exposure: "not_reported",
    Overall_survival_in_days: "348-609 (25%-50%)",
    Recurrence_status: "No",
    Survival_status: "Dead",
    caseId: "C3N-02010",
    Immune_subtype: "CD8-/IFNG-",
  },
  {
    Tumor: "HNSCC",
    Sex: "Male",
    Age: ">=70 (>=75%)",
    Participant_Country: "United States",
    Grade: "G1",
    Stage: "III",
    BMI: "not_reported",
    Alcohol_consumption: "not_reported",
    Tobacco_use: "not_reported",
    Secondhand_smoke_exposure: "not_reported",
    Overall_survival_in_days: "not_reported",
    Recurrence_status: "No",
    Survival_status: "not_reported",
    caseId: "C3N-03045",
    Immune_subtype: "CD8-/IFNG-",
  },
  {
    Tumor: "GBM",
    Sex: "Male",
    Age: "<55 (25%)",
    Participant_Country: "Russia",
    Grade: "not_reported",
    Stage: "not_reported",
    BMI: ">=30",
    Alcohol_consumption: "non-drinker",
    Tobacco_use: "past-smoker",
    Secondhand_smoke_exposure: "Yes",
    Overall_survival_in_days: "348-609 (25%-50%)",
    Recurrence_status: "Yes",
    Survival_status: "Alive",
    caseId: "C3L-01046",
    Immune_subtype: "CD8+/IFNG+",
  },
  {
    Tumor: "HGSC",
    Sex: "Female",
    Age: "63-70 (50%-75%)",
    Participant_Country: "not_reported",
    Grade: "G3",
    Stage: "III",
    BMI: "not_reported",
    Alcohol_consumption: "not_reported",
    Tobacco_use: "not_reported",
    Secondhand_smoke_exposure: "not_reported",
    Overall_survival_in_days: "<348 (25%)",
    Recurrence_status: "Yes",
    Survival_status: "Dead",
    caseId: "04OV040",
    Immune_subtype: "CD8-/IFNG-",
  },
  {
    Tumor: "LSCC",
    Sex: "Female",
    Age: ">=70 (>=75%)",
    Participant_Country: "United States",
    Grade: "G3",
    Stage: "II",
    BMI: "25-30",
    Alcohol_consumption: "non-drinker",
    Tobacco_use: "past-smoker",
    Secondhand_smoke_exposure: "not_reported",
    Overall_survival_in_days: ">=1016 (>=75%)",
    Recurrence_status: "No",
    Survival_status: "Dead",
    caseId: "C3N-00247",
    Immune_subtype: "CD8+/IFNG+",
  },
  {
    Tumor: "BRCA",
    Sex: "Female",
    Age: "55-63 (25%-50%)",
    Participant_Country: "not_reported",
    Grade: "not_reported",
    Stage: "II",
    BMI: "not_reported",
    Alcohol_consumption: "not_reported",
    Tobacco_use: "not_reported",
    Secondhand_smoke_exposure: "not_reported",
    Overall_survival_in_days: "348-609 (25%-50%)",
    Recurrence_status: "No",
    Survival_status: "Dead",
    caseId: "11BR019",
    Immune_subtype: "CD8-/IFNG+",
  },
  {
    Tumor: "GBM",
    Sex: "Male",
    Age: "55-63 (25%-50%)",
    Participant_Country: "United States",
    Grade: "not_reported",
    Stage: "not_reported",
    BMI: "25-30",
    Alcohol_consumption: "drinker",
    Tobacco_use: "non-smoker",
    Secondhand_smoke_exposure: "not_reported",
    Overall_survival_in_days: "<348 (25%)",
    Recurrence_status: "No",
    Survival_status: "Alive",
    caseId: "C3L-02705",
    Immune_subtype: "Fibroblast/TGFBeta",
  },
  {
    Tumor: "BRCA",
    Sex: "Female",
    Age: ">=70 (>=75%)",
    Participant_Country: "not_reported",
    Grade: "not_reported",
    Stage: "III",
    BMI: "not_reported",
    Alcohol_consumption: "not_reported",
    Tobacco_use: "not_reported",
    Secondhand_smoke_exposure: "not_reported",
    Overall_survival_in_days: "<348 (25%)",
    Recurrence_status: "No",
    Survival_status: "Alive",
    caseId: "11BR017",
    Immune_subtype: "CD8-/IFNG+",
  },
  {
    Tumor: "BRCA",
    Sex: "Female",
    Age: "<55 (25%)",
    Participant_Country: "not_reported",
    Grade: "not_reported",
    Stage: "not_reported",
    BMI: "not_reported",
    Alcohol_consumption: "not_reported",
    Tobacco_use: "not_reported",
    Secondhand_smoke_exposure: "not_reported",
    Overall_survival_in_days: "not_reported",
    Recurrence_status: "No",
    Survival_status: "not_reported",
    caseId: "01BR020",
    Immune_subtype: "not_reported",
  },
  {
    Tumor: "UCEC",
    Sex: "Female",
    Age: ">=70 (>=75%)",
    Participant_Country: "United States",
    Grade: "G2",
    Stage: "III",
    BMI: "18.5-25",
    Alcohol_consumption: "not_reported",
    Tobacco_use: "not_reported",
    Secondhand_smoke_exposure: "not_reported",
    Overall_survival_in_days: "<348 (25%)",
    Recurrence_status: "No",
    Survival_status: "Alive",
    caseId: "C3L-00771",
    Immune_subtype: "Fibroblast/TGFBeta",
  },
  {
    Tumor: "GBM",
    Sex: "Male",
    Age: "<55 (25%)",
    Participant_Country: "Poland",
    Grade: "not_reported",
    Stage: "not_reported",
    BMI: ">=30",
    Alcohol_consumption: "drinker",
    Tobacco_use: "non-smoker",
    Secondhand_smoke_exposure: "No",
    Overall_survival_in_days: "<348 (25%)",
    Recurrence_status: "No",
    Survival_status: "Alive",
    caseId: "C3N-00663",
    Immune_subtype: "Fibroblast/TGFBeta",
  },
  {
    Tumor: "LUAD",
    Sex: "Female",
    Age: "55-63 (25%-50%)",
    Participant_Country: "Vietnam",
    Grade: "G3",
    Stage: "I",
    BMI: "25-30",
    Alcohol_consumption: "non-drinker",
    Tobacco_use: "non-smoker",
    Secondhand_smoke_exposure: "No",
    Overall_survival_in_days: "348-609 (25%-50%)",
    Recurrence_status: "No",
    Survival_status: "Dead",
    caseId: "C3N-01413",
    Immune_subtype: "CD8+/IFNG+",
  },
  {
    Tumor: "COAD",
    Sex: "Male",
    Age: "63-70 (50%-75%)",
    Participant_Country: "not_reported",
    Grade: "not_reported",
    Stage: "III",
    BMI: "not_reported",
    Alcohol_consumption: "not_reported",
    Tobacco_use: "not_reported",
    Secondhand_smoke_exposure: "not_reported",
    Overall_survival_in_days: "348-609 (25%-50%)",
    Recurrence_status: "No",
    Survival_status: "Dead",
    caseId: "16CO002",
    Immune_subtype: "CD8-/IFNG-",
  },
  {
    Tumor: "ccRCC",
    Sex: "Female",
    Age: "55-63 (25%-50%)",
    Participant_Country: "United States",
    Grade: "G4",
    Stage: "IV",
    BMI: "25-30",
    Alcohol_consumption: "drinker",
    Tobacco_use: "non-smoker",
    Secondhand_smoke_exposure: "not_reported",
    Overall_survival_in_days: "<348 (25%)",
    Recurrence_status: "No",
    Survival_status: "Alive",
    caseId: "C3L-00011",
    Immune_subtype: "CD8-/IFNG-",
  },
  {
    Tumor: "HGSC",
    Sex: "Female",
    Age: "55-63 (25%-50%)",
    Participant_Country: "not_reported",
    Grade: "G1",
    Stage: "III",
    BMI: "not_reported",
    Alcohol_consumption: "not_reported",
    Tobacco_use: "not_reported",
    Secondhand_smoke_exposure: "not_reported",
    Overall_survival_in_days: "not_reported",
    Recurrence_status: "No",
    Survival_status: "Dead",
    caseId: "13OV003",
    Immune_subtype: "Fibroblast/TGFBeta",
  },
  {
    Tumor: "LSCC",
    Sex: "Male",
    Age: "55-63 (25%-50%)",
    Participant_Country: "Ukraine",
    Grade: "not_reported",
    Stage: "I",
    BMI: "25-30",
    Alcohol_consumption: "non-drinker",
    Tobacco_use: "non-smoker",
    Secondhand_smoke_exposure: "not_reported",
    Overall_survival_in_days: "<348 (25%)",
    Recurrence_status: "No",
    Survival_status: "Dead",
    caseId: "C3N-03886",
    Immune_subtype: "CD8-/IFNG-",
  },
  {
    Tumor: "HGSC",
    Sex: "Female",
    Age: ">=70 (>=75%)",
    Participant_Country: "not_reported",
    Grade: "G3",
    Stage: "III",
    BMI: "not_reported",
    Alcohol_consumption: "not_reported",
    Tobacco_use: "not_reported",
    Secondhand_smoke_exposure: "not_reported",
    Overall_survival_in_days: "<348 (25%)",
    Recurrence_status: "No",
    Survival_status: "Dead",
    caseId: "04OV011",
    Immune_subtype: "CD8-/IFNG-",
  },
  {
    Tumor: "LSCC",
    Sex: "Male",
    Age: "63-70 (50%-75%)",
    Participant_Country: "Ukraine",
    Grade: "not_reported",
    Stage: "I",
    BMI: "18.5-25",
    Alcohol_consumption: "drinker",
    Tobacco_use: "non-smoker",
    Secondhand_smoke_exposure: "not_reported",
    Overall_survival_in_days: "<348 (25%)",
    Recurrence_status: "No",
    Survival_status: "Dead",
    caseId: "C3N-03486",
    Immune_subtype: "CD8-/IFNG+",
  },
  {
    Tumor: "COAD",
    Sex: "Male",
    Age: ">=70 (>=75%)",
    Participant_Country: "not_reported",
    Grade: "not_reported",
    Stage: "IV",
    BMI: "not_reported",
    Alcohol_consumption: "not_reported",
    Tobacco_use: "not_reported",
    Secondhand_smoke_exposure: "not_reported",
    Overall_survival_in_days: "348-609 (25%-50%)",
    Recurrence_status: "No",
    Survival_status: "Dead",
    caseId: "11CO022",
    Immune_subtype: "Fibroblast/TGFBeta",
  },
  {
    Tumor: "HNSCC",
    Sex: "Female",
    Age: "55-63 (25%-50%)",
    Participant_Country: "Ukraine",
    Grade: "G1",
    Stage: "III",
    BMI: ">=30",
    Alcohol_consumption: "non-drinker",
    Tobacco_use: "non-smoker",
    Secondhand_smoke_exposure: "not_reported",
    Overall_survival_in_days: "609-1016 (50%-75%)",
    Recurrence_status: "No",
    Survival_status: "Dead",
    caseId: "C3N-03928",
    Immune_subtype: "CD8+/IFNG+",
  },
  {
    Tumor: "LUAD",
    Sex: "Male",
    Age: "55-63 (25%-50%)",
    Participant_Country: "Vietnam",
    Grade: "G3",
    Stage: "III",
    BMI: "18.5-25",
    Alcohol_consumption: "drinker",
    Tobacco_use: "current smoker",
    Secondhand_smoke_exposure: "No",
    Overall_survival_in_days: "348-609 (25%-50%)",
    Recurrence_status: "Yes",
    Survival_status: "Dead",
    caseId: "C3N-01016",
    Immune_subtype: "CD8-/IFNG+",
  },
  {
    Tumor: "HNSCC",
    Sex: "Male",
    Age: "63-70 (50%-75%)",
    Participant_Country: "Poland",
    Grade: "G1",
    Stage: "II",
    BMI: "18.5-25",
    Alcohol_consumption: "past-drinker",
    Tobacco_use: "current smoker",
    Secondhand_smoke_exposure: "Yes",
    Overall_survival_in_days: ">=1016 (>=75%)",
    Recurrence_status: "No",
    Survival_status: "Dead",
    caseId: "C3N-03458",
    Immune_subtype: "CD8-/IFNG-",
  },
  {
    Tumor: "PDAC",
    Sex: "Male",
    Age: "63-70 (50%-75%)",
    Participant_Country: "China",
    Grade: "G3",
    Stage: "I",
    BMI: "18.5-25",
    Alcohol_consumption: "non-drinker",
    Tobacco_use: "non-smoker",
    Secondhand_smoke_exposure: "not_reported",
    Overall_survival_in_days: ">=1016 (>=75%)",
    Recurrence_status: "No",
    Survival_status: "Dead",
    caseId: "C3N-02585",
    Immune_subtype: "CD8-/IFNG+",
  },
  {
    Tumor: "HNSCC",
    Sex: "Female",
    Age: "63-70 (50%-75%)",
    Participant_Country: "Poland",
    Grade: "G3",
    Stage: "IV",
    BMI: "18.5-25",
    Alcohol_consumption: "not_reported",
    Tobacco_use: "not_reported",
    Secondhand_smoke_exposure: "not_reported",
    Overall_survival_in_days: "<348 (25%)",
    Recurrence_status: "No",
    Survival_status: "Alive",
    caseId: "C3N-01339",
    Immune_subtype: "CD8-/IFNG-",
  },
  {
    Tumor: "UCEC",
    Sex: "Female",
    Age: ">=70 (>=75%)",
    Participant_Country: "United States",
    Grade: "G1",
    Stage: "I",
    BMI: ">=30",
    Alcohol_consumption: "drinker",
    Tobacco_use: "not_reported",
    Secondhand_smoke_exposure: "not_reported",
    Overall_survival_in_days: ">=1016 (>=75%)",
    Recurrence_status: "No",
    Survival_status: "Dead",
    caseId: "C3L-01256",
    Immune_subtype: "Fibroblast/TGFBeta",
  },
  {
    Tumor: "PDAC",
    Sex: "Female",
    Age: ">=70 (>=75%)",
    Participant_Country: "United States",
    Grade: "G3",
    Stage: "II",
    BMI: "18.5-25",
    Alcohol_consumption: "drinker",
    Tobacco_use: "non-smoker",
    Secondhand_smoke_exposure: "not_reported",
    Overall_survival_in_days: "609-1016 (50%-75%)",
    Recurrence_status: "No",
    Survival_status: "Alive",
    caseId: "C3L-03743",
    Immune_subtype: "CD8-/IFNG-",
  },
  {
    Tumor: "LSCC",
    Sex: "Male",
    Age: "63-70 (50%-75%)",
    Participant_Country: "Poland",
    Grade: "G2",
    Stage: "III",
    BMI: "25-30",
    Alcohol_consumption: "not_reported",
    Tobacco_use: "not_reported",
    Secondhand_smoke_exposure: "not_reported",
    Overall_survival_in_days: "609-1016 (50%-75%)",
    Recurrence_status: "No",
    Survival_status: "Alive",
    caseId: "C3N-01893",
    Immune_subtype: "CD8+/IFNG+",
  },
  {
    Tumor: "PDAC",
    Sex: "Male",
    Age: ">=70 (>=75%)",
    Participant_Country: "not_reported",
    Grade: "G2",
    Stage: "II",
    BMI: "25-30",
    Alcohol_consumption: "not_reported",
    Tobacco_use: "past-smoker",
    Secondhand_smoke_exposure: "not_reported",
    Overall_survival_in_days: "348-609 (25%-50%)",
    Recurrence_status: "Yes",
    Survival_status: "Dead",
    caseId: "C3L-03356",
    Immune_subtype: "Eosinophils/Endothelial",
  },
  {
    Tumor: "COAD",
    Sex: "Female",
    Age: "63-70 (50%-75%)",
    Participant_Country: "not_reported",
    Grade: "not_reported",
    Stage: "IV",
    BMI: "not_reported",
    Alcohol_consumption: "not_reported",
    Tobacco_use: "not_reported",
    Secondhand_smoke_exposure: "not_reported",
    Overall_survival_in_days: "<348 (25%)",
    Recurrence_status: "No",
    Survival_status: "Alive",
    caseId: "11CO044",
    Immune_subtype: "CD8-/IFNG+",
  },
  {
    Tumor: "UCEC",
    Sex: "Female",
    Age: ">=70 (>=75%)",
    Participant_Country: "United States",
    Grade: "G2",
    Stage: "I",
    BMI: ">=30",
    Alcohol_consumption: "not_reported",
    Tobacco_use: "past-smoker",
    Secondhand_smoke_exposure: "not_reported",
    Overall_survival_in_days: ">=1016 (>=75%)",
    Recurrence_status: "Yes",
    Survival_status: "Alive",
    caseId: "C3L-00090",
    Immune_subtype: "CD8-/IFNG-",
  },
  {
    Tumor: "COAD",
    Sex: "Male",
    Age: "63-70 (50%-75%)",
    Participant_Country: "not_reported",
    Grade: "not_reported",
    Stage: "II",
    BMI: "not_reported",
    Alcohol_consumption: "not_reported",
    Tobacco_use: "not_reported",
    Secondhand_smoke_exposure: "not_reported",
    Overall_survival_in_days: "<348 (25%)",
    Recurrence_status: "No",
    Survival_status: "Dead",
    caseId: "09CO014",
    Immune_subtype: "CD8-/IFNG+",
  },
  {
    Tumor: "ccRCC",
    Sex: "Female",
    Age: ">=70 (>=75%)",
    Participant_Country: "Poland",
    Grade: "G2",
    Stage: "I",
    BMI: "18.5-25",
    Alcohol_consumption: "drinker",
    Tobacco_use: "non-smoker",
    Secondhand_smoke_exposure: "No",
    Overall_survival_in_days: ">=1016 (>=75%)",
    Recurrence_status: "No",
    Survival_status: "Dead",
    caseId: "C3N-00317",
    Immune_subtype: "CD8+/IFNG+",
  },
  {
    Tumor: "HNSCC",
    Sex: "Male",
    Age: "63-70 (50%-75%)",
    Participant_Country: "Ukraine",
    Grade: "G3",
    Stage: "IV",
    BMI: "18.5-25",
    Alcohol_consumption: "non-drinker",
    Tobacco_use: "current smoker",
    Secondhand_smoke_exposure: "not_reported",
    Overall_survival_in_days: "609-1016 (50%-75%)",
    Recurrence_status: "No",
    Survival_status: "Dead",
    caseId: "C3N-02713",
    Immune_subtype: "CD8-/IFNG-",
  },
  {
    Tumor: "COAD",
    Sex: "Male",
    Age: ">=70 (>=75%)",
    Participant_Country: "not_reported",
    Grade: "not_reported",
    Stage: "I",
    BMI: "not_reported",
    Alcohol_consumption: "not_reported",
    Tobacco_use: "not_reported",
    Secondhand_smoke_exposure: "not_reported",
    Overall_survival_in_days: "<348 (25%)",
    Recurrence_status: "No",
    Survival_status: "Dead",
    caseId: "09CO019",
    Immune_subtype: "Fibroblast/TGFBeta",
  },
  {
    Tumor: "HNSCC",
    Sex: "Male",
    Age: "<55 (25%)",
    Participant_Country: "Russia",
    Grade: "G2",
    Stage: "IV",
    BMI: "18.5-25",
    Alcohol_consumption: "drinker",
    Tobacco_use: "current smoker",
    Secondhand_smoke_exposure: "Yes",
    Overall_survival_in_days: ">=1016 (>=75%)",
    Recurrence_status: "No",
    Survival_status: "Dead",
    caseId: "C3L-03378",
    Immune_subtype: "Fibroblast/TGFBeta",
  },
  {
    Tumor: "BRCA",
    Sex: "Female",
    Age: "<55 (25%)",
    Participant_Country: "not_reported",
    Grade: "not_reported",
    Stage: "II",
    BMI: "not_reported",
    Alcohol_consumption: "not_reported",
    Tobacco_use: "not_reported",
    Secondhand_smoke_exposure: "not_reported",
    Overall_survival_in_days: "348-609 (25%-50%)",
    Recurrence_status: "No",
    Survival_status: "Dead",
    caseId: "11BR038",
    Immune_subtype: "Eosinophils/Endothelial",
  },
  {
    Tumor: "UCEC",
    Sex: "Female",
    Age: ">=70 (>=75%)",
    Participant_Country: "United States",
    Grade: "G2",
    Stage: "I",
    BMI: ">=30",
    Alcohol_consumption: "non-drinker",
    Tobacco_use: "non-smoker",
    Secondhand_smoke_exposure: "not_reported",
    Overall_survival_in_days: ">=1016 (>=75%)",
    Recurrence_status: "No",
    Survival_status: "Dead",
    caseId: "C3L-00770",
    Immune_subtype: "Fibroblast/TGFBeta",
  },
  {
    Tumor: "UCEC",
    Sex: "Female",
    Age: "<55 (25%)",
    Participant_Country: "United States",
    Grade: "G2",
    Stage: "I",
    BMI: ">=30",
    Alcohol_consumption: "non-drinker",
    Tobacco_use: "non-smoker",
    Secondhand_smoke_exposure: "not_reported",
    Overall_survival_in_days: ">=1016 (>=75%)",
    Recurrence_status: "Yes",
    Survival_status: "Dead",
    caseId: "C3L-00161",
    Immune_subtype: "CD8-/IFNG-",
  },
  {
    Tumor: "LUAD",
    Sex: "Male",
    Age: "63-70 (50%-75%)",
    Participant_Country: "United States",
    Grade: "G2",
    Stage: "I",
    BMI: "25-30",
    Alcohol_consumption: "non-drinker",
    Tobacco_use: "past-smoker",
    Secondhand_smoke_exposure: "not_reported",
    Overall_survival_in_days: ">=1016 (>=75%)",
    Recurrence_status: "No",
    Survival_status: "Dead",
    caseId: "C3N-00199",
    Immune_subtype: "CD8-/IFNG-",
  },
  {
    Tumor: "COAD",
    Sex: "Female",
    Age: ">=70 (>=75%)",
    Participant_Country: "not_reported",
    Grade: "not_reported",
    Stage: "II",
    BMI: "not_reported",
    Alcohol_consumption: "not_reported",
    Tobacco_use: "not_reported",
    Secondhand_smoke_exposure: "not_reported",
    Overall_survival_in_days: "<348 (25%)",
    Recurrence_status: "No",
    Survival_status: "Dead",
    caseId: "09CO022",
    Immune_subtype: "CD8-/IFNG+",
  },
  {
    Tumor: "GBM",
    Sex: "Female",
    Age: "63-70 (50%-75%)",
    Participant_Country: "Poland",
    Grade: "not_reported",
    Stage: "not_reported",
    BMI: "18.5-25",
    Alcohol_consumption: "non-drinker",
    Tobacco_use: "not_reported",
    Secondhand_smoke_exposure: "not_reported",
    Overall_survival_in_days: "<348 (25%)",
    Recurrence_status: "No",
    Survival_status: "Alive",
    caseId: "C3N-01334",
    Immune_subtype: "Brain/Neuro",
  },
  {
    Tumor: "UCEC",
    Sex: "Female",
    Age: "63-70 (50%-75%)",
    Participant_Country: "Ukraine",
    Grade: "G2",
    Stage: "I",
    BMI: "25-30",
    Alcohol_consumption: "non-drinker",
    Tobacco_use: "non-smoker",
    Secondhand_smoke_exposure: "not_reported",
    Overall_survival_in_days: "609-1016 (50%-75%)",
    Recurrence_status: "No",
    Survival_status: "Dead",
    caseId: "C3N-00337",
    Immune_subtype: "CD8-/IFNG-",
  },
  {
    Tumor: "GBM",
    Sex: "Male",
    Age: ">=70 (>=75%)",
    Participant_Country: "United States",
    Grade: "not_reported",
    Stage: "not_reported",
    BMI: "25-30",
    Alcohol_consumption: "not_reported",
    Tobacco_use: "past-smoker",
    Secondhand_smoke_exposure: "not_reported",
    Overall_survival_in_days: "609-1016 (50%-75%)",
    Recurrence_status: "Yes",
    Survival_status: "Alive",
    caseId: "C3L-02504",
    Immune_subtype: "CD8-/IFNG-",
  },
  {
    Tumor: "BRCA",
    Sex: "Female",
    Age: "<55 (25%)",
    Participant_Country: "not_reported",
    Grade: "not_reported",
    Stage: "II",
    BMI: "not_reported",
    Alcohol_consumption: "not_reported",
    Tobacco_use: "not_reported",
    Secondhand_smoke_exposure: "not_reported",
    Overall_survival_in_days: "<348 (25%)",
    Recurrence_status: "No",
    Survival_status: "Dead",
    caseId: "11BR022",
    Immune_subtype: "Fibroblast/TGFBeta",
  },
  {
    Tumor: "ccRCC",
    Sex: "Male",
    Age: ">=70 (>=75%)",
    Participant_Country: "United States",
    Grade: "G3",
    Stage: "III",
    BMI: "25-30",
    Alcohol_consumption: "not_reported",
    Tobacco_use: "not_reported",
    Secondhand_smoke_exposure: "not_reported",
    Overall_survival_in_days: "609-1016 (50%-75%)",
    Recurrence_status: "No",
    Survival_status: "Dead",
    caseId: "C3L-00791",
    Immune_subtype: "CD8-/IFNG+",
  },
  {
    Tumor: "GBM",
    Sex: "Male",
    Age: "63-70 (50%-75%)",
    Participant_Country: "Russia",
    Grade: "not_reported",
    Stage: "not_reported",
    BMI: "25-30",
    Alcohol_consumption: "non-drinker",
    Tobacco_use: "non-smoker",
    Secondhand_smoke_exposure: "No",
    Overall_survival_in_days: "348-609 (25%-50%)",
    Recurrence_status: "No",
    Survival_status: "Dead",
    caseId: "C3L-01155",
    Immune_subtype: "Brain/Neuro",
  },
  {
    Tumor: "ccRCC",
    Sex: "Male",
    Age: "55-63 (25%-50%)",
    Participant_Country: "Poland",
    Grade: "G3",
    Stage: "II",
    BMI: "18.5-25",
    Alcohol_consumption: "drinker",
    Tobacco_use: "non-smoker",
    Secondhand_smoke_exposure: "No",
    Overall_survival_in_days: ">=1016 (>=75%)",
    Recurrence_status: "Yes",
    Survival_status: "Dead",
    caseId: "C3N-01651",
    Immune_subtype: "CD8-/IFNG+",
  },
  {
    Tumor: "HGSC",
    Sex: "Female",
    Age: "<55 (25%)",
    Participant_Country: "not_reported",
    Grade: "G3",
    Stage: "III",
    BMI: "not_reported",
    Alcohol_consumption: "not_reported",
    Tobacco_use: "not_reported",
    Secondhand_smoke_exposure: "not_reported",
    Overall_survival_in_days: "348-609 (25%-50%)",
    Recurrence_status: "No",
    Survival_status: "Dead",
    caseId: "02OV036",
    Immune_subtype: "CD8-/IFNG-",
  },
  {
    Tumor: "PDAC",
    Sex: "Male",
    Age: "55-63 (25%-50%)",
    Participant_Country: "Russia",
    Grade: "G2",
    Stage: "II",
    BMI: "25-30",
    Alcohol_consumption: "not_reported",
    Tobacco_use: "non-smoker",
    Secondhand_smoke_exposure: "Yes",
    Overall_survival_in_days: "<348 (25%)",
    Recurrence_status: "No",
    Survival_status: "Dead",
    caseId: "C3L-01051",
    Immune_subtype: "CD8-/IFNG-",
  },
  {
    Tumor: "ccRCC",
    Sex: "Male",
    Age: ">=70 (>=75%)",
    Participant_Country: "United States",
    Grade: "G2",
    Stage: "I",
    BMI: "25-30",
    Alcohol_consumption: "not_reported",
    Tobacco_use: "not_reported",
    Secondhand_smoke_exposure: "not_reported",
    Overall_survival_in_days: "<348 (25%)",
    Recurrence_status: "No",
    Survival_status: "Dead",
    caseId: "C3L-00812",
    Immune_subtype: "ccRCC/Endothelial",
  },
  {
    Tumor: "LUAD",
    Sex: "Female",
    Age: "63-70 (50%-75%)",
    Participant_Country: "Vietnam",
    Grade: "G2",
    Stage: "III",
    BMI: "<18.5",
    Alcohol_consumption: "non-drinker",
    Tobacco_use: "non-smoker",
    Secondhand_smoke_exposure: "No",
    Overall_survival_in_days: "348-609 (25%-50%)",
    Recurrence_status: "No",
    Survival_status: "Dead",
    caseId: "C3N-01416",
    Immune_subtype: "Eosinophils/Endothelial",
  },
  {
    Tumor: "ccRCC",
    Sex: "Male",
    Age: "63-70 (50%-75%)",
    Participant_Country: "United States",
    Grade: "G3",
    Stage: "III",
    BMI: ">=30",
    Alcohol_consumption: "non-drinker",
    Tobacco_use: "non-smoker",
    Secondhand_smoke_exposure: "not_reported",
    Overall_survival_in_days: "609-1016 (50%-75%)",
    Recurrence_status: "Yes",
    Survival_status: "Alive",
    caseId: "C3L-00607",
    Immune_subtype: "CD8+/IFNG+",
  },
  {
    Tumor: "HGSC",
    Sex: "Female",
    Age: "<55 (25%)",
    Participant_Country: "not_reported",
    Grade: "G3",
    Stage: "III",
    BMI: "not_reported",
    Alcohol_consumption: "not_reported",
    Tobacco_use: "not_reported",
    Secondhand_smoke_exposure: "not_reported",
    Overall_survival_in_days: "348-609 (25%-50%)",
    Recurrence_status: "No",
    Survival_status: "Dead",
    caseId: "15OV001",
    Immune_subtype: "Fibroblast/TGFBeta",
  },
  {
    Tumor: "GBM",
    Sex: "Female",
    Age: "<55 (25%)",
    Participant_Country: "China",
    Grade: "not_reported",
    Stage: "not_reported",
    BMI: "<18.5",
    Alcohol_consumption: "non-drinker",
    Tobacco_use: "non-smoker",
    Secondhand_smoke_exposure: "No",
    Overall_survival_in_days: "609-1016 (50%-75%)",
    Recurrence_status: "No",
    Survival_status: "Dead",
    caseId: "C3N-02783",
    Immune_subtype: "Brain/Neuro",
  },
  {
    Tumor: "BRCA",
    Sex: "Female",
    Age: ">=70 (>=75%)",
    Participant_Country: "not_reported",
    Grade: "not_reported",
    Stage: "II",
    BMI: "not_reported",
    Alcohol_consumption: "not_reported",
    Tobacco_use: "not_reported",
    Secondhand_smoke_exposure: "not_reported",
    Overall_survival_in_days: "<348 (25%)",
    Recurrence_status: "No",
    Survival_status: "Dead",
    caseId: "06BR003",
    Immune_subtype: "CD8-/IFNG-",
  },
  {
    Tumor: "LUAD",
    Sex: "Female",
    Age: "63-70 (50%-75%)",
    Participant_Country: "Vietnam",
    Grade: "G3",
    Stage: "II",
    BMI: "<18.5",
    Alcohol_consumption: "non-drinker",
    Tobacco_use: "non-smoker",
    Secondhand_smoke_exposure: "No",
    Overall_survival_in_days: "348-609 (25%-50%)",
    Recurrence_status: "Yes",
    Survival_status: "Dead",
    caseId: "C3N-01024",
    Immune_subtype: "CD8-/IFNG-",
  },
  {
    Tumor: "LSCC",
    Sex: "Male",
    Age: "63-70 (50%-75%)",
    Participant_Country: "United States",
    Grade: "G2",
    Stage: "I",
    BMI: "18.5-25",
    Alcohol_consumption: "drinker",
    Tobacco_use: "past-smoker",
    Secondhand_smoke_exposure: "not_reported",
    Overall_survival_in_days: ">=1016 (>=75%)",
    Recurrence_status: "No",
    Survival_status: "Dead",
    caseId: "C3L-04391",
    Immune_subtype: "Fibroblast/TGFBeta",
  },
  {
    Tumor: "UCEC",
    Sex: "Female",
    Age: "55-63 (25%-50%)",
    Participant_Country: "Ukraine",
    Grade: "G2",
    Stage: "II",
    BMI: ">=30",
    Alcohol_consumption: "non-drinker",
    Tobacco_use: "non-smoker",
    Secondhand_smoke_exposure: "not_reported",
    Overall_survival_in_days: "609-1016 (50%-75%)",
    Recurrence_status: "Yes",
    Survival_status: "Dead",
    caseId: "C3N-01211",
    Immune_subtype: "CD8-/IFNG-",
  },
  {
    Tumor: "BRCA",
    Sex: "Female",
    Age: "<55 (25%)",
    Participant_Country: "not_reported",
    Grade: "not_reported",
    Stage: "II",
    BMI: "not_reported",
    Alcohol_consumption: "not_reported",
    Tobacco_use: "not_reported",
    Secondhand_smoke_exposure: "not_reported",
    Overall_survival_in_days: "348-609 (25%-50%)",
    Recurrence_status: "No",
    Survival_status: "Dead",
    caseId: "11BR016",
    Immune_subtype: "CD8-/IFNG+",
  },
  {
    Tumor: "PDAC",
    Sex: "Female",
    Age: "63-70 (50%-75%)",
    Participant_Country: "Poland",
    Grade: "G2",
    Stage: "II",
    BMI: "18.5-25",
    Alcohol_consumption: "non-drinker",
    Tobacco_use: "non-smoker",
    Secondhand_smoke_exposure: "No",
    Overall_survival_in_days: "<348 (25%)",
    Recurrence_status: "No",
    Survival_status: "Alive",
    caseId: "C3N-01375",
    Immune_subtype: "CD8-/IFNG+",
  },
  {
    Tumor: "LUAD",
    Sex: "Female",
    Age: "63-70 (50%-75%)",
    Participant_Country: "United States",
    Grade: "G2",
    Stage: "I",
    BMI: ">=30",
    Alcohol_consumption: "drinker",
    Tobacco_use: "non-smoker",
    Secondhand_smoke_exposure: "not_reported",
    Overall_survival_in_days: ">=1016 (>=75%)",
    Recurrence_status: "Yes",
    Survival_status: "Alive",
    caseId: "C3L-00093",
    Immune_subtype: "Eosinophils/Endothelial",
  },
  {
    Tumor: "PDAC",
    Sex: "Male",
    Age: "63-70 (50%-75%)",
    Participant_Country: "United States",
    Grade: "G3",
    Stage: "II",
    BMI: "25-30",
    Alcohol_consumption: "drinker",
    Tobacco_use: "non-smoker",
    Secondhand_smoke_exposure: "not_reported",
    Overall_survival_in_days: "<348 (25%)",
    Recurrence_status: "No",
    Survival_status: "Alive",
    caseId: "C3L-01971",
    Immune_subtype: "Eosinophils/Endothelial",
  },
  {
    Tumor: "LSCC",
    Sex: "Male",
    Age: "55-63 (25%-50%)",
    Participant_Country: "Bulgaria",
    Grade: "G2",
    Stage: "II",
    BMI: ">=30",
    Alcohol_consumption: "drinker",
    Tobacco_use: "past-smoker",
    Secondhand_smoke_exposure: "not_reported",
    Overall_survival_in_days: "<348 (25%)",
    Recurrence_status: "No",
    Survival_status: "Dead",
    caseId: "C3L-02629",
    Immune_subtype: "CD8+/IFNG+",
  },
  {
    Tumor: "COAD",
    Sex: "Male",
    Age: ">=70 (>=75%)",
    Participant_Country: "not_reported",
    Grade: "not_reported",
    Stage: "I",
    BMI: "not_reported",
    Alcohol_consumption: "not_reported",
    Tobacco_use: "not_reported",
    Secondhand_smoke_exposure: "not_reported",
    Overall_survival_in_days: "348-609 (25%-50%)",
    Recurrence_status: "No",
    Survival_status: "Dead",
    caseId: "21CO006",
    Immune_subtype: "Fibroblast/TGFBeta",
  },
  {
    Tumor: "UCEC",
    Sex: "Female",
    Age: "55-63 (25%-50%)",
    Participant_Country: "Ukraine",
    Grade: "G2",
    Stage: "III",
    BMI: ">=30",
    Alcohol_consumption: "non-drinker",
    Tobacco_use: "non-smoker",
    Secondhand_smoke_exposure: "not_reported",
    Overall_survival_in_days: "609-1016 (50%-75%)",
    Recurrence_status: "No",
    Survival_status: "Dead",
    caseId: "C3N-01212",
    Immune_subtype: "CD8-/IFNG-",
  },
  {
    Tumor: "HNSCC",
    Sex: "Male",
    Age: "63-70 (50%-75%)",
    Participant_Country: "Poland",
    Grade: "G3",
    Stage: "IV",
    BMI: ">=30",
    Alcohol_consumption: "not_reported",
    Tobacco_use: "not_reported",
    Secondhand_smoke_exposure: "not_reported",
    Overall_survival_in_days: "<348 (25%)",
    Recurrence_status: "No",
    Survival_status: "Alive",
    caseId: "C3N-00307",
    Immune_subtype: "Fibroblast/TGFBeta",
  },
  {
    Tumor: "LUAD",
    Sex: "Male",
    Age: ">=70 (>=75%)",
    Participant_Country: "United States",
    Grade: "G2",
    Stage: "III",
    BMI: ">=30",
    Alcohol_consumption: "not_reported",
    Tobacco_use: "past-smoker",
    Secondhand_smoke_exposure: "not_reported",
    Overall_survival_in_days: "609-1016 (50%-75%)",
    Recurrence_status: "Yes",
    Survival_status: "Alive",
    caseId: "C3L-02219",
    Immune_subtype: "Eosinophils/Endothelial",
  },
  {
    Tumor: "COAD",
    Sex: "Female",
    Age: ">=70 (>=75%)",
    Participant_Country: "not_reported",
    Grade: "not_reported",
    Stage: "III",
    BMI: "not_reported",
    Alcohol_consumption: "not_reported",
    Tobacco_use: "not_reported",
    Secondhand_smoke_exposure: "not_reported",
    Overall_survival_in_days: "348-609 (25%-50%)",
    Recurrence_status: "No",
    Survival_status: "Dead",
    caseId: "11CO045",
    Immune_subtype: "Fibroblast/TGFBeta",
  },
  {
    Tumor: "BRCA",
    Sex: "Female",
    Age: "<55 (25%)",
    Participant_Country: "not_reported",
    Grade: "not_reported",
    Stage: "II",
    BMI: "not_reported",
    Alcohol_consumption: "not_reported",
    Tobacco_use: "not_reported",
    Secondhand_smoke_exposure: "not_reported",
    Overall_survival_in_days: "348-609 (25%-50%)",
    Recurrence_status: "No",
    Survival_status: "Dead",
    caseId: "11BR006",
    Immune_subtype: "Fibroblast/TGFBeta",
  },
  {
    Tumor: "UCEC",
    Sex: "Female",
    Age: "63-70 (50%-75%)",
    Participant_Country: "United States",
    Grade: "G1",
    Stage: "I",
    BMI: ">=30",
    Alcohol_consumption: "not_reported",
    Tobacco_use: "non-smoker",
    Secondhand_smoke_exposure: "not_reported",
    Overall_survival_in_days: ">=1016 (>=75%)",
    Recurrence_status: "No",
    Survival_status: "Dead",
    caseId: "C3L-00949",
    Immune_subtype: "Fibroblast/TGFBeta",
  },
  {
    Tumor: "ccRCC",
    Sex: "Male",
    Age: "55-63 (25%-50%)",
    Participant_Country: "United States",
    Grade: "G4",
    Stage: "III",
    BMI: "25-30",
    Alcohol_consumption: "non-drinker",
    Tobacco_use: "past-smoker",
    Secondhand_smoke_exposure: "not_reported",
    Overall_survival_in_days: ">=1016 (>=75%)",
    Recurrence_status: "Yes",
    Survival_status: "Alive",
    caseId: "C3L-01313",
    Immune_subtype: "CD8-/IFNG-",
  },
  {
    Tumor: "LUAD",
    Sex: "Male",
    Age: "63-70 (50%-75%)",
    Participant_Country: "Vietnam",
    Grade: "G2",
    Stage: "II",
    BMI: "18.5-25",
    Alcohol_consumption: "drinker",
    Tobacco_use: "current smoker",
    Secondhand_smoke_exposure: "No",
    Overall_survival_in_days: "348-609 (25%-50%)",
    Recurrence_status: "No",
    Survival_status: "Dead",
    caseId: "C3N-02149",
    Immune_subtype: "Fibroblast/TGFBeta",
  },
  {
    Tumor: "UCEC",
    Sex: "Female",
    Age: "63-70 (50%-75%)",
    Participant_Country: "Ukraine",
    Grade: "G1",
    Stage: "I",
    BMI: ">=30",
    Alcohol_consumption: "non-drinker",
    Tobacco_use: "non-smoker",
    Secondhand_smoke_exposure: "not_reported",
    Overall_survival_in_days: "609-1016 (50%-75%)",
    Recurrence_status: "No",
    Survival_status: "Dead",
    caseId: "C3N-00334",
    Immune_subtype: "CD8-/IFNG-",
  },
  {
    Tumor: "BRCA",
    Sex: "Female",
    Age: "63-70 (50%-75%)",
    Participant_Country: "not_reported",
    Grade: "not_reported",
    Stage: "III",
    BMI: "not_reported",
    Alcohol_consumption: "not_reported",
    Tobacco_use: "not_reported",
    Secondhand_smoke_exposure: "not_reported",
    Overall_survival_in_days: "348-609 (25%-50%)",
    Recurrence_status: "No",
    Survival_status: "Dead",
    caseId: "11BR073",
    Immune_subtype: "CD8+/IFNG+",
  },
  {
    Tumor: "LSCC",
    Sex: "Male",
    Age: "<55 (25%)",
    Participant_Country: "United States",
    Grade: "G2",
    Stage: "II",
    BMI: "18.5-25",
    Alcohol_consumption: "drinker",
    Tobacco_use: "current smoker",
    Secondhand_smoke_exposure: "not_reported",
    Overall_survival_in_days: ">=1016 (>=75%)",
    Recurrence_status: "Yes",
    Survival_status: "Dead",
    caseId: "C3L-01285",
    Immune_subtype: "CD8-/IFNG-",
  },
  {
    Tumor: "BRCA",
    Sex: "Female",
    Age: "55-63 (25%-50%)",
    Participant_Country: "not_reported",
    Grade: "not_reported",
    Stage: "II",
    BMI: "not_reported",
    Alcohol_consumption: "not_reported",
    Tobacco_use: "not_reported",
    Secondhand_smoke_exposure: "not_reported",
    Overall_survival_in_days: "348-609 (25%-50%)",
    Recurrence_status: "No",
    Survival_status: "Dead",
    caseId: "18BR009",
    Immune_subtype: "CD8+/IFNG+",
  },
  {
    Tumor: "HGSC",
    Sex: "Female",
    Age: "<55 (25%)",
    Participant_Country: "not_reported",
    Grade: "not_reported",
    Stage: "not_reported",
    BMI: "not_reported",
    Alcohol_consumption: "not_reported",
    Tobacco_use: "not_reported",
    Secondhand_smoke_exposure: "not_reported",
    Overall_survival_in_days: "<348 (25%)",
    Recurrence_status: "No",
    Survival_status: "Dead",
    caseId: "17OV027",
    Immune_subtype: "Fibroblast/TGFBeta",
  },
  {
    Tumor: "LSCC",
    Sex: "Male",
    Age: "55-63 (25%-50%)",
    Participant_Country: "Vietnam",
    Grade: "G2",
    Stage: "II",
    BMI: "18.5-25",
    Alcohol_consumption: "drinker",
    Tobacco_use: "current smoker",
    Secondhand_smoke_exposure: "No",
    Overall_survival_in_days: "609-1016 (50%-75%)",
    Recurrence_status: "No",
    Survival_status: "Dead",
    caseId: "C3N-00555",
    Immune_subtype: "CD8-/IFNG-",
  },
  {
    Tumor: "HNSCC",
    Sex: "Male",
    Age: "55-63 (25%-50%)",
    Participant_Country: "Poland",
    Grade: "G2",
    Stage: "II",
    BMI: "25-30",
    Alcohol_consumption: "drinker",
    Tobacco_use: "non-smoker",
    Secondhand_smoke_exposure: "No",
    Overall_survival_in_days: ">=1016 (>=75%)",
    Recurrence_status: "No",
    Survival_status: "Dead",
    caseId: "C3N-03013",
    Immune_subtype: "Fibroblast/TGFBeta",
  },
  {
    Tumor: "GBM",
    Sex: "Female",
    Age: "<55 (25%)",
    Participant_Country: "China",
    Grade: "not_reported",
    Stage: "not_reported",
    BMI: "18.5-25",
    Alcohol_consumption: "non-drinker",
    Tobacco_use: "non-smoker",
    Secondhand_smoke_exposure: "No",
    Overall_survival_in_days: "<348 (25%)",
    Recurrence_status: "No",
    Survival_status: "Alive",
    caseId: "C3N-03473",
    Immune_subtype: "CD8-/IFNG-",
  },
  {
    Tumor: "BRCA",
    Sex: "Female",
    Age: ">=70 (>=75%)",
    Participant_Country: "not_reported",
    Grade: "not_reported",
    Stage: "III",
    BMI: "not_reported",
    Alcohol_consumption: "not_reported",
    Tobacco_use: "not_reported",
    Secondhand_smoke_exposure: "not_reported",
    Overall_survival_in_days: "not_reported",
    Recurrence_status: "No",
    Survival_status: "not_reported",
    caseId: "01BR044",
    Immune_subtype: "not_reported",
  },
  {
    Tumor: "HNSCC",
    Sex: "Male",
    Age: "<55 (25%)",
    Participant_Country: "Ukraine",
    Grade: "G2",
    Stage: "IV",
    BMI: "18.5-25",
    Alcohol_consumption: "drinker",
    Tobacco_use: "current smoker",
    Secondhand_smoke_exposure: "not_reported",
    Overall_survival_in_days: "609-1016 (50%-75%)",
    Recurrence_status: "No",
    Survival_status: "Dead",
    caseId: "C3N-03226",
    Immune_subtype: "Fibroblast/TGFBeta",
  },
  {
    Tumor: "COAD",
    Sex: "Male",
    Age: ">=70 (>=75%)",
    Participant_Country: "not_reported",
    Grade: "not_reported",
    Stage: "I",
    BMI: "not_reported",
    Alcohol_consumption: "not_reported",
    Tobacco_use: "not_reported",
    Secondhand_smoke_exposure: "not_reported",
    Overall_survival_in_days: "<348 (25%)",
    Recurrence_status: "No",
    Survival_status: "Dead",
    caseId: "09CO006",
    Immune_subtype: "CD8-/IFNG-",
  },
  {
    Tumor: "HGSC",
    Sex: "Female",
    Age: ">=70 (>=75%)",
    Participant_Country: "not_reported",
    Grade: "G3",
    Stage: "III",
    BMI: "not_reported",
    Alcohol_consumption: "not_reported",
    Tobacco_use: "not_reported",
    Secondhand_smoke_exposure: "not_reported",
    Overall_survival_in_days: ">=1016 (>=75%)",
    Recurrence_status: "No",
    Survival_status: "Dead",
    caseId: "04OV050",
    Immune_subtype: "CD8-/IFNG-",
  },
  {
    Tumor: "GBM",
    Sex: "Male",
    Age: "<55 (25%)",
    Participant_Country: "China",
    Grade: "not_reported",
    Stage: "not_reported",
    BMI: "18.5-25",
    Alcohol_consumption: "non-drinker",
    Tobacco_use: "non-smoker",
    Secondhand_smoke_exposure: "No",
    Overall_survival_in_days: "<348 (25%)",
    Recurrence_status: "Yes",
    Survival_status: "Dead",
    caseId: "C3N-02186",
    Immune_subtype: "Brain/Neuro",
  },
  {
    Tumor: "PDAC",
    Sex: "Female",
    Age: "55-63 (25%-50%)",
    Participant_Country: "China",
    Grade: "G2",
    Stage: "I",
    BMI: "18.5-25",
    Alcohol_consumption: "non-drinker",
    Tobacco_use: "non-smoker",
    Secondhand_smoke_exposure: "not_reported",
    Overall_survival_in_days: "609-1016 (50%-75%)",
    Recurrence_status: "Yes",
    Survival_status: "Dead",
    caseId: "C3N-02944",
    Immune_subtype: "CD8+/IFNG+",
  },
  {
    Tumor: "LSCC",
    Sex: "Male",
    Age: ">=70 (>=75%)",
    Participant_Country: "Poland",
    Grade: "G2",
    Stage: "II",
    BMI: ">=30",
    Alcohol_consumption: "not_reported",
    Tobacco_use: "not_reported",
    Secondhand_smoke_exposure: "not_reported",
    Overall_survival_in_days: "609-1016 (50%-75%)",
    Recurrence_status: "No",
    Survival_status: "Alive",
    caseId: "C3N-01892",
    Immune_subtype: "Fibroblast/TGFBeta",
  },
  {
    Tumor: "PDAC",
    Sex: "Male",
    Age: "<55 (25%)",
    Participant_Country: "United States",
    Grade: "G3",
    Stage: "II",
    BMI: "25-30",
    Alcohol_consumption: "not_reported",
    Tobacco_use: "non-smoker",
    Secondhand_smoke_exposure: "not_reported",
    Overall_survival_in_days: "<348 (25%)",
    Recurrence_status: "Yes",
    Survival_status: "Alive",
    caseId: "C3L-00102",
    Immune_subtype: "Fibroblast/TGFBeta",
  },
  {
    Tumor: "HNSCC",
    Sex: "Male",
    Age: "55-63 (25%-50%)",
    Participant_Country: "Poland",
    Grade: "G2",
    Stage: "IV",
    BMI: ">=30",
    Alcohol_consumption: "not_reported",
    Tobacco_use: "not_reported",
    Secondhand_smoke_exposure: "not_reported",
    Overall_survival_in_days: "609-1016 (50%-75%)",
    Recurrence_status: "No",
    Survival_status: "Alive",
    caseId: "C3N-01943",
    Immune_subtype: "CD8-/IFNG-",
  },
  {
    Tumor: "LSCC",
    Sex: "Male",
    Age: "63-70 (50%-75%)",
    Participant_Country: "Poland",
    Grade: "G2",
    Stage: "III",
    BMI: "25-30",
    Alcohol_consumption: "non-drinker",
    Tobacco_use: "past-smoker",
    Secondhand_smoke_exposure: "No",
    Overall_survival_in_days: ">=1016 (>=75%)",
    Recurrence_status: "Yes",
    Survival_status: "Alive",
    caseId: "C3N-02675",
    Immune_subtype: "CD8+/IFNG+",
  },
  {
    Tumor: "PDAC",
    Sex: "Female",
    Age: ">=70 (>=75%)",
    Participant_Country: "United States",
    Grade: "G2",
    Stage: "not_reported",
    BMI: "not_reported",
    Alcohol_consumption: "not_reported",
    Tobacco_use: "not_reported",
    Secondhand_smoke_exposure: "not_reported",
    Overall_survival_in_days: "not_reported",
    Recurrence_status: "No",
    Survival_status: "not_reported",
    caseId: "C3N-03039",
    Immune_subtype: "CD8-/IFNG-",
  },
  {
    Tumor: "GBM",
    Sex: "Female",
    Age: "<55 (25%)",
    Participant_Country: "Russia",
    Grade: "not_reported",
    Stage: "not_reported",
    BMI: "25-30",
    Alcohol_consumption: "non-drinker",
    Tobacco_use: "non-smoker",
    Secondhand_smoke_exposure: "No",
    Overall_survival_in_days: "<348 (25%)",
    Recurrence_status: "No",
    Survival_status: "Alive",
    caseId: "C3L-03392",
    Immune_subtype: "CD8+/IFNG+",
  },
  {
    Tumor: "BRCA",
    Sex: "Female",
    Age: "<55 (25%)",
    Participant_Country: "not_reported",
    Grade: "not_reported",
    Stage: "not_reported",
    BMI: "not_reported",
    Alcohol_consumption: "not_reported",
    Tobacco_use: "not_reported",
    Secondhand_smoke_exposure: "not_reported",
    Overall_survival_in_days: "not_reported",
    Recurrence_status: "No",
    Survival_status: "not_reported",
    caseId: "01BR023",
    Immune_subtype: "not_reported",
  },
  {
    Tumor: "HGSC",
    Sex: "Female",
    Age: "<55 (25%)",
    Participant_Country: "not_reported",
    Grade: "G3",
    Stage: "III",
    BMI: "not_reported",
    Alcohol_consumption: "not_reported",
    Tobacco_use: "not_reported",
    Secondhand_smoke_exposure: "not_reported",
    Overall_survival_in_days: "348-609 (25%-50%)",
    Recurrence_status: "No",
    Survival_status: "Dead",
    caseId: "17OV002",
    Immune_subtype: "CD8-/IFNG-",
  },
  {
    Tumor: "GBM",
    Sex: "Male",
    Age: "<55 (25%)",
    Participant_Country: "United States",
    Grade: "not_reported",
    Stage: "not_reported",
    BMI: "25-30",
    Alcohol_consumption: "not_reported",
    Tobacco_use: "past-smoker",
    Secondhand_smoke_exposure: "not_reported",
    Overall_survival_in_days: "<348 (25%)",
    Recurrence_status: "No",
    Survival_status: "Dead",
    caseId: "C3L-02704",
    Immune_subtype: "Fibroblast/TGFBeta",
  },
  {
    Tumor: "LUAD",
    Sex: "Male",
    Age: "63-70 (50%-75%)",
    Participant_Country: "China",
    Grade: "G3",
    Stage: "II",
    BMI: "18.5-25",
    Alcohol_consumption: "not_reported",
    Tobacco_use: "not_reported",
    Secondhand_smoke_exposure: "not_reported",
    Overall_survival_in_days: ">=1016 (>=75%)",
    Recurrence_status: "No",
    Survival_status: "Dead",
    caseId: "C3N-02588",
    Immune_subtype: "CD8-/IFNG+",
  },
  {
    Tumor: "COAD",
    Sex: "Female",
    Age: ">=70 (>=75%)",
    Participant_Country: "not_reported",
    Grade: "not_reported",
    Stage: "III",
    BMI: "not_reported",
    Alcohol_consumption: "not_reported",
    Tobacco_use: "not_reported",
    Secondhand_smoke_exposure: "not_reported",
    Overall_survival_in_days: "<348 (25%)",
    Recurrence_status: "No",
    Survival_status: "Dead",
    caseId: "05CO048",
    Immune_subtype: "CD8-/IFNG-",
  },
  {
    Tumor: "UCEC",
    Sex: "Female",
    Age: "<55 (25%)",
    Participant_Country: "United States",
    Grade: "G1",
    Stage: "I",
    BMI: ">=30",
    Alcohol_consumption: "non-drinker",
    Tobacco_use: "past-smoker",
    Secondhand_smoke_exposure: "not_reported",
    Overall_survival_in_days: ">=1016 (>=75%)",
    Recurrence_status: "No",
    Survival_status: "Dead",
    caseId: "C3L-00362",
    Immune_subtype: "CD8-/IFNG-",
  },
  {
    Tumor: "PDAC",
    Sex: "Female",
    Age: "63-70 (50%-75%)",
    Participant_Country: "Poland",
    Grade: "G2",
    Stage: "III",
    BMI: "<18.5",
    Alcohol_consumption: "drinker",
    Tobacco_use: "non-smoker",
    Secondhand_smoke_exposure: "No",
    Overall_survival_in_days: "609-1016 (50%-75%)",
    Recurrence_status: "No",
    Survival_status: "Dead",
    caseId: "C3N-03006",
    Immune_subtype: "CD8-/IFNG+",
  },
  {
    Tumor: "GBM",
    Sex: "Male",
    Age: "<55 (25%)",
    Participant_Country: "United States",
    Grade: "not_reported",
    Stage: "not_reported",
    BMI: ">=30",
    Alcohol_consumption: "not_reported",
    Tobacco_use: "non-smoker",
    Secondhand_smoke_exposure: "not_reported",
    Overall_survival_in_days: ">=1016 (>=75%)",
    Recurrence_status: "Yes",
    Survival_status: "Alive",
    caseId: "C3L-01887",
    Immune_subtype: "CD8-/IFNG-",
  },
  {
    Tumor: "PDAC",
    Sex: "Female",
    Age: ">=70 (>=75%)",
    Participant_Country: "United States",
    Grade: "G2",
    Stage: "II",
    BMI: ">=30",
    Alcohol_consumption: "non-drinker",
    Tobacco_use: "non-smoker",
    Secondhand_smoke_exposure: "not_reported",
    Overall_survival_in_days: "609-1016 (50%-75%)",
    Recurrence_status: "Yes",
    Survival_status: "Alive",
    caseId: "C3L-01598",
    Immune_subtype: "Eosinophils/Endothelial",
  },
  {
    Tumor: "GBM",
    Sex: "Male",
    Age: "55-63 (25%-50%)",
    Participant_Country: "Russia",
    Grade: "not_reported",
    Stage: "not_reported",
    BMI: "25-30",
    Alcohol_consumption: "non-drinker",
    Tobacco_use: "non-smoker",
    Secondhand_smoke_exposure: "No",
    Overall_survival_in_days: "<348 (25%)",
    Recurrence_status: "No",
    Survival_status: "Alive",
    caseId: "C3L-03405",
    Immune_subtype: "CD8-/IFNG-",
  },
  {
    Tumor: "LUAD",
    Sex: "Male",
    Age: ">=70 (>=75%)",
    Participant_Country: "Russia",
    Grade: "G2",
    Stage: "I",
    BMI: ">=30",
    Alcohol_consumption: "non-drinker",
    Tobacco_use: "non-smoker",
    Secondhand_smoke_exposure: "No",
    Overall_survival_in_days: ">=1016 (>=75%)",
    Recurrence_status: "No",
    Survival_status: "Dead",
    caseId: "C3L-00973",
    Immune_subtype: "CD8+/IFNG+",
  },
  {
    Tumor: "LUAD",
    Sex: "Male",
    Age: ">=70 (>=75%)",
    Participant_Country: "Vietnam",
    Grade: "G2",
    Stage: "I",
    BMI: "<18.5",
    Alcohol_consumption: "drinker",
    Tobacco_use: "current smoker",
    Secondhand_smoke_exposure: "No",
    Overall_survival_in_days: "<348 (25%)",
    Recurrence_status: "No",
    Survival_status: "Alive",
    caseId: "C3N-00175",
    Immune_subtype: "Fibroblast/TGFBeta",
  },
  {
    Tumor: "COAD",
    Sex: "Male",
    Age: "not_reported",
    Participant_Country: "not_reported",
    Grade: "not_reported",
    Stage: "II",
    BMI: "not_reported",
    Alcohol_consumption: "not_reported",
    Tobacco_use: "not_reported",
    Secondhand_smoke_exposure: "not_reported",
    Overall_survival_in_days: "<348 (25%)",
    Recurrence_status: "No",
    Survival_status: "Dead",
    caseId: "05CO003",
    Immune_subtype: "CD8-/IFNG+",
  },
  {
    Tumor: "UCEC",
    Sex: "Female",
    Age: "63-70 (50%-75%)",
    Participant_Country: "United States",
    Grade: "G3",
    Stage: "III",
    BMI: ">=30",
    Alcohol_consumption: "drinker",
    Tobacco_use: "past-smoker",
    Secondhand_smoke_exposure: "not_reported",
    Overall_survival_in_days: ">=1016 (>=75%)",
    Recurrence_status: "No",
    Survival_status: "Dead",
    caseId: "C3L-00905",
    Immune_subtype: "CD8-/IFNG-",
  },
  {
    Tumor: "ccRCC",
    Sex: "Male",
    Age: "<55 (25%)",
    Participant_Country: "United States",
    Grade: "G3",
    Stage: "I",
    BMI: ">=30",
    Alcohol_consumption: "non-drinker",
    Tobacco_use: "current smoker",
    Secondhand_smoke_exposure: "not_reported",
    Overall_survival_in_days: "609-1016 (50%-75%)",
    Recurrence_status: "No",
    Survival_status: "Dead",
    caseId: "C3L-00447",
    Immune_subtype: "CD8-/IFNG+",
  },
  {
    Tumor: "HGSC",
    Sex: "Female",
    Age: "55-63 (25%-50%)",
    Participant_Country: "not_reported",
    Grade: "G2",
    Stage: "III",
    BMI: "not_reported",
    Alcohol_consumption: "not_reported",
    Tobacco_use: "not_reported",
    Secondhand_smoke_exposure: "not_reported",
    Overall_survival_in_days: "348-609 (25%-50%)",
    Recurrence_status: "No",
    Survival_status: "Dead",
    caseId: "17OV018",
    Immune_subtype: "Fibroblast/TGFBeta",
  },
  {
    Tumor: "BRCA",
    Sex: "Female",
    Age: ">=70 (>=75%)",
    Participant_Country: "not_reported",
    Grade: "not_reported",
    Stage: "II",
    BMI: "not_reported",
    Alcohol_consumption: "not_reported",
    Tobacco_use: "not_reported",
    Secondhand_smoke_exposure: "not_reported",
    Overall_survival_in_days: "348-609 (25%-50%)",
    Recurrence_status: "No",
    Survival_status: "Dead",
    caseId: "11BR054",
    Immune_subtype: "CD8-/IFNG-",
  },
  {
    Tumor: "BRCA",
    Sex: "Female",
    Age: ">=70 (>=75%)",
    Participant_Country: "not_reported",
    Grade: "not_reported",
    Stage: "II",
    BMI: "not_reported",
    Alcohol_consumption: "not_reported",
    Tobacco_use: "not_reported",
    Secondhand_smoke_exposure: "not_reported",
    Overall_survival_in_days: "348-609 (25%-50%)",
    Recurrence_status: "No",
    Survival_status: "Dead",
    caseId: "14BR005",
    Immune_subtype: "CD8-/IFNG-",
  },
  {
    Tumor: "COAD",
    Sex: "Female",
    Age: "63-70 (50%-75%)",
    Participant_Country: "not_reported",
    Grade: "not_reported",
    Stage: "II",
    BMI: "not_reported",
    Alcohol_consumption: "not_reported",
    Tobacco_use: "not_reported",
    Secondhand_smoke_exposure: "not_reported",
    Overall_survival_in_days: "348-609 (25%-50%)",
    Recurrence_status: "No",
    Survival_status: "Dead",
    caseId: "05CO035",
    Immune_subtype: "CD8-/IFNG-",
  },
  {
    Tumor: "COAD",
    Sex: "Male",
    Age: "<55 (25%)",
    Participant_Country: "not_reported",
    Grade: "not_reported",
    Stage: "III",
    BMI: "not_reported",
    Alcohol_consumption: "not_reported",
    Tobacco_use: "not_reported",
    Secondhand_smoke_exposure: "not_reported",
    Overall_survival_in_days: "<348 (25%)",
    Recurrence_status: "No",
    Survival_status: "Dead",
    caseId: "09CO008",
    Immune_subtype: "CD8-/IFNG-",
  },
  {
    Tumor: "LUAD",
    Sex: "Male",
    Age: "<55 (25%)",
    Participant_Country: "Vietnam",
    Grade: "G2",
    Stage: "I",
    BMI: "<18.5",
    Alcohol_consumption: "drinker",
    Tobacco_use: "current smoker",
    Secondhand_smoke_exposure: "No",
    Overall_survival_in_days: "348-609 (25%-50%)",
    Recurrence_status: "No",
    Survival_status: "Dead",
    caseId: "C3N-00551",
    Immune_subtype: "CD8-/IFNG+",
  },
  {
    Tumor: "BRCA",
    Sex: "Female",
    Age: "63-70 (50%-75%)",
    Participant_Country: "not_reported",
    Grade: "not_reported",
    Stage: "III",
    BMI: "not_reported",
    Alcohol_consumption: "not_reported",
    Tobacco_use: "not_reported",
    Secondhand_smoke_exposure: "not_reported",
    Overall_survival_in_days: "348-609 (25%-50%)",
    Recurrence_status: "No",
    Survival_status: "Dead",
    caseId: "11BR072",
    Immune_subtype: "Fibroblast/TGFBeta",
  },
  {
    Tumor: "LSCC",
    Sex: "Female",
    Age: "63-70 (50%-75%)",
    Participant_Country: "Poland",
    Grade: "G2",
    Stage: "II",
    BMI: ">=30",
    Alcohol_consumption: "not_reported",
    Tobacco_use: "not_reported",
    Secondhand_smoke_exposure: "not_reported",
    Overall_survival_in_days: ">=1016 (>=75%)",
    Recurrence_status: "No",
    Survival_status: "Dead",
    caseId: "C3N-02300",
    Immune_subtype: "Fibroblast/TGFBeta",
  },
  {
    Tumor: "COAD",
    Sex: "Male",
    Age: "63-70 (50%-75%)",
    Participant_Country: "not_reported",
    Grade: "not_reported",
    Stage: "II",
    BMI: "not_reported",
    Alcohol_consumption: "not_reported",
    Tobacco_use: "not_reported",
    Secondhand_smoke_exposure: "not_reported",
    Overall_survival_in_days: "348-609 (25%-50%)",
    Recurrence_status: "No",
    Survival_status: "Dead",
    caseId: "11CO047",
    Immune_subtype: "Fibroblast/TGFBeta",
  },
  {
    Tumor: "GBM",
    Sex: "Male",
    Age: "<55 (25%)",
    Participant_Country: "China",
    Grade: "not_reported",
    Stage: "not_reported",
    BMI: "25-30",
    Alcohol_consumption: "non-drinker",
    Tobacco_use: "non-smoker",
    Secondhand_smoke_exposure: "not_reported",
    Overall_survival_in_days: ">=1016 (>=75%)",
    Recurrence_status: "Yes",
    Survival_status: "Dead",
    caseId: "C3N-01517",
    Immune_subtype: "CD8-/IFNG-",
  },
  {
    Tumor: "UCEC",
    Sex: "Female",
    Age: "55-63 (25%-50%)",
    Participant_Country: "United States",
    Grade: "G3",
    Stage: "I",
    BMI: ">=30",
    Alcohol_consumption: "non-drinker",
    Tobacco_use: "past-smoker",
    Secondhand_smoke_exposure: "not_reported",
    Overall_survival_in_days: "609-1016 (50%-75%)",
    Recurrence_status: "No",
    Survival_status: "Dead",
    caseId: "C3L-00963",
    Immune_subtype: "Fibroblast/TGFBeta",
  },
  {
    Tumor: "LUAD",
    Sex: "Female",
    Age: "<55 (25%)",
    Participant_Country: "Vietnam",
    Grade: "G2",
    Stage: "I",
    BMI: "<18.5",
    Alcohol_consumption: "non-drinker",
    Tobacco_use: "non-smoker",
    Secondhand_smoke_exposure: "No",
    Overall_survival_in_days: "348-609 (25%-50%)",
    Recurrence_status: "No",
    Survival_status: "Dead",
    caseId: "C3N-00572",
    Immune_subtype: "Eosinophils/Endothelial",
  },
  {
    Tumor: "COAD",
    Sex: "Male",
    Age: ">=70 (>=75%)",
    Participant_Country: "not_reported",
    Grade: "not_reported",
    Stage: "III",
    BMI: "not_reported",
    Alcohol_consumption: "not_reported",
    Tobacco_use: "not_reported",
    Secondhand_smoke_exposure: "not_reported",
    Overall_survival_in_days: "<348 (25%)",
    Recurrence_status: "No",
    Survival_status: "Dead",
    caseId: "06CO001",
    Immune_subtype: "CD8-/IFNG-",
  },
  {
    Tumor: "PDAC",
    Sex: "Male",
    Age: "55-63 (25%-50%)",
    Participant_Country: "Poland",
    Grade: "G3",
    Stage: "IV",
    BMI: "25-30",
    Alcohol_consumption: "non-drinker",
    Tobacco_use: "non-smoker",
    Secondhand_smoke_exposure: "No",
    Overall_survival_in_days: "<348 (25%)",
    Recurrence_status: "No",
    Survival_status: "Alive",
    caseId: "C3N-00302",
    Immune_subtype: "CD8-/IFNG-",
  },
  {
    Tumor: "HNSCC",
    Sex: "Male",
    Age: "55-63 (25%-50%)",
    Participant_Country: "Russia",
    Grade: "G1",
    Stage: "II",
    BMI: "25-30",
    Alcohol_consumption: "non-drinker",
    Tobacco_use: "current smoker",
    Secondhand_smoke_exposure: "Yes",
    Overall_survival_in_days: ">=1016 (>=75%)",
    Recurrence_status: "Yes",
    Survival_status: "Dead",
    caseId: "C3L-00999",
    Immune_subtype: "Fibroblast/TGFBeta",
  },
  {
    Tumor: "LUAD",
    Sex: "Female",
    Age: "<55 (25%)",
    Participant_Country: "Vietnam",
    Grade: "G3",
    Stage: "I",
    BMI: "18.5-25",
    Alcohol_consumption: "non-drinker",
    Tobacco_use: "non-smoker",
    Secondhand_smoke_exposure: "No",
    Overall_survival_in_days: "348-609 (25%-50%)",
    Recurrence_status: "No",
    Survival_status: "Dead",
    caseId: "C3N-00552",
    Immune_subtype: "CD8+/IFNG+",
  },
  {
    Tumor: "LSCC",
    Sex: "Male",
    Age: "63-70 (50%-75%)",
    Participant_Country: "United States",
    Grade: "G2",
    Stage: "II",
    BMI: "25-30",
    Alcohol_consumption: "non-drinker",
    Tobacco_use: "past-smoker",
    Secondhand_smoke_exposure: "not_reported",
    Overall_survival_in_days: ">=1016 (>=75%)",
    Recurrence_status: "No",
    Survival_status: "Dead",
    caseId: "C3L-02968",
    Immune_subtype: "CD8+/IFNG+",
  },
  {
    Tumor: "BRCA",
    Sex: "Female",
    Age: "<55 (25%)",
    Participant_Country: "not_reported",
    Grade: "not_reported",
    Stage: "II",
    BMI: "not_reported",
    Alcohol_consumption: "not_reported",
    Tobacco_use: "not_reported",
    Secondhand_smoke_exposure: "not_reported",
    Overall_survival_in_days: "348-609 (25%-50%)",
    Recurrence_status: "No",
    Survival_status: "Dead",
    caseId: "05BR003",
    Immune_subtype: "Fibroblast/TGFBeta",
  },
  {
    Tumor: "HGSC",
    Sex: "Female",
    Age: "55-63 (25%-50%)",
    Participant_Country: "not_reported",
    Grade: "G3",
    Stage: "IV",
    BMI: "not_reported",
    Alcohol_consumption: "not_reported",
    Tobacco_use: "not_reported",
    Secondhand_smoke_exposure: "not_reported",
    Overall_survival_in_days: ">=1016 (>=75%)",
    Recurrence_status: "Yes",
    Survival_status: "Dead",
    caseId: "04OV053",
    Immune_subtype: "CD8-/IFNG+",
  },
  {
    Tumor: "PDAC",
    Sex: "Male",
    Age: "63-70 (50%-75%)",
    Participant_Country: "Poland",
    Grade: "G3",
    Stage: "II",
    BMI: "25-30",
    Alcohol_consumption: "drinker",
    Tobacco_use: "non-smoker",
    Secondhand_smoke_exposure: "No",
    Overall_survival_in_days: "348-609 (25%-50%)",
    Recurrence_status: "Yes",
    Survival_status: "Alive",
    caseId: "C3N-00518",
    Immune_subtype: "Eosinophils/Endothelial",
  },
  {
    Tumor: "PDAC",
    Sex: "Male",
    Age: ">=70 (>=75%)",
    Participant_Country: "not_reported",
    Grade: "G1",
    Stage: "II",
    BMI: "25-30",
    Alcohol_consumption: "drinker",
    Tobacco_use: "past-smoker",
    Secondhand_smoke_exposure: "not_reported",
    Overall_survival_in_days: ">=1016 (>=75%)",
    Recurrence_status: "Yes",
    Survival_status: "Alive",
    caseId: "C3L-00622",
    Immune_subtype: "Fibroblast/TGFBeta",
  },
  {
    Tumor: "LUAD",
    Sex: "Male",
    Age: ">=70 (>=75%)",
    Participant_Country: "United States",
    Grade: "G2",
    Stage: "II",
    BMI: "25-30",
    Alcohol_consumption: "drinker",
    Tobacco_use: "past-smoker",
    Secondhand_smoke_exposure: "not_reported",
    Overall_survival_in_days: ">=1016 (>=75%)",
    Recurrence_status: "No",
    Survival_status: "Dead",
    caseId: "C3N-01799",
    Immune_subtype: "CD8+/IFNG+",
  },
  {
    Tumor: "UCEC",
    Sex: "Female",
    Age: ">=70 (>=75%)",
    Participant_Country: "Ukraine",
    Grade: "G3",
    Stage: "II",
    BMI: ">=30",
    Alcohol_consumption: "non-drinker",
    Tobacco_use: "non-smoker",
    Secondhand_smoke_exposure: "not_reported",
    Overall_survival_in_days: "609-1016 (50%-75%)",
    Recurrence_status: "Yes",
    Survival_status: "Dead",
    caseId: "C3N-01537",
    Immune_subtype: "CD8+/IFNG+",
  },
  {
    Tumor: "HNSCC",
    Sex: "Male",
    Age: "55-63 (25%-50%)",
    Participant_Country: "Poland",
    Grade: "G2",
    Stage: "IV",
    BMI: "18.5-25",
    Alcohol_consumption: "drinker",
    Tobacco_use: "current smoker",
    Secondhand_smoke_exposure: "No",
    Overall_survival_in_days: ">=1016 (>=75%)",
    Recurrence_status: "No",
    Survival_status: "Dead",
    caseId: "C3N-01756",
    Immune_subtype: "CD8-/IFNG-",
  },
  {
    Tumor: "GBM",
    Sex: "Male",
    Age: "63-70 (50%-75%)",
    Participant_Country: "Poland",
    Grade: "not_reported",
    Stage: "not_reported",
    BMI: ">=30",
    Alcohol_consumption: "not_reported",
    Tobacco_use: "not_reported",
    Secondhand_smoke_exposure: "not_reported",
    Overall_survival_in_days: "<348 (25%)",
    Recurrence_status: "Yes",
    Survival_status: "Alive",
    caseId: "C3N-02256",
    Immune_subtype: "Brain/Neuro",
  },
  {
    Tumor: "LUAD",
    Sex: "Male",
    Age: ">=70 (>=75%)",
    Participant_Country: "China",
    Grade: "G2",
    Stage: "I",
    BMI: "18.5-25",
    Alcohol_consumption: "non-drinker",
    Tobacco_use: "non-smoker",
    Secondhand_smoke_exposure: "not_reported",
    Overall_survival_in_days: "348-609 (25%-50%)",
    Recurrence_status: "No",
    Survival_status: "Dead",
    caseId: "C3N-02002",
    Immune_subtype: "CD8-/IFNG-",
  },
  {
    Tumor: "HGSC",
    Sex: "Female",
    Age: "63-70 (50%-75%)",
    Participant_Country: "not_reported",
    Grade: "not_reported",
    Stage: "III",
    BMI: "not_reported",
    Alcohol_consumption: "not_reported",
    Tobacco_use: "not_reported",
    Secondhand_smoke_exposure: "not_reported",
    Overall_survival_in_days: "<348 (25%)",
    Recurrence_status: "No",
    Survival_status: "Dead",
    caseId: "17OV014",
    Immune_subtype: "Fibroblast/TGFBeta",
  },
  {
    Tumor: "LUAD",
    Sex: "Male",
    Age: "55-63 (25%-50%)",
    Participant_Country: "Vietnam",
    Grade: "G3",
    Stage: "I",
    BMI: "<18.5",
    Alcohol_consumption: "drinker",
    Tobacco_use: "non-smoker",
    Secondhand_smoke_exposure: "No",
    Overall_survival_in_days: "348-609 (25%-50%)",
    Recurrence_status: "No",
    Survival_status: "Dead",
    caseId: "C3N-01405",
    Immune_subtype: "CD8-/IFNG+",
  },
  {
    Tumor: "GBM",
    Sex: "Female",
    Age: "63-70 (50%-75%)",
    Participant_Country: "Russia",
    Grade: "not_reported",
    Stage: "not_reported",
    BMI: "25-30",
    Alcohol_consumption: "non-drinker",
    Tobacco_use: "non-smoker",
    Secondhand_smoke_exposure: "No",
    Overall_survival_in_days: "609-1016 (50%-75%)",
    Recurrence_status: "No",
    Survival_status: "Alive",
    caseId: "C3L-01157",
    Immune_subtype: "Brain/Neuro",
  },
  {
    Tumor: "UCEC",
    Sex: "Female",
    Age: ">=70 (>=75%)",
    Participant_Country: "Ukraine",
    Grade: "G3",
    Stage: "I",
    BMI: ">=30",
    Alcohol_consumption: "non-drinker",
    Tobacco_use: "non-smoker",
    Secondhand_smoke_exposure: "not_reported",
    Overall_survival_in_days: "609-1016 (50%-75%)",
    Recurrence_status: "No",
    Survival_status: "Dead",
    caseId: "C3N-00836",
    Immune_subtype: "CD8+/IFNG+",
  },
  {
    Tumor: "UCEC",
    Sex: "Female",
    Age: "63-70 (50%-75%)",
    Participant_Country: "United States",
    Grade: "G2",
    Stage: "II",
    BMI: ">=30",
    Alcohol_consumption: "non-drinker",
    Tobacco_use: "past-smoker",
    Secondhand_smoke_exposure: "not_reported",
    Overall_survival_in_days: ">=1016 (>=75%)",
    Recurrence_status: "No",
    Survival_status: "Dead",
    caseId: "C3L-00921",
    Immune_subtype: "Fibroblast/TGFBeta",
  },
  {
    Tumor: "PDAC",
    Sex: "Male",
    Age: "<55 (25%)",
    Participant_Country: "Poland",
    Grade: "G2",
    Stage: "III",
    BMI: "18.5-25",
    Alcohol_consumption: "drinker",
    Tobacco_use: "current smoker",
    Secondhand_smoke_exposure: "No",
    Overall_survival_in_days: "609-1016 (50%-75%)",
    Recurrence_status: "Yes",
    Survival_status: "Alive",
    caseId: "C3N-03754",
    Immune_subtype: "CD8-/IFNG-",
  },
  {
    Tumor: "COAD",
    Sex: "Female",
    Age: "63-70 (50%-75%)",
    Participant_Country: "not_reported",
    Grade: "not_reported",
    Stage: "III",
    BMI: "not_reported",
    Alcohol_consumption: "not_reported",
    Tobacco_use: "not_reported",
    Secondhand_smoke_exposure: "not_reported",
    Overall_survival_in_days: "<348 (25%)",
    Recurrence_status: "No",
    Survival_status: "Dead",
    caseId: "05CO026",
    Immune_subtype: "CD8-/IFNG-",
  },
  {
    Tumor: "HNSCC",
    Sex: "Male",
    Age: "<55 (25%)",
    Participant_Country: "Ukraine",
    Grade: "G2",
    Stage: "II",
    BMI: "18.5-25",
    Alcohol_consumption: "non-drinker",
    Tobacco_use: "past-smoker",
    Secondhand_smoke_exposure: "Yes",
    Overall_survival_in_days: "348-609 (25%-50%)",
    Recurrence_status: "No",
    Survival_status: "Dead",
    caseId: "C3L-00997",
    Immune_subtype: "Fibroblast/TGFBeta",
  },
  {
    Tumor: "HNSCC",
    Sex: "Male",
    Age: ">=70 (>=75%)",
    Participant_Country: "Poland",
    Grade: "G2",
    Stage: "IV",
    BMI: "18.5-25",
    Alcohol_consumption: "not_reported",
    Tobacco_use: "past-smoker",
    Secondhand_smoke_exposure: "not_reported",
    Overall_survival_in_days: ">=1016 (>=75%)",
    Recurrence_status: "No",
    Survival_status: "Alive",
    caseId: "C3N-01340",
    Immune_subtype: "Fibroblast/TGFBeta",
  },
  {
    Tumor: "HNSCC",
    Sex: "Male",
    Age: "63-70 (50%-75%)",
    Participant_Country: "Poland",
    Grade: "G2",
    Stage: "IV",
    BMI: ">=30",
    Alcohol_consumption: "drinker",
    Tobacco_use: "past-smoker",
    Secondhand_smoke_exposure: "No",
    Overall_survival_in_days: ">=1016 (>=75%)",
    Recurrence_status: "No",
    Survival_status: "Alive",
    caseId: "C3N-03781",
    Immune_subtype: "Fibroblast/TGFBeta",
  },
  {
    Tumor: "COAD",
    Sex: "Male",
    Age: "63-70 (50%-75%)",
    Participant_Country: "not_reported",
    Grade: "not_reported",
    Stage: "II",
    BMI: "not_reported",
    Alcohol_consumption: "not_reported",
    Tobacco_use: "not_reported",
    Secondhand_smoke_exposure: "not_reported",
    Overall_survival_in_days: "348-609 (25%-50%)",
    Recurrence_status: "Yes",
    Survival_status: "Dead",
    caseId: "11CO077",
    Immune_subtype: "Fibroblast/TGFBeta",
  },
  {
    Tumor: "GBM",
    Sex: "Female",
    Age: "<55 (25%)",
    Participant_Country: "Russia",
    Grade: "not_reported",
    Stage: "not_reported",
    BMI: "25-30",
    Alcohol_consumption: "non-drinker",
    Tobacco_use: "non-smoker",
    Secondhand_smoke_exposure: "No",
    Overall_survival_in_days: "348-609 (25%-50%)",
    Recurrence_status: "Yes",
    Survival_status: "Alive",
    caseId: "C3L-01154",
    Immune_subtype: "CD8-/IFNG-",
  },
  {
    Tumor: "ccRCC",
    Sex: "Male",
    Age: "63-70 (50%-75%)",
    Participant_Country: "Ukraine",
    Grade: "G3",
    Stage: "II",
    BMI: "25-30",
    Alcohol_consumption: "non-drinker",
    Tobacco_use: "not_reported",
    Secondhand_smoke_exposure: "not_reported",
    Overall_survival_in_days: "not_reported",
    Recurrence_status: "No",
    Survival_status: "Dead",
    caseId: "C3N-00154",
    Immune_subtype: "CD8+/IFNG+",
  },
  {
    Tumor: "GBM",
    Sex: "Female",
    Age: "<55 (25%)",
    Participant_Country: "China",
    Grade: "not_reported",
    Stage: "not_reported",
    BMI: "18.5-25",
    Alcohol_consumption: "non-drinker",
    Tobacco_use: "non-smoker",
    Secondhand_smoke_exposure: "No",
    Overall_survival_in_days: "348-609 (25%-50%)",
    Recurrence_status: "No",
    Survival_status: "Dead",
    caseId: "C3N-01814",
    Immune_subtype: "CD8-/IFNG-",
  },
  {
    Tumor: "BRCA",
    Sex: "Female",
    Age: ">=70 (>=75%)",
    Participant_Country: "not_reported",
    Grade: "not_reported",
    Stage: "II",
    BMI: "not_reported",
    Alcohol_consumption: "not_reported",
    Tobacco_use: "not_reported",
    Secondhand_smoke_exposure: "not_reported",
    Overall_survival_in_days: "348-609 (25%-50%)",
    Recurrence_status: "No",
    Survival_status: "Dead",
    caseId: "11BR024",
    Immune_subtype: "CD8+/IFNG+",
  },
  {
    Tumor: "COAD",
    Sex: "Male",
    Age: ">=70 (>=75%)",
    Participant_Country: "not_reported",
    Grade: "not_reported",
    Stage: "II",
    BMI: "not_reported",
    Alcohol_consumption: "not_reported",
    Tobacco_use: "not_reported",
    Secondhand_smoke_exposure: "not_reported",
    Overall_survival_in_days: "348-609 (25%-50%)",
    Recurrence_status: "No",
    Survival_status: "Dead",
    caseId: "11CO018",
    Immune_subtype: "CD8-/IFNG+",
  },
  {
    Tumor: "BRCA",
    Sex: "Female",
    Age: "<55 (25%)",
    Participant_Country: "not_reported",
    Grade: "not_reported",
    Stage: "III",
    BMI: "not_reported",
    Alcohol_consumption: "not_reported",
    Tobacco_use: "not_reported",
    Secondhand_smoke_exposure: "not_reported",
    Overall_survival_in_days: "348-609 (25%-50%)",
    Recurrence_status: "No",
    Survival_status: "Dead",
    caseId: "01BR017",
    Immune_subtype: "CD8+/IFNG+",
  },
  {
    Tumor: "PDAC",
    Sex: "Male",
    Age: "55-63 (25%-50%)",
    Participant_Country: "not_reported",
    Grade: "G3",
    Stage: "II",
    BMI: "25-30",
    Alcohol_consumption: "drinker",
    Tobacco_use: "past-smoker",
    Secondhand_smoke_exposure: "not_reported",
    Overall_survival_in_days: "<348 (25%)",
    Recurrence_status: "Yes",
    Survival_status: "Alive",
    caseId: "C3L-01703",
    Immune_subtype: "Eosinophils/Endothelial",
  },
  {
    Tumor: "COAD",
    Sex: "Male",
    Age: "<55 (25%)",
    Participant_Country: "not_reported",
    Grade: "not_reported",
    Stage: "II",
    BMI: "not_reported",
    Alcohol_consumption: "not_reported",
    Tobacco_use: "not_reported",
    Secondhand_smoke_exposure: "not_reported",
    Overall_survival_in_days: "348-609 (25%-50%)",
    Recurrence_status: "No",
    Survival_status: "Dead",
    caseId: "05CO053",
    Immune_subtype: "CD8-/IFNG-",
  },
  {
    Tumor: "BRCA",
    Sex: "Female",
    Age: "<55 (25%)",
    Participant_Country: "not_reported",
    Grade: "not_reported",
    Stage: "III",
    BMI: "not_reported",
    Alcohol_consumption: "not_reported",
    Tobacco_use: "not_reported",
    Secondhand_smoke_exposure: "not_reported",
    Overall_survival_in_days: "348-609 (25%-50%)",
    Recurrence_status: "No",
    Survival_status: "Dead",
    caseId: "01BR033",
    Immune_subtype: "Fibroblast/TGFBeta",
  },
  {
    Tumor: "ccRCC",
    Sex: "Female",
    Age: ">=70 (>=75%)",
    Participant_Country: "United States",
    Grade: "G3",
    Stage: "II",
    BMI: "25-30",
    Alcohol_consumption: "non-drinker",
    Tobacco_use: "non-smoker",
    Secondhand_smoke_exposure: "not_reported",
    Overall_survival_in_days: ">=1016 (>=75%)",
    Recurrence_status: "No",
    Survival_status: "Dead",
    caseId: "C3L-00792",
    Immune_subtype: "CD8-/IFNG+",
  },
  {
    Tumor: "PDAC",
    Sex: "Male",
    Age: "<55 (25%)",
    Participant_Country: "Russia",
    Grade: "G4",
    Stage: "II",
    BMI: "25-30",
    Alcohol_consumption: "not_reported",
    Tobacco_use: "non-smoker",
    Secondhand_smoke_exposure: "No",
    Overall_survival_in_days: "<348 (25%)",
    Recurrence_status: "No",
    Survival_status: "Alive",
    caseId: "C3L-01037",
    Immune_subtype: "CD8-/IFNG+",
  },
  {
    Tumor: "PDAC",
    Sex: "Male",
    Age: "<55 (25%)",
    Participant_Country: "China",
    Grade: "G3",
    Stage: "III",
    BMI: "18.5-25",
    Alcohol_consumption: "non-drinker",
    Tobacco_use: "non-smoker",
    Secondhand_smoke_exposure: "not_reported",
    Overall_survival_in_days: "348-609 (25%-50%)",
    Recurrence_status: "No",
    Survival_status: "Alive",
    caseId: "C3N-04126",
    Immune_subtype: "CD8-/IFNG+",
  },
  {
    Tumor: "HNSCC",
    Sex: "Male",
    Age: "<55 (25%)",
    Participant_Country: "China",
    Grade: "G1",
    Stage: "III",
    BMI: "18.5-25",
    Alcohol_consumption: "drinker",
    Tobacco_use: "non-smoker",
    Secondhand_smoke_exposure: "not_reported",
    Overall_survival_in_days: "609-1016 (50%-75%)",
    Recurrence_status: "No",
    Survival_status: "Dead",
    caseId: "C3N-03837",
    Immune_subtype: "CD8+/IFNG+",
  },
  {
    Tumor: "HGSC",
    Sex: "Female",
    Age: ">=70 (>=75%)",
    Participant_Country: "not_reported",
    Grade: "not_reported",
    Stage: "not_reported",
    BMI: "not_reported",
    Alcohol_consumption: "not_reported",
    Tobacco_use: "not_reported",
    Secondhand_smoke_exposure: "not_reported",
    Overall_survival_in_days: "<348 (25%)",
    Recurrence_status: "No",
    Survival_status: "Dead",
    caseId: "17OV033",
    Immune_subtype: "CD8-/IFNG-",
  },
  {
    Tumor: "HGSC",
    Sex: "Female",
    Age: "55-63 (25%-50%)",
    Participant_Country: "not_reported",
    Grade: "not_reported",
    Stage: "not_reported",
    BMI: "not_reported",
    Alcohol_consumption: "not_reported",
    Tobacco_use: "not_reported",
    Secondhand_smoke_exposure: "not_reported",
    Overall_survival_in_days: "<348 (25%)",
    Recurrence_status: "No",
    Survival_status: "Dead",
    caseId: "17OV036",
    Immune_subtype: "Fibroblast/TGFBeta",
  },
  {
    Tumor: "ccRCC",
    Sex: "Male",
    Age: "<55 (25%)",
    Participant_Country: "United States",
    Grade: "G3",
    Stage: "III",
    BMI: ">=30",
    Alcohol_consumption: "not_reported",
    Tobacco_use: "not_reported",
    Secondhand_smoke_exposure: "not_reported",
    Overall_survival_in_days: "<348 (25%)",
    Recurrence_status: "No",
    Survival_status: "Dead",
    caseId: "C3L-01557",
    Immune_subtype: "ccRCC/Endothelial",
  },
  {
    Tumor: "LUAD",
    Sex: "Female",
    Age: "55-63 (25%-50%)",
    Participant_Country: "China",
    Grade: "G2",
    Stage: "I",
    BMI: "18.5-25",
    Alcohol_consumption: "non-drinker",
    Tobacco_use: "non-smoker",
    Secondhand_smoke_exposure: "not_reported",
    Overall_survival_in_days: ">=1016 (>=75%)",
    Recurrence_status: "No",
    Survival_status: "Dead",
    caseId: "C3N-02587",
    Immune_subtype: "Eosinophils/Endothelial",
  },
  {
    Tumor: "HNSCC",
    Sex: "Female",
    Age: "63-70 (50%-75%)",
    Participant_Country: "Ukraine",
    Grade: "G2",
    Stage: "III",
    BMI: "25-30",
    Alcohol_consumption: "non-drinker",
    Tobacco_use: "non-smoker",
    Secondhand_smoke_exposure: "not_reported",
    Overall_survival_in_days: "609-1016 (50%-75%)",
    Recurrence_status: "No",
    Survival_status: "Dead",
    caseId: "C3N-03933",
    Immune_subtype: "CD8+/IFNG+",
  },
  {
    Tumor: "COAD",
    Sex: "Female",
    Age: "63-70 (50%-75%)",
    Participant_Country: "not_reported",
    Grade: "not_reported",
    Stage: "II",
    BMI: "not_reported",
    Alcohol_consumption: "not_reported",
    Tobacco_use: "not_reported",
    Secondhand_smoke_exposure: "not_reported",
    Overall_survival_in_days: "348-609 (25%-50%)",
    Recurrence_status: "No",
    Survival_status: "Dead",
    caseId: "11CO054",
    Immune_subtype: "CD8-/IFNG+",
  },
  {
    Tumor: "HNSCC",
    Sex: "Male",
    Age: "55-63 (25%-50%)",
    Participant_Country: "Ukraine",
    Grade: "G2",
    Stage: "III",
    BMI: ">=30",
    Alcohol_consumption: "drinker",
    Tobacco_use: "current smoker",
    Secondhand_smoke_exposure: "not_reported",
    Overall_survival_in_days: "609-1016 (50%-75%)",
    Recurrence_status: "No",
    Survival_status: "Dead",
    caseId: "C3N-03888",
    Immune_subtype: "CD8-/IFNG+",
  },
  {
    Tumor: "LUAD",
    Sex: "Female",
    Age: "<55 (25%)",
    Participant_Country: "China",
    Grade: "G2",
    Stage: "III",
    BMI: "18.5-25",
    Alcohol_consumption: "not_reported",
    Tobacco_use: "not_reported",
    Secondhand_smoke_exposure: "not_reported",
    Overall_survival_in_days: ">=1016 (>=75%)",
    Recurrence_status: "Yes",
    Survival_status: "Dead",
    caseId: "C3N-02067",
    Immune_subtype: "Eosinophils/Endothelial",
  },
  {
    Tumor: "ccRCC",
    Sex: "Female",
    Age: "55-63 (25%-50%)",
    Participant_Country: "Ukraine",
    Grade: "G2",
    Stage: "I",
    BMI: ">=30",
    Alcohol_consumption: "non-drinker",
    Tobacco_use: "non-smoker",
    Secondhand_smoke_exposure: "not_reported",
    Overall_survival_in_days: "609-1016 (50%-75%)",
    Recurrence_status: "No",
    Survival_status: "Dead",
    caseId: "C3N-00380",
    Immune_subtype: "ccRCC/Endothelial",
  },
  {
    Tumor: "HGSC",
    Sex: "Female",
    Age: ">=70 (>=75%)",
    Participant_Country: "not_reported",
    Grade: "not_reported",
    Stage: "III",
    BMI: "not_reported",
    Alcohol_consumption: "not_reported",
    Tobacco_use: "not_reported",
    Secondhand_smoke_exposure: "not_reported",
    Overall_survival_in_days: "348-609 (25%-50%)",
    Recurrence_status: "No",
    Survival_status: "Dead",
    caseId: "17OV017",
    Immune_subtype: "CD8-/IFNG-",
  },
  {
    Tumor: "HGSC",
    Sex: "Female",
    Age: "<55 (25%)",
    Participant_Country: "not_reported",
    Grade: "G3",
    Stage: "III",
    BMI: "not_reported",
    Alcohol_consumption: "not_reported",
    Tobacco_use: "not_reported",
    Secondhand_smoke_exposure: "not_reported",
    Overall_survival_in_days: "not_reported",
    Recurrence_status: "No",
    Survival_status: "not_reported",
    caseId: "01OV029",
    Immune_subtype: "not_reported",
  },
  {
    Tumor: "COAD",
    Sex: "Male",
    Age: "<55 (25%)",
    Participant_Country: "not_reported",
    Grade: "not_reported",
    Stage: "III",
    BMI: "not_reported",
    Alcohol_consumption: "not_reported",
    Tobacco_use: "not_reported",
    Secondhand_smoke_exposure: "not_reported",
    Overall_survival_in_days: "348-609 (25%-50%)",
    Recurrence_status: "No",
    Survival_status: "Dead",
    caseId: "11CO020",
    Immune_subtype: "CD8-/IFNG-",
  },
  {
    Tumor: "LSCC",
    Sex: "Female",
    Age: ">=70 (>=75%)",
    Participant_Country: "United States",
    Grade: "G3",
    Stage: "III",
    BMI: "18.5-25",
    Alcohol_consumption: "drinker",
    Tobacco_use: "current smoker",
    Secondhand_smoke_exposure: "not_reported",
    Overall_survival_in_days: ">=1016 (>=75%)",
    Recurrence_status: "Yes",
    Survival_status: "Alive",
    caseId: "C3L-01663",
    Immune_subtype: "Fibroblast/TGFBeta",
  },
  {
    Tumor: "ccRCC",
    Sex: "Male",
    Age: "63-70 (50%-75%)",
    Participant_Country: "Poland",
    Grade: "G2",
    Stage: "I",
    BMI: ">=30",
    Alcohol_consumption: "drinker",
    Tobacco_use: "current smoker",
    Secondhand_smoke_exposure: "not_reported",
    Overall_survival_in_days: ">=1016 (>=75%)",
    Recurrence_status: "No",
    Survival_status: "Dead",
    caseId: "C3N-00494",
    Immune_subtype: "CD8-/IFNG-",
  },
  {
    Tumor: "BRCA",
    Sex: "Female",
    Age: ">=70 (>=75%)",
    Participant_Country: "not_reported",
    Grade: "not_reported",
    Stage: "III",
    BMI: "not_reported",
    Alcohol_consumption: "not_reported",
    Tobacco_use: "not_reported",
    Secondhand_smoke_exposure: "not_reported",
    Overall_survival_in_days: "<348 (25%)",
    Recurrence_status: "No",
    Survival_status: "Dead",
    caseId: "09BR007",
    Immune_subtype: "Fibroblast/TGFBeta",
  },
  {
    Tumor: "HNSCC",
    Sex: "Male",
    Age: "63-70 (50%-75%)",
    Participant_Country: "Poland",
    Grade: "G1",
    Stage: "IV",
    BMI: ">=30",
    Alcohol_consumption: "drinker",
    Tobacco_use: "current smoker",
    Secondhand_smoke_exposure: "Yes",
    Overall_survival_in_days: "609-1016 (50%-75%)",
    Recurrence_status: "Yes",
    Survival_status: "Alive",
    caseId: "C3N-03783",
    Immune_subtype: "Fibroblast/TGFBeta",
  },
  {
    Tumor: "GBM",
    Sex: "Male",
    Age: "63-70 (50%-75%)",
    Participant_Country: "Poland",
    Grade: "not_reported",
    Stage: "not_reported",
    BMI: "18.5-25",
    Alcohol_consumption: "non-drinker",
    Tobacco_use: "non-smoker",
    Secondhand_smoke_exposure: "No",
    Overall_survival_in_days: "348-609 (25%-50%)",
    Recurrence_status: "Yes",
    Survival_status: "Alive",
    caseId: "C3N-01856",
    Immune_subtype: "Fibroblast/TGFBeta",
  },
  {
    Tumor: "LSCC",
    Sex: "Female",
    Age: "63-70 (50%-75%)",
    Participant_Country: "Poland",
    Grade: "G2",
    Stage: "II",
    BMI: "18.5-25",
    Alcohol_consumption: "drinker",
    Tobacco_use: "past-smoker",
    Secondhand_smoke_exposure: "not_reported",
    Overall_survival_in_days: ">=1016 (>=75%)",
    Recurrence_status: "No",
    Survival_status: "Dead",
    caseId: "C3N-00497",
    Immune_subtype: "CD8+/IFNG+",
  },
  {
    Tumor: "ccRCC",
    Sex: "Female",
    Age: "<55 (25%)",
    Participant_Country: "Ukraine",
    Grade: "G3",
    Stage: "IV",
    BMI: "<18.5",
    Alcohol_consumption: "non-drinker",
    Tobacco_use: "not_reported",
    Secondhand_smoke_exposure: "not_reported",
    Overall_survival_in_days: "not_reported",
    Recurrence_status: "No",
    Survival_status: "Dead",
    caseId: "C3N-00150",
    Immune_subtype: "ccRCC/Endothelial",
  },
  {
    Tumor: "PDAC",
    Sex: "Male",
    Age: ">=70 (>=75%)",
    Participant_Country: "United States",
    Grade: "G2",
    Stage: "II",
    BMI: "25-30",
    Alcohol_consumption: "drinker",
    Tobacco_use: "past-smoker",
    Secondhand_smoke_exposure: "not_reported",
    Overall_survival_in_days: ">=1016 (>=75%)",
    Recurrence_status: "No",
    Survival_status: "Dead",
    caseId: "C3L-00589",
    Immune_subtype: "Eosinophils/Endothelial",
  },
  {
    Tumor: "HGSC",
    Sex: "Female",
    Age: "<55 (25%)",
    Participant_Country: "not_reported",
    Grade: "G3",
    Stage: "IV",
    BMI: "not_reported",
    Alcohol_consumption: "not_reported",
    Tobacco_use: "not_reported",
    Secondhand_smoke_exposure: "not_reported",
    Overall_survival_in_days: "<348 (25%)",
    Recurrence_status: "No",
    Survival_status: "Dead",
    caseId: "02OV041",
    Immune_subtype: "CD8-/IFNG-",
  },
  {
    Tumor: "GBM",
    Sex: "Male",
    Age: "55-63 (25%-50%)",
    Participant_Country: "China",
    Grade: "not_reported",
    Stage: "not_reported",
    BMI: "18.5-25",
    Alcohol_consumption: "non-drinker",
    Tobacco_use: "current smoker",
    Secondhand_smoke_exposure: "No",
    Overall_survival_in_days: "<348 (25%)",
    Recurrence_status: "No",
    Survival_status: "Alive",
    caseId: "C3N-01818",
    Immune_subtype: "Brain/Neuro",
  },
  {
    Tumor: "GBM",
    Sex: "Male",
    Age: "63-70 (50%-75%)",
    Participant_Country: "China",
    Grade: "not_reported",
    Stage: "not_reported",
    BMI: "18.5-25",
    Alcohol_consumption: "non-drinker",
    Tobacco_use: "current smoker",
    Secondhand_smoke_exposure: "No",
    Overall_survival_in_days: "609-1016 (50%-75%)",
    Recurrence_status: "Yes",
    Survival_status: "Alive",
    caseId: "C3N-02788",
    Immune_subtype: "Brain/Neuro",
  },
  {
    Tumor: "PDAC",
    Sex: "Female",
    Age: "63-70 (50%-75%)",
    Participant_Country: "not_reported",
    Grade: "G2",
    Stage: "II",
    BMI: "18.5-25",
    Alcohol_consumption: "not_reported",
    Tobacco_use: "past-smoker",
    Secondhand_smoke_exposure: "not_reported",
    Overall_survival_in_days: "348-609 (25%-50%)",
    Recurrence_status: "Yes",
    Survival_status: "Alive",
    caseId: "C3L-03628",
    Immune_subtype: "Eosinophils/Endothelial",
  },
  {
    Tumor: "ccRCC",
    Sex: "Male",
    Age: "<55 (25%)",
    Participant_Country: "United States",
    Grade: "G2",
    Stage: "I",
    BMI: ">=30",
    Alcohol_consumption: "drinker",
    Tobacco_use: "non-smoker",
    Secondhand_smoke_exposure: "not_reported",
    Overall_survival_in_days: "348-609 (25%-50%)",
    Recurrence_status: "No",
    Survival_status: "Dead",
    caseId: "C3L-00790",
    Immune_subtype: "ccRCC/Endothelial",
  },
  {
    Tumor: "LUAD",
    Sex: "Male",
    Age: "63-70 (50%-75%)",
    Participant_Country: "China",
    Grade: "G3",
    Stage: "II",
    BMI: "18.5-25",
    Alcohol_consumption: "drinker",
    Tobacco_use: "current smoker",
    Secondhand_smoke_exposure: "not_reported",
    Overall_survival_in_days: "348-609 (25%-50%)",
    Recurrence_status: "No",
    Survival_status: "Dead",
    caseId: "C3N-02424",
    Immune_subtype: "CD8+/IFNG+",
  },
  {
    Tumor: "ccRCC",
    Sex: "Female",
    Age: "<55 (25%)",
    Participant_Country: "United States",
    Grade: "G3",
    Stage: "IV",
    BMI: "25-30",
    Alcohol_consumption: "non-drinker",
    Tobacco_use: "current smoker",
    Secondhand_smoke_exposure: "not_reported",
    Overall_survival_in_days: "<348 (25%)",
    Recurrence_status: "Yes",
    Survival_status: "Dead",
    caseId: "C3N-00194",
    Immune_subtype: "Fibroblast/TGFBeta",
  },
  {
    Tumor: "COAD",
    Sex: "Female",
    Age: "63-70 (50%-75%)",
    Participant_Country: "not_reported",
    Grade: "not_reported",
    Stage: "II",
    BMI: "not_reported",
    Alcohol_consumption: "not_reported",
    Tobacco_use: "not_reported",
    Secondhand_smoke_exposure: "not_reported",
    Overall_survival_in_days: "348-609 (25%-50%)",
    Recurrence_status: "No",
    Survival_status: "Dead",
    caseId: "11CO039",
    Immune_subtype: "CD8-/IFNG-",
  },
  {
    Tumor: "HNSCC",
    Sex: "Male",
    Age: ">=70 (>=75%)",
    Participant_Country: "Poland",
    Grade: "G2",
    Stage: "III",
    BMI: "25-30",
    Alcohol_consumption: "drinker",
    Tobacco_use: "current smoker",
    Secondhand_smoke_exposure: "No",
    Overall_survival_in_days: ">=1016 (>=75%)",
    Recurrence_status: "No",
    Survival_status: "Dead",
    caseId: "C3N-04277",
    Immune_subtype: "Fibroblast/TGFBeta",
  },
  {
    Tumor: "HNSCC",
    Sex: "Male",
    Age: "63-70 (50%-75%)",
    Participant_Country: "Bulgaria",
    Grade: "G2",
    Stage: "I",
    BMI: "18.5-25",
    Alcohol_consumption: "drinker",
    Tobacco_use: "non-smoker",
    Secondhand_smoke_exposure: "No",
    Overall_survival_in_days: "348-609 (25%-50%)",
    Recurrence_status: "No",
    Survival_status: "Dead",
    caseId: "C3L-04025",
    Immune_subtype: "Fibroblast/TGFBeta",
  },
  {
    Tumor: "LSCC",
    Sex: "Female",
    Age: ">=70 (>=75%)",
    Participant_Country: "United States",
    Grade: "G2",
    Stage: "I",
    BMI: "25-30",
    Alcohol_consumption: "non-drinker",
    Tobacco_use: "past-smoker",
    Secondhand_smoke_exposure: "not_reported",
    Overall_survival_in_days: "<348 (25%)",
    Recurrence_status: "No",
    Survival_status: "Dead",
    caseId: "C3L-00568",
    Immune_subtype: "Eosinophils/Endothelial",
  },
  {
    Tumor: "LSCC",
    Sex: "Male",
    Age: ">=70 (>=75%)",
    Participant_Country: "Bulgaria",
    Grade: "G3",
    Stage: "III",
    BMI: "18.5-25",
    Alcohol_consumption: "drinker",
    Tobacco_use: "past-smoker",
    Secondhand_smoke_exposure: "Yes",
    Overall_survival_in_days: "348-609 (25%-50%)",
    Recurrence_status: "No",
    Survival_status: "Alive",
    caseId: "C3L-02660",
    Immune_subtype: "Eosinophils/Endothelial",
  },
  {
    Tumor: "ccRCC",
    Sex: "Male",
    Age: ">=70 (>=75%)",
    Participant_Country: "United States",
    Grade: "G2",
    Stage: "I",
    BMI: ">=30",
    Alcohol_consumption: "not_reported",
    Tobacco_use: "not_reported",
    Secondhand_smoke_exposure: "not_reported",
    Overall_survival_in_days: ">=1016 (>=75%)",
    Recurrence_status: "No",
    Survival_status: "Dead",
    caseId: "C3L-00817",
    Immune_subtype: "ccRCC/Endothelial",
  },
  {
    Tumor: "UCEC",
    Sex: "Female",
    Age: "63-70 (50%-75%)",
    Participant_Country: "Ukraine",
    Grade: "G2",
    Stage: "I",
    BMI: ">=30",
    Alcohol_consumption: "non-drinker",
    Tobacco_use: "non-smoker",
    Secondhand_smoke_exposure: "not_reported",
    Overall_survival_in_days: "609-1016 (50%-75%)",
    Recurrence_status: "No",
    Survival_status: "Dead",
    caseId: "C3N-00333",
    Immune_subtype: "Fibroblast/TGFBeta",
  },
  {
    Tumor: "BRCA",
    Sex: "Female",
    Age: "63-70 (50%-75%)",
    Participant_Country: "not_reported",
    Grade: "not_reported",
    Stage: "III",
    BMI: "not_reported",
    Alcohol_consumption: "not_reported",
    Tobacco_use: "not_reported",
    Secondhand_smoke_exposure: "not_reported",
    Overall_survival_in_days: "348-609 (25%-50%)",
    Recurrence_status: "No",
    Survival_status: "Dead",
    caseId: "14BR008",
    Immune_subtype: "Fibroblast/TGFBeta",
  },
  {
    Tumor: "BRCA",
    Sex: "Female",
    Age: "63-70 (50%-75%)",
    Participant_Country: "not_reported",
    Grade: "not_reported",
    Stage: "II",
    BMI: "not_reported",
    Alcohol_consumption: "not_reported",
    Tobacco_use: "not_reported",
    Secondhand_smoke_exposure: "not_reported",
    Overall_survival_in_days: "348-609 (25%-50%)",
    Recurrence_status: "No",
    Survival_status: "Dead",
    caseId: "18BR007",
    Immune_subtype: "Fibroblast/TGFBeta",
  },
  {
    Tumor: "UCEC",
    Sex: "Female",
    Age: "<55 (25%)",
    Participant_Country: "Ukraine",
    Grade: "G2",
    Stage: "I",
    BMI: "18.5-25",
    Alcohol_consumption: "non-drinker",
    Tobacco_use: "non-smoker",
    Secondhand_smoke_exposure: "not_reported",
    Overall_survival_in_days: "609-1016 (50%-75%)",
    Recurrence_status: "No",
    Survival_status: "Dead",
    caseId: "C3N-00339",
    Immune_subtype: "CD8-/IFNG-",
  },
  {
    Tumor: "BRCA",
    Sex: "Female",
    Age: "<55 (25%)",
    Participant_Country: "not_reported",
    Grade: "not_reported",
    Stage: "II",
    BMI: "not_reported",
    Alcohol_consumption: "not_reported",
    Tobacco_use: "not_reported",
    Secondhand_smoke_exposure: "not_reported",
    Overall_survival_in_days: "348-609 (25%-50%)",
    Recurrence_status: "No",
    Survival_status: "Dead",
    caseId: "11BR059",
    Immune_subtype: "Fibroblast/TGFBeta",
  },
  {
    Tumor: "LSCC",
    Sex: "Male",
    Age: "63-70 (50%-75%)",
    Participant_Country: "China",
    Grade: "G2",
    Stage: "II",
    BMI: "18.5-25",
    Alcohol_consumption: "drinker",
    Tobacco_use: "past-smoker",
    Secondhand_smoke_exposure: "not_reported",
    Overall_survival_in_days: ">=1016 (>=75%)",
    Recurrence_status: "No",
    Survival_status: "Dead",
    caseId: "C3N-02374",
    Immune_subtype: "CD8-/IFNG-",
  },
  {
    Tumor: "ccRCC",
    Sex: "Male",
    Age: "63-70 (50%-75%)",
    Participant_Country: "United States",
    Grade: "G2",
    Stage: "I",
    BMI: ">=30",
    Alcohol_consumption: "drinker",
    Tobacco_use: "past-smoker",
    Secondhand_smoke_exposure: "not_reported",
    Overall_survival_in_days: "<348 (25%)",
    Recurrence_status: "No",
    Survival_status: "Dead",
    caseId: "C3L-01885",
    Immune_subtype: "ccRCC/Endothelial",
  },
  {
    Tumor: "GBM",
    Sex: "Female",
    Age: "<55 (25%)",
    Participant_Country: "United States",
    Grade: "not_reported",
    Stage: "not_reported",
    BMI: "18.5-25",
    Alcohol_consumption: "not_reported",
    Tobacco_use: "non-smoker",
    Secondhand_smoke_exposure: "not_reported",
    Overall_survival_in_days: ">=1016 (>=75%)",
    Recurrence_status: "Yes",
    Survival_status: "Dead",
    caseId: "C3L-02041",
    Immune_subtype: "CD8+/IFNG+",
  },
  {
    Tumor: "COAD",
    Sex: "Female",
    Age: "55-63 (25%-50%)",
    Participant_Country: "not_reported",
    Grade: "not_reported",
    Stage: "III",
    BMI: "not_reported",
    Alcohol_consumption: "not_reported",
    Tobacco_use: "not_reported",
    Secondhand_smoke_exposure: "not_reported",
    Overall_survival_in_days: "348-609 (25%-50%)",
    Recurrence_status: "Yes",
    Survival_status: "Dead",
    caseId: "01CO019",
    Immune_subtype: "CD8-/IFNG-",
  },
  {
    Tumor: "BRCA",
    Sex: "Female",
    Age: ">=70 (>=75%)",
    Participant_Country: "not_reported",
    Grade: "not_reported",
    Stage: "II",
    BMI: "not_reported",
    Alcohol_consumption: "not_reported",
    Tobacco_use: "not_reported",
    Secondhand_smoke_exposure: "not_reported",
    Overall_survival_in_days: "348-609 (25%-50%)",
    Recurrence_status: "No",
    Survival_status: "Dead",
    caseId: "11BR042",
    Immune_subtype: "Fibroblast/TGFBeta",
  },
  {
    Tumor: "LSCC",
    Sex: "Male",
    Age: "63-70 (50%-75%)",
    Participant_Country: "Bulgaria",
    Grade: "G3",
    Stage: "II",
    BMI: "25-30",
    Alcohol_consumption: "non-drinker",
    Tobacco_use: "past-smoker",
    Secondhand_smoke_exposure: "not_reported",
    Overall_survival_in_days: "348-609 (25%-50%)",
    Recurrence_status: "No",
    Survival_status: "Alive",
    caseId: "C3L-02625",
    Immune_subtype: "CD8+/IFNG+",
  },
  {
    Tumor: "GBM",
    Sex: "Female",
    Age: "<55 (25%)",
    Participant_Country: "Russia",
    Grade: "not_reported",
    Stage: "not_reported",
    BMI: "25-30",
    Alcohol_consumption: "non-drinker",
    Tobacco_use: "non-smoker",
    Secondhand_smoke_exposure: "No",
    Overall_survival_in_days: "609-1016 (50%-75%)",
    Recurrence_status: "No",
    Survival_status: "Dead",
    caseId: "C3L-03400",
    Immune_subtype: "CD8+/IFNG+",
  },
  {
    Tumor: "HNSCC",
    Sex: "Male",
    Age: "<55 (25%)",
    Participant_Country: "Ukraine",
    Grade: "G2",
    Stage: "II",
    BMI: "18.5-25",
    Alcohol_consumption: "drinker",
    Tobacco_use: "past-smoker",
    Secondhand_smoke_exposure: "not_reported",
    Overall_survival_in_days: "<348 (25%)",
    Recurrence_status: "Yes",
    Survival_status: "Alive",
    caseId: "C3L-00994",
    Immune_subtype: "Fibroblast/TGFBeta",
  },
  {
    Tumor: "COAD",
    Sex: "Female",
    Age: "55-63 (25%-50%)",
    Participant_Country: "not_reported",
    Grade: "not_reported",
    Stage: "II",
    BMI: "not_reported",
    Alcohol_consumption: "not_reported",
    Tobacco_use: "not_reported",
    Secondhand_smoke_exposure: "not_reported",
    Overall_survival_in_days: "348-609 (25%-50%)",
    Recurrence_status: "No",
    Survival_status: "Dead",
    caseId: "11CO058",
    Immune_subtype: "Fibroblast/TGFBeta",
  },
  {
    Tumor: "HGSC",
    Sex: "Female",
    Age: "<55 (25%)",
    Participant_Country: "not_reported",
    Grade: "G3",
    Stage: "IV",
    BMI: "not_reported",
    Alcohol_consumption: "not_reported",
    Tobacco_use: "not_reported",
    Secondhand_smoke_exposure: "not_reported",
    Overall_survival_in_days: ">=1016 (>=75%)",
    Recurrence_status: "Yes",
    Survival_status: "Dead",
    caseId: "04OV063",
    Immune_subtype: "Fibroblast/TGFBeta",
  },
  {
    Tumor: "LSCC",
    Sex: "Male",
    Age: "63-70 (50%-75%)",
    Participant_Country: "China",
    Grade: "G3",
    Stage: "I",
    BMI: "18.5-25",
    Alcohol_consumption: "drinker",
    Tobacco_use: "past-smoker",
    Secondhand_smoke_exposure: "not_reported",
    Overall_survival_in_days: ">=1016 (>=75%)",
    Recurrence_status: "No",
    Survival_status: "Dead",
    caseId: "C3N-03875",
    Immune_subtype: "CD8-/IFNG+",
  },
  {
    Tumor: "LSCC",
    Sex: "Male",
    Age: "55-63 (25%-50%)",
    Participant_Country: "Russia",
    Grade: "G3",
    Stage: "I",
    BMI: ">=30",
    Alcohol_consumption: "non-drinker",
    Tobacco_use: "current smoker",
    Secondhand_smoke_exposure: "Yes",
    Overall_survival_in_days: ">=1016 (>=75%)",
    Recurrence_status: "No",
    Survival_status: "Dead",
    caseId: "C3L-01000",
    Immune_subtype: "CD8+/IFNG+",
  },
  {
    Tumor: "LSCC",
    Sex: "Female",
    Age: "63-70 (50%-75%)",
    Participant_Country: "China",
    Grade: "G3",
    Stage: "I",
    BMI: "18.5-25",
    Alcohol_consumption: "non-drinker",
    Tobacco_use: "non-smoker",
    Secondhand_smoke_exposure: "not_reported",
    Overall_survival_in_days: "348-609 (25%-50%)",
    Recurrence_status: "Yes",
    Survival_status: "Alive",
    caseId: "C3N-02426",
    Immune_subtype: "Fibroblast/TGFBeta",
  },
  {
    Tumor: "HGSC",
    Sex: "Female",
    Age: "<55 (25%)",
    Participant_Country: "not_reported",
    Grade: "G3",
    Stage: "III",
    BMI: "not_reported",
    Alcohol_consumption: "not_reported",
    Tobacco_use: "not_reported",
    Secondhand_smoke_exposure: "not_reported",
    Overall_survival_in_days: "<348 (25%)",
    Recurrence_status: "No",
    Survival_status: "Dead",
    caseId: "04OV039",
    Immune_subtype: "CD8-/IFNG-",
  },
  {
    Tumor: "ccRCC",
    Sex: "Female",
    Age: "55-63 (25%-50%)",
    Participant_Country: "Ukraine",
    Grade: "G3",
    Stage: "IV",
    BMI: "25-30",
    Alcohol_consumption: "non-drinker",
    Tobacco_use: "non-smoker",
    Secondhand_smoke_exposure: "not_reported",
    Overall_survival_in_days: "<348 (25%)",
    Recurrence_status: "No",
    Survival_status: "Alive",
    caseId: "C3N-01200",
    Immune_subtype: "CD8+/IFNG+",
  },
  {
    Tumor: "ccRCC",
    Sex: "Male",
    Age: "<55 (25%)",
    Participant_Country: "Poland",
    Grade: "G2",
    Stage: "I",
    BMI: ">=30",
    Alcohol_consumption: "drinker",
    Tobacco_use: "non-smoker",
    Secondhand_smoke_exposure: "No",
    Overall_survival_in_days: ">=1016 (>=75%)",
    Recurrence_status: "No",
    Survival_status: "Dead",
    caseId: "C3N-01361",
    Immune_subtype: "ccRCC/Endothelial",
  },
  {
    Tumor: "COAD",
    Sex: "Male",
    Age: "55-63 (25%-50%)",
    Participant_Country: "not_reported",
    Grade: "not_reported",
    Stage: "I",
    BMI: "not_reported",
    Alcohol_consumption: "not_reported",
    Tobacco_use: "not_reported",
    Secondhand_smoke_exposure: "not_reported",
    Overall_survival_in_days: "<348 (25%)",
    Recurrence_status: "No",
    Survival_status: "Dead",
    caseId: "01CO013",
    Immune_subtype: "CD8-/IFNG-",
  },
  {
    Tumor: "LUAD",
    Sex: "Male",
    Age: ">=70 (>=75%)",
    Participant_Country: "China",
    Grade: "G2",
    Stage: "II",
    BMI: "18.5-25",
    Alcohol_consumption: "drinker",
    Tobacco_use: "past-smoker",
    Secondhand_smoke_exposure: "not_reported",
    Overall_survival_in_days: "<348 (25%)",
    Recurrence_status: "No",
    Survival_status: "Alive",
    caseId: "C3N-02586",
    Immune_subtype: "CD8-/IFNG+",
  },
  {
    Tumor: "LSCC",
    Sex: "Male",
    Age: "63-70 (50%-75%)",
    Participant_Country: "United States",
    Grade: "G2",
    Stage: "I",
    BMI: "25-30",
    Alcohol_consumption: "drinker",
    Tobacco_use: "past-smoker",
    Secondhand_smoke_exposure: "not_reported",
    Overall_survival_in_days: ">=1016 (>=75%)",
    Recurrence_status: "No",
    Survival_status: "Dead",
    caseId: "C3L-01838",
    Immune_subtype: "Fibroblast/TGFBeta",
  },
  {
    Tumor: "HGSC",
    Sex: "Female",
    Age: ">=70 (>=75%)",
    Participant_Country: "not_reported",
    Grade: "G3",
    Stage: "III",
    BMI: "not_reported",
    Alcohol_consumption: "not_reported",
    Tobacco_use: "not_reported",
    Secondhand_smoke_exposure: "not_reported",
    Overall_survival_in_days: "<348 (25%)",
    Recurrence_status: "No",
    Survival_status: "Alive",
    caseId: "01OV026",
    Immune_subtype: "Fibroblast/TGFBeta",
  },
  {
    Tumor: "GBM",
    Sex: "Female",
    Age: ">=70 (>=75%)",
    Participant_Country: "Russia",
    Grade: "not_reported",
    Stage: "not_reported",
    BMI: "18.5-25",
    Alcohol_consumption: "non-drinker",
    Tobacco_use: "non-smoker",
    Secondhand_smoke_exposure: "Yes",
    Overall_survival_in_days: "<348 (25%)",
    Recurrence_status: "No",
    Survival_status: "Alive",
    caseId: "C3L-01045",
    Immune_subtype: "CD8-/IFNG-",
  },
  {
    Tumor: "ccRCC",
    Sex: "Male",
    Age: "55-63 (25%-50%)",
    Participant_Country: "Ukraine",
    Grade: "G2",
    Stage: "II",
    BMI: "18.5-25",
    Alcohol_consumption: "drinker",
    Tobacco_use: "current smoker",
    Secondhand_smoke_exposure: "not_reported",
    Overall_survival_in_days: "609-1016 (50%-75%)",
    Recurrence_status: "No",
    Survival_status: "Dead",
    caseId: "C3N-01214",
    Immune_subtype: "CD8+/IFNG+",
  },
  {
    Tumor: "HNSCC",
    Sex: "Male",
    Age: "55-63 (25%-50%)",
    Participant_Country: "Poland",
    Grade: "G2",
    Stage: "IV",
    BMI: "18.5-25",
    Alcohol_consumption: "drinker",
    Tobacco_use: "past-smoker",
    Secondhand_smoke_exposure: "No",
    Overall_survival_in_days: "609-1016 (50%-75%)",
    Recurrence_status: "No",
    Survival_status: "Alive",
    caseId: "C3N-01758",
    Immune_subtype: "Fibroblast/TGFBeta",
  },
  {
    Tumor: "ccRCC",
    Sex: "Female",
    Age: "55-63 (25%-50%)",
    Participant_Country: "Poland",
    Grade: "G1",
    Stage: "I",
    BMI: "18.5-25",
    Alcohol_consumption: "drinker",
    Tobacco_use: "current smoker",
    Secondhand_smoke_exposure: "not_reported",
    Overall_survival_in_days: ">=1016 (>=75%)",
    Recurrence_status: "Yes",
    Survival_status: "Dead",
    caseId: "C3N-00305",
    Immune_subtype: "ccRCC/Endothelial",
  },
  {
    Tumor: "LUAD",
    Sex: "Female",
    Age: "55-63 (25%-50%)",
    Participant_Country: "China",
    Grade: "G3",
    Stage: "II",
    BMI: "18.5-25",
    Alcohol_consumption: "non-drinker",
    Tobacco_use: "non-smoker",
    Secondhand_smoke_exposure: "not_reported",
    Overall_survival_in_days: "348-609 (25%-50%)",
    Recurrence_status: "No",
    Survival_status: "Alive",
    caseId: "C3N-02379",
    Immune_subtype: "CD8-/IFNG+",
  },
  {
    Tumor: "COAD",
    Sex: "Female",
    Age: "55-63 (25%-50%)",
    Participant_Country: "not_reported",
    Grade: "not_reported",
    Stage: "II",
    BMI: "not_reported",
    Alcohol_consumption: "not_reported",
    Tobacco_use: "not_reported",
    Secondhand_smoke_exposure: "not_reported",
    Overall_survival_in_days: "348-609 (25%-50%)",
    Recurrence_status: "No",
    Survival_status: "Dead",
    caseId: "11CO051",
    Immune_subtype: "Fibroblast/TGFBeta",
  },
  {
    Tumor: "LUAD",
    Sex: "Female",
    Age: ">=70 (>=75%)",
    Participant_Country: "United States",
    Grade: "G2",
    Stage: "I",
    BMI: ">=30",
    Alcohol_consumption: "non-drinker",
    Tobacco_use: "past-smoker",
    Secondhand_smoke_exposure: "not_reported",
    Overall_survival_in_days: ">=1016 (>=75%)",
    Recurrence_status: "No",
    Survival_status: "Dead",
    caseId: "C3L-01889",
    Immune_subtype: "CD8-/IFNG-",
  },
  {
    Tumor: "BRCA",
    Sex: "Female",
    Age: ">=70 (>=75%)",
    Participant_Country: "not_reported",
    Grade: "not_reported",
    Stage: "not_reported",
    BMI: "not_reported",
    Alcohol_consumption: "not_reported",
    Tobacco_use: "not_reported",
    Secondhand_smoke_exposure: "not_reported",
    Overall_survival_in_days: "not_reported",
    Recurrence_status: "No",
    Survival_status: "not_reported",
    caseId: "03BR011",
    Immune_subtype: "not_reported",
  },
  {
    Tumor: "GBM",
    Sex: "Female",
    Age: "<55 (25%)",
    Participant_Country: "China",
    Grade: "not_reported",
    Stage: "not_reported",
    BMI: "18.5-25",
    Alcohol_consumption: "non-drinker",
    Tobacco_use: "non-smoker",
    Secondhand_smoke_exposure: "No",
    Overall_survival_in_days: ">=1016 (>=75%)",
    Recurrence_status: "No",
    Survival_status: "Dead",
    caseId: "C3N-02786",
    Immune_subtype: "CD8+/IFNG+",
  },
  {
    Tumor: "LUAD",
    Sex: "Male",
    Age: "55-63 (25%-50%)",
    Participant_Country: "Vietnam",
    Grade: "G3",
    Stage: "II",
    BMI: "<18.5",
    Alcohol_consumption: "drinker",
    Tobacco_use: "current smoker",
    Secondhand_smoke_exposure: "No",
    Overall_survival_in_days: "348-609 (25%-50%)",
    Recurrence_status: "No",
    Survival_status: "Dead",
    caseId: "C3N-00546",
    Immune_subtype: "CD8+/IFNG+",
  },
  {
    Tumor: "BRCA",
    Sex: "Female",
    Age: "<55 (25%)",
    Participant_Country: "not_reported",
    Grade: "not_reported",
    Stage: "III",
    BMI: "not_reported",
    Alcohol_consumption: "not_reported",
    Tobacco_use: "not_reported",
    Secondhand_smoke_exposure: "not_reported",
    Overall_survival_in_days: "348-609 (25%-50%)",
    Recurrence_status: "No",
    Survival_status: "Dead",
    caseId: "11BR044",
    Immune_subtype: "CD8-/IFNG+",
  },
  {
    Tumor: "PDAC",
    Sex: "Female",
    Age: "63-70 (50%-75%)",
    Participant_Country: "Poland",
    Grade: "G3",
    Stage: "III",
    BMI: "18.5-25",
    Alcohol_consumption: "non-drinker",
    Tobacco_use: "non-smoker",
    Secondhand_smoke_exposure: "No",
    Overall_survival_in_days: "<348 (25%)",
    Recurrence_status: "No",
    Survival_status: "Alive",
    caseId: "C3N-04283",
    Immune_subtype: "Fibroblast/TGFBeta",
  },
  {
    Tumor: "HNSCC",
    Sex: "Male",
    Age: "55-63 (25%-50%)",
    Participant_Country: "Poland",
    Grade: "G2",
    Stage: "I",
    BMI: "<18.5",
    Alcohol_consumption: "drinker",
    Tobacco_use: "current smoker",
    Secondhand_smoke_exposure: "Yes",
    Overall_survival_in_days: "609-1016 (50%-75%)",
    Recurrence_status: "No",
    Survival_status: "Alive",
    caseId: "C3N-03009",
    Immune_subtype: "CD8-/IFNG-",
  },
  {
    Tumor: "UCEC",
    Sex: "Female",
    Age: "55-63 (25%-50%)",
    Participant_Country: "United States",
    Grade: "G2",
    Stage: "I",
    BMI: ">=30",
    Alcohol_consumption: "non-drinker",
    Tobacco_use: "current smoker",
    Secondhand_smoke_exposure: "not_reported",
    Overall_survival_in_days: ">=1016 (>=75%)",
    Recurrence_status: "No",
    Survival_status: "Dead",
    caseId: "C3L-00769",
    Immune_subtype: "Fibroblast/TGFBeta",
  },
  {
    Tumor: "BRCA",
    Sex: "Female",
    Age: "<55 (25%)",
    Participant_Country: "not_reported",
    Grade: "not_reported",
    Stage: "not_reported",
    BMI: "not_reported",
    Alcohol_consumption: "not_reported",
    Tobacco_use: "not_reported",
    Secondhand_smoke_exposure: "not_reported",
    Overall_survival_in_days: "not_reported",
    Recurrence_status: "No",
    Survival_status: "not_reported",
    caseId: "01BR026",
    Immune_subtype: "CD8+/IFNG+",
  },
  {
    Tumor: "ccRCC",
    Sex: "Male",
    Age: "63-70 (50%-75%)",
    Participant_Country: "Poland",
    Grade: "G2",
    Stage: "I",
    BMI: "<18.5",
    Alcohol_consumption: "drinker",
    Tobacco_use: "past-smoker",
    Secondhand_smoke_exposure: "not_reported",
    Overall_survival_in_days: ">=1016 (>=75%)",
    Recurrence_status: "No",
    Survival_status: "Dead",
    caseId: "C3N-00495",
    Immune_subtype: "ccRCC/Endothelial",
  },
  {
    Tumor: "HGSC",
    Sex: "Female",
    Age: "<55 (25%)",
    Participant_Country: "not_reported",
    Grade: "not_reported",
    Stage: "not_reported",
    BMI: "not_reported",
    Alcohol_consumption: "not_reported",
    Tobacco_use: "not_reported",
    Secondhand_smoke_exposure: "not_reported",
    Overall_survival_in_days: "<348 (25%)",
    Recurrence_status: "Yes",
    Survival_status: "Dead",
    caseId: "17OV029",
    Immune_subtype: "CD8-/IFNG-",
  },
  {
    Tumor: "LUAD",
    Sex: "Male",
    Age: "63-70 (50%-75%)",
    Participant_Country: "United States",
    Grade: "G2",
    Stage: "II",
    BMI: "25-30",
    Alcohol_consumption: "drinker",
    Tobacco_use: "past-smoker",
    Secondhand_smoke_exposure: "not_reported",
    Overall_survival_in_days: "609-1016 (50%-75%)",
    Recurrence_status: "No",
    Survival_status: "Dead",
    caseId: "C3L-02508",
    Immune_subtype: "Eosinophils/Endothelial",
  },
  {
    Tumor: "HNSCC",
    Sex: "Male",
    Age: "63-70 (50%-75%)",
    Participant_Country: "China",
    Grade: "G1",
    Stage: "IV",
    BMI: "18.5-25",
    Alcohol_consumption: "drinker",
    Tobacco_use: "past-smoker",
    Secondhand_smoke_exposure: "not_reported",
    Overall_survival_in_days: "348-609 (25%-50%)",
    Recurrence_status: "No",
    Survival_status: "Alive",
    caseId: "C3N-03876",
    Immune_subtype: "CD8-/IFNG-",
  },
  {
    Tumor: "LUAD",
    Sex: "Female",
    Age: "<55 (25%)",
    Participant_Country: "Vietnam",
    Grade: "G2",
    Stage: "III",
    BMI: "18.5-25",
    Alcohol_consumption: "drinker",
    Tobacco_use: "non-smoker",
    Secondhand_smoke_exposure: "No",
    Overall_survival_in_days: "348-609 (25%-50%)",
    Recurrence_status: "No",
    Survival_status: "Dead",
    caseId: "C3N-02158",
    Immune_subtype: "CD8-/IFNG+",
  },
  {
    Tumor: "LSCC",
    Sex: "Male",
    Age: ">=70 (>=75%)",
    Participant_Country: "Poland",
    Grade: "G3",
    Stage: "I",
    BMI: "25-30",
    Alcohol_consumption: "drinker",
    Tobacco_use: "past-smoker",
    Secondhand_smoke_exposure: "not_reported",
    Overall_survival_in_days: ">=1016 (>=75%)",
    Recurrence_status: "No",
    Survival_status: "Dead",
    caseId: "C3N-03424",
    Immune_subtype: "CD8+/IFNG+",
  },
  {
    Tumor: "PDAC",
    Sex: "Female",
    Age: "63-70 (50%-75%)",
    Participant_Country: "Poland",
    Grade: "G3",
    Stage: "II",
    BMI: "25-30",
    Alcohol_consumption: "non-drinker",
    Tobacco_use: "non-smoker",
    Secondhand_smoke_exposure: "No",
    Overall_survival_in_days: "609-1016 (50%-75%)",
    Recurrence_status: "Yes",
    Survival_status: "Alive",
    caseId: "C3N-00436",
    Immune_subtype: "Eosinophils/Endothelial",
  },
  {
    Tumor: "BRCA",
    Sex: "Female",
    Age: "<55 (25%)",
    Participant_Country: "not_reported",
    Grade: "not_reported",
    Stage: "I",
    BMI: "not_reported",
    Alcohol_consumption: "not_reported",
    Tobacco_use: "not_reported",
    Secondhand_smoke_exposure: "not_reported",
    Overall_survival_in_days: "348-609 (25%-50%)",
    Recurrence_status: "No",
    Survival_status: "Dead",
    caseId: "05BR005",
    Immune_subtype: "Fibroblast/TGFBeta",
  },
  {
    Tumor: "BRCA",
    Sex: "Female",
    Age: "<55 (25%)",
    Participant_Country: "not_reported",
    Grade: "not_reported",
    Stage: "not_reported",
    BMI: "not_reported",
    Alcohol_consumption: "not_reported",
    Tobacco_use: "not_reported",
    Secondhand_smoke_exposure: "not_reported",
    Overall_survival_in_days: "not_reported",
    Recurrence_status: "No",
    Survival_status: "not_reported",
    caseId: "03BR006",
    Immune_subtype: "CD8-/IFNG-",
  },
  {
    Tumor: "UCEC",
    Sex: "Female",
    Age: "63-70 (50%-75%)",
    Participant_Country: "United States",
    Grade: "G2",
    Stage: "I",
    BMI: ">=30",
    Alcohol_consumption: "non-drinker",
    Tobacco_use: "non-smoker",
    Secondhand_smoke_exposure: "not_reported",
    Overall_survival_in_days: ">=1016 (>=75%)",
    Recurrence_status: "No",
    Survival_status: "Dead",
    caseId: "C3L-00780",
    Immune_subtype: "Fibroblast/TGFBeta",
  },
  {
    Tumor: "COAD",
    Sex: "Female",
    Age: "<55 (25%)",
    Participant_Country: "not_reported",
    Grade: "not_reported",
    Stage: "II",
    BMI: "not_reported",
    Alcohol_consumption: "not_reported",
    Tobacco_use: "not_reported",
    Secondhand_smoke_exposure: "not_reported",
    Overall_survival_in_days: "<348 (25%)",
    Recurrence_status: "No",
    Survival_status: "Dead",
    caseId: "15CO002",
    Immune_subtype: "CD8-/IFNG-",
  },
  {
    Tumor: "ccRCC",
    Sex: "Male",
    Age: ">=70 (>=75%)",
    Participant_Country: "United States",
    Grade: "G2",
    Stage: "III",
    BMI: "25-30",
    Alcohol_consumption: "non-drinker",
    Tobacco_use: "non-smoker",
    Secondhand_smoke_exposure: "not_reported",
    Overall_survival_in_days: "<348 (25%)",
    Recurrence_status: "No",
    Survival_status: "Alive",
    caseId: "C3L-00369",
    Immune_subtype: "ccRCC/Endothelial",
  },
  {
    Tumor: "COAD",
    Sex: "Female",
    Age: ">=70 (>=75%)",
    Participant_Country: "not_reported",
    Grade: "not_reported",
    Stage: "II",
    BMI: "not_reported",
    Alcohol_consumption: "not_reported",
    Tobacco_use: "not_reported",
    Secondhand_smoke_exposure: "not_reported",
    Overall_survival_in_days: "348-609 (25%-50%)",
    Recurrence_status: "No",
    Survival_status: "Dead",
    caseId: "05CO041",
    Immune_subtype: "CD8-/IFNG+",
  },
  {
    Tumor: "HNSCC",
    Sex: "Male",
    Age: "<55 (25%)",
    Participant_Country: "China",
    Grade: "G1",
    Stage: "III",
    BMI: "18.5-25",
    Alcohol_consumption: "non-drinker",
    Tobacco_use: "current smoker",
    Secondhand_smoke_exposure: "not_reported",
    Overall_survival_in_days: "609-1016 (50%-75%)",
    Recurrence_status: "No",
    Survival_status: "Dead",
    caseId: "C3N-03878",
    Immune_subtype: "Fibroblast/TGFBeta",
  },
  {
    Tumor: "LUAD",
    Sex: "Male",
    Age: ">=70 (>=75%)",
    Participant_Country: "Vietnam",
    Grade: "G2",
    Stage: "III",
    BMI: "<18.5",
    Alcohol_consumption: "non-drinker",
    Tobacco_use: "non-smoker",
    Secondhand_smoke_exposure: "No",
    Overall_survival_in_days: "348-609 (25%-50%)",
    Recurrence_status: "No",
    Survival_status: "Dead",
    caseId: "C3N-01415",
    Immune_subtype: "CD8-/IFNG+",
  },
  {
    Tumor: "PDAC",
    Sex: "Male",
    Age: "63-70 (50%-75%)",
    Participant_Country: "Poland",
    Grade: "G3",
    Stage: "II",
    BMI: "18.5-25",
    Alcohol_consumption: "drinker",
    Tobacco_use: "past-smoker",
    Secondhand_smoke_exposure: "No",
    Overall_survival_in_days: ">=1016 (>=75%)",
    Recurrence_status: "Yes",
    Survival_status: "Alive",
    caseId: "C3N-00516",
    Immune_subtype: "CD8-/IFNG+",
  },
  {
    Tumor: "PDAC",
    Sex: "Female",
    Age: "<55 (25%)",
    Participant_Country: "Poland",
    Grade: "G2",
    Stage: "III",
    BMI: "<18.5",
    Alcohol_consumption: "drinker",
    Tobacco_use: "non-smoker",
    Secondhand_smoke_exposure: "No",
    Overall_survival_in_days: "609-1016 (50%-75%)",
    Recurrence_status: "Yes",
    Survival_status: "Alive",
    caseId: "C3N-01388",
    Immune_subtype: "Fibroblast/TGFBeta",
  },
  {
    Tumor: "LSCC",
    Sex: "Male",
    Age: "<55 (25%)",
    Participant_Country: "China",
    Grade: "G3",
    Stage: "II",
    BMI: "18.5-25",
    Alcohol_consumption: "non-drinker",
    Tobacco_use: "current smoker",
    Secondhand_smoke_exposure: "not_reported",
    Overall_survival_in_days: "609-1016 (50%-75%)",
    Recurrence_status: "No",
    Survival_status: "Dead",
    caseId: "C3N-03851",
    Immune_subtype: "CD8-/IFNG-",
  },
  {
    Tumor: "HNSCC",
    Sex: "Male",
    Age: "<55 (25%)",
    Participant_Country: "Poland",
    Grade: "G1",
    Stage: "III",
    BMI: "18.5-25",
    Alcohol_consumption: "drinker",
    Tobacco_use: "current smoker",
    Secondhand_smoke_exposure: "No",
    Overall_survival_in_days: "609-1016 (50%-75%)",
    Recurrence_status: "No",
    Survival_status: "Alive",
    caseId: "C3N-02695",
    Immune_subtype: "CD8-/IFNG-",
  },
  {
    Tumor: "UCEC",
    Sex: "Female",
    Age: "55-63 (25%-50%)",
    Participant_Country: "Ukraine",
    Grade: "G2",
    Stage: "III",
    BMI: ">=30",
    Alcohol_consumption: "non-drinker",
    Tobacco_use: "non-smoker",
    Secondhand_smoke_exposure: "not_reported",
    Overall_survival_in_days: "609-1016 (50%-75%)",
    Recurrence_status: "No",
    Survival_status: "Dead",
    caseId: "C3N-01267",
    Immune_subtype: "ccRCC/Endothelial",
  },
  {
    Tumor: "GBM",
    Sex: "Female",
    Age: "<55 (25%)",
    Participant_Country: "China",
    Grade: "not_reported",
    Stage: "not_reported",
    BMI: "18.5-25",
    Alcohol_consumption: "non-drinker",
    Tobacco_use: "non-smoker",
    Secondhand_smoke_exposure: "No",
    Overall_survival_in_days: "609-1016 (50%-75%)",
    Recurrence_status: "Yes",
    Survival_status: "Dead",
    caseId: "C3N-02782",
    Immune_subtype: "CD8-/IFNG-",
  },
  {
    Tumor: "COAD",
    Sex: "Female",
    Age: ">=70 (>=75%)",
    Participant_Country: "not_reported",
    Grade: "not_reported",
    Stage: "II",
    BMI: "not_reported",
    Alcohol_consumption: "not_reported",
    Tobacco_use: "not_reported",
    Secondhand_smoke_exposure: "not_reported",
    Overall_survival_in_days: "348-609 (25%-50%)",
    Recurrence_status: "No",
    Survival_status: "Dead",
    caseId: "14CO005",
    Immune_subtype: "Fibroblast/TGFBeta",
  },
  {
    Tumor: "PDAC",
    Sex: "Male",
    Age: "63-70 (50%-75%)",
    Participant_Country: "China",
    Grade: "G2",
    Stage: "III",
    BMI: "25-30",
    Alcohol_consumption: "non-drinker",
    Tobacco_use: "non-smoker",
    Secondhand_smoke_exposure: "not_reported",
    Overall_survival_in_days: ">=1016 (>=75%)",
    Recurrence_status: "No",
    Survival_status: "Dead",
    caseId: "C3N-02940",
    Immune_subtype: "CD8-/IFNG+",
  },
  {
    Tumor: "LUAD",
    Sex: "Male",
    Age: ">=70 (>=75%)",
    Participant_Country: "Bulgaria",
    Grade: "G3",
    Stage: "II",
    BMI: "18.5-25",
    Alcohol_consumption: "drinker",
    Tobacco_use: "current smoker",
    Secondhand_smoke_exposure: "Yes",
    Overall_survival_in_days: "not_reported",
    Recurrence_status: "No",
    Survival_status: "not_reported",
    caseId: "C3N-00203",
    Immune_subtype: "Eosinophils/Endothelial",
  },
  {
    Tumor: "LUAD",
    Sex: "Female",
    Age: "55-63 (25%-50%)",
    Participant_Country: "Bulgaria",
    Grade: "G2",
    Stage: "I",
    BMI: "25-30",
    Alcohol_consumption: "drinker",
    Tobacco_use: "current smoker",
    Secondhand_smoke_exposure: "not_reported",
    Overall_survival_in_days: "609-1016 (50%-75%)",
    Recurrence_status: "No",
    Survival_status: "Dead",
    caseId: "C3N-00217",
    Immune_subtype: "CD8-/IFNG-",
  },
  {
    Tumor: "COAD",
    Sex: "Female",
    Age: "<55 (25%)",
    Participant_Country: "not_reported",
    Grade: "not_reported",
    Stage: "III",
    BMI: "not_reported",
    Alcohol_consumption: "not_reported",
    Tobacco_use: "not_reported",
    Secondhand_smoke_exposure: "not_reported",
    Overall_survival_in_days: "348-609 (25%-50%)",
    Recurrence_status: "No",
    Survival_status: "Dead",
    caseId: "27CO004",
    Immune_subtype: "Fibroblast/TGFBeta",
  },
  {
    Tumor: "LUAD",
    Sex: "Male",
    Age: "55-63 (25%-50%)",
    Participant_Country: "Vietnam",
    Grade: "G3",
    Stage: "I",
    BMI: "18.5-25",
    Alcohol_consumption: "drinker",
    Tobacco_use: "current smoker",
    Secondhand_smoke_exposure: "No",
    Overall_survival_in_days: "609-1016 (50%-75%)",
    Recurrence_status: "No",
    Survival_status: "Dead",
    caseId: "C3N-00549",
    Immune_subtype: "CD8-/IFNG-",
  },
  {
    Tumor: "ccRCC",
    Sex: "Male",
    Age: "<55 (25%)",
    Participant_Country: "United States",
    Grade: "G2",
    Stage: "I",
    BMI: "25-30",
    Alcohol_consumption: "non-drinker",
    Tobacco_use: "non-smoker",
    Secondhand_smoke_exposure: "not_reported",
    Overall_survival_in_days: "609-1016 (50%-75%)",
    Recurrence_status: "No",
    Survival_status: "Dead",
    caseId: "C3N-01808",
    Immune_subtype: "ccRCC/Endothelial",
  },
  {
    Tumor: "ccRCC",
    Sex: "Male",
    Age: ">=70 (>=75%)",
    Participant_Country: "Poland",
    Grade: "G2",
    Stage: "I",
    BMI: ">=30",
    Alcohol_consumption: "drinker",
    Tobacco_use: "past-smoker",
    Secondhand_smoke_exposure: "No",
    Overall_survival_in_days: "<348 (25%)",
    Recurrence_status: "Yes",
    Survival_status: "Alive",
    caseId: "C3N-00314",
    Immune_subtype: "ccRCC/Endothelial",
  },
  {
    Tumor: "UCEC",
    Sex: "Female",
    Age: ">=70 (>=75%)",
    Participant_Country: "United States",
    Grade: "G2",
    Stage: "I",
    BMI: ">=30",
    Alcohol_consumption: "non-drinker",
    Tobacco_use: "non-smoker",
    Secondhand_smoke_exposure: "not_reported",
    Overall_survival_in_days: ">=1016 (>=75%)",
    Recurrence_status: "No",
    Survival_status: "Dead",
    caseId: "C3L-00947",
    Immune_subtype: "CD8-/IFNG+",
  },
  {
    Tumor: "LSCC",
    Sex: "Male",
    Age: "55-63 (25%-50%)",
    Participant_Country: "China",
    Grade: "G3",
    Stage: "II",
    BMI: "18.5-25",
    Alcohol_consumption: "not_reported",
    Tobacco_use: "past-smoker",
    Secondhand_smoke_exposure: "not_reported",
    Overall_survival_in_days: ">=1016 (>=75%)",
    Recurrence_status: "No",
    Survival_status: "Dead",
    caseId: "C3N-02375",
    Immune_subtype: "CD8-/IFNG-",
  },
  {
    Tumor: "PDAC",
    Sex: "Female",
    Age: "55-63 (25%-50%)",
    Participant_Country: "Poland",
    Grade: "G2",
    Stage: "II",
    BMI: "<18.5",
    Alcohol_consumption: "non-drinker",
    Tobacco_use: "non-smoker",
    Secondhand_smoke_exposure: "No",
    Overall_survival_in_days: "348-609 (25%-50%)",
    Recurrence_status: "Yes",
    Survival_status: "Alive",
    caseId: "C3N-03439",
    Immune_subtype: "Eosinophils/Endothelial",
  },
  {
    Tumor: "ccRCC",
    Sex: "Female",
    Age: "55-63 (25%-50%)",
    Participant_Country: "Ukraine",
    Grade: "G2",
    Stage: "I",
    BMI: "25-30",
    Alcohol_consumption: "non-drinker",
    Tobacco_use: "not_reported",
    Secondhand_smoke_exposure: "not_reported",
    Overall_survival_in_days: "not_reported",
    Recurrence_status: "No",
    Survival_status: "Dead",
    caseId: "C3N-00646",
    Immune_subtype: "ccRCC/Endothelial",
  },
  {
    Tumor: "GBM",
    Sex: "Female",
    Age: "55-63 (25%-50%)",
    Participant_Country: "United States",
    Grade: "not_reported",
    Stage: "not_reported",
    BMI: "18.5-25",
    Alcohol_consumption: "non-drinker",
    Tobacco_use: "current smoker",
    Secondhand_smoke_exposure: "Yes",
    Overall_survival_in_days: "<348 (25%)",
    Recurrence_status: "Yes",
    Survival_status: "Alive",
    caseId: "C3L-03266",
    Immune_subtype: "Fibroblast/TGFBeta",
  },
  {
    Tumor: "BRCA",
    Sex: "Female",
    Age: ">=70 (>=75%)",
    Participant_Country: "not_reported",
    Grade: "not_reported",
    Stage: "II",
    BMI: "not_reported",
    Alcohol_consumption: "not_reported",
    Tobacco_use: "not_reported",
    Secondhand_smoke_exposure: "not_reported",
    Overall_survival_in_days: "<348 (25%)",
    Recurrence_status: "No",
    Survival_status: "Dead",
    caseId: "11BR012",
    Immune_subtype: "CD8-/IFNG-",
  },
  {
    Tumor: "ccRCC",
    Sex: "Male",
    Age: "<55 (25%)",
    Participant_Country: "United States",
    Grade: "G2",
    Stage: "I",
    BMI: ">=30",
    Alcohol_consumption: "not_reported",
    Tobacco_use: "not_reported",
    Secondhand_smoke_exposure: "not_reported",
    Overall_survival_in_days: "348-609 (25%-50%)",
    Recurrence_status: "No",
    Survival_status: "Dead",
    caseId: "C3L-00799",
    Immune_subtype: "ccRCC/Endothelial",
  },
  {
    Tumor: "GBM",
    Sex: "Male",
    Age: "55-63 (25%-50%)",
    Participant_Country: "Poland",
    Grade: "not_reported",
    Stage: "not_reported",
    BMI: "25-30",
    Alcohol_consumption: "non-drinker",
    Tobacco_use: "non-smoker",
    Secondhand_smoke_exposure: "not_reported",
    Overall_survival_in_days: "<348 (25%)",
    Recurrence_status: "No",
    Survival_status: "Alive",
    caseId: "C3N-01366",
    Immune_subtype: "CD8-/IFNG-",
  },
  {
    Tumor: "HGSC",
    Sex: "Female",
    Age: "55-63 (25%-50%)",
    Participant_Country: "not_reported",
    Grade: "G3",
    Stage: "III",
    BMI: "not_reported",
    Alcohol_consumption: "not_reported",
    Tobacco_use: "not_reported",
    Secondhand_smoke_exposure: "not_reported",
    Overall_survival_in_days: "<348 (25%)",
    Recurrence_status: "No",
    Survival_status: "Dead",
    caseId: "26OV002",
    Immune_subtype: "Fibroblast/TGFBeta",
  },
  {
    Tumor: "LSCC",
    Sex: "Male",
    Age: "55-63 (25%-50%)",
    Participant_Country: "United States",
    Grade: "G3",
    Stage: "II",
    BMI: "25-30",
    Alcohol_consumption: "drinker",
    Tobacco_use: "current smoker",
    Secondhand_smoke_exposure: "not_reported",
    Overall_survival_in_days: "609-1016 (50%-75%)",
    Recurrence_status: "Yes",
    Survival_status: "Alive",
    caseId: "C3L-02964",
    Immune_subtype: "CD8+/IFNG+",
  },
  {
    Tumor: "HNSCC",
    Sex: "Male",
    Age: "63-70 (50%-75%)",
    Participant_Country: "Bulgaria",
    Grade: "G3",
    Stage: "I",
    BMI: "25-30",
    Alcohol_consumption: "drinker",
    Tobacco_use: "current smoker",
    Secondhand_smoke_exposure: "Yes",
    Overall_survival_in_days: "<348 (25%)",
    Recurrence_status: "No",
    Survival_status: "Dead",
    caseId: "C3L-02621",
    Immune_subtype: "Fibroblast/TGFBeta",
  },
  {
    Tumor: "PDAC",
    Sex: "Male",
    Age: "<55 (25%)",
    Participant_Country: "China",
    Grade: "G1",
    Stage: "II",
    BMI: "18.5-25",
    Alcohol_consumption: "non-drinker",
    Tobacco_use: "non-smoker",
    Secondhand_smoke_exposure: "not_reported",
    Overall_survival_in_days: "348-609 (25%-50%)",
    Recurrence_status: "No",
    Survival_status: "Alive",
    caseId: "C3N-03190",
    Immune_subtype: "CD8-/IFNG-",
  },
  {
    Tumor: "LUAD",
    Sex: "Female",
    Age: "63-70 (50%-75%)",
    Participant_Country: "Vietnam",
    Grade: "G2",
    Stage: "I",
    BMI: "18.5-25",
    Alcohol_consumption: "non-drinker",
    Tobacco_use: "non-smoker",
    Secondhand_smoke_exposure: "No",
    Overall_survival_in_days: "348-609 (25%-50%)",
    Recurrence_status: "No",
    Survival_status: "Dead",
    caseId: "C3N-02145",
    Immune_subtype: "Eosinophils/Endothelial",
  },
  {
    Tumor: "LSCC",
    Sex: "Female",
    Age: "<55 (25%)",
    Participant_Country: "Bulgaria",
    Grade: "G2",
    Stage: "I",
    BMI: "25-30",
    Alcohol_consumption: "drinker",
    Tobacco_use: "current smoker",
    Secondhand_smoke_exposure: "Yes",
    Overall_survival_in_days: "<348 (25%)",
    Recurrence_status: "No",
    Survival_status: "Dead",
    caseId: "C3L-03961",
    Immune_subtype: "CD8+/IFNG+",
  },
  {
    Tumor: "BRCA",
    Sex: "Female",
    Age: ">=70 (>=75%)",
    Participant_Country: "not_reported",
    Grade: "not_reported",
    Stage: "III",
    BMI: "not_reported",
    Alcohol_consumption: "not_reported",
    Tobacco_use: "not_reported",
    Secondhand_smoke_exposure: "not_reported",
    Overall_survival_in_days: "348-609 (25%-50%)",
    Recurrence_status: "No",
    Survival_status: "Dead",
    caseId: "11BR075",
    Immune_subtype: "Fibroblast/TGFBeta",
  },
  {
    Tumor: "ccRCC",
    Sex: "Male",
    Age: "63-70 (50%-75%)",
    Participant_Country: "Poland",
    Grade: "G2",
    Stage: "II",
    BMI: "25-30",
    Alcohol_consumption: "drinker",
    Tobacco_use: "past-smoker",
    Secondhand_smoke_exposure: "not_reported",
    Overall_survival_in_days: ">=1016 (>=75%)",
    Recurrence_status: "No",
    Survival_status: "Dead",
    caseId: "C3N-01648",
    Immune_subtype: "CD8+/IFNG+",
  },
  {
    Tumor: "LSCC",
    Sex: "Male",
    Age: "<55 (25%)",
    Participant_Country: "Bulgaria",
    Grade: "G3",
    Stage: "III",
    BMI: ">=30",
    Alcohol_consumption: "non-drinker",
    Tobacco_use: "non-smoker",
    Secondhand_smoke_exposure: "not_reported",
    Overall_survival_in_days: "<348 (25%)",
    Recurrence_status: "No",
    Survival_status: "Alive",
    caseId: "C3N-00211",
    Immune_subtype: "Fibroblast/TGFBeta",
  },
  {
    Tumor: "HNSCC",
    Sex: "Male",
    Age: "55-63 (25%-50%)",
    Participant_Country: "Poland",
    Grade: "G2",
    Stage: "III",
    BMI: "<18.5",
    Alcohol_consumption: "not_reported",
    Tobacco_use: "past-smoker",
    Secondhand_smoke_exposure: "not_reported",
    Overall_survival_in_days: ">=1016 (>=75%)",
    Recurrence_status: "No",
    Survival_status: "Alive",
    caseId: "C3N-01945",
    Immune_subtype: "Fibroblast/TGFBeta",
  },
  {
    Tumor: "HNSCC",
    Sex: "Female",
    Age: "63-70 (50%-75%)",
    Participant_Country: "United States",
    Grade: "G2",
    Stage: "IV",
    BMI: "not_reported",
    Alcohol_consumption: "not_reported",
    Tobacco_use: "not_reported",
    Secondhand_smoke_exposure: "not_reported",
    Overall_survival_in_days: "not_reported",
    Recurrence_status: "No",
    Survival_status: "not_reported",
    caseId: "C3N-03042",
    Immune_subtype: "Fibroblast/TGFBeta",
  },
  {
    Tumor: "HNSCC",
    Sex: "Male",
    Age: "55-63 (25%-50%)",
    Participant_Country: "Ukraine",
    Grade: "G1",
    Stage: "III",
    BMI: "18.5-25",
    Alcohol_consumption: "drinker",
    Tobacco_use: "past-smoker",
    Secondhand_smoke_exposure: "not_reported",
    Overall_survival_in_days: "<348 (25%)",
    Recurrence_status: "No",
    Survival_status: "Alive",
    caseId: "C3L-00995",
    Immune_subtype: "Fibroblast/TGFBeta",
  },
  {
    Tumor: "BRCA",
    Sex: "Female",
    Age: "55-63 (25%-50%)",
    Participant_Country: "not_reported",
    Grade: "not_reported",
    Stage: "II",
    BMI: "not_reported",
    Alcohol_consumption: "not_reported",
    Tobacco_use: "not_reported",
    Secondhand_smoke_exposure: "not_reported",
    Overall_survival_in_days: "348-609 (25%-50%)",
    Recurrence_status: "No",
    Survival_status: "Dead",
    caseId: "11BR053",
    Immune_subtype: "CD8-/IFNG-",
  },
  {
    Tumor: "LUAD",
    Sex: "Female",
    Age: ">=70 (>=75%)",
    Participant_Country: "United States",
    Grade: "G1",
    Stage: "I",
    BMI: ">=30",
    Alcohol_consumption: "non-drinker",
    Tobacco_use: "past-smoker",
    Secondhand_smoke_exposure: "not_reported",
    Overall_survival_in_days: "<348 (25%)",
    Recurrence_status: "No",
    Survival_status: "Dead",
    caseId: "C3L-00510",
    Immune_subtype: "CD8-/IFNG+",
  },
  {
    Tumor: "BRCA",
    Sex: "Female",
    Age: "63-70 (50%-75%)",
    Participant_Country: "not_reported",
    Grade: "not_reported",
    Stage: "III",
    BMI: "not_reported",
    Alcohol_consumption: "not_reported",
    Tobacco_use: "not_reported",
    Secondhand_smoke_exposure: "not_reported",
    Overall_survival_in_days: "348-609 (25%-50%)",
    Recurrence_status: "No",
    Survival_status: "Dead",
    caseId: "18BR002",
    Immune_subtype: "CD8+/IFNG+",
  },
  {
    Tumor: "GBM",
    Sex: "Male",
    Age: ">=70 (>=75%)",
    Participant_Country: "Poland",
    Grade: "not_reported",
    Stage: "not_reported",
    BMI: "18.5-25",
    Alcohol_consumption: "non-drinker",
    Tobacco_use: "non-smoker",
    Secondhand_smoke_exposure: "No",
    Overall_survival_in_days: "not_reported",
    Recurrence_status: "No",
    Survival_status: "not_reported",
    caseId: "C3N-01367",
    Immune_subtype: "CD8+/IFNG+",
  },
  {
    Tumor: "BRCA",
    Sex: "Female",
    Age: "<55 (25%)",
    Participant_Country: "not_reported",
    Grade: "not_reported",
    Stage: "II",
    BMI: "not_reported",
    Alcohol_consumption: "not_reported",
    Tobacco_use: "not_reported",
    Secondhand_smoke_exposure: "not_reported",
    Overall_survival_in_days: "not_reported",
    Recurrence_status: "No",
    Survival_status: "not_reported",
    caseId: "05BR042",
    Immune_subtype: "CD8-/IFNG+",
  },
  {
    Tumor: "PDAC",
    Sex: "Female",
    Age: "55-63 (25%-50%)",
    Participant_Country: "Poland",
    Grade: "G2",
    Stage: "III",
    BMI: "18.5-25",
    Alcohol_consumption: "non-drinker",
    Tobacco_use: "non-smoker",
    Secondhand_smoke_exposure: "No",
    Overall_survival_in_days: "348-609 (25%-50%)",
    Recurrence_status: "Yes",
    Survival_status: "Alive",
    caseId: "C3N-01168",
    Immune_subtype: "CD8-/IFNG+",
  },
  {
    Tumor: "HGSC",
    Sex: "Female",
    Age: "<55 (25%)",
    Participant_Country: "not_reported",
    Grade: "G3",
    Stage: "III",
    BMI: "not_reported",
    Alcohol_consumption: "not_reported",
    Tobacco_use: "not_reported",
    Secondhand_smoke_exposure: "not_reported",
    Overall_survival_in_days: "<348 (25%)",
    Recurrence_status: "No",
    Survival_status: "Dead",
    caseId: "17OV039",
    Immune_subtype: "CD8-/IFNG-",
  },
  {
    Tumor: "GBM",
    Sex: "Male",
    Age: "55-63 (25%-50%)",
    Participant_Country: "Poland",
    Grade: "not_reported",
    Stage: "not_reported",
    BMI: "18.5-25",
    Alcohol_consumption: "drinker",
    Tobacco_use: "non-smoker",
    Secondhand_smoke_exposure: "No",
    Overall_survival_in_days: "<348 (25%)",
    Recurrence_status: "No",
    Survival_status: "Dead",
    caseId: "C3N-01852",
    Immune_subtype: "CD8-/IFNG-",
  },
  {
    Tumor: "BRCA",
    Sex: "Female",
    Age: ">=70 (>=75%)",
    Participant_Country: "not_reported",
    Grade: "not_reported",
    Stage: "II",
    BMI: "not_reported",
    Alcohol_consumption: "not_reported",
    Tobacco_use: "not_reported",
    Secondhand_smoke_exposure: "not_reported",
    Overall_survival_in_days: "348-609 (25%-50%)",
    Recurrence_status: "No",
    Survival_status: "Dead",
    caseId: "03BR010",
    Immune_subtype: "CD8-/IFNG-",
  },
  {
    Tumor: "GBM",
    Sex: "Female",
    Age: ">=70 (>=75%)",
    Participant_Country: "United States",
    Grade: "not_reported",
    Stage: "not_reported",
    BMI: "25-30",
    Alcohol_consumption: "drinker",
    Tobacco_use: "past-smoker",
    Secondhand_smoke_exposure: "not_reported",
    Overall_survival_in_days: "<348 (25%)",
    Recurrence_status: "No",
    Survival_status: "Alive",
    caseId: "C3N-01515",
    Immune_subtype: "Fibroblast/TGFBeta",
  },
  {
    Tumor: "BRCA",
    Sex: "Female",
    Age: ">=70 (>=75%)",
    Participant_Country: "not_reported",
    Grade: "not_reported",
    Stage: "III",
    BMI: "not_reported",
    Alcohol_consumption: "not_reported",
    Tobacco_use: "not_reported",
    Secondhand_smoke_exposure: "not_reported",
    Overall_survival_in_days: "not_reported",
    Recurrence_status: "No",
    Survival_status: "not_reported",
    caseId: "13BR009",
    Immune_subtype: "CD8-/IFNG-",
  },
  {
    Tumor: "UCEC",
    Sex: "Female",
    Age: "<55 (25%)",
    Participant_Country: "Ukraine",
    Grade: "G2",
    Stage: "I",
    BMI: ">=30",
    Alcohol_consumption: "non-drinker",
    Tobacco_use: "non-smoker",
    Secondhand_smoke_exposure: "not_reported",
    Overall_survival_in_days: "609-1016 (50%-75%)",
    Recurrence_status: "No",
    Survival_status: "Dead",
    caseId: "C3N-00379",
    Immune_subtype: "CD8-/IFNG-",
  },
  {
    Tumor: "ccRCC",
    Sex: "Male",
    Age: "<55 (25%)",
    Participant_Country: "Ukraine",
    Grade: "G2",
    Stage: "I",
    BMI: "25-30",
    Alcohol_consumption: "non-drinker",
    Tobacco_use: "not_reported",
    Secondhand_smoke_exposure: "not_reported",
    Overall_survival_in_days: "not_reported",
    Recurrence_status: "No",
    Survival_status: "Dead",
    caseId: "C3N-00148",
    Immune_subtype: "ccRCC/Endothelial",
  },
  {
    Tumor: "UCEC",
    Sex: "Female",
    Age: "55-63 (25%-50%)",
    Participant_Country: "United States",
    Grade: "G1",
    Stage: "I",
    BMI: ">=30",
    Alcohol_consumption: "non-drinker",
    Tobacco_use: "past-smoker",
    Secondhand_smoke_exposure: "not_reported",
    Overall_survival_in_days: ">=1016 (>=75%)",
    Recurrence_status: "No",
    Survival_status: "Dead",
    caseId: "C3L-00145",
    Immune_subtype: "Fibroblast/TGFBeta",
  },
  {
    Tumor: "COAD",
    Sex: "Female",
    Age: "<55 (25%)",
    Participant_Country: "not_reported",
    Grade: "not_reported",
    Stage: "III",
    BMI: "not_reported",
    Alcohol_consumption: "not_reported",
    Tobacco_use: "not_reported",
    Secondhand_smoke_exposure: "not_reported",
    Overall_survival_in_days: "348-609 (25%-50%)",
    Recurrence_status: "No",
    Survival_status: "Dead",
    caseId: "05CO034",
    Immune_subtype: "CD8-/IFNG-",
  },
  {
    Tumor: "BRCA",
    Sex: "Female",
    Age: "55-63 (25%-50%)",
    Participant_Country: "not_reported",
    Grade: "not_reported",
    Stage: "III",
    BMI: "not_reported",
    Alcohol_consumption: "not_reported",
    Tobacco_use: "not_reported",
    Secondhand_smoke_exposure: "not_reported",
    Overall_survival_in_days: "348-609 (25%-50%)",
    Recurrence_status: "No",
    Survival_status: "Dead",
    caseId: "03BR002",
    Immune_subtype: "Fibroblast/TGFBeta",
  },
  {
    Tumor: "LSCC",
    Sex: "Female",
    Age: "63-70 (50%-75%)",
    Participant_Country: "Poland",
    Grade: "G2",
    Stage: "I",
    BMI: "18.5-25",
    Alcohol_consumption: "not_reported",
    Tobacco_use: "past-smoker",
    Secondhand_smoke_exposure: "not_reported",
    Overall_survival_in_days: ">=1016 (>=75%)",
    Recurrence_status: "No",
    Survival_status: "Dead",
    caseId: "C3N-03425",
    Immune_subtype: "CD8-/IFNG+",
  },
  {
    Tumor: "COAD",
    Sex: "Female",
    Age: "63-70 (50%-75%)",
    Participant_Country: "not_reported",
    Grade: "not_reported",
    Stage: "III",
    BMI: "not_reported",
    Alcohol_consumption: "not_reported",
    Tobacco_use: "not_reported",
    Secondhand_smoke_exposure: "not_reported",
    Overall_survival_in_days: "348-609 (25%-50%)",
    Recurrence_status: "No",
    Survival_status: "Dead",
    caseId: "11CO005",
    Immune_subtype: "Fibroblast/TGFBeta",
  },
  {
    Tumor: "PDAC",
    Sex: "Female",
    Age: "<55 (25%)",
    Participant_Country: "China",
    Grade: "G2",
    Stage: "II",
    BMI: "18.5-25",
    Alcohol_consumption: "non-drinker",
    Tobacco_use: "non-smoker",
    Secondhand_smoke_exposure: "not_reported",
    Overall_survival_in_days: "609-1016 (50%-75%)",
    Recurrence_status: "Yes",
    Survival_status: "Dead",
    caseId: "C3N-03839",
    Immune_subtype: "CD8-/IFNG-",
  },
  {
    Tumor: "PDAC",
    Sex: "Female",
    Age: "63-70 (50%-75%)",
    Participant_Country: "China",
    Grade: "G2",
    Stage: "I",
    BMI: "25-30",
    Alcohol_consumption: "non-drinker",
    Tobacco_use: "non-smoker",
    Secondhand_smoke_exposure: "not_reported",
    Overall_survival_in_days: "609-1016 (50%-75%)",
    Recurrence_status: "Yes",
    Survival_status: "Alive",
    caseId: "C3L-02118",
    Immune_subtype: "CD8-/IFNG-",
  },
  {
    Tumor: "GBM",
    Sex: "Male",
    Age: "<55 (25%)",
    Participant_Country: "China",
    Grade: "not_reported",
    Stage: "not_reported",
    BMI: ">=30",
    Alcohol_consumption: "non-drinker",
    Tobacco_use: "current smoker",
    Secondhand_smoke_exposure: "No",
    Overall_survival_in_days: "348-609 (25%-50%)",
    Recurrence_status: "No",
    Survival_status: "Alive",
    caseId: "C3N-03180",
    Immune_subtype: "Brain/Neuro",
  },
  {
    Tumor: "HNSCC",
    Sex: "Female",
    Age: "55-63 (25%-50%)",
    Participant_Country: "Bulgaria",
    Grade: "G3",
    Stage: "III",
    BMI: "18.5-25",
    Alcohol_consumption: "non-drinker",
    Tobacco_use: "non-smoker",
    Secondhand_smoke_exposure: "not_reported",
    Overall_survival_in_days: "<348 (25%)",
    Recurrence_status: "No",
    Survival_status: "Alive",
    caseId: "C3N-00204",
    Immune_subtype: "CD8-/IFNG-",
  },
  {
    Tumor: "BRCA",
    Sex: "Female",
    Age: "<55 (25%)",
    Participant_Country: "not_reported",
    Grade: "not_reported",
    Stage: "not_reported",
    BMI: "not_reported",
    Alcohol_consumption: "not_reported",
    Tobacco_use: "not_reported",
    Secondhand_smoke_exposure: "not_reported",
    Overall_survival_in_days: "not_reported",
    Recurrence_status: "No",
    Survival_status: "not_reported",
    caseId: "01BR008",
    Immune_subtype: "not_reported",
  },
  {
    Tumor: "GBM",
    Sex: "Female",
    Age: "63-70 (50%-75%)",
    Participant_Country: "United States",
    Grade: "not_reported",
    Stage: "not_reported",
    BMI: "18.5-25",
    Alcohol_consumption: "not_reported",
    Tobacco_use: "non-smoker",
    Secondhand_smoke_exposure: "not_reported",
    Overall_survival_in_days: "348-609 (25%-50%)",
    Recurrence_status: "Yes",
    Survival_status: "Alive",
    caseId: "C3L-01834",
    Immune_subtype: "Brain/Neuro",
  },
  {
    Tumor: "ccRCC",
    Sex: "Male",
    Age: "63-70 (50%-75%)",
    Participant_Country: "Ukraine",
    Grade: "G1",
    Stage: "III",
    BMI: "18.5-25",
    Alcohol_consumption: "non-drinker",
    Tobacco_use: "non-smoker",
    Secondhand_smoke_exposure: "not_reported",
    Overall_survival_in_days: "609-1016 (50%-75%)",
    Recurrence_status: "No",
    Survival_status: "Dead",
    caseId: "C3N-01213",
    Immune_subtype: "ccRCC/Endothelial",
  },
  {
    Tumor: "BRCA",
    Sex: "Female",
    Age: ">=70 (>=75%)",
    Participant_Country: "not_reported",
    Grade: "not_reported",
    Stage: "not_reported",
    BMI: "not_reported",
    Alcohol_consumption: "not_reported",
    Tobacco_use: "not_reported",
    Secondhand_smoke_exposure: "not_reported",
    Overall_survival_in_days: "not_reported",
    Recurrence_status: "No",
    Survival_status: "not_reported",
    caseId: "21BR001",
    Immune_subtype: "CD8+/IFNG+",
  },
  {
    Tumor: "ccRCC",
    Sex: "Male",
    Age: "55-63 (25%-50%)",
    Participant_Country: "United States",
    Grade: "G2",
    Stage: "I",
    BMI: ">=30",
    Alcohol_consumption: "drinker",
    Tobacco_use: "past-smoker",
    Secondhand_smoke_exposure: "not_reported",
    Overall_survival_in_days: ">=1016 (>=75%)",
    Recurrence_status: "No",
    Survival_status: "Dead",
    caseId: "C3L-01603",
    Immune_subtype: "CD8+/IFNG+",
  },
  {
    Tumor: "COAD",
    Sex: "Male",
    Age: "55-63 (25%-50%)",
    Participant_Country: "not_reported",
    Grade: "not_reported",
    Stage: "IV",
    BMI: "not_reported",
    Alcohol_consumption: "not_reported",
    Tobacco_use: "not_reported",
    Secondhand_smoke_exposure: "not_reported",
    Overall_survival_in_days: "<348 (25%)",
    Recurrence_status: "No",
    Survival_status: "Dead",
    caseId: "20CO006",
    Immune_subtype: "not_reported",
  },
  {
    Tumor: "BRCA",
    Sex: "Female",
    Age: ">=70 (>=75%)",
    Participant_Country: "not_reported",
    Grade: "not_reported",
    Stage: "II",
    BMI: "not_reported",
    Alcohol_consumption: "not_reported",
    Tobacco_use: "not_reported",
    Secondhand_smoke_exposure: "not_reported",
    Overall_survival_in_days: "348-609 (25%-50%)",
    Recurrence_status: "No",
    Survival_status: "Dead",
    caseId: "05BR026",
    Immune_subtype: "Fibroblast/TGFBeta",
  },
  {
    Tumor: "LSCC",
    Sex: "Male",
    Age: "63-70 (50%-75%)",
    Participant_Country: "Poland",
    Grade: "G3",
    Stage: "I",
    BMI: ">=30",
    Alcohol_consumption: "not_reported",
    Tobacco_use: "current smoker",
    Secondhand_smoke_exposure: "not_reported",
    Overall_survival_in_days: ">=1016 (>=75%)",
    Recurrence_status: "No",
    Survival_status: "Dead",
    caseId: "C3N-01194",
    Immune_subtype: "CD8+/IFNG+",
  },
  {
    Tumor: "COAD",
    Sex: "Male",
    Age: ">=70 (>=75%)",
    Participant_Country: "not_reported",
    Grade: "not_reported",
    Stage: "I",
    BMI: "not_reported",
    Alcohol_consumption: "not_reported",
    Tobacco_use: "not_reported",
    Secondhand_smoke_exposure: "not_reported",
    Overall_survival_in_days: "348-609 (25%-50%)",
    Recurrence_status: "No",
    Survival_status: "Dead",
    caseId: "05CO054",
    Immune_subtype: "CD8-/IFNG+",
  },
  {
    Tumor: "HNSCC",
    Sex: "Male",
    Age: ">=70 (>=75%)",
    Participant_Country: "Poland",
    Grade: "G2",
    Stage: "II",
    BMI: "25-30",
    Alcohol_consumption: "drinker",
    Tobacco_use: "current smoker",
    Secondhand_smoke_exposure: "No",
    Overall_survival_in_days: "609-1016 (50%-75%)",
    Recurrence_status: "No",
    Survival_status: "Alive",
    caseId: "C3N-03433",
    Immune_subtype: "CD8-/IFNG-",
  },
  {
    Tumor: "COAD",
    Sex: "Male",
    Age: ">=70 (>=75%)",
    Participant_Country: "not_reported",
    Grade: "not_reported",
    Stage: "I",
    BMI: "not_reported",
    Alcohol_consumption: "not_reported",
    Tobacco_use: "not_reported",
    Secondhand_smoke_exposure: "not_reported",
    Overall_survival_in_days: "348-609 (25%-50%)",
    Recurrence_status: "No",
    Survival_status: "Dead",
    caseId: "01CO015",
    Immune_subtype: "CD8-/IFNG-",
  },
  {
    Tumor: "ccRCC",
    Sex: "Male",
    Age: "63-70 (50%-75%)",
    Participant_Country: "United States",
    Grade: "G2",
    Stage: "I",
    BMI: ">=30",
    Alcohol_consumption: "drinker",
    Tobacco_use: "current smoker",
    Secondhand_smoke_exposure: "not_reported",
    Overall_survival_in_days: "<348 (25%)",
    Recurrence_status: "No",
    Survival_status: "Dead",
    caseId: "C3L-00796",
    Immune_subtype: "Fibroblast/TGFBeta",
  },
  {
    Tumor: "UCEC",
    Sex: "Female",
    Age: ">=70 (>=75%)",
    Participant_Country: "United States",
    Grade: "G1",
    Stage: "I",
    BMI: ">=30",
    Alcohol_consumption: "non-drinker",
    Tobacco_use: "non-smoker",
    Secondhand_smoke_exposure: "not_reported",
    Overall_survival_in_days: ">=1016 (>=75%)",
    Recurrence_status: "No",
    Survival_status: "Dead",
    caseId: "C3L-01252",
    Immune_subtype: "Fibroblast/TGFBeta",
  },
  {
    Tumor: "LUAD",
    Sex: "Male",
    Age: "63-70 (50%-75%)",
    Participant_Country: "United States",
    Grade: "G2",
    Stage: "I",
    BMI: "25-30",
    Alcohol_consumption: "non-drinker",
    Tobacco_use: "past-smoker",
    Secondhand_smoke_exposure: "not_reported",
    Overall_survival_in_days: ">=1016 (>=75%)",
    Recurrence_status: "Yes",
    Survival_status: "Dead",
    caseId: "C3N-00959",
    Immune_subtype: "Eosinophils/Endothelial",
  },
  {
    Tumor: "HGSC",
    Sex: "Female",
    Age: "55-63 (25%-50%)",
    Participant_Country: "not_reported",
    Grade: "G3",
    Stage: "III",
    BMI: "not_reported",
    Alcohol_consumption: "not_reported",
    Tobacco_use: "not_reported",
    Secondhand_smoke_exposure: "not_reported",
    Overall_survival_in_days: "348-609 (25%-50%)",
    Recurrence_status: "No",
    Survival_status: "Dead",
    caseId: "04OV031",
    Immune_subtype: "CD8-/IFNG+",
  },
  {
    Tumor: "HGSC",
    Sex: "Female",
    Age: "<55 (25%)",
    Participant_Country: "not_reported",
    Grade: "G3",
    Stage: "III",
    BMI: "not_reported",
    Alcohol_consumption: "not_reported",
    Tobacco_use: "not_reported",
    Secondhand_smoke_exposure: "not_reported",
    Overall_survival_in_days: "348-609 (25%-50%)",
    Recurrence_status: "No",
    Survival_status: "Dead",
    caseId: "04OV001",
    Immune_subtype: "CD8-/IFNG-",
  },
  {
    Tumor: "HNSCC",
    Sex: "Male",
    Age: "55-63 (25%-50%)",
    Participant_Country: "Ukraine",
    Grade: "G1",
    Stage: "IV",
    BMI: "18.5-25",
    Alcohol_consumption: "drinker",
    Tobacco_use: "current smoker",
    Secondhand_smoke_exposure: "not_reported",
    Overall_survival_in_days: "348-609 (25%-50%)",
    Recurrence_status: "No",
    Survival_status: "Dead",
    caseId: "C3L-01138",
    Immune_subtype: "CD8-/IFNG-",
  },
  {
    Tumor: "HNSCC",
    Sex: "Male",
    Age: "55-63 (25%-50%)",
    Participant_Country: "Bulgaria",
    Grade: "G3",
    Stage: "II",
    BMI: "25-30",
    Alcohol_consumption: "drinker",
    Tobacco_use: "current smoker",
    Secondhand_smoke_exposure: "not_reported",
    Overall_survival_in_days: ">=1016 (>=75%)",
    Recurrence_status: "No",
    Survival_status: "Dead",
    caseId: "C3L-04354",
    Immune_subtype: "Fibroblast/TGFBeta",
  },
  {
    Tumor: "UCEC",
    Sex: "Female",
    Age: "63-70 (50%-75%)",
    Participant_Country: "Ukraine",
    Grade: "G2",
    Stage: "I",
    BMI: ">=30",
    Alcohol_consumption: "non-drinker",
    Tobacco_use: "non-smoker",
    Secondhand_smoke_exposure: "not_reported",
    Overall_survival_in_days: "609-1016 (50%-75%)",
    Recurrence_status: "No",
    Survival_status: "Dead",
    caseId: "C3N-00322",
    Immune_subtype: "CD8-/IFNG-",
  },
  {
    Tumor: "ccRCC",
    Sex: "Male",
    Age: "<55 (25%)",
    Participant_Country: "Vietnam",
    Grade: "G3",
    Stage: "I",
    BMI: "18.5-25",
    Alcohol_consumption: "drinker",
    Tobacco_use: "current smoker",
    Secondhand_smoke_exposure: "No",
    Overall_survival_in_days: "609-1016 (50%-75%)",
    Recurrence_status: "No",
    Survival_status: "Dead",
    caseId: "C3N-00168",
    Immune_subtype: "ccRCC/Endothelial",
  },
  {
    Tumor: "ccRCC",
    Sex: "Male",
    Age: "55-63 (25%-50%)",
    Participant_Country: "United States",
    Grade: "G2",
    Stage: "III",
    BMI: "25-30",
    Alcohol_consumption: "drinker",
    Tobacco_use: "non-smoker",
    Secondhand_smoke_exposure: "not_reported",
    Overall_survival_in_days: ">=1016 (>=75%)",
    Recurrence_status: "No",
    Survival_status: "Dead",
    caseId: "C3N-00733",
    Immune_subtype: "CD8-/IFNG+",
  },
  {
    Tumor: "HNSCC",
    Sex: "Male",
    Age: ">=70 (>=75%)",
    Participant_Country: "Poland",
    Grade: "G2",
    Stage: "IV",
    BMI: "18.5-25",
    Alcohol_consumption: "past-drinker",
    Tobacco_use: "past-smoker",
    Secondhand_smoke_exposure: "No",
    Overall_survival_in_days: "348-609 (25%-50%)",
    Recurrence_status: "No",
    Survival_status: "Alive",
    caseId: "C3N-04273",
    Immune_subtype: "CD8-/IFNG-",
  },
  {
    Tumor: "UCEC",
    Sex: "Female",
    Age: "63-70 (50%-75%)",
    Participant_Country: "Ukraine",
    Grade: "G2",
    Stage: "III",
    BMI: ">=30",
    Alcohol_consumption: "non-drinker",
    Tobacco_use: "non-smoker",
    Secondhand_smoke_exposure: "not_reported",
    Overall_survival_in_days: "609-1016 (50%-75%)",
    Recurrence_status: "No",
    Survival_status: "Dead",
    caseId: "C3N-00847",
    Immune_subtype: "CD8-/IFNG-",
  },
  {
    Tumor: "HNSCC",
    Sex: "Male",
    Age: "63-70 (50%-75%)",
    Participant_Country: "Poland",
    Grade: "G2",
    Stage: "IV",
    BMI: "18.5-25",
    Alcohol_consumption: "drinker",
    Tobacco_use: "current smoker",
    Secondhand_smoke_exposure: "Yes",
    Overall_survival_in_days: ">=1016 (>=75%)",
    Recurrence_status: "No",
    Survival_status: "Dead",
    caseId: "C3N-03456",
    Immune_subtype: "CD8-/IFNG-",
  },
  {
    Tumor: "PDAC",
    Sex: "Male",
    Age: "63-70 (50%-75%)",
    Participant_Country: "United States",
    Grade: "G2",
    Stage: "III",
    BMI: ">=30",
    Alcohol_consumption: "drinker",
    Tobacco_use: "past-smoker",
    Secondhand_smoke_exposure: "not_reported",
    Overall_survival_in_days: "<348 (25%)",
    Recurrence_status: "No",
    Survival_status: "Dead",
    caseId: "C3N-03173",
    Immune_subtype: "CD8-/IFNG-",
  },
  {
    Tumor: "LSCC",
    Sex: "Female",
    Age: ">=70 (>=75%)",
    Participant_Country: "United States",
    Grade: "G3",
    Stage: "I",
    BMI: ">=30",
    Alcohol_consumption: "not_reported",
    Tobacco_use: "past-smoker",
    Secondhand_smoke_exposure: "not_reported",
    Overall_survival_in_days: ">=1016 (>=75%)",
    Recurrence_status: "No",
    Survival_status: "Dead",
    caseId: "C3L-00923",
    Immune_subtype: "Eosinophils/Endothelial",
  },
  {
    Tumor: "COAD",
    Sex: "Female",
    Age: "55-63 (25%-50%)",
    Participant_Country: "not_reported",
    Grade: "not_reported",
    Stage: "I",
    BMI: "not_reported",
    Alcohol_consumption: "not_reported",
    Tobacco_use: "not_reported",
    Secondhand_smoke_exposure: "not_reported",
    Overall_survival_in_days: "<348 (25%)",
    Recurrence_status: "No",
    Survival_status: "Dead",
    caseId: "05CO028",
    Immune_subtype: "CD8-/IFNG-",
  },
  {
    Tumor: "ccRCC",
    Sex: "Female",
    Age: "55-63 (25%-50%)",
    Participant_Country: "United States",
    Grade: "G2",
    Stage: "III",
    BMI: ">=30",
    Alcohol_consumption: "drinker",
    Tobacco_use: "not_reported",
    Secondhand_smoke_exposure: "not_reported",
    Overall_survival_in_days: ">=1016 (>=75%)",
    Recurrence_status: "No",
    Survival_status: "Dead",
    caseId: "C3L-00418",
    Immune_subtype: "ccRCC/Endothelial",
  },
  {
    Tumor: "COAD",
    Sex: "Female",
    Age: "<55 (25%)",
    Participant_Country: "not_reported",
    Grade: "not_reported",
    Stage: "III",
    BMI: "not_reported",
    Alcohol_consumption: "not_reported",
    Tobacco_use: "not_reported",
    Secondhand_smoke_exposure: "not_reported",
    Overall_survival_in_days: "348-609 (25%-50%)",
    Recurrence_status: "No",
    Survival_status: "Dead",
    caseId: "20CO001",
    Immune_subtype: "Fibroblast/TGFBeta",
  },
  {
    Tumor: "BRCA",
    Sex: "Female",
    Age: "<55 (25%)",
    Participant_Country: "not_reported",
    Grade: "not_reported",
    Stage: "II",
    BMI: "not_reported",
    Alcohol_consumption: "not_reported",
    Tobacco_use: "not_reported",
    Secondhand_smoke_exposure: "not_reported",
    Overall_survival_in_days: "<348 (25%)",
    Recurrence_status: "No",
    Survival_status: "Dead",
    caseId: "09BR005",
    Immune_subtype: "CD8-/IFNG-",
  },
  {
    Tumor: "ccRCC",
    Sex: "Male",
    Age: "63-70 (50%-75%)",
    Participant_Country: "United States",
    Grade: "G4",
    Stage: "IV",
    BMI: ">=30",
    Alcohol_consumption: "drinker",
    Tobacco_use: "past-smoker",
    Secondhand_smoke_exposure: "not_reported",
    Overall_survival_in_days: ">=1016 (>=75%)",
    Recurrence_status: "Yes",
    Survival_status: "Dead",
    caseId: "C3L-01281",
    Immune_subtype: "ccRCC/Endothelial",
  },
  {
    Tumor: "ccRCC",
    Sex: "Female",
    Age: "63-70 (50%-75%)",
    Participant_Country: "United States",
    Grade: "G1",
    Stage: "I",
    BMI: "18.5-25",
    Alcohol_consumption: "drinker",
    Tobacco_use: "past-smoker",
    Secondhand_smoke_exposure: "not_reported",
    Overall_survival_in_days: ">=1016 (>=75%)",
    Recurrence_status: "No",
    Survival_status: "Dead",
    caseId: "C3L-00902",
    Immune_subtype: "ccRCC/Endothelial",
  },
  {
    Tumor: "HGSC",
    Sex: "Female",
    Age: "<55 (25%)",
    Participant_Country: "not_reported",
    Grade: "not_reported",
    Stage: "III",
    BMI: "not_reported",
    Alcohol_consumption: "not_reported",
    Tobacco_use: "not_reported",
    Secondhand_smoke_exposure: "not_reported",
    Overall_survival_in_days: "348-609 (25%-50%)",
    Recurrence_status: "No",
    Survival_status: "Dead",
    caseId: "17OV013",
    Immune_subtype: "Fibroblast/TGFBeta",
  },
  {
    Tumor: "UCEC",
    Sex: "Female",
    Age: "55-63 (25%-50%)",
    Participant_Country: "United States",
    Grade: "G2",
    Stage: "I",
    BMI: ">=30",
    Alcohol_consumption: "not_reported",
    Tobacco_use: "non-smoker",
    Secondhand_smoke_exposure: "not_reported",
    Overall_survival_in_days: "609-1016 (50%-75%)",
    Recurrence_status: "No",
    Survival_status: "Dead",
    caseId: "C3L-01744",
    Immune_subtype: "CD8-/IFNG-",
  },
  {
    Tumor: "LUAD",
    Sex: "Male",
    Age: ">=70 (>=75%)",
    Participant_Country: "United States",
    Grade: "G2",
    Stage: "I",
    BMI: "25-30",
    Alcohol_consumption: "non-drinker",
    Tobacco_use: "current smoker",
    Secondhand_smoke_exposure: "not_reported",
    Overall_survival_in_days: "348-609 (25%-50%)",
    Recurrence_status: "No",
    Survival_status: "Alive",
    caseId: "C3L-00009",
    Immune_subtype: "Fibroblast/TGFBeta",
  },
  {
    Tumor: "LSCC",
    Sex: "Female",
    Age: ">=70 (>=75%)",
    Participant_Country: "United States",
    Grade: "G2",
    Stage: "I",
    BMI: ">=30",
    Alcohol_consumption: "not_reported",
    Tobacco_use: "past-smoker",
    Secondhand_smoke_exposure: "not_reported",
    Overall_survival_in_days: ">=1016 (>=75%)",
    Recurrence_status: "Yes",
    Survival_status: "Dead",
    caseId: "C3L-02358",
    Immune_subtype: "CD8-/IFNG-",
  },
  {
    Tumor: "ccRCC",
    Sex: "Male",
    Age: "<55 (25%)",
    Participant_Country: "United States",
    Grade: "G2",
    Stage: "I",
    BMI: ">=30",
    Alcohol_consumption: "non-drinker",
    Tobacco_use: "non-smoker",
    Secondhand_smoke_exposure: "not_reported",
    Overall_survival_in_days: "609-1016 (50%-75%)",
    Recurrence_status: "Yes",
    Survival_status: "Dead",
    caseId: "C3N-00244",
    Immune_subtype: "CD8-/IFNG+",
  },
  {
    Tumor: "HGSC",
    Sex: "Female",
    Age: "<55 (25%)",
    Participant_Country: "not_reported",
    Grade: "G3",
    Stage: "III",
    BMI: "not_reported",
    Alcohol_consumption: "not_reported",
    Tobacco_use: "not_reported",
    Secondhand_smoke_exposure: "not_reported",
    Overall_survival_in_days: "348-609 (25%-50%)",
    Recurrence_status: "No",
    Survival_status: "Dead",
    caseId: "02OV023",
    Immune_subtype: "Fibroblast/TGFBeta",
  },
  {
    Tumor: "HNSCC",
    Sex: "Male",
    Age: "63-70 (50%-75%)",
    Participant_Country: "Poland",
    Grade: "G2",
    Stage: "IV",
    BMI: "18.5-25",
    Alcohol_consumption: "drinker",
    Tobacco_use: "past-smoker",
    Secondhand_smoke_exposure: "Yes",
    Overall_survival_in_days: ">=1016 (>=75%)",
    Recurrence_status: "No",
    Survival_status: "Alive",
    caseId: "C3N-01859",
    Immune_subtype: "CD8+/IFNG+",
  },
  {
    Tumor: "HNSCC",
    Sex: "Male",
    Age: "<55 (25%)",
    Participant_Country: "Poland",
    Grade: "G2",
    Stage: "III",
    BMI: "18.5-25",
    Alcohol_consumption: "not_reported",
    Tobacco_use: "not_reported",
    Secondhand_smoke_exposure: "not_reported",
    Overall_survival_in_days: "609-1016 (50%-75%)",
    Recurrence_status: "Yes",
    Survival_status: "Alive",
    caseId: "C3N-01338",
    Immune_subtype: "CD8-/IFNG-",
  },
  {
    Tumor: "ccRCC",
    Sex: "Male",
    Age: "<55 (25%)",
    Participant_Country: "United States",
    Grade: "G3",
    Stage: "I",
    BMI: ">=30",
    Alcohol_consumption: "non-drinker",
    Tobacco_use: "non-smoker",
    Secondhand_smoke_exposure: "not_reported",
    Overall_survival_in_days: "<348 (25%)",
    Recurrence_status: "No",
    Survival_status: "Dead",
    caseId: "C3L-00583",
    Immune_subtype: "Fibroblast/TGFBeta",
  },
  {
    Tumor: "ccRCC",
    Sex: "Male",
    Age: "55-63 (25%-50%)",
    Participant_Country: "United States",
    Grade: "G3",
    Stage: "III",
    BMI: ">=30",
    Alcohol_consumption: "non-drinker",
    Tobacco_use: "non-smoker",
    Secondhand_smoke_exposure: "not_reported",
    Overall_survival_in_days: ">=1016 (>=75%)",
    Recurrence_status: "Yes",
    Survival_status: "Alive",
    caseId: "C3L-01286",
    Immune_subtype: "CD8-/IFNG-",
  },
  {
    Tumor: "LUAD",
    Sex: "Female",
    Age: "55-63 (25%-50%)",
    Participant_Country: "United States",
    Grade: "G1",
    Stage: "I",
    BMI: "18.5-25",
    Alcohol_consumption: "drinker",
    Tobacco_use: "current smoker",
    Secondhand_smoke_exposure: "not_reported",
    Overall_survival_in_days: "609-1016 (50%-75%)",
    Recurrence_status: "No",
    Survival_status: "Dead",
    caseId: "C3L-01890",
    Immune_subtype: "not_reported",
  },
  {
    Tumor: "HNSCC",
    Sex: "Male",
    Age: ">=70 (>=75%)",
    Participant_Country: "Poland",
    Grade: "G2",
    Stage: "III",
    BMI: ">=30",
    Alcohol_consumption: "drinker",
    Tobacco_use: "past-smoker",
    Secondhand_smoke_exposure: "No",
    Overall_survival_in_days: "348-609 (25%-50%)",
    Recurrence_status: "Yes",
    Survival_status: "Dead",
    caseId: "C3N-04278",
    Immune_subtype: "CD8-/IFNG-",
  },
  {
    Tumor: "UCEC",
    Sex: "Female",
    Age: "63-70 (50%-75%)",
    Participant_Country: "Ukraine",
    Grade: "G2",
    Stage: "I",
    BMI: ">=30",
    Alcohol_consumption: "non-drinker",
    Tobacco_use: "non-smoker",
    Secondhand_smoke_exposure: "not_reported",
    Overall_survival_in_days: "609-1016 (50%-75%)",
    Recurrence_status: "No",
    Survival_status: "Dead",
    caseId: "C3N-00377",
    Immune_subtype: "CD8-/IFNG-",
  },
  {
    Tumor: "ccRCC",
    Sex: "Male",
    Age: "63-70 (50%-75%)",
    Participant_Country: "Poland",
    Grade: "G3",
    Stage: "I",
    BMI: "25-30",
    Alcohol_consumption: "drinker",
    Tobacco_use: "non-smoker",
    Secondhand_smoke_exposure: "not_reported",
    Overall_survival_in_days: ">=1016 (>=75%)",
    Recurrence_status: "No",
    Survival_status: "Dead",
    caseId: "C3N-00315",
    Immune_subtype: "CD8+/IFNG+",
  },
  {
    Tumor: "UCEC",
    Sex: "Female",
    Age: "<55 (25%)",
    Participant_Country: "United States",
    Grade: "G1",
    Stage: "III",
    BMI: "18.5-25",
    Alcohol_consumption: "drinker",
    Tobacco_use: "non-smoker",
    Secondhand_smoke_exposure: "not_reported",
    Overall_survival_in_days: ">=1016 (>=75%)",
    Recurrence_status: "No",
    Survival_status: "Dead",
    caseId: "C3L-00586",
    Immune_subtype: "CD8-/IFNG+",
  },
  {
    Tumor: "UCEC",
    Sex: "Female",
    Age: ">=70 (>=75%)",
    Participant_Country: "Ukraine",
    Grade: "G2",
    Stage: "I",
    BMI: "25-30",
    Alcohol_consumption: "non-drinker",
    Tobacco_use: "non-smoker",
    Secondhand_smoke_exposure: "not_reported",
    Overall_survival_in_days: ">=1016 (>=75%)",
    Recurrence_status: "No",
    Survival_status: "Dead",
    caseId: "C3N-00323",
    Immune_subtype: "CD8-/IFNG-",
  },
  {
    Tumor: "BRCA",
    Sex: "Female",
    Age: "<55 (25%)",
    Participant_Country: "not_reported",
    Grade: "not_reported",
    Stage: "III",
    BMI: "not_reported",
    Alcohol_consumption: "not_reported",
    Tobacco_use: "not_reported",
    Secondhand_smoke_exposure: "not_reported",
    Overall_survival_in_days: "<348 (25%)",
    Recurrence_status: "No",
    Survival_status: "Alive",
    caseId: "22BR006",
    Immune_subtype: "Fibroblast/TGFBeta",
  },
  {
    Tumor: "HNSCC",
    Sex: "Male",
    Age: "63-70 (50%-75%)",
    Participant_Country: "Ukraine",
    Grade: "G2",
    Stage: "III",
    BMI: "<18.5",
    Alcohol_consumption: "drinker",
    Tobacco_use: "current smoker",
    Secondhand_smoke_exposure: "not_reported",
    Overall_survival_in_days: "609-1016 (50%-75%)",
    Recurrence_status: "No",
    Survival_status: "Dead",
    caseId: "C3N-03488",
    Immune_subtype: "CD8-/IFNG-",
  },
  {
    Tumor: "HNSCC",
    Sex: "Male",
    Age: "55-63 (25%-50%)",
    Participant_Country: "Poland",
    Grade: "G3",
    Stage: "IV",
    BMI: ">=30",
    Alcohol_consumption: "drinker",
    Tobacco_use: "non-smoker",
    Secondhand_smoke_exposure: "No",
    Overall_survival_in_days: ">=1016 (>=75%)",
    Recurrence_status: "No",
    Survival_status: "Dead",
    caseId: "C3N-03015",
    Immune_subtype: "CD8-/IFNG+",
  },
  {
    Tumor: "UCEC",
    Sex: "Female",
    Age: "55-63 (25%-50%)",
    Participant_Country: "United States",
    Grade: "G2",
    Stage: "II",
    BMI: ">=30",
    Alcohol_consumption: "drinker",
    Tobacco_use: "past-smoker",
    Secondhand_smoke_exposure: "not_reported",
    Overall_survival_in_days: ">=1016 (>=75%)",
    Recurrence_status: "No",
    Survival_status: "Dead",
    caseId: "C3L-00413",
    Immune_subtype: "CD8-/IFNG-",
  },
  {
    Tumor: "LUAD",
    Sex: "Female",
    Age: "55-63 (25%-50%)",
    Participant_Country: "United States",
    Grade: "G2",
    Stage: "II",
    BMI: "25-30",
    Alcohol_consumption: "non-drinker",
    Tobacco_use: "current smoker",
    Secondhand_smoke_exposure: "not_reported",
    Overall_survival_in_days: ">=1016 (>=75%)",
    Recurrence_status: "No",
    Survival_status: "Dead",
    caseId: "C3L-02348",
    Immune_subtype: "CD8+/IFNG+",
  },
  {
    Tumor: "UCEC",
    Sex: "Female",
    Age: "55-63 (25%-50%)",
    Participant_Country: "Ukraine",
    Grade: "G3",
    Stage: "III",
    BMI: "25-30",
    Alcohol_consumption: "non-drinker",
    Tobacco_use: "non-smoker",
    Secondhand_smoke_exposure: "not_reported",
    Overall_survival_in_days: "609-1016 (50%-75%)",
    Recurrence_status: "Yes",
    Survival_status: "Dead",
    caseId: "C3N-01219",
    Immune_subtype: "CD8+/IFNG+",
  },
  {
    Tumor: "PDAC",
    Sex: "Female",
    Age: "<55 (25%)",
    Participant_Country: "China",
    Grade: "G2",
    Stage: "III",
    BMI: "18.5-25",
    Alcohol_consumption: "non-drinker",
    Tobacco_use: "non-smoker",
    Secondhand_smoke_exposure: "No",
    Overall_survival_in_days: "348-609 (25%-50%)",
    Recurrence_status: "No",
    Survival_status: "Dead",
    caseId: "C3N-02573",
    Immune_subtype: "CD8-/IFNG+",
  },
  {
    Tumor: "HNSCC",
    Sex: "Male",
    Age: "55-63 (25%-50%)",
    Participant_Country: "Poland",
    Grade: "G2",
    Stage: "III",
    BMI: "<18.5",
    Alcohol_consumption: "drinker",
    Tobacco_use: "past-smoker",
    Secondhand_smoke_exposure: "Yes",
    Overall_survival_in_days: "348-609 (25%-50%)",
    Recurrence_status: "No",
    Survival_status: "Dead",
    caseId: "C3N-02279",
    Immune_subtype: "CD8+/IFNG+",
  },
  {
    Tumor: "COAD",
    Sex: "Female",
    Age: "63-70 (50%-75%)",
    Participant_Country: "not_reported",
    Grade: "not_reported",
    Stage: "III",
    BMI: "not_reported",
    Alcohol_consumption: "not_reported",
    Tobacco_use: "not_reported",
    Secondhand_smoke_exposure: "not_reported",
    Overall_survival_in_days: "<348 (25%)",
    Recurrence_status: "No",
    Survival_status: "Dead",
    caseId: "11CO072",
    Immune_subtype: "CD8-/IFNG+",
  },
  {
    Tumor: "LSCC",
    Sex: "Male",
    Age: ">=70 (>=75%)",
    Participant_Country: "Poland",
    Grade: "G2",
    Stage: "II",
    BMI: "18.5-25",
    Alcohol_consumption: "drinker",
    Tobacco_use: "past-smoker",
    Secondhand_smoke_exposure: "not_reported",
    Overall_survival_in_days: ">=1016 (>=75%)",
    Recurrence_status: "No",
    Survival_status: "Dead",
    caseId: "C3N-02289",
    Immune_subtype: "Eosinophils/Endothelial",
  },
  {
    Tumor: "PDAC",
    Sex: "Male",
    Age: "63-70 (50%-75%)",
    Participant_Country: "United States",
    Grade: "G2",
    Stage: "II",
    BMI: "25-30",
    Alcohol_consumption: "drinker",
    Tobacco_use: "non-smoker",
    Secondhand_smoke_exposure: "No",
    Overall_survival_in_days: "<348 (25%)",
    Recurrence_status: "No",
    Survival_status: "Dead",
    caseId: "C3L-01453",
    Immune_subtype: "Eosinophils/Endothelial",
  },
  {
    Tumor: "GBM",
    Sex: "Female",
    Age: "55-63 (25%-50%)",
    Participant_Country: "Bulgaria",
    Grade: "not_reported",
    Stage: "not_reported",
    BMI: "18.5-25",
    Alcohol_consumption: "non-drinker",
    Tobacco_use: "non-smoker",
    Secondhand_smoke_exposure: "No",
    Overall_survival_in_days: "609-1016 (50%-75%)",
    Recurrence_status: "No",
    Survival_status: "Dead",
    caseId: "C3L-03968",
    Immune_subtype: "CD8+/IFNG+",
  },
  {
    Tumor: "LUAD",
    Sex: "Male",
    Age: "55-63 (25%-50%)",
    Participant_Country: "Vietnam",
    Grade: "G2",
    Stage: "I",
    BMI: "18.5-25",
    Alcohol_consumption: "drinker",
    Tobacco_use: "non-smoker",
    Secondhand_smoke_exposure: "No",
    Overall_survival_in_days: "348-609 (25%-50%)",
    Recurrence_status: "No",
    Survival_status: "Dead",
    caseId: "C3N-01414",
    Immune_subtype: "Fibroblast/TGFBeta",
  },
  {
    Tumor: "UCEC",
    Sex: "Female",
    Age: "<55 (25%)",
    Participant_Country: "United States",
    Grade: "G1",
    Stage: "I",
    BMI: ">=30",
    Alcohol_consumption: "non-drinker",
    Tobacco_use: "non-smoker",
    Secondhand_smoke_exposure: "not_reported",
    Overall_survival_in_days: ">=1016 (>=75%)",
    Recurrence_status: "No",
    Survival_status: "Dead",
    caseId: "C3L-00136",
    Immune_subtype: "ccRCC/Endothelial",
  },
  {
    Tumor: "GBM",
    Sex: "Male",
    Age: "<55 (25%)",
    Participant_Country: "United States",
    Grade: "not_reported",
    Stage: "not_reported",
    BMI: ">=30",
    Alcohol_consumption: "not_reported",
    Tobacco_use: "not_reported",
    Secondhand_smoke_exposure: "not_reported",
    Overall_survival_in_days: "348-609 (25%-50%)",
    Recurrence_status: "Yes",
    Survival_status: "Alive",
    caseId: "C3L-02465",
    Immune_subtype: "CD8+/IFNG+",
  },
  {
    Tumor: "LUAD",
    Sex: "Female",
    Age: "<55 (25%)",
    Participant_Country: "China",
    Grade: "G2",
    Stage: "II",
    BMI: "18.5-25",
    Alcohol_consumption: "non-drinker",
    Tobacco_use: "non-smoker",
    Secondhand_smoke_exposure: "not_reported",
    Overall_survival_in_days: ">=1016 (>=75%)",
    Recurrence_status: "No",
    Survival_status: "Dead",
    caseId: "C3N-02000",
    Immune_subtype: "Eosinophils/Endothelial",
  },
  {
    Tumor: "PDAC",
    Sex: "Male",
    Age: ">=70 (>=75%)",
    Participant_Country: "United States",
    Grade: "G3",
    Stage: "II",
    BMI: "25-30",
    Alcohol_consumption: "not_reported",
    Tobacco_use: "non-smoker",
    Secondhand_smoke_exposure: "not_reported",
    Overall_survival_in_days: "348-609 (25%-50%)",
    Recurrence_status: "Yes",
    Survival_status: "Alive",
    caseId: "C3L-00819",
    Immune_subtype: "CD8-/IFNG-",
  },
  {
    Tumor: "LSCC",
    Sex: "Male",
    Age: ">=70 (>=75%)",
    Participant_Country: "United States",
    Grade: "G2",
    Stage: "III",
    BMI: "25-30",
    Alcohol_consumption: "not_reported",
    Tobacco_use: "past-smoker",
    Secondhand_smoke_exposure: "Yes",
    Overall_survival_in_days: "348-609 (25%-50%)",
    Recurrence_status: "No",
    Survival_status: "Dead",
    caseId: "C3N-00221",
    Immune_subtype: "CD8-/IFNG-",
  },
  {
    Tumor: "HNSCC",
    Sex: "Female",
    Age: ">=70 (>=75%)",
    Participant_Country: "Ukraine",
    Grade: "G1",
    Stage: "II",
    BMI: "18.5-25",
    Alcohol_consumption: "non-drinker",
    Tobacco_use: "non-smoker",
    Secondhand_smoke_exposure: "not_reported",
    Overall_survival_in_days: "<348 (25%)",
    Recurrence_status: "No",
    Survival_status: "Dead",
    caseId: "C3N-02700",
    Immune_subtype: "CD8-/IFNG+",
  },
  {
    Tumor: "LUAD",
    Sex: "Male",
    Age: "<55 (25%)",
    Participant_Country: "Vietnam",
    Grade: "G3",
    Stage: "I",
    BMI: "<18.5",
    Alcohol_consumption: "drinker",
    Tobacco_use: "current smoker",
    Secondhand_smoke_exposure: "No",
    Overall_survival_in_days: "348-609 (25%-50%)",
    Recurrence_status: "No",
    Survival_status: "Dead",
    caseId: "C3N-01488",
    Immune_subtype: "CD8+/IFNG+",
  },
  {
    Tumor: "COAD",
    Sex: "Female",
    Age: "55-63 (25%-50%)",
    Participant_Country: "not_reported",
    Grade: "not_reported",
    Stage: "II",
    BMI: "not_reported",
    Alcohol_consumption: "not_reported",
    Tobacco_use: "not_reported",
    Secondhand_smoke_exposure: "not_reported",
    Overall_survival_in_days: "348-609 (25%-50%)",
    Recurrence_status: "No",
    Survival_status: "Dead",
    caseId: "16CO003",
    Immune_subtype: "Fibroblast/TGFBeta",
  },
  {
    Tumor: "PDAC",
    Sex: "Female",
    Age: "<55 (25%)",
    Participant_Country: "China",
    Grade: "G2",
    Stage: "I",
    BMI: "18.5-25",
    Alcohol_consumption: "non-drinker",
    Tobacco_use: "non-smoker",
    Secondhand_smoke_exposure: "not_reported",
    Overall_survival_in_days: "348-609 (25%-50%)",
    Recurrence_status: "Yes",
    Survival_status: "Alive",
    caseId: "C3N-02579",
    Immune_subtype: "CD8-/IFNG-",
  },
  {
    Tumor: "HNSCC",
    Sex: "Male",
    Age: "<55 (25%)",
    Participant_Country: "Poland",
    Grade: "G2",
    Stage: "II",
    BMI: "18.5-25",
    Alcohol_consumption: "not_reported",
    Tobacco_use: "not_reported",
    Secondhand_smoke_exposure: "not_reported",
    Overall_survival_in_days: "<348 (25%)",
    Recurrence_status: "No",
    Survival_status: "Alive",
    caseId: "C3N-00299",
    Immune_subtype: "CD8+/IFNG+",
  },
  {
    Tumor: "LSCC",
    Sex: "Male",
    Age: ">=70 (>=75%)",
    Participant_Country: "United States",
    Grade: "G2",
    Stage: "I",
    BMI: ">=30",
    Alcohol_consumption: "non-drinker",
    Tobacco_use: "past-smoker",
    Secondhand_smoke_exposure: "not_reported",
    Overall_survival_in_days: ">=1016 (>=75%)",
    Recurrence_status: "No",
    Survival_status: "Dead",
    caseId: "C3L-02969",
    Immune_subtype: "CD8+/IFNG+",
  },
  {
    Tumor: "LSCC",
    Sex: "Female",
    Age: "63-70 (50%-75%)",
    Participant_Country: "United States",
    Grade: "G3",
    Stage: "II",
    BMI: "25-30",
    Alcohol_consumption: "non-drinker",
    Tobacco_use: "past-smoker",
    Secondhand_smoke_exposure: "not_reported",
    Overall_survival_in_days: ">=1016 (>=75%)",
    Recurrence_status: "Yes",
    Survival_status: "Dead",
    caseId: "C3L-00415",
    Immune_subtype: "Eosinophils/Endothelial",
  },
  {
    Tumor: "COAD",
    Sex: "Female",
    Age: "<55 (25%)",
    Participant_Country: "not_reported",
    Grade: "not_reported",
    Stage: "II",
    BMI: "not_reported",
    Alcohol_consumption: "not_reported",
    Tobacco_use: "not_reported",
    Secondhand_smoke_exposure: "not_reported",
    Overall_survival_in_days: "348-609 (25%-50%)",
    Recurrence_status: "No",
    Survival_status: "Dead",
    caseId: "11CO061",
    Immune_subtype: "CD8+/IFNG+",
  },
  {
    Tumor: "UCEC",
    Sex: "Female",
    Age: "<55 (25%)",
    Participant_Country: "United States",
    Grade: "G3",
    Stage: "II",
    BMI: ">=30",
    Alcohol_consumption: "non-drinker",
    Tobacco_use: "non-smoker",
    Secondhand_smoke_exposure: "not_reported",
    Overall_survival_in_days: ">=1016 (>=75%)",
    Recurrence_status: "No",
    Survival_status: "Dead",
    caseId: "C3L-00781",
    Immune_subtype: "CD8-/IFNG+",
  },
  {
    Tumor: "BRCA",
    Sex: "Female",
    Age: ">=70 (>=75%)",
    Participant_Country: "not_reported",
    Grade: "not_reported",
    Stage: "III",
    BMI: "not_reported",
    Alcohol_consumption: "not_reported",
    Tobacco_use: "not_reported",
    Secondhand_smoke_exposure: "not_reported",
    Overall_survival_in_days: "348-609 (25%-50%)",
    Recurrence_status: "No",
    Survival_status: "Dead",
    caseId: "11BR011",
    Immune_subtype: "CD8+/IFNG+",
  },
  {
    Tumor: "UCEC",
    Sex: "Female",
    Age: "63-70 (50%-75%)",
    Participant_Country: "United States",
    Grade: "G1",
    Stage: "I",
    BMI: ">=30",
    Alcohol_consumption: "not_reported",
    Tobacco_use: "non-smoker",
    Secondhand_smoke_exposure: "not_reported",
    Overall_survival_in_days: ">=1016 (>=75%)",
    Recurrence_status: "No",
    Survival_status: "Dead",
    caseId: "C3L-00361",
    Immune_subtype: "CD8-/IFNG-",
  },
  {
    Tumor: "LSCC",
    Sex: "Female",
    Age: "63-70 (50%-75%)",
    Participant_Country: "United States",
    Grade: "G3",
    Stage: "not_reported",
    BMI: "not_reported",
    Alcohol_consumption: "not_reported",
    Tobacco_use: "not_reported",
    Secondhand_smoke_exposure: "not_reported",
    Overall_survival_in_days: "not_reported",
    Recurrence_status: "No",
    Survival_status: "not_reported",
    caseId: "C3N-02252",
    Immune_subtype: "CD8-/IFNG+",
  },
  {
    Tumor: "LSCC",
    Sex: "Male",
    Age: "63-70 (50%-75%)",
    Participant_Country: "Poland",
    Grade: "not_reported",
    Stage: "II",
    BMI: "25-30",
    Alcohol_consumption: "not_reported",
    Tobacco_use: "past-smoker",
    Secondhand_smoke_exposure: "not_reported",
    Overall_survival_in_days: "<348 (25%)",
    Recurrence_status: "No",
    Survival_status: "Dead",
    caseId: "C3N-02283",
    Immune_subtype: "CD8-/IFNG-",
  },
  {
    Tumor: "HGSC",
    Sex: "Female",
    Age: "<55 (25%)",
    Participant_Country: "not_reported",
    Grade: "G3",
    Stage: "III",
    BMI: "not_reported",
    Alcohol_consumption: "not_reported",
    Tobacco_use: "not_reported",
    Secondhand_smoke_exposure: "not_reported",
    Overall_survival_in_days: "348-609 (25%-50%)",
    Recurrence_status: "No",
    Survival_status: "Dead",
    caseId: "01OV039",
    Immune_subtype: "CD8-/IFNG+",
  },
  {
    Tumor: "HGSC",
    Sex: "Female",
    Age: "63-70 (50%-75%)",
    Participant_Country: "not_reported",
    Grade: "G3",
    Stage: "III",
    BMI: "not_reported",
    Alcohol_consumption: "not_reported",
    Tobacco_use: "not_reported",
    Secondhand_smoke_exposure: "not_reported",
    Overall_survival_in_days: "<348 (25%)",
    Recurrence_status: "No",
    Survival_status: "Dead",
    caseId: "01OV041",
    Immune_subtype: "Fibroblast/TGFBeta",
  },
  {
    Tumor: "COAD",
    Sex: "Female",
    Age: ">=70 (>=75%)",
    Participant_Country: "not_reported",
    Grade: "not_reported",
    Stage: "III",
    BMI: "not_reported",
    Alcohol_consumption: "not_reported",
    Tobacco_use: "not_reported",
    Secondhand_smoke_exposure: "not_reported",
    Overall_survival_in_days: "348-609 (25%-50%)",
    Recurrence_status: "Yes",
    Survival_status: "Dead",
    caseId: "01CO014",
    Immune_subtype: "CD8-/IFNG-",
  },
  {
    Tumor: "PDAC",
    Sex: "Male",
    Age: ">=70 (>=75%)",
    Participant_Country: "Canada",
    Grade: "G2",
    Stage: "III",
    BMI: "18.5-25",
    Alcohol_consumption: "drinker",
    Tobacco_use: "current smoker",
    Secondhand_smoke_exposure: "not_reported",
    Overall_survival_in_days: "609-1016 (50%-75%)",
    Recurrence_status: "Yes",
    Survival_status: "Alive",
    caseId: "C3L-04475",
    Immune_subtype: "Fibroblast/TGFBeta",
  },
  {
    Tumor: "ccRCC",
    Sex: "Male",
    Age: "55-63 (25%-50%)",
    Participant_Country: "United States",
    Grade: "G3",
    Stage: "III",
    BMI: "18.5-25",
    Alcohol_consumption: "non-drinker",
    Tobacco_use: "non-smoker",
    Secondhand_smoke_exposure: "not_reported",
    Overall_survival_in_days: "<348 (25%)",
    Recurrence_status: "No",
    Survival_status: "Dead",
    caseId: "C3L-00813",
    Immune_subtype: "ccRCC/Endothelial",
  },
  {
    Tumor: "BRCA",
    Sex: "Female",
    Age: ">=70 (>=75%)",
    Participant_Country: "not_reported",
    Grade: "not_reported",
    Stage: "not_reported",
    BMI: "not_reported",
    Alcohol_consumption: "not_reported",
    Tobacco_use: "not_reported",
    Secondhand_smoke_exposure: "not_reported",
    Overall_survival_in_days: "not_reported",
    Recurrence_status: "No",
    Survival_status: "not_reported",
    caseId: "21BR002",
    Immune_subtype: "CD8+/IFNG+",
  },
  {
    Tumor: "LUAD",
    Sex: "Male",
    Age: "55-63 (25%-50%)",
    Participant_Country: "Vietnam",
    Grade: "G3",
    Stage: "I",
    BMI: "25-30",
    Alcohol_consumption: "non-drinker",
    Tobacco_use: "non-smoker",
    Secondhand_smoke_exposure: "No",
    Overall_survival_in_days: "<348 (25%)",
    Recurrence_status: "No",
    Survival_status: "Alive",
    caseId: "C3N-01074",
    Immune_subtype: "CD8-/IFNG-",
  },
  {
    Tumor: "HNSCC",
    Sex: "Male",
    Age: "<55 (25%)",
    Participant_Country: "Poland",
    Grade: "G2",
    Stage: "II",
    BMI: "18.5-25",
    Alcohol_consumption: "not_reported",
    Tobacco_use: "current smoker",
    Secondhand_smoke_exposure: "not_reported",
    Overall_survival_in_days: ">=1016 (>=75%)",
    Recurrence_status: "No",
    Survival_status: "Dead",
    caseId: "C3N-00825",
    Immune_subtype: "CD8+/IFNG+",
  },
  {
    Tumor: "HNSCC",
    Sex: "Male",
    Age: "55-63 (25%-50%)",
    Participant_Country: "Poland",
    Grade: "G2",
    Stage: "IV",
    BMI: "25-30",
    Alcohol_consumption: "drinker",
    Tobacco_use: "past-smoker",
    Secondhand_smoke_exposure: "No",
    Overall_survival_in_days: "609-1016 (50%-75%)",
    Recurrence_status: "No",
    Survival_status: "Alive",
    caseId: "C3N-02694",
    Immune_subtype: "CD8+/IFNG+",
  },
  {
    Tumor: "LUAD",
    Sex: "Male",
    Age: "63-70 (50%-75%)",
    Participant_Country: "United States",
    Grade: "G2",
    Stage: "I",
    BMI: "25-30",
    Alcohol_consumption: "drinker",
    Tobacco_use: "past-smoker",
    Secondhand_smoke_exposure: "not_reported",
    Overall_survival_in_days: ">=1016 (>=75%)",
    Recurrence_status: "No",
    Survival_status: "Dead",
    caseId: "C3L-02350",
    Immune_subtype: "Eosinophils/Endothelial",
  },
  {
    Tumor: "HGSC",
    Sex: "Female",
    Age: "63-70 (50%-75%)",
    Participant_Country: "not_reported",
    Grade: "G3",
    Stage: "III",
    BMI: "not_reported",
    Alcohol_consumption: "not_reported",
    Tobacco_use: "not_reported",
    Secondhand_smoke_exposure: "not_reported",
    Overall_survival_in_days: "348-609 (25%-50%)",
    Recurrence_status: "No",
    Survival_status: "Dead",
    caseId: "20OV005",
    Immune_subtype: "CD8-/IFNG-",
  },
  {
    Tumor: "PDAC",
    Sex: "Male",
    Age: ">=70 (>=75%)",
    Participant_Country: "United States",
    Grade: "G2",
    Stage: "IV",
    BMI: "18.5-25",
    Alcohol_consumption: "drinker",
    Tobacco_use: "non-smoker",
    Secondhand_smoke_exposure: "not_reported",
    Overall_survival_in_days: "<348 (25%)",
    Recurrence_status: "No",
    Survival_status: "Alive",
    caseId: "C3L-02899",
    Immune_subtype: "Fibroblast/TGFBeta",
  },
  {
    Tumor: "HGSC",
    Sex: "Female",
    Age: "55-63 (25%-50%)",
    Participant_Country: "not_reported",
    Grade: "G3",
    Stage: "III",
    BMI: "not_reported",
    Alcohol_consumption: "not_reported",
    Tobacco_use: "not_reported",
    Secondhand_smoke_exposure: "not_reported",
    Overall_survival_in_days: "<348 (25%)",
    Recurrence_status: "No",
    Survival_status: "Dead",
    caseId: "04OV012",
    Immune_subtype: "Fibroblast/TGFBeta",
  },
  {
    Tumor: "LUAD",
    Sex: "Male",
    Age: "55-63 (25%-50%)",
    Participant_Country: "Ukraine",
    Grade: "G2",
    Stage: "III",
    BMI: "25-30",
    Alcohol_consumption: "drinker",
    Tobacco_use: "non-smoker",
    Secondhand_smoke_exposure: "not_reported",
    Overall_survival_in_days: "348-609 (25%-50%)",
    Recurrence_status: "Yes",
    Survival_status: "Alive",
    caseId: "C3N-01823",
    Immune_subtype: "CD8-/IFNG+",
  },
  {
    Tumor: "UCEC",
    Sex: "Female",
    Age: "<55 (25%)",
    Participant_Country: "United States",
    Grade: "G2",
    Stage: "I",
    BMI: ">=30",
    Alcohol_consumption: "drinker",
    Tobacco_use: "current smoker",
    Secondhand_smoke_exposure: "not_reported",
    Overall_survival_in_days: "348-609 (25%-50%)",
    Recurrence_status: "No",
    Survival_status: "Dead",
    caseId: "C3N-00743",
    Immune_subtype: "CD8-/IFNG-",
  },
  {
    Tumor: "LUAD",
    Sex: "Male",
    Age: "63-70 (50%-75%)",
    Participant_Country: "Vietnam",
    Grade: "G2",
    Stage: "I",
    BMI: "18.5-25",
    Alcohol_consumption: "drinker",
    Tobacco_use: "non-smoker",
    Secondhand_smoke_exposure: "No",
    Overall_survival_in_days: "348-609 (25%-50%)",
    Recurrence_status: "No",
    Survival_status: "Dead",
    caseId: "C3N-00579",
    Immune_subtype: "CD8-/IFNG+",
  },
  {
    Tumor: "HNSCC",
    Sex: "Male",
    Age: "<55 (25%)",
    Participant_Country: "Poland",
    Grade: "G1",
    Stage: "IV",
    BMI: "18.5-25",
    Alcohol_consumption: "drinker",
    Tobacco_use: "current smoker",
    Secondhand_smoke_exposure: "Yes",
    Overall_survival_in_days: "609-1016 (50%-75%)",
    Recurrence_status: "No",
    Survival_status: "Dead",
    caseId: "C3N-01337",
    Immune_subtype: "CD8-/IFNG+",
  },
  {
    Tumor: "GBM",
    Sex: "Female",
    Age: "63-70 (50%-75%)",
    Participant_Country: "Poland",
    Grade: "not_reported",
    Stage: "not_reported",
    BMI: "18.5-25",
    Alcohol_consumption: "non-drinker",
    Tobacco_use: "non-smoker",
    Secondhand_smoke_exposure: "Yes",
    Overall_survival_in_days: ">=1016 (>=75%)",
    Recurrence_status: "Yes",
    Survival_status: "Dead",
    caseId: "C3N-00665",
    Immune_subtype: "Fibroblast/TGFBeta",
  },
  {
    Tumor: "UCEC",
    Sex: "Female",
    Age: "63-70 (50%-75%)",
    Participant_Country: "Ukraine",
    Grade: "G2",
    Stage: "I",
    BMI: "25-30",
    Alcohol_consumption: "non-drinker",
    Tobacco_use: "non-smoker",
    Secondhand_smoke_exposure: "not_reported",
    Overall_survival_in_days: "609-1016 (50%-75%)",
    Recurrence_status: "No",
    Survival_status: "Dead",
    caseId: "C3N-00850",
    Immune_subtype: "CD8-/IFNG+",
  },
  {
    Tumor: "BRCA",
    Sex: "Female",
    Age: ">=70 (>=75%)",
    Participant_Country: "not_reported",
    Grade: "not_reported",
    Stage: "II",
    BMI: "not_reported",
    Alcohol_consumption: "not_reported",
    Tobacco_use: "not_reported",
    Secondhand_smoke_exposure: "not_reported",
    Overall_survival_in_days: "<348 (25%)",
    Recurrence_status: "No",
    Survival_status: "Dead",
    caseId: "15BR003",
    Immune_subtype: "Fibroblast/TGFBeta",
  },
  {
    Tumor: "COAD",
    Sex: "Male",
    Age: ">=70 (>=75%)",
    Participant_Country: "not_reported",
    Grade: "not_reported",
    Stage: "III",
    BMI: "not_reported",
    Alcohol_consumption: "not_reported",
    Tobacco_use: "not_reported",
    Secondhand_smoke_exposure: "not_reported",
    Overall_survival_in_days: "348-609 (25%-50%)",
    Recurrence_status: "No",
    Survival_status: "Dead",
    caseId: "11CO057",
    Immune_subtype: "Fibroblast/TGFBeta",
  },
  {
    Tumor: "ccRCC",
    Sex: "Male",
    Age: "<55 (25%)",
    Participant_Country: "United States",
    Grade: "G3",
    Stage: "III",
    BMI: ">=30",
    Alcohol_consumption: "non-drinker",
    Tobacco_use: "non-smoker",
    Secondhand_smoke_exposure: "Yes",
    Overall_survival_in_days: "<348 (25%)",
    Recurrence_status: "No",
    Survival_status: "Alive",
    caseId: "C3L-00079",
    Immune_subtype: "CD8+/IFNG+",
  },
  {
    Tumor: "PDAC",
    Sex: "Female",
    Age: "63-70 (50%-75%)",
    Participant_Country: "not_reported",
    Grade: "G2",
    Stage: "II",
    BMI: "18.5-25",
    Alcohol_consumption: "drinker",
    Tobacco_use: "past-smoker",
    Secondhand_smoke_exposure: "not_reported",
    Overall_survival_in_days: "348-609 (25%-50%)",
    Recurrence_status: "Yes",
    Survival_status: "Alive",
    caseId: "C3L-02606",
    Immune_subtype: "Fibroblast/TGFBeta",
  },
  {
    Tumor: "GBM",
    Sex: "Female",
    Age: "<55 (25%)",
    Participant_Country: "Russia",
    Grade: "not_reported",
    Stage: "not_reported",
    BMI: "25-30",
    Alcohol_consumption: "non-drinker",
    Tobacco_use: "non-smoker",
    Secondhand_smoke_exposure: "No",
    Overall_survival_in_days: "609-1016 (50%-75%)",
    Recurrence_status: "Yes",
    Survival_status: "Dead",
    caseId: "C3L-03390",
    Immune_subtype: "CD8-/IFNG-",
  },
  {
    Tumor: "BRCA",
    Sex: "Female",
    Age: "<55 (25%)",
    Participant_Country: "not_reported",
    Grade: "not_reported",
    Stage: "II",
    BMI: "not_reported",
    Alcohol_consumption: "not_reported",
    Tobacco_use: "not_reported",
    Secondhand_smoke_exposure: "not_reported",
    Overall_survival_in_days: "348-609 (25%-50%)",
    Recurrence_status: "No",
    Survival_status: "Dead",
    caseId: "11BR050",
    Immune_subtype: "Fibroblast/TGFBeta",
  },
  {
    Tumor: "UCEC",
    Sex: "Female",
    Age: "63-70 (50%-75%)",
    Participant_Country: "Ukraine",
    Grade: "G1",
    Stage: "I",
    BMI: "25-30",
    Alcohol_consumption: "non-drinker",
    Tobacco_use: "non-smoker",
    Secondhand_smoke_exposure: "not_reported",
    Overall_survival_in_days: "<348 (25%)",
    Recurrence_status: "No",
    Survival_status: "Dead",
    caseId: "C3N-00321",
    Immune_subtype: "CD8-/IFNG-",
  },
  {
    Tumor: "HGSC",
    Sex: "Female",
    Age: "<55 (25%)",
    Participant_Country: "not_reported",
    Grade: "G3",
    Stage: "III",
    BMI: "not_reported",
    Alcohol_consumption: "not_reported",
    Tobacco_use: "not_reported",
    Secondhand_smoke_exposure: "not_reported",
    Overall_survival_in_days: "348-609 (25%-50%)",
    Recurrence_status: "No",
    Survival_status: "Dead",
    caseId: "04OV008",
    Immune_subtype: "Fibroblast/TGFBeta",
  },
  {
    Tumor: "LUAD",
    Sex: "Male",
    Age: ">=70 (>=75%)",
    Participant_Country: "Poland",
    Grade: "not_reported",
    Stage: "I",
    BMI: "25-30",
    Alcohol_consumption: "not_reported",
    Tobacco_use: "not_reported",
    Secondhand_smoke_exposure: "not_reported",
    Overall_survival_in_days: ">=1016 (>=75%)",
    Recurrence_status: "No",
    Survival_status: "Dead",
    caseId: "C3N-00293",
    Immune_subtype: "Fibroblast/TGFBeta",
  },
  {
    Tumor: "GBM",
    Sex: "Male",
    Age: "<55 (25%)",
    Participant_Country: "China",
    Grade: "not_reported",
    Stage: "not_reported",
    BMI: "25-30",
    Alcohol_consumption: "non-drinker",
    Tobacco_use: "non-smoker",
    Secondhand_smoke_exposure: "No",
    Overall_survival_in_days: ">=1016 (>=75%)",
    Recurrence_status: "Yes",
    Survival_status: "Alive",
    caseId: "C3N-03188",
    Immune_subtype: "Brain/Neuro",
  },
  {
    Tumor: "LSCC",
    Sex: "Female",
    Age: "55-63 (25%-50%)",
    Participant_Country: "China",
    Grade: "G3",
    Stage: "III",
    BMI: "25-30",
    Alcohol_consumption: "non-drinker",
    Tobacco_use: "non-smoker",
    Secondhand_smoke_exposure: "not_reported",
    Overall_survival_in_days: "609-1016 (50%-75%)",
    Recurrence_status: "Yes",
    Survival_status: "Dead",
    caseId: "C3N-03848",
    Immune_subtype: "CD8+/IFNG+",
  },
  {
    Tumor: "UCEC",
    Sex: "Female",
    Age: "63-70 (50%-75%)",
    Participant_Country: "United States",
    Grade: "G1",
    Stage: "I",
    BMI: "<18.5",
    Alcohol_consumption: "drinker",
    Tobacco_use: "current smoker",
    Secondhand_smoke_exposure: "not_reported",
    Overall_survival_in_days: "609-1016 (50%-75%)",
    Recurrence_status: "No",
    Survival_status: "Dead",
    caseId: "C3L-00946",
    Immune_subtype: "Fibroblast/TGFBeta",
  },
  {
    Tumor: "HGSC",
    Sex: "Female",
    Age: "<55 (25%)",
    Participant_Country: "not_reported",
    Grade: "G3",
    Stage: "III",
    BMI: "not_reported",
    Alcohol_consumption: "not_reported",
    Tobacco_use: "not_reported",
    Secondhand_smoke_exposure: "not_reported",
    Overall_survival_in_days: "348-609 (25%-50%)",
    Recurrence_status: "No",
    Survival_status: "Dead",
    caseId: "02OV006",
    Immune_subtype: "CD8-/IFNG+",
  },
  {
    Tumor: "COAD",
    Sex: "Male",
    Age: ">=70 (>=75%)",
    Participant_Country: "not_reported",
    Grade: "not_reported",
    Stage: "III",
    BMI: "not_reported",
    Alcohol_consumption: "not_reported",
    Tobacco_use: "not_reported",
    Secondhand_smoke_exposure: "not_reported",
    Overall_survival_in_days: "<348 (25%)",
    Recurrence_status: "No",
    Survival_status: "Dead",
    caseId: "05CO032",
    Immune_subtype: "CD8-/IFNG+",
  },
  {
    Tumor: "LSCC",
    Sex: "Male",
    Age: "63-70 (50%-75%)",
    Participant_Country: "China",
    Grade: "G3",
    Stage: "III",
    BMI: "25-30",
    Alcohol_consumption: "non-drinker",
    Tobacco_use: "current smoker",
    Secondhand_smoke_exposure: "not_reported",
    Overall_survival_in_days: "609-1016 (50%-75%)",
    Recurrence_status: "No",
    Survival_status: "Dead",
    caseId: "C3N-03051",
    Immune_subtype: "Fibroblast/TGFBeta",
  },
  {
    Tumor: "BRCA",
    Sex: "Female",
    Age: ">=70 (>=75%)",
    Participant_Country: "not_reported",
    Grade: "not_reported",
    Stage: "II",
    BMI: "not_reported",
    Alcohol_consumption: "not_reported",
    Tobacco_use: "not_reported",
    Secondhand_smoke_exposure: "not_reported",
    Overall_survival_in_days: "348-609 (25%-50%)",
    Recurrence_status: "No",
    Survival_status: "Dead",
    caseId: "01BR027",
    Immune_subtype: "CD8-/IFNG+",
  },
  {
    Tumor: "UCEC",
    Sex: "Female",
    Age: "63-70 (50%-75%)",
    Participant_Country: "Poland",
    Grade: "G2",
    Stage: "III",
    BMI: ">=30",
    Alcohol_consumption: "non-drinker",
    Tobacco_use: "non-smoker",
    Secondhand_smoke_exposure: "not_reported",
    Overall_survival_in_days: "609-1016 (50%-75%)",
    Recurrence_status: "No",
    Survival_status: "Dead",
    caseId: "C3N-00858",
    Immune_subtype: "CD8-/IFNG-",
  },
  {
    Tumor: "ccRCC",
    Sex: "Female",
    Age: "55-63 (25%-50%)",
    Participant_Country: "United States",
    Grade: "G3",
    Stage: "II",
    BMI: "25-30",
    Alcohol_consumption: "drinker",
    Tobacco_use: "past-smoker",
    Secondhand_smoke_exposure: "not_reported",
    Overall_survival_in_days: ">=1016 (>=75%)",
    Recurrence_status: "Yes",
    Survival_status: "Dead",
    caseId: "C3L-00908",
    Immune_subtype: "CD8+/IFNG+",
  },
  {
    Tumor: "LUAD",
    Sex: "Male",
    Age: "<55 (25%)",
    Participant_Country: "China",
    Grade: "G3",
    Stage: "II",
    BMI: "18.5-25",
    Alcohol_consumption: "drinker",
    Tobacco_use: "non-smoker",
    Secondhand_smoke_exposure: "not_reported",
    Overall_survival_in_days: "609-1016 (50%-75%)",
    Recurrence_status: "Yes",
    Survival_status: "Alive",
    caseId: "C3N-02729",
    Immune_subtype: "CD8-/IFNG-",
  },
  {
    Tumor: "HNSCC",
    Sex: "Male",
    Age: "63-70 (50%-75%)",
    Participant_Country: "Poland",
    Grade: "G2",
    Stage: "II",
    BMI: "25-30",
    Alcohol_consumption: "non-drinker",
    Tobacco_use: "past-smoker",
    Secondhand_smoke_exposure: "not_reported",
    Overall_survival_in_days: ">=1016 (>=75%)",
    Recurrence_status: "No",
    Survival_status: "Dead",
    caseId: "C3N-01946",
    Immune_subtype: "CD8+/IFNG+",
  },
  {
    Tumor: "COAD",
    Sex: "Female",
    Age: "63-70 (50%-75%)",
    Participant_Country: "not_reported",
    Grade: "not_reported",
    Stage: "II",
    BMI: "not_reported",
    Alcohol_consumption: "not_reported",
    Tobacco_use: "not_reported",
    Secondhand_smoke_exposure: "not_reported",
    Overall_survival_in_days: "348-609 (25%-50%)",
    Recurrence_status: "No",
    Survival_status: "Alive",
    caseId: "01CO005",
    Immune_subtype: "CD8-/IFNG-",
  },
  {
    Tumor: "LUAD",
    Sex: "Male",
    Age: "63-70 (50%-75%)",
    Participant_Country: "Vietnam",
    Grade: "G3",
    Stage: "II",
    BMI: "18.5-25",
    Alcohol_consumption: "non-drinker",
    Tobacco_use: "non-smoker",
    Secondhand_smoke_exposure: "No",
    Overall_survival_in_days: "348-609 (25%-50%)",
    Recurrence_status: "No",
    Survival_status: "Dead",
    caseId: "C3N-01410",
    Immune_subtype: "CD8-/IFNG-",
  },
  {
    Tumor: "COAD",
    Sex: "Female",
    Age: "55-63 (25%-50%)",
    Participant_Country: "not_reported",
    Grade: "not_reported",
    Stage: "III",
    BMI: "not_reported",
    Alcohol_consumption: "not_reported",
    Tobacco_use: "not_reported",
    Secondhand_smoke_exposure: "not_reported",
    Overall_survival_in_days: "<348 (25%)",
    Recurrence_status: "Yes",
    Survival_status: "Dead",
    caseId: "22CO006",
    Immune_subtype: "CD8+/IFNG+",
  },
  {
    Tumor: "LUAD",
    Sex: "Male",
    Age: "63-70 (50%-75%)",
    Participant_Country: "United States",
    Grade: "G2",
    Stage: "III",
    BMI: "<18.5",
    Alcohol_consumption: "not_reported",
    Tobacco_use: "past-smoker",
    Secondhand_smoke_exposure: "not_reported",
    Overall_survival_in_days: "<348 (25%)",
    Recurrence_status: "No",
    Survival_status: "Alive",
    caseId: "C3L-00913",
    Immune_subtype: "CD8+/IFNG+",
  },
  {
    Tumor: "PDAC",
    Sex: "Male",
    Age: ">=70 (>=75%)",
    Participant_Country: "United States",
    Grade: "G2",
    Stage: "I",
    BMI: "25-30",
    Alcohol_consumption: "non-drinker",
    Tobacco_use: "past-smoker",
    Secondhand_smoke_exposure: "not_reported",
    Overall_survival_in_days: "<348 (25%)",
    Recurrence_status: "No",
    Survival_status: "Dead",
    caseId: "C3L-02897",
    Immune_subtype: "CD8-/IFNG-",
  },
  {
    Tumor: "PDAC",
    Sex: "Female",
    Age: "55-63 (25%-50%)",
    Participant_Country: "not_reported",
    Grade: "G2",
    Stage: "II",
    BMI: "<18.5",
    Alcohol_consumption: "drinker",
    Tobacco_use: "current smoker",
    Secondhand_smoke_exposure: "not_reported",
    Overall_survival_in_days: "348-609 (25%-50%)",
    Recurrence_status: "Yes",
    Survival_status: "Alive",
    caseId: "C3L-00598",
    Immune_subtype: "CD8-/IFNG-",
  },
  {
    Tumor: "COAD",
    Sex: "Female",
    Age: ">=70 (>=75%)",
    Participant_Country: "not_reported",
    Grade: "not_reported",
    Stage: "I",
    BMI: "not_reported",
    Alcohol_consumption: "not_reported",
    Tobacco_use: "not_reported",
    Secondhand_smoke_exposure: "not_reported",
    Overall_survival_in_days: "<348 (25%)",
    Recurrence_status: "No",
    Survival_status: "Dead",
    caseId: "09CO011",
    Immune_subtype: "CD8-/IFNG+",
  },
  {
    Tumor: "GBM",
    Sex: "Female",
    Age: "55-63 (25%-50%)",
    Participant_Country: "China",
    Grade: "not_reported",
    Stage: "not_reported",
    BMI: "18.5-25",
    Alcohol_consumption: "non-drinker",
    Tobacco_use: "non-smoker",
    Secondhand_smoke_exposure: "No",
    Overall_survival_in_days: "348-609 (25%-50%)",
    Recurrence_status: "Yes",
    Survival_status: "Dead",
    caseId: "C3N-02185",
    Immune_subtype: "CD8-/IFNG-",
  },
  {
    Tumor: "BRCA",
    Sex: "Female",
    Age: "55-63 (25%-50%)",
    Participant_Country: "not_reported",
    Grade: "not_reported",
    Stage: "II",
    BMI: "not_reported",
    Alcohol_consumption: "not_reported",
    Tobacco_use: "not_reported",
    Secondhand_smoke_exposure: "not_reported",
    Overall_survival_in_days: "<348 (25%)",
    Recurrence_status: "No",
    Survival_status: "Dead",
    caseId: "11BR055",
    Immune_subtype: "Fibroblast/TGFBeta",
  },
  {
    Tumor: "ccRCC",
    Sex: "Male",
    Age: "<55 (25%)",
    Participant_Country: "United States",
    Grade: "G2",
    Stage: "I",
    BMI: ">=30",
    Alcohol_consumption: "non-drinker",
    Tobacco_use: "non-smoker",
    Secondhand_smoke_exposure: "not_reported",
    Overall_survival_in_days: ">=1016 (>=75%)",
    Recurrence_status: "Yes",
    Survival_status: "Dead",
    caseId: "C3N-00246",
    Immune_subtype: "CD8-/IFNG-",
  },
  {
    Tumor: "LSCC",
    Sex: "Female",
    Age: "63-70 (50%-75%)",
    Participant_Country: "United States",
    Grade: "G3",
    Stage: "I",
    BMI: "not_reported",
    Alcohol_consumption: "not_reported",
    Tobacco_use: "not_reported",
    Secondhand_smoke_exposure: "not_reported",
    Overall_survival_in_days: "not_reported",
    Recurrence_status: "No",
    Survival_status: "not_reported",
    caseId: "C3N-02494",
    Immune_subtype: "CD8+/IFNG+",
  },
  {
    Tumor: "HNSCC",
    Sex: "Male",
    Age: ">=70 (>=75%)",
    Participant_Country: "Poland",
    Grade: "G2",
    Stage: "IV",
    BMI: "18.5-25",
    Alcohol_consumption: "not_reported",
    Tobacco_use: "not_reported",
    Secondhand_smoke_exposure: "not_reported",
    Overall_survival_in_days: "609-1016 (50%-75%)",
    Recurrence_status: "No",
    Survival_status: "Alive",
    caseId: "C3N-01752",
    Immune_subtype: "CD8-/IFNG-",
  },
  {
    Tumor: "PDAC",
    Sex: "Female",
    Age: "63-70 (50%-75%)",
    Participant_Country: "Russia",
    Grade: "G2",
    Stage: "II",
    BMI: "25-30",
    Alcohol_consumption: "not_reported",
    Tobacco_use: "non-smoker",
    Secondhand_smoke_exposure: "No",
    Overall_survival_in_days: "348-609 (25%-50%)",
    Recurrence_status: "No",
    Survival_status: "Dead",
    caseId: "C3L-01031",
    Immune_subtype: "CD8-/IFNG+",
  },
  {
    Tumor: "BRCA",
    Sex: "Female",
    Age: "<55 (25%)",
    Participant_Country: "not_reported",
    Grade: "not_reported",
    Stage: "III",
    BMI: "not_reported",
    Alcohol_consumption: "not_reported",
    Tobacco_use: "not_reported",
    Secondhand_smoke_exposure: "not_reported",
    Overall_survival_in_days: "348-609 (25%-50%)",
    Recurrence_status: "No",
    Survival_status: "Dead",
    caseId: "11BR009",
    Immune_subtype: "CD8-/IFNG-",
  },
  {
    Tumor: "BRCA",
    Sex: "Female",
    Age: "55-63 (25%-50%)",
    Participant_Country: "not_reported",
    Grade: "not_reported",
    Stage: "III",
    BMI: "not_reported",
    Alcohol_consumption: "not_reported",
    Tobacco_use: "not_reported",
    Secondhand_smoke_exposure: "not_reported",
    Overall_survival_in_days: "348-609 (25%-50%)",
    Recurrence_status: "No",
    Survival_status: "Dead",
    caseId: "11BR015",
    Immune_subtype: "CD8-/IFNG-",
  },
  {
    Tumor: "HNSCC",
    Sex: "Male",
    Age: "55-63 (25%-50%)",
    Participant_Country: "Ukraine",
    Grade: "G2",
    Stage: "III",
    BMI: "18.5-25",
    Alcohol_consumption: "past-drinker",
    Tobacco_use: "current smoker",
    Secondhand_smoke_exposure: "Yes",
    Overall_survival_in_days: "348-609 (25%-50%)",
    Recurrence_status: "No",
    Survival_status: "Dead",
    caseId: "C3L-00987",
    Immune_subtype: "CD8-/IFNG-",
  },
  {
    Tumor: "PDAC",
    Sex: "Male",
    Age: ">=70 (>=75%)",
    Participant_Country: "Bulgaria",
    Grade: "G2",
    Stage: "I",
    BMI: "25-30",
    Alcohol_consumption: "drinker",
    Tobacco_use: "non-smoker",
    Secondhand_smoke_exposure: "No",
    Overall_survival_in_days: "<348 (25%)",
    Recurrence_status: "No",
    Survival_status: "Alive",
    caseId: "C3L-02701",
    Immune_subtype: "Eosinophils/Endothelial",
  },
  {
    Tumor: "HGSC",
    Sex: "Female",
    Age: "55-63 (25%-50%)",
    Participant_Country: "not_reported",
    Grade: "G3",
    Stage: "III",
    BMI: "not_reported",
    Alcohol_consumption: "not_reported",
    Tobacco_use: "not_reported",
    Secondhand_smoke_exposure: "not_reported",
    Overall_survival_in_days: "348-609 (25%-50%)",
    Recurrence_status: "No",
    Survival_status: "Dead",
    caseId: "01OV017",
    Immune_subtype: "CD8-/IFNG-",
  },
  {
    Tumor: "COAD",
    Sex: "Female",
    Age: ">=70 (>=75%)",
    Participant_Country: "not_reported",
    Grade: "not_reported",
    Stage: "IV",
    BMI: "not_reported",
    Alcohol_consumption: "not_reported",
    Tobacco_use: "not_reported",
    Secondhand_smoke_exposure: "not_reported",
    Overall_survival_in_days: "<348 (25%)",
    Recurrence_status: "No",
    Survival_status: "Alive",
    caseId: "11CO021",
    Immune_subtype: "Fibroblast/TGFBeta",
  },
  {
    Tumor: "ccRCC",
    Sex: "Male",
    Age: "55-63 (25%-50%)",
    Participant_Country: "United States",
    Grade: "G4",
    Stage: "IV",
    BMI: "25-30",
    Alcohol_consumption: "drinker",
    Tobacco_use: "non-smoker",
    Secondhand_smoke_exposure: "not_reported",
    Overall_survival_in_days: "609-1016 (50%-75%)",
    Recurrence_status: "No",
    Survival_status: "Alive",
    caseId: "C3L-01287",
    Immune_subtype: "CD8+/IFNG+",
  },
  {
    Tumor: "LUAD",
    Sex: "Male",
    Age: "55-63 (25%-50%)",
    Participant_Country: "United States",
    Grade: "G2",
    Stage: "I",
    BMI: ">=30",
    Alcohol_consumption: "not_reported",
    Tobacco_use: "current smoker",
    Secondhand_smoke_exposure: "not_reported",
    Overall_survival_in_days: ">=1016 (>=75%)",
    Recurrence_status: "No",
    Survival_status: "Dead",
    caseId: "C3L-00095",
    Immune_subtype: "CD8+/IFNG+",
  },
  {
    Tumor: "GBM",
    Sex: "Male",
    Age: "<55 (25%)",
    Participant_Country: "United States",
    Grade: "not_reported",
    Stage: "not_reported",
    BMI: ">=30",
    Alcohol_consumption: "non-drinker",
    Tobacco_use: "non-smoker",
    Secondhand_smoke_exposure: "Yes",
    Overall_survival_in_days: ">=1016 (>=75%)",
    Recurrence_status: "No",
    Survival_status: "Dead",
    caseId: "C3L-02984",
    Immune_subtype: "Brain/Neuro",
  },
  {
    Tumor: "HGSC",
    Sex: "Female",
    Age: "<55 (25%)",
    Participant_Country: "not_reported",
    Grade: "G3",
    Stage: "III",
    BMI: "not_reported",
    Alcohol_consumption: "not_reported",
    Tobacco_use: "not_reported",
    Secondhand_smoke_exposure: "not_reported",
    Overall_survival_in_days: "<348 (25%)",
    Recurrence_status: "No",
    Survival_status: "Dead",
    caseId: "17OV030",
    Immune_subtype: "CD8+/IFNG+",
  },
  {
    Tumor: "GBM",
    Sex: "Male",
    Age: "<55 (25%)",
    Participant_Country: "China",
    Grade: "not_reported",
    Stage: "not_reported",
    BMI: "25-30",
    Alcohol_consumption: "non-drinker",
    Tobacco_use: "non-smoker",
    Secondhand_smoke_exposure: "No",
    Overall_survival_in_days: "609-1016 (50%-75%)",
    Recurrence_status: "Yes",
    Survival_status: "Alive",
    caseId: "C3N-03182",
    Immune_subtype: "CD8+/IFNG+",
  },
  {
    Tumor: "COAD",
    Sex: "Female",
    Age: "<55 (25%)",
    Participant_Country: "not_reported",
    Grade: "not_reported",
    Stage: "II",
    BMI: "not_reported",
    Alcohol_consumption: "not_reported",
    Tobacco_use: "not_reported",
    Secondhand_smoke_exposure: "not_reported",
    Overall_survival_in_days: "348-609 (25%-50%)",
    Recurrence_status: "No",
    Survival_status: "Dead",
    caseId: "11CO027",
    Immune_subtype: "CD8-/IFNG-",
  },
  {
    Tumor: "BRCA",
    Sex: "Female",
    Age: "<55 (25%)",
    Participant_Country: "not_reported",
    Grade: "not_reported",
    Stage: "III",
    BMI: "not_reported",
    Alcohol_consumption: "not_reported",
    Tobacco_use: "not_reported",
    Secondhand_smoke_exposure: "not_reported",
    Overall_survival_in_days: "348-609 (25%-50%)",
    Recurrence_status: "No",
    Survival_status: "Dead",
    caseId: "11BR014",
    Immune_subtype: "Fibroblast/TGFBeta",
  },
  {
    Tumor: "HNSCC",
    Sex: "Male",
    Age: "63-70 (50%-75%)",
    Participant_Country: "Poland",
    Grade: "G2",
    Stage: "II",
    BMI: "18.5-25",
    Alcohol_consumption: "not_reported",
    Tobacco_use: "not_reported",
    Secondhand_smoke_exposure: "not_reported",
    Overall_survival_in_days: ">=1016 (>=75%)",
    Recurrence_status: "No",
    Survival_status: "Dead",
    caseId: "C3N-01757",
    Immune_subtype: "CD8-/IFNG-",
  },
  {
    Tumor: "UCEC",
    Sex: "Female",
    Age: "55-63 (25%-50%)",
    Participant_Country: "United States",
    Grade: "G1",
    Stage: "I",
    BMI: ">=30",
    Alcohol_consumption: "non-drinker",
    Tobacco_use: "non-smoker",
    Secondhand_smoke_exposure: "not_reported",
    Overall_survival_in_days: ">=1016 (>=75%)",
    Recurrence_status: "No",
    Survival_status: "Dead",
    caseId: "C3L-00563",
    Immune_subtype: "CD8-/IFNG-",
  },
  {
    Tumor: "UCEC",
    Sex: "Female",
    Age: "55-63 (25%-50%)",
    Participant_Country: "United States",
    Grade: "G3",
    Stage: "I",
    BMI: "18.5-25",
    Alcohol_consumption: "non-drinker",
    Tobacco_use: "non-smoker",
    Secondhand_smoke_exposure: "not_reported",
    Overall_survival_in_days: ">=1016 (>=75%)",
    Recurrence_status: "No",
    Survival_status: "Dead",
    caseId: "C3L-00098",
    Immune_subtype: "CD8-/IFNG+",
  },
  {
    Tumor: "LUAD",
    Sex: "Male",
    Age: "55-63 (25%-50%)",
    Participant_Country: "United States",
    Grade: "G2",
    Stage: "I",
    BMI: ">=30",
    Alcohol_consumption: "non-drinker",
    Tobacco_use: "current smoker",
    Secondhand_smoke_exposure: "not_reported",
    Overall_survival_in_days: ">=1016 (>=75%)",
    Recurrence_status: "Yes",
    Survival_status: "Dead",
    caseId: "C3L-00080",
    Immune_subtype: "CD8-/IFNG-",
  },
  {
    Tumor: "PDAC",
    Sex: "Male",
    Age: ">=70 (>=75%)",
    Participant_Country: "United States",
    Grade: "G2",
    Stage: "not_reported",
    BMI: "not_reported",
    Alcohol_consumption: "not_reported",
    Tobacco_use: "not_reported",
    Secondhand_smoke_exposure: "not_reported",
    Overall_survival_in_days: "not_reported",
    Recurrence_status: "No",
    Survival_status: "not_reported",
    caseId: "C3N-00709",
    Immune_subtype: "CD8-/IFNG-",
  },
  {
    Tumor: "COAD",
    Sex: "Female",
    Age: "63-70 (50%-75%)",
    Participant_Country: "not_reported",
    Grade: "not_reported",
    Stage: "III",
    BMI: "not_reported",
    Alcohol_consumption: "not_reported",
    Tobacco_use: "not_reported",
    Secondhand_smoke_exposure: "not_reported",
    Overall_survival_in_days: "348-609 (25%-50%)",
    Recurrence_status: "No",
    Survival_status: "Dead",
    caseId: "11CO008",
    Immune_subtype: "Fibroblast/TGFBeta",
  },
  {
    Tumor: "LSCC",
    Sex: "Female",
    Age: "63-70 (50%-75%)",
    Participant_Country: "Ukraine",
    Grade: "G3",
    Stage: "not_reported",
    BMI: "not_reported",
    Alcohol_consumption: "not_reported",
    Tobacco_use: "not_reported",
    Secondhand_smoke_exposure: "not_reported",
    Overall_survival_in_days: "not_reported",
    Recurrence_status: "No",
    Survival_status: "not_reported",
    caseId: "C3N-03093",
    Immune_subtype: "CD8+/IFNG+",
  },
  {
    Tumor: "ccRCC",
    Sex: "Male",
    Age: "55-63 (25%-50%)",
    Participant_Country: "United States",
    Grade: "G2",
    Stage: "I",
    BMI: ">=30",
    Alcohol_consumption: "drinker",
    Tobacco_use: "past-smoker",
    Secondhand_smoke_exposure: "not_reported",
    Overall_survival_in_days: ">=1016 (>=75%)",
    Recurrence_status: "No",
    Survival_status: "Dead",
    caseId: "C3L-00097",
    Immune_subtype: "ccRCC/Endothelial",
  },
  {
    Tumor: "ccRCC",
    Sex: "Male",
    Age: "<55 (25%)",
    Participant_Country: "United States",
    Grade: "G4",
    Stage: "III",
    BMI: "25-30",
    Alcohol_consumption: "non-drinker",
    Tobacco_use: "non-smoker",
    Secondhand_smoke_exposure: "not_reported",
    Overall_survival_in_days: ">=1016 (>=75%)",
    Recurrence_status: "Yes",
    Survival_status: "Dead",
    caseId: "C3L-00561",
    Immune_subtype: "CD8+/IFNG+",
  },
  {
    Tumor: "ccRCC",
    Sex: "Male",
    Age: "<55 (25%)",
    Participant_Country: "Ukraine",
    Grade: "G4",
    Stage: "IV",
    BMI: "18.5-25",
    Alcohol_consumption: "non-drinker",
    Tobacco_use: "current smoker",
    Secondhand_smoke_exposure: "not_reported",
    Overall_survival_in_days: "609-1016 (50%-75%)",
    Recurrence_status: "No",
    Survival_status: "Alive",
    caseId: "C3N-01220",
    Immune_subtype: "CD8+/IFNG+",
  },
  {
    Tumor: "HNSCC",
    Sex: "Male",
    Age: "<55 (25%)",
    Participant_Country: "Ukraine",
    Grade: "G2",
    Stage: "III",
    BMI: ">=30",
    Alcohol_consumption: "non-drinker",
    Tobacco_use: "non-smoker",
    Secondhand_smoke_exposure: "not_reported",
    Overall_survival_in_days: "609-1016 (50%-75%)",
    Recurrence_status: "No",
    Survival_status: "Dead",
    caseId: "C3N-03612",
    Immune_subtype: "CD8+/IFNG+",
  },
  {
    Tumor: "ccRCC",
    Sex: "Male",
    Age: ">=70 (>=75%)",
    Participant_Country: "United States",
    Grade: "G2",
    Stage: "I",
    BMI: ">=30",
    Alcohol_consumption: "not_reported",
    Tobacco_use: "not_reported",
    Secondhand_smoke_exposure: "not_reported",
    Overall_survival_in_days: "<348 (25%)",
    Recurrence_status: "No",
    Survival_status: "Dead",
    caseId: "C3L-00814",
    Immune_subtype: "CD8-/IFNG+",
  },
  {
    Tumor: "ccRCC",
    Sex: "Female",
    Age: "55-63 (25%-50%)",
    Participant_Country: "United States",
    Grade: "G3",
    Stage: "I",
    BMI: ">=30",
    Alcohol_consumption: "non-drinker",
    Tobacco_use: "non-smoker",
    Secondhand_smoke_exposure: "not_reported",
    Overall_survival_in_days: ">=1016 (>=75%)",
    Recurrence_status: "No",
    Survival_status: "Dead",
    caseId: "C3L-00610",
    Immune_subtype: "ccRCC/Endothelial",
  },
  {
    Tumor: "HGSC",
    Sex: "Female",
    Age: "55-63 (25%-50%)",
    Participant_Country: "not_reported",
    Grade: "G3",
    Stage: "III",
    BMI: "not_reported",
    Alcohol_consumption: "not_reported",
    Tobacco_use: "not_reported",
    Secondhand_smoke_exposure: "not_reported",
    Overall_survival_in_days: "609-1016 (50%-75%)",
    Recurrence_status: "No",
    Survival_status: "Dead",
    caseId: "26OV009",
    Immune_subtype: "CD8-/IFNG-",
  },
  {
    Tumor: "HGSC",
    Sex: "Female",
    Age: "55-63 (25%-50%)",
    Participant_Country: "not_reported",
    Grade: "G3",
    Stage: "III",
    BMI: "not_reported",
    Alcohol_consumption: "not_reported",
    Tobacco_use: "not_reported",
    Secondhand_smoke_exposure: "not_reported",
    Overall_survival_in_days: "348-609 (25%-50%)",
    Recurrence_status: "No",
    Survival_status: "Dead",
    caseId: "02OV022",
    Immune_subtype: "Fibroblast/TGFBeta",
  },
  {
    Tumor: "LSCC",
    Sex: "Female",
    Age: "55-63 (25%-50%)",
    Participant_Country: "United States",
    Grade: "G2",
    Stage: "II",
    BMI: ">=30",
    Alcohol_consumption: "non-drinker",
    Tobacco_use: "non-smoker",
    Secondhand_smoke_exposure: "not_reported",
    Overall_survival_in_days: "348-609 (25%-50%)",
    Recurrence_status: "Yes",
    Survival_status: "Alive",
    caseId: "C3L-00081",
    Immune_subtype: "CD8-/IFNG-",
  },
  {
    Tumor: "PDAC",
    Sex: "Male",
    Age: ">=70 (>=75%)",
    Participant_Country: "United States",
    Grade: "G2",
    Stage: "III",
    BMI: ">=30",
    Alcohol_consumption: "drinker",
    Tobacco_use: "current smoker",
    Secondhand_smoke_exposure: "not_reported",
    Overall_survival_in_days: "<348 (25%)",
    Recurrence_status: "No",
    Survival_status: "Alive",
    caseId: "C3L-02809",
    Immune_subtype: "Fibroblast/TGFBeta",
  },
  {
    Tumor: "COAD",
    Sex: "Male",
    Age: "55-63 (25%-50%)",
    Participant_Country: "not_reported",
    Grade: "not_reported",
    Stage: "I",
    BMI: "not_reported",
    Alcohol_consumption: "not_reported",
    Tobacco_use: "not_reported",
    Secondhand_smoke_exposure: "not_reported",
    Overall_survival_in_days: "<348 (25%)",
    Recurrence_status: "No",
    Survival_status: "Dead",
    caseId: "05CO047",
    Immune_subtype: "CD8-/IFNG-",
  },
  {
    Tumor: "LUAD",
    Sex: "Male",
    Age: ">=70 (>=75%)",
    Participant_Country: "United States",
    Grade: "G3",
    Stage: "I",
    BMI: "18.5-25",
    Alcohol_consumption: "drinker",
    Tobacco_use: "past-smoker",
    Secondhand_smoke_exposure: "Yes",
    Overall_survival_in_days: ">=1016 (>=75%)",
    Recurrence_status: "Yes",
    Survival_status: "Alive",
    caseId: "C3L-01683",
    Immune_subtype: "CD8+/IFNG+",
  },
  {
    Tumor: "LUAD",
    Sex: "Male",
    Age: "<55 (25%)",
    Participant_Country: "Vietnam",
    Grade: "G2",
    Stage: "III",
    BMI: "18.5-25",
    Alcohol_consumption: "drinker",
    Tobacco_use: "current smoker",
    Secondhand_smoke_exposure: "No",
    Overall_survival_in_days: "<348 (25%)",
    Recurrence_status: "No",
    Survival_status: "Alive",
    caseId: "C3N-00578",
    Immune_subtype: "Fibroblast/TGFBeta",
  },
  {
    Tumor: "LSCC",
    Sex: "Male",
    Age: "63-70 (50%-75%)",
    Participant_Country: "United States",
    Grade: "G2",
    Stage: "I",
    BMI: ">=30",
    Alcohol_consumption: "drinker",
    Tobacco_use: "past-smoker",
    Secondhand_smoke_exposure: "not_reported",
    Overall_survival_in_days: ">=1016 (>=75%)",
    Recurrence_status: "No",
    Survival_status: "Dead",
    caseId: "C3L-01606",
    Immune_subtype: "Eosinophils/Endothelial",
  },
  {
    Tumor: "ccRCC",
    Sex: "Male",
    Age: "55-63 (25%-50%)",
    Participant_Country: "United States",
    Grade: "G3",
    Stage: "I",
    BMI: "25-30",
    Alcohol_consumption: "drinker",
    Tobacco_use: "past-smoker",
    Secondhand_smoke_exposure: "not_reported",
    Overall_survival_in_days: ">=1016 (>=75%)",
    Recurrence_status: "No",
    Survival_status: "Dead",
    caseId: "C3L-01861",
    Immune_subtype: "ccRCC/Endothelial",
  },
  {
    Tumor: "HNSCC",
    Sex: "Female",
    Age: "63-70 (50%-75%)",
    Participant_Country: "China",
    Grade: "G2",
    Stage: "IV",
    BMI: "18.5-25",
    Alcohol_consumption: "non-drinker",
    Tobacco_use: "non-smoker",
    Secondhand_smoke_exposure: "not_reported",
    Overall_survival_in_days: ">=1016 (>=75%)",
    Recurrence_status: "No",
    Survival_status: "Dead",
    caseId: "C3N-02925",
    Immune_subtype: "CD8+/IFNG+",
  },
  {
    Tumor: "LUAD",
    Sex: "Female",
    Age: "<55 (25%)",
    Participant_Country: "Vietnam",
    Grade: "G2",
    Stage: "III",
    BMI: "18.5-25",
    Alcohol_consumption: "non-drinker",
    Tobacco_use: "non-smoker",
    Secondhand_smoke_exposure: "No",
    Overall_survival_in_days: "348-609 (25%-50%)",
    Recurrence_status: "No",
    Survival_status: "Dead",
    caseId: "C3N-01021",
    Immune_subtype: "Eosinophils/Endothelial",
  },
  {
    Tumor: "PDAC",
    Sex: "Male",
    Age: ">=70 (>=75%)",
    Participant_Country: "not_reported",
    Grade: "G3",
    Stage: "I",
    BMI: "25-30",
    Alcohol_consumption: "drinker",
    Tobacco_use: "non-smoker",
    Secondhand_smoke_exposure: "not_reported",
    Overall_survival_in_days: "<348 (25%)",
    Recurrence_status: "Yes",
    Survival_status: "Alive",
    caseId: "C3L-03639",
    Immune_subtype: "CD8-/IFNG+",
  },
  {
    Tumor: "LSCC",
    Sex: "Male",
    Age: "55-63 (25%-50%)",
    Participant_Country: "Bulgaria",
    Grade: "G3",
    Stage: "I",
    BMI: "18.5-25",
    Alcohol_consumption: "drinker",
    Tobacco_use: "current smoker",
    Secondhand_smoke_exposure: "Yes",
    Overall_survival_in_days: "348-609 (25%-50%)",
    Recurrence_status: "No",
    Survival_status: "Dead",
    caseId: "C3L-04014",
    Immune_subtype: "Fibroblast/TGFBeta",
  },
  {
    Tumor: "LUAD",
    Sex: "Female",
    Age: "55-63 (25%-50%)",
    Participant_Country: "United States",
    Grade: "G2",
    Stage: "III",
    BMI: "18.5-25",
    Alcohol_consumption: "drinker",
    Tobacco_use: "non-smoker",
    Secondhand_smoke_exposure: "not_reported",
    Overall_survival_in_days: ">=1016 (>=75%)",
    Recurrence_status: "Yes",
    Survival_status: "Dead",
    caseId: "C3L-00604",
    Immune_subtype: "CD8-/IFNG+",
  },
  {
    Tumor: "GBM",
    Sex: "Female",
    Age: ">=70 (>=75%)",
    Participant_Country: "United States",
    Grade: "not_reported",
    Stage: "not_reported",
    BMI: ">=30",
    Alcohol_consumption: "drinker",
    Tobacco_use: "past-smoker",
    Secondhand_smoke_exposure: "not_reported",
    Overall_survival_in_days: "<348 (25%)",
    Recurrence_status: "No",
    Survival_status: "Alive",
    caseId: "C3L-03260",
    Immune_subtype: "CD8-/IFNG-",
  },
  {
    Tumor: "BRCA",
    Sex: "Female",
    Age: "<55 (25%)",
    Participant_Country: "not_reported",
    Grade: "not_reported",
    Stage: "II",
    BMI: "not_reported",
    Alcohol_consumption: "not_reported",
    Tobacco_use: "not_reported",
    Secondhand_smoke_exposure: "not_reported",
    Overall_survival_in_days: "348-609 (25%-50%)",
    Recurrence_status: "No",
    Survival_status: "Dead",
    caseId: "11BR032",
    Immune_subtype: "Fibroblast/TGFBeta",
  },
  {
    Tumor: "LSCC",
    Sex: "Male",
    Age: "55-63 (25%-50%)",
    Participant_Country: "Bulgaria",
    Grade: "G3",
    Stage: "III",
    BMI: "18.5-25",
    Alcohol_consumption: "non-drinker",
    Tobacco_use: "past-smoker",
    Secondhand_smoke_exposure: "Yes",
    Overall_survival_in_days: ">=1016 (>=75%)",
    Recurrence_status: "Yes",
    Survival_status: "Alive",
    caseId: "C3L-02649",
    Immune_subtype: "CD8-/IFNG-",
  },
  {
    Tumor: "HGSC",
    Sex: "Female",
    Age: "<55 (25%)",
    Participant_Country: "not_reported",
    Grade: "G3",
    Stage: "III",
    BMI: "not_reported",
    Alcohol_consumption: "not_reported",
    Tobacco_use: "not_reported",
    Secondhand_smoke_exposure: "not_reported",
    Overall_survival_in_days: "<348 (25%)",
    Recurrence_status: "No",
    Survival_status: "Dead",
    caseId: "02OV029",
    Immune_subtype: "CD8-/IFNG-",
  },
  {
    Tumor: "UCEC",
    Sex: "Female",
    Age: "<55 (25%)",
    Participant_Country: "United States",
    Grade: "G1",
    Stage: "III",
    BMI: ">=30",
    Alcohol_consumption: "non-drinker",
    Tobacco_use: "non-smoker",
    Secondhand_smoke_exposure: "not_reported",
    Overall_survival_in_days: ">=1016 (>=75%)",
    Recurrence_status: "No",
    Survival_status: "Dead",
    caseId: "C3N-00734",
    Immune_subtype: "Fibroblast/TGFBeta",
  },
  {
    Tumor: "HGSC",
    Sex: "Female",
    Age: "<55 (25%)",
    Participant_Country: "not_reported",
    Grade: "G3",
    Stage: "IV",
    BMI: "not_reported",
    Alcohol_consumption: "not_reported",
    Tobacco_use: "not_reported",
    Secondhand_smoke_exposure: "not_reported",
    Overall_survival_in_days: "<348 (25%)",
    Recurrence_status: "No",
    Survival_status: "Dead",
    caseId: "04OV036",
    Immune_subtype: "CD8-/IFNG+",
  },
  {
    Tumor: "LUAD",
    Sex: "Male",
    Age: "63-70 (50%-75%)",
    Participant_Country: "Vietnam",
    Grade: "G3",
    Stage: "II",
    BMI: "<18.5",
    Alcohol_consumption: "non-drinker",
    Tobacco_use: "non-smoker",
    Secondhand_smoke_exposure: "No",
    Overall_survival_in_days: "348-609 (25%-50%)",
    Recurrence_status: "Yes",
    Survival_status: "Dead",
    caseId: "C3N-00556",
    Immune_subtype: "CD8+/IFNG+",
  },
  {
    Tumor: "HGSC",
    Sex: "Female",
    Age: "55-63 (25%-50%)",
    Participant_Country: "not_reported",
    Grade: "G3",
    Stage: "IV",
    BMI: "not_reported",
    Alcohol_consumption: "not_reported",
    Tobacco_use: "not_reported",
    Secondhand_smoke_exposure: "not_reported",
    Overall_survival_in_days: "348-609 (25%-50%)",
    Recurrence_status: "Yes",
    Survival_status: "Dead",
    caseId: "04OV027",
    Immune_subtype: "CD8+/IFNG+",
  },
  {
    Tumor: "GBM",
    Sex: "Male",
    Age: "<55 (25%)",
    Participant_Country: "Poland",
    Grade: "not_reported",
    Stage: "not_reported",
    BMI: ">=30",
    Alcohol_consumption: "non-drinker",
    Tobacco_use: "past-smoker",
    Secondhand_smoke_exposure: "No",
    Overall_survival_in_days: ">=1016 (>=75%)",
    Recurrence_status: "Yes",
    Survival_status: "Dead",
    caseId: "C3N-00661",
    Immune_subtype: "Fibroblast/TGFBeta",
  },
  {
    Tumor: "PDAC",
    Sex: "Male",
    Age: ">=70 (>=75%)",
    Participant_Country: "United States",
    Grade: "G2",
    Stage: "I",
    BMI: "25-30",
    Alcohol_consumption: "past-drinker",
    Tobacco_use: "current smoker",
    Secondhand_smoke_exposure: "not_reported",
    Overall_survival_in_days: ">=1016 (>=75%)",
    Recurrence_status: "No",
    Survival_status: "Dead",
    caseId: "C3N-01012",
    Immune_subtype: "CD8-/IFNG-",
  },
  {
    Tumor: "HNSCC",
    Sex: "Male",
    Age: "<55 (25%)",
    Participant_Country: "Ukraine",
    Grade: "G2",
    Stage: "IV",
    BMI: ">=30",
    Alcohol_consumption: "drinker",
    Tobacco_use: "current smoker",
    Secondhand_smoke_exposure: "not_reported",
    Overall_survival_in_days: "609-1016 (50%-75%)",
    Recurrence_status: "No",
    Survival_status: "Dead",
    caseId: "C3N-03889",
    Immune_subtype: "Fibroblast/TGFBeta",
  },
  {
    Tumor: "PDAC",
    Sex: "Female",
    Age: "<55 (25%)",
    Participant_Country: "Poland",
    Grade: "G2",
    Stage: "II",
    BMI: "18.5-25",
    Alcohol_consumption: "non-drinker",
    Tobacco_use: "non-smoker",
    Secondhand_smoke_exposure: "No",
    Overall_survival_in_days: "609-1016 (50%-75%)",
    Recurrence_status: "Yes",
    Survival_status: "Alive",
    caseId: "C3N-00511",
    Immune_subtype: "CD8-/IFNG+",
  },
  {
    Tumor: "HNSCC",
    Sex: "Male",
    Age: "63-70 (50%-75%)",
    Participant_Country: "Poland",
    Grade: "G2",
    Stage: "IV",
    BMI: "25-30",
    Alcohol_consumption: "drinker",
    Tobacco_use: "past-smoker",
    Secondhand_smoke_exposure: "No",
    Overall_survival_in_days: ">=1016 (>=75%)",
    Recurrence_status: "Yes",
    Survival_status: "Dead",
    caseId: "C3N-04276",
    Immune_subtype: "CD8-/IFNG-",
  },
  {
    Tumor: "COAD",
    Sex: "Female",
    Age: ">=70 (>=75%)",
    Participant_Country: "not_reported",
    Grade: "not_reported",
    Stage: "I",
    BMI: "not_reported",
    Alcohol_consumption: "not_reported",
    Tobacco_use: "not_reported",
    Secondhand_smoke_exposure: "not_reported",
    Overall_survival_in_days: "348-609 (25%-50%)",
    Recurrence_status: "No",
    Survival_status: "Dead",
    caseId: "01CO022",
    Immune_subtype: "CD8-/IFNG-",
  },
  {
    Tumor: "GBM",
    Sex: "Male",
    Age: "55-63 (25%-50%)",
    Participant_Country: "United States",
    Grade: "not_reported",
    Stage: "not_reported",
    BMI: "18.5-25",
    Alcohol_consumption: "drinker",
    Tobacco_use: "non-smoker",
    Secondhand_smoke_exposure: "not_reported",
    Overall_survival_in_days: "<348 (25%)",
    Recurrence_status: "No",
    Survival_status: "Dead",
    caseId: "C3L-02707",
    Immune_subtype: "CD8-/IFNG-",
  },
  {
    Tumor: "COAD",
    Sex: "Female",
    Age: "63-70 (50%-75%)",
    Participant_Country: "not_reported",
    Grade: "not_reported",
    Stage: "II",
    BMI: "not_reported",
    Alcohol_consumption: "not_reported",
    Tobacco_use: "not_reported",
    Secondhand_smoke_exposure: "not_reported",
    Overall_survival_in_days: "348-609 (25%-50%)",
    Recurrence_status: "No",
    Survival_status: "Dead",
    caseId: "11CO007",
    Immune_subtype: "Fibroblast/TGFBeta",
  },
  {
    Tumor: "PDAC",
    Sex: "Female",
    Age: ">=70 (>=75%)",
    Participant_Country: "not_reported",
    Grade: "G2",
    Stage: "II",
    BMI: ">=30",
    Alcohol_consumption: "non-drinker",
    Tobacco_use: "non-smoker",
    Secondhand_smoke_exposure: "not_reported",
    Overall_survival_in_days: "348-609 (25%-50%)",
    Recurrence_status: "Yes",
    Survival_status: "Alive",
    caseId: "C3L-01687",
    Immune_subtype: "Eosinophils/Endothelial",
  },
  {
    Tumor: "GBM",
    Sex: "Male",
    Age: "<55 (25%)",
    Participant_Country: "China",
    Grade: "not_reported",
    Stage: "not_reported",
    BMI: "18.5-25",
    Alcohol_consumption: "non-drinker",
    Tobacco_use: "non-smoker",
    Secondhand_smoke_exposure: "not_reported",
    Overall_survival_in_days: "<348 (25%)",
    Recurrence_status: "Yes",
    Survival_status: "Alive",
    caseId: "C3N-02190",
    Immune_subtype: "CD8+/IFNG+",
  },
  {
    Tumor: "BRCA",
    Sex: "Female",
    Age: "<55 (25%)",
    Participant_Country: "not_reported",
    Grade: "not_reported",
    Stage: "II",
    BMI: "not_reported",
    Alcohol_consumption: "not_reported",
    Tobacco_use: "not_reported",
    Secondhand_smoke_exposure: "not_reported",
    Overall_survival_in_days: "348-609 (25%-50%)",
    Recurrence_status: "No",
    Survival_status: "Dead",
    caseId: "06BR014",
    Immune_subtype: "CD8-/IFNG-",
  },
  {
    Tumor: "COAD",
    Sex: "Female",
    Age: "63-70 (50%-75%)",
    Participant_Country: "not_reported",
    Grade: "not_reported",
    Stage: "II",
    BMI: "not_reported",
    Alcohol_consumption: "not_reported",
    Tobacco_use: "not_reported",
    Secondhand_smoke_exposure: "not_reported",
    Overall_survival_in_days: "348-609 (25%-50%)",
    Recurrence_status: "No",
    Survival_status: "Dead",
    caseId: "11CO043",
    Immune_subtype: "Fibroblast/TGFBeta",
  },
  {
    Tumor: "LSCC",
    Sex: "Female",
    Age: ">=70 (>=75%)",
    Participant_Country: "Poland",
    Grade: "G2",
    Stage: "II",
    BMI: "25-30",
    Alcohol_consumption: "not_reported",
    Tobacco_use: "past-smoker",
    Secondhand_smoke_exposure: "not_reported",
    Overall_survival_in_days: ">=1016 (>=75%)",
    Recurrence_status: "No",
    Survival_status: "Dead",
    caseId: "C3N-02285",
    Immune_subtype: "Fibroblast/TGFBeta",
  },
  {
    Tumor: "LSCC",
    Sex: "Male",
    Age: "<55 (25%)",
    Participant_Country: "Bulgaria",
    Grade: "G2",
    Stage: "III",
    BMI: ">=30",
    Alcohol_consumption: "drinker",
    Tobacco_use: "past-smoker",
    Secondhand_smoke_exposure: "not_reported",
    Overall_survival_in_days: ">=1016 (>=75%)",
    Recurrence_status: "No",
    Survival_status: "Dead",
    caseId: "C3L-03965",
    Immune_subtype: "CD8-/IFNG-",
  },
  {
    Tumor: "LUAD",
    Sex: "Female",
    Age: "63-70 (50%-75%)",
    Participant_Country: "United States",
    Grade: "G1",
    Stage: "I",
    BMI: "18.5-25",
    Alcohol_consumption: "not_reported",
    Tobacco_use: "past-smoker",
    Secondhand_smoke_exposure: "not_reported",
    Overall_survival_in_days: "not_reported",
    Recurrence_status: "No",
    Survival_status: "not_reported",
    caseId: "C3N-00738",
    Immune_subtype: "Eosinophils/Endothelial",
  },
  {
    Tumor: "PDAC",
    Sex: "Male",
    Age: "63-70 (50%-75%)",
    Participant_Country: "Canada",
    Grade: "G3",
    Stage: "II",
    BMI: "18.5-25",
    Alcohol_consumption: "past-drinker",
    Tobacco_use: "past-smoker",
    Secondhand_smoke_exposure: "not_reported",
    Overall_survival_in_days: ">=1016 (>=75%)",
    Recurrence_status: "Yes",
    Survival_status: "Alive",
    caseId: "C3L-02890",
    Immune_subtype: "CD8-/IFNG+",
  },
  {
    Tumor: "PDAC",
    Sex: "Male",
    Age: "55-63 (25%-50%)",
    Participant_Country: "Poland",
    Grade: "G3",
    Stage: "IV",
    BMI: "25-30",
    Alcohol_consumption: "non-drinker",
    Tobacco_use: "non-smoker",
    Secondhand_smoke_exposure: "No",
    Overall_survival_in_days: "348-609 (25%-50%)",
    Recurrence_status: "Yes",
    Survival_status: "Alive",
    caseId: "C3N-03000",
    Immune_subtype: "Fibroblast/TGFBeta",
  },
  {
    Tumor: "LUAD",
    Sex: "Male",
    Age: "55-63 (25%-50%)",
    Participant_Country: "Poland",
    Grade: "not_reported",
    Stage: "II",
    BMI: "25-30",
    Alcohol_consumption: "not_reported",
    Tobacco_use: "past-smoker",
    Secondhand_smoke_exposure: "not_reported",
    Overall_survival_in_days: ">=1016 (>=75%)",
    Recurrence_status: "Yes",
    Survival_status: "Dead",
    caseId: "C3N-00294",
    Immune_subtype: "CD8-/IFNG-",
  },
  {
    Tumor: "HGSC",
    Sex: "Female",
    Age: "<55 (25%)",
    Participant_Country: "not_reported",
    Grade: "G3",
    Stage: "III",
    BMI: "not_reported",
    Alcohol_consumption: "not_reported",
    Tobacco_use: "not_reported",
    Secondhand_smoke_exposure: "not_reported",
    Overall_survival_in_days: "348-609 (25%-50%)",
    Recurrence_status: "No",
    Survival_status: "Dead",
    caseId: "04OV024",
    Immune_subtype: "CD8-/IFNG-",
  },
  {
    Tumor: "UCEC",
    Sex: "Female",
    Age: "63-70 (50%-75%)",
    Participant_Country: "United States",
    Grade: "G1",
    Stage: "I",
    BMI: ">=30",
    Alcohol_consumption: "non-drinker",
    Tobacco_use: "non-smoker",
    Secondhand_smoke_exposure: "not_reported",
    Overall_survival_in_days: ">=1016 (>=75%)",
    Recurrence_status: "No",
    Survival_status: "Dead",
    caseId: "C3L-00942",
    Immune_subtype: "Fibroblast/TGFBeta",
  },
  {
    Tumor: "GBM",
    Sex: "Male",
    Age: "<55 (25%)",
    Participant_Country: "China",
    Grade: "not_reported",
    Stage: "not_reported",
    BMI: "25-30",
    Alcohol_consumption: "non-drinker",
    Tobacco_use: "non-smoker",
    Secondhand_smoke_exposure: "No",
    Overall_survival_in_days: "<348 (25%)",
    Recurrence_status: "No",
    Survival_status: "Alive",
    caseId: "C3N-03183",
    Immune_subtype: "CD8+/IFNG+",
  },
  {
    Tumor: "COAD",
    Sex: "Female",
    Age: "55-63 (25%-50%)",
    Participant_Country: "not_reported",
    Grade: "not_reported",
    Stage: "II",
    BMI: "not_reported",
    Alcohol_consumption: "not_reported",
    Tobacco_use: "not_reported",
    Secondhand_smoke_exposure: "not_reported",
    Overall_survival_in_days: "348-609 (25%-50%)",
    Recurrence_status: "No",
    Survival_status: "Dead",
    caseId: "11CO010",
    Immune_subtype: "CD8-/IFNG+",
  },
  {
    Tumor: "BRCA",
    Sex: "Female",
    Age: ">=70 (>=75%)",
    Participant_Country: "not_reported",
    Grade: "not_reported",
    Stage: "II",
    BMI: "not_reported",
    Alcohol_consumption: "not_reported",
    Tobacco_use: "not_reported",
    Secondhand_smoke_exposure: "not_reported",
    Overall_survival_in_days: "348-609 (25%-50%)",
    Recurrence_status: "No",
    Survival_status: "Dead",
    caseId: "11BR027",
    Immune_subtype: "CD8-/IFNG-",
  },
  {
    Tumor: "UCEC",
    Sex: "Female",
    Age: ">=70 (>=75%)",
    Participant_Country: "United States",
    Grade: "G1",
    Stage: "I",
    BMI: "<18.5",
    Alcohol_consumption: "not_reported",
    Tobacco_use: "non-smoker",
    Secondhand_smoke_exposure: "not_reported",
    Overall_survival_in_days: "348-609 (25%-50%)",
    Recurrence_status: "No",
    Survival_status: "Dead",
    caseId: "C3L-01257",
    Immune_subtype: "CD8-/IFNG+",
  },
  {
    Tumor: "LUAD",
    Sex: "Male",
    Age: "<55 (25%)",
    Participant_Country: "Vietnam",
    Grade: "G3",
    Stage: "III",
    BMI: "18.5-25",
    Alcohol_consumption: "drinker",
    Tobacco_use: "current smoker",
    Secondhand_smoke_exposure: "No",
    Overall_survival_in_days: "348-609 (25%-50%)",
    Recurrence_status: "No",
    Survival_status: "Dead",
    caseId: "C3N-00560",
    Immune_subtype: "CD8-/IFNG-",
  },
  {
    Tumor: "PDAC",
    Sex: "Male",
    Age: "63-70 (50%-75%)",
    Participant_Country: "China",
    Grade: "G2",
    Stage: "I",
    BMI: "25-30",
    Alcohol_consumption: "drinker",
    Tobacco_use: "past-smoker",
    Secondhand_smoke_exposure: "not_reported",
    Overall_survival_in_days: ">=1016 (>=75%)",
    Recurrence_status: "Yes",
    Survival_status: "Alive",
    caseId: "C3L-02116",
    Immune_subtype: "CD8+/IFNG+",
  },
  {
    Tumor: "PDAC",
    Sex: "Male",
    Age: ">=70 (>=75%)",
    Participant_Country: "Canada",
    Grade: "G2",
    Stage: "II",
    BMI: ">=30",
    Alcohol_consumption: "not_reported",
    Tobacco_use: "past-smoker",
    Secondhand_smoke_exposure: "not_reported",
    Overall_survival_in_days: "<348 (25%)",
    Recurrence_status: "No",
    Survival_status: "Dead",
    caseId: "C3L-03630",
    Immune_subtype: "CD8-/IFNG+",
  },
  {
    Tumor: "UCEC",
    Sex: "Female",
    Age: "55-63 (25%-50%)",
    Participant_Country: "Ukraine",
    Grade: "G3",
    Stage: "I",
    BMI: "25-30",
    Alcohol_consumption: "non-drinker",
    Tobacco_use: "not_reported",
    Secondhand_smoke_exposure: "not_reported",
    Overall_survival_in_days: "not_reported",
    Recurrence_status: "No",
    Survival_status: "Dead",
    caseId: "C3N-00151",
    Immune_subtype: "CD8-/IFNG-",
  },
  {
    Tumor: "GBM",
    Sex: "Female",
    Age: ">=70 (>=75%)",
    Participant_Country: "China",
    Grade: "not_reported",
    Stage: "not_reported",
    BMI: "18.5-25",
    Alcohol_consumption: "non-drinker",
    Tobacco_use: "non-smoker",
    Secondhand_smoke_exposure: "No",
    Overall_survival_in_days: "<348 (25%)",
    Recurrence_status: "No",
    Survival_status: "Alive",
    caseId: "C3N-01815",
    Immune_subtype: "CD8+/IFNG+",
  },
  {
    Tumor: "HNSCC",
    Sex: "Male",
    Age: "55-63 (25%-50%)",
    Participant_Country: "China",
    Grade: "G3",
    Stage: "III",
    BMI: "18.5-25",
    Alcohol_consumption: "drinker",
    Tobacco_use: "current smoker",
    Secondhand_smoke_exposure: "not_reported",
    Overall_survival_in_days: "609-1016 (50%-75%)",
    Recurrence_status: "Yes",
    Survival_status: "Alive",
    caseId: "C3N-03841",
    Immune_subtype: "CD8+/IFNG+",
  },
  {
    Tumor: "ccRCC",
    Sex: "Male",
    Age: "<55 (25%)",
    Participant_Country: "United States",
    Grade: "G3",
    Stage: "I",
    BMI: ">=30",
    Alcohol_consumption: "non-drinker",
    Tobacco_use: "non-smoker",
    Secondhand_smoke_exposure: "not_reported",
    Overall_survival_in_days: "609-1016 (50%-75%)",
    Recurrence_status: "No",
    Survival_status: "Dead",
    caseId: "C3L-01283",
    Immune_subtype: "CD8+/IFNG+",
  },
  {
    Tumor: "GBM",
    Sex: "Female",
    Age: ">=70 (>=75%)",
    Participant_Country: "United States",
    Grade: "not_reported",
    Stage: "not_reported",
    BMI: ">=30",
    Alcohol_consumption: "non-drinker",
    Tobacco_use: "not_reported",
    Secondhand_smoke_exposure: "not_reported",
    Overall_survival_in_days: "348-609 (25%-50%)",
    Recurrence_status: "Yes",
    Survival_status: "Alive",
    caseId: "C3L-02955",
    Immune_subtype: "Brain/Neuro",
  },
  {
    Tumor: "UCEC",
    Sex: "Female",
    Age: "55-63 (25%-50%)",
    Participant_Country: "Poland",
    Grade: "G3",
    Stage: "III",
    BMI: ">=30",
    Alcohol_consumption: "non-drinker",
    Tobacco_use: "current smoker",
    Secondhand_smoke_exposure: "Yes",
    Overall_survival_in_days: ">=1016 (>=75%)",
    Recurrence_status: "No",
    Survival_status: "Dead",
    caseId: "C3N-01346",
    Immune_subtype: "CD8+/IFNG+",
  },
  {
    Tumor: "HNSCC",
    Sex: "Male",
    Age: "55-63 (25%-50%)",
    Participant_Country: "Ukraine",
    Grade: "G2",
    Stage: "II",
    BMI: "18.5-25",
    Alcohol_consumption: "drinker",
    Tobacco_use: "current smoker",
    Secondhand_smoke_exposure: "not_reported",
    Overall_survival_in_days: "<348 (25%)",
    Recurrence_status: "No",
    Survival_status: "Dead",
    caseId: "C3N-03619",
    Immune_subtype: "Fibroblast/TGFBeta",
  },
  {
    Tumor: "PDAC",
    Sex: "Female",
    Age: "55-63 (25%-50%)",
    Participant_Country: "China",
    Grade: "G3",
    Stage: "II",
    BMI: "18.5-25",
    Alcohol_consumption: "non-drinker",
    Tobacco_use: "non-smoker",
    Secondhand_smoke_exposure: "not_reported",
    Overall_survival_in_days: "<348 (25%)",
    Recurrence_status: "Yes",
    Survival_status: "Alive",
    caseId: "C3N-03061",
    Immune_subtype: "CD8-/IFNG+",
  },
  {
    Tumor: "ccRCC",
    Sex: "Male",
    Age: ">=70 (>=75%)",
    Participant_Country: "United States",
    Grade: "G3",
    Stage: "III",
    BMI: "25-30",
    Alcohol_consumption: "drinker",
    Tobacco_use: "non-smoker",
    Secondhand_smoke_exposure: "not_reported",
    Overall_survival_in_days: ">=1016 (>=75%)",
    Recurrence_status: "No",
    Survival_status: "Dead",
    caseId: "C3L-01607",
    Immune_subtype: "CD8+/IFNG+",
  },
  {
    Tumor: "ccRCC",
    Sex: "Female",
    Age: "<55 (25%)",
    Participant_Country: "United States",
    Grade: "G2",
    Stage: "III",
    BMI: ">=30",
    Alcohol_consumption: "drinker",
    Tobacco_use: "current smoker",
    Secondhand_smoke_exposure: "not_reported",
    Overall_survival_in_days: "<348 (25%)",
    Recurrence_status: "No",
    Survival_status: "Dead",
    caseId: "C3L-00183",
    Immune_subtype: "ccRCC/Endothelial",
  },
  {
    Tumor: "PDAC",
    Sex: "Female",
    Age: "55-63 (25%-50%)",
    Participant_Country: "China",
    Grade: "G2",
    Stage: "II",
    BMI: "18.5-25",
    Alcohol_consumption: "non-drinker",
    Tobacco_use: "non-smoker",
    Secondhand_smoke_exposure: "not_reported",
    Overall_survival_in_days: "609-1016 (50%-75%)",
    Recurrence_status: "No",
    Survival_status: "Dead",
    caseId: "C3N-03840",
    Immune_subtype: "CD8-/IFNG+",
  },
  {
    Tumor: "GBM",
    Sex: "Female",
    Age: "<55 (25%)",
    Participant_Country: "China",
    Grade: "not_reported",
    Stage: "not_reported",
    BMI: "25-30",
    Alcohol_consumption: "non-drinker",
    Tobacco_use: "non-smoker",
    Secondhand_smoke_exposure: "No",
    Overall_survival_in_days: "609-1016 (50%-75%)",
    Recurrence_status: "Yes",
    Survival_status: "Alive",
    caseId: "C3N-01816",
    Immune_subtype: "CD8-/IFNG+",
  },
  {
    Tumor: "LUAD",
    Sex: "Female",
    Age: "63-70 (50%-75%)",
    Participant_Country: "United States",
    Grade: "G2",
    Stage: "I",
    BMI: ">=30",
    Alcohol_consumption: "drinker",
    Tobacco_use: "past-smoker",
    Secondhand_smoke_exposure: "Yes",
    Overall_survival_in_days: ">=1016 (>=75%)",
    Recurrence_status: "No",
    Survival_status: "Dead",
    caseId: "C3L-01330",
    Immune_subtype: "CD8-/IFNG+",
  },
  {
    Tumor: "LSCC",
    Sex: "Male",
    Age: "63-70 (50%-75%)",
    Participant_Country: "Bulgaria",
    Grade: "G3",
    Stage: "III",
    BMI: ">=30",
    Alcohol_consumption: "non-drinker",
    Tobacco_use: "current smoker",
    Secondhand_smoke_exposure: "not_reported",
    Overall_survival_in_days: "348-609 (25%-50%)",
    Recurrence_status: "No",
    Survival_status: "Dead",
    caseId: "C3L-02619",
    Immune_subtype: "Fibroblast/TGFBeta",
  },
  {
    Tumor: "LSCC",
    Sex: "Male",
    Age: "63-70 (50%-75%)",
    Participant_Country: "Ukraine",
    Grade: "G2",
    Stage: "I",
    BMI: "25-30",
    Alcohol_consumption: "non-drinker",
    Tobacco_use: "non-smoker",
    Secondhand_smoke_exposure: "not_reported",
    Overall_survival_in_days: "348-609 (25%-50%)",
    Recurrence_status: "Yes",
    Survival_status: "Alive",
    caseId: "C3N-01846",
    Immune_subtype: "CD8-/IFNG-",
  },
  {
    Tumor: "PDAC",
    Sex: "Male",
    Age: ">=70 (>=75%)",
    Participant_Country: "Poland",
    Grade: "G2",
    Stage: "II",
    BMI: "18.5-25",
    Alcohol_consumption: "non-drinker",
    Tobacco_use: "past-smoker",
    Secondhand_smoke_exposure: "No",
    Overall_survival_in_days: "348-609 (25%-50%)",
    Recurrence_status: "Yes",
    Survival_status: "Alive",
    caseId: "C3N-02589",
    Immune_subtype: "CD8-/IFNG+",
  },
  {
    Tumor: "ccRCC",
    Sex: "Female",
    Age: "55-63 (25%-50%)",
    Participant_Country: "United States",
    Grade: "G2",
    Stage: "I",
    BMI: ">=30",
    Alcohol_consumption: "not_reported",
    Tobacco_use: "not_reported",
    Secondhand_smoke_exposure: "not_reported",
    Overall_survival_in_days: "<348 (25%)",
    Recurrence_status: "No",
    Survival_status: "Dead",
    caseId: "C3L-00766",
    Immune_subtype: "ccRCC/Endothelial",
  },
  {
    Tumor: "PDAC",
    Sex: "Female",
    Age: "63-70 (50%-75%)",
    Participant_Country: "Poland",
    Grade: "G2",
    Stage: "I",
    BMI: ">=30",
    Alcohol_consumption: "non-drinker",
    Tobacco_use: "non-smoker",
    Secondhand_smoke_exposure: "No",
    Overall_survival_in_days: "<348 (25%)",
    Recurrence_status: "No",
    Survival_status: "Alive",
    caseId: "C3N-03780",
    Immune_subtype: "Eosinophils/Endothelial",
  },
  {
    Tumor: "COAD",
    Sex: "Male",
    Age: "55-63 (25%-50%)",
    Participant_Country: "not_reported",
    Grade: "not_reported",
    Stage: "IV",
    BMI: "not_reported",
    Alcohol_consumption: "not_reported",
    Tobacco_use: "not_reported",
    Secondhand_smoke_exposure: "not_reported",
    Overall_survival_in_days: "<348 (25%)",
    Recurrence_status: "No",
    Survival_status: "Alive",
    caseId: "20CO003",
    Immune_subtype: "CD8+/IFNG+",
  },
  {
    Tumor: "LUAD",
    Sex: "Male",
    Age: ">=70 (>=75%)",
    Participant_Country: "United States",
    Grade: "G2",
    Stage: "II",
    BMI: "18.5-25",
    Alcohol_consumption: "drinker",
    Tobacco_use: "current smoker",
    Secondhand_smoke_exposure: "not_reported",
    Overall_survival_in_days: ">=1016 (>=75%)",
    Recurrence_status: "No",
    Survival_status: "Dead",
    caseId: "C3L-00263",
    Immune_subtype: "CD8+/IFNG+",
  },
  {
    Tumor: "PDAC",
    Sex: "Male",
    Age: "55-63 (25%-50%)",
    Participant_Country: "Poland",
    Grade: "G2",
    Stage: "III",
    BMI: "25-30",
    Alcohol_consumption: "non-drinker",
    Tobacco_use: "non-smoker",
    Secondhand_smoke_exposure: "No",
    Overall_survival_in_days: "609-1016 (50%-75%)",
    Recurrence_status: "Yes",
    Survival_status: "Alive",
    caseId: "C3N-03666",
    Immune_subtype: "Fibroblast/TGFBeta",
  },
  {
    Tumor: "COAD",
    Sex: "Female",
    Age: ">=70 (>=75%)",
    Participant_Country: "not_reported",
    Grade: "not_reported",
    Stage: "III",
    BMI: "not_reported",
    Alcohol_consumption: "not_reported",
    Tobacco_use: "not_reported",
    Secondhand_smoke_exposure: "not_reported",
    Overall_survival_in_days: "348-609 (25%-50%)",
    Recurrence_status: "No",
    Survival_status: "Dead",
    caseId: "11CO019",
    Immune_subtype: "Fibroblast/TGFBeta",
  },
  {
    Tumor: "BRCA",
    Sex: "Female",
    Age: "<55 (25%)",
    Participant_Country: "not_reported",
    Grade: "not_reported",
    Stage: "II",
    BMI: "not_reported",
    Alcohol_consumption: "not_reported",
    Tobacco_use: "not_reported",
    Secondhand_smoke_exposure: "not_reported",
    Overall_survival_in_days: "348-609 (25%-50%)",
    Recurrence_status: "No",
    Survival_status: "Dead",
    caseId: "11BR030",
    Immune_subtype: "CD8+/IFNG+",
  },
  {
    Tumor: "HGSC",
    Sex: "Female",
    Age: "<55 (25%)",
    Participant_Country: "not_reported",
    Grade: "G3",
    Stage: "III",
    BMI: "not_reported",
    Alcohol_consumption: "not_reported",
    Tobacco_use: "not_reported",
    Secondhand_smoke_exposure: "not_reported",
    Overall_survival_in_days: "348-609 (25%-50%)",
    Recurrence_status: "Yes",
    Survival_status: "Dead",
    caseId: "01OV018",
    Immune_subtype: "Fibroblast/TGFBeta",
  },
  {
    Tumor: "UCEC",
    Sex: "Female",
    Age: ">=70 (>=75%)",
    Participant_Country: "United States",
    Grade: "not_reported",
    Stage: "III",
    BMI: "18.5-25",
    Alcohol_consumption: "not_reported",
    Tobacco_use: "non-smoker",
    Secondhand_smoke_exposure: "not_reported",
    Overall_survival_in_days: "348-609 (25%-50%)",
    Recurrence_status: "No",
    Survival_status: "Alive",
    caseId: "C3L-00139",
    Immune_subtype: "CD8-/IFNG-",
  },
  {
    Tumor: "LSCC",
    Sex: "Male",
    Age: ">=70 (>=75%)",
    Participant_Country: "United States",
    Grade: "G2",
    Stage: "I",
    BMI: "18.5-25",
    Alcohol_consumption: "past-drinker",
    Tobacco_use: "current smoker",
    Secondhand_smoke_exposure: "not_reported",
    Overall_survival_in_days: ">=1016 (>=75%)",
    Recurrence_status: "No",
    Survival_status: "Dead",
    caseId: "C3L-02963",
    Immune_subtype: "Eosinophils/Endothelial",
  },
  {
    Tumor: "UCEC",
    Sex: "Female",
    Age: "55-63 (25%-50%)",
    Participant_Country: "Ukraine",
    Grade: "G2",
    Stage: "I",
    BMI: "18.5-25",
    Alcohol_consumption: "non-drinker",
    Tobacco_use: "non-smoker",
    Secondhand_smoke_exposure: "not_reported",
    Overall_survival_in_days: "609-1016 (50%-75%)",
    Recurrence_status: "No",
    Survival_status: "Dead",
    caseId: "C3N-00388",
    Immune_subtype: "CD8-/IFNG-",
  },
  {
    Tumor: "COAD",
    Sex: "Female",
    Age: "<55 (25%)",
    Participant_Country: "not_reported",
    Grade: "not_reported",
    Stage: "II",
    BMI: "not_reported",
    Alcohol_consumption: "not_reported",
    Tobacco_use: "not_reported",
    Secondhand_smoke_exposure: "not_reported",
    Overall_survival_in_days: "348-609 (25%-50%)",
    Recurrence_status: "No",
    Survival_status: "Dead",
    caseId: "11CO030",
    Immune_subtype: "Fibroblast/TGFBeta",
  },
  {
    Tumor: "GBM",
    Sex: "Female",
    Age: "55-63 (25%-50%)",
    Participant_Country: "Poland",
    Grade: "not_reported",
    Stage: "not_reported",
    BMI: "18.5-25",
    Alcohol_consumption: "non-drinker",
    Tobacco_use: "non-smoker",
    Secondhand_smoke_exposure: "No",
    Overall_survival_in_days: "not_reported",
    Recurrence_status: "No",
    Survival_status: "not_reported",
    caseId: "C3N-01369",
    Immune_subtype: "CD8+/IFNG+",
  },
  {
    Tumor: "UCEC",
    Sex: "Female",
    Age: "55-63 (25%-50%)",
    Participant_Country: "United States",
    Grade: "G1",
    Stage: "I",
    BMI: "25-30",
    Alcohol_consumption: "drinker",
    Tobacco_use: "current smoker",
    Secondhand_smoke_exposure: "not_reported",
    Overall_survival_in_days: ">=1016 (>=75%)",
    Recurrence_status: "No",
    Survival_status: "Alive",
    caseId: "C3L-00961",
    Immune_subtype: "Fibroblast/TGFBeta",
  },
  {
    Tumor: "COAD",
    Sex: "Male",
    Age: "<55 (25%)",
    Participant_Country: "not_reported",
    Grade: "not_reported",
    Stage: "II",
    BMI: "not_reported",
    Alcohol_consumption: "not_reported",
    Tobacco_use: "not_reported",
    Secondhand_smoke_exposure: "not_reported",
    Overall_survival_in_days: "<348 (25%)",
    Recurrence_status: "No",
    Survival_status: "Dead",
    caseId: "22CO004",
    Immune_subtype: "CD8-/IFNG-",
  },
  {
    Tumor: "HNSCC",
    Sex: "Male",
    Age: ">=70 (>=75%)",
    Participant_Country: "Bulgaria",
    Grade: "G2",
    Stage: "I",
    BMI: "18.5-25",
    Alcohol_consumption: "non-drinker",
    Tobacco_use: "non-smoker",
    Secondhand_smoke_exposure: "No",
    Overall_survival_in_days: "<348 (25%)",
    Recurrence_status: "No",
    Survival_status: "Dead",
    caseId: "C3L-04791",
    Immune_subtype: "CD8-/IFNG+",
  },
  {
    Tumor: "BRCA",
    Sex: "Female",
    Age: "<55 (25%)",
    Participant_Country: "not_reported",
    Grade: "not_reported",
    Stage: "II",
    BMI: "not_reported",
    Alcohol_consumption: "not_reported",
    Tobacco_use: "not_reported",
    Secondhand_smoke_exposure: "not_reported",
    Overall_survival_in_days: "not_reported",
    Recurrence_status: "No",
    Survival_status: "not_reported",
    caseId: "01BR040",
    Immune_subtype: "CD8+/IFNG+",
  },
  {
    Tumor: "LUAD",
    Sex: "Female",
    Age: "55-63 (25%-50%)",
    Participant_Country: "Vietnam",
    Grade: "G3",
    Stage: "III",
    BMI: "18.5-25",
    Alcohol_consumption: "non-drinker",
    Tobacco_use: "non-smoker",
    Secondhand_smoke_exposure: "No",
    Overall_survival_in_days: "<348 (25%)",
    Recurrence_status: "No",
    Survival_status: "Alive",
    caseId: "C3N-02087",
    Immune_subtype: "CD8+/IFNG+",
  },
  {
    Tumor: "LSCC",
    Sex: "Male",
    Age: ">=70 (>=75%)",
    Participant_Country: "Ukraine",
    Grade: "G2",
    Stage: "I",
    BMI: ">=30",
    Alcohol_consumption: "drinker",
    Tobacco_use: "non-smoker",
    Secondhand_smoke_exposure: "not_reported",
    Overall_survival_in_days: "<348 (25%)",
    Recurrence_status: "No",
    Survival_status: "Dead",
    caseId: "C3N-04155",
    Immune_subtype: "CD8-/IFNG-",
  },
  {
    Tumor: "LUAD",
    Sex: "Male",
    Age: ">=70 (>=75%)",
    Participant_Country: "United States",
    Grade: "G2",
    Stage: "I",
    BMI: "25-30",
    Alcohol_consumption: "non-drinker",
    Tobacco_use: "past-smoker",
    Secondhand_smoke_exposure: "not_reported",
    Overall_survival_in_days: "348-609 (25%-50%)",
    Recurrence_status: "No",
    Survival_status: "Dead",
    caseId: "C3N-00737",
    Immune_subtype: "CD8+/IFNG+",
  },
  {
    Tumor: "ccRCC",
    Sex: "Male",
    Age: "55-63 (25%-50%)",
    Participant_Country: "Vietnam",
    Grade: "G2",
    Stage: "II",
    BMI: "18.5-25",
    Alcohol_consumption: "non-drinker",
    Tobacco_use: "non-smoker",
    Secondhand_smoke_exposure: "No",
    Overall_survival_in_days: "<348 (25%)",
    Recurrence_status: "No",
    Survival_status: "Dead",
    caseId: "C3N-00573",
    Immune_subtype: "Fibroblast/TGFBeta",
  },
  {
    Tumor: "UCEC",
    Sex: "Female",
    Age: "55-63 (25%-50%)",
    Participant_Country: "United States",
    Grade: "G1",
    Stage: "I",
    BMI: ">=30",
    Alcohol_consumption: "drinker",
    Tobacco_use: "past-smoker",
    Secondhand_smoke_exposure: "not_reported",
    Overall_survival_in_days: "609-1016 (50%-75%)",
    Recurrence_status: "No",
    Survival_status: "Dead",
    caseId: "C3L-00008",
    Immune_subtype: "CD8-/IFNG-",
  },
  {
    Tumor: "GBM",
    Sex: "Male",
    Age: "63-70 (50%-75%)",
    Participant_Country: "China",
    Grade: "not_reported",
    Stage: "not_reported",
    BMI: "25-30",
    Alcohol_consumption: "drinker",
    Tobacco_use: "past-smoker",
    Secondhand_smoke_exposure: "No",
    Overall_survival_in_days: "609-1016 (50%-75%)",
    Recurrence_status: "Yes",
    Survival_status: "Alive",
    caseId: "C3N-02770",
    Immune_subtype: "Brain/Neuro",
  },
  {
    Tumor: "LUAD",
    Sex: "Male",
    Age: "55-63 (25%-50%)",
    Participant_Country: "Vietnam",
    Grade: "G3",
    Stage: "I",
    BMI: "18.5-25",
    Alcohol_consumption: "drinker",
    Tobacco_use: "non-smoker",
    Secondhand_smoke_exposure: "No",
    Overall_survival_in_days: "348-609 (25%-50%)",
    Recurrence_status: "No",
    Survival_status: "Dead",
    caseId: "C3N-01030",
    Immune_subtype: "CD8+/IFNG+",
  },
  {
    Tumor: "HGSC",
    Sex: "Female",
    Age: "55-63 (25%-50%)",
    Participant_Country: "not_reported",
    Grade: "not_reported",
    Stage: "III",
    BMI: "not_reported",
    Alcohol_consumption: "not_reported",
    Tobacco_use: "not_reported",
    Secondhand_smoke_exposure: "not_reported",
    Overall_survival_in_days: "<348 (25%)",
    Recurrence_status: "No",
    Survival_status: "Dead",
    caseId: "17OV040",
    Immune_subtype: "CD8+/IFNG+",
  },
  {
    Tumor: "LSCC",
    Sex: "Male",
    Age: "55-63 (25%-50%)",
    Participant_Country: "Bulgaria",
    Grade: "G2",
    Stage: "II",
    BMI: "25-30",
    Alcohol_consumption: "drinker",
    Tobacco_use: "past-smoker",
    Secondhand_smoke_exposure: "Yes",
    Overall_survival_in_days: "348-609 (25%-50%)",
    Recurrence_status: "Yes",
    Survival_status: "Dead",
    caseId: "C3L-02164",
    Immune_subtype: "CD8+/IFNG+",
  },
  {
    Tumor: "UCEC",
    Sex: "Female",
    Age: "55-63 (25%-50%)",
    Participant_Country: "Ukraine",
    Grade: "G3",
    Stage: "I",
    BMI: "25-30",
    Alcohol_consumption: "non-drinker",
    Tobacco_use: "non-smoker",
    Secondhand_smoke_exposure: "not_reported",
    Overall_survival_in_days: "609-1016 (50%-75%)",
    Recurrence_status: "No",
    Survival_status: "Dead",
    caseId: "C3N-00335",
    Immune_subtype: "CD8-/IFNG+",
  },
  {
    Tumor: "BRCA",
    Sex: "Female",
    Age: "63-70 (50%-75%)",
    Participant_Country: "not_reported",
    Grade: "not_reported",
    Stage: "III",
    BMI: "not_reported",
    Alcohol_consumption: "not_reported",
    Tobacco_use: "not_reported",
    Secondhand_smoke_exposure: "not_reported",
    Overall_survival_in_days: "348-609 (25%-50%)",
    Recurrence_status: "No",
    Survival_status: "Dead",
    caseId: "11BR051",
    Immune_subtype: "Fibroblast/TGFBeta",
  },
  {
    Tumor: "HNSCC",
    Sex: "Female",
    Age: "<55 (25%)",
    Participant_Country: "Poland",
    Grade: "G2",
    Stage: "III",
    BMI: "25-30",
    Alcohol_consumption: "not_reported",
    Tobacco_use: "not_reported",
    Secondhand_smoke_exposure: "not_reported",
    Overall_survival_in_days: "609-1016 (50%-75%)",
    Recurrence_status: "No",
    Survival_status: "Alive",
    caseId: "C3N-00822",
    Immune_subtype: "CD8+/IFNG+",
  },
  {
    Tumor: "HNSCC",
    Sex: "Male",
    Age: "63-70 (50%-75%)",
    Participant_Country: "Poland",
    Grade: "G2",
    Stage: "III",
    BMI: "18.5-25",
    Alcohol_consumption: "drinker",
    Tobacco_use: "past-smoker",
    Secondhand_smoke_exposure: "Yes",
    Overall_survival_in_days: ">=1016 (>=75%)",
    Recurrence_status: "No",
    Survival_status: "Dead",
    caseId: "C3N-01754",
    Immune_subtype: "CD8-/IFNG-",
  },
  {
    Tumor: "HNSCC",
    Sex: "Male",
    Age: "<55 (25%)",
    Participant_Country: "Poland",
    Grade: "G2",
    Stage: "III",
    BMI: "25-30",
    Alcohol_consumption: "not_reported",
    Tobacco_use: "not_reported",
    Secondhand_smoke_exposure: "not_reported",
    Overall_survival_in_days: "609-1016 (50%-75%)",
    Recurrence_status: "No",
    Survival_status: "Dead",
    caseId: "C3N-00306",
    Immune_subtype: "CD8-/IFNG+",
  },
  {
    Tumor: "HNSCC",
    Sex: "Female",
    Age: "<55 (25%)",
    Participant_Country: "Ukraine",
    Grade: "G2",
    Stage: "IV",
    BMI: ">=30",
    Alcohol_consumption: "non-drinker",
    Tobacco_use: "non-smoker",
    Secondhand_smoke_exposure: "not_reported",
    Overall_survival_in_days: "609-1016 (50%-75%)",
    Recurrence_status: "Yes",
    Survival_status: "Dead",
    caseId: "C3N-03487",
    Immune_subtype: "CD8+/IFNG+",
  },
  {
    Tumor: "BRCA",
    Sex: "Female",
    Age: "55-63 (25%-50%)",
    Participant_Country: "not_reported",
    Grade: "not_reported",
    Stage: "III",
    BMI: "not_reported",
    Alcohol_consumption: "not_reported",
    Tobacco_use: "not_reported",
    Secondhand_smoke_exposure: "not_reported",
    Overall_survival_in_days: "348-609 (25%-50%)",
    Recurrence_status: "No",
    Survival_status: "Dead",
    caseId: "01BR025",
    Immune_subtype: "CD8-/IFNG-",
  },
  {
    Tumor: "LUAD",
    Sex: "Female",
    Age: "55-63 (25%-50%)",
    Participant_Country: "China",
    Grade: "G3",
    Stage: "IV",
    BMI: "25-30",
    Alcohol_consumption: "non-drinker",
    Tobacco_use: "non-smoker",
    Secondhand_smoke_exposure: "not_reported",
    Overall_survival_in_days: "609-1016 (50%-75%)",
    Recurrence_status: "Yes",
    Survival_status: "Alive",
    caseId: "C3N-02422",
    Immune_subtype: "CD8-/IFNG-",
  },
  {
    Tumor: "HGSC",
    Sex: "Female",
    Age: "63-70 (50%-75%)",
    Participant_Country: "not_reported",
    Grade: "not_reported",
    Stage: "not_reported",
    BMI: "not_reported",
    Alcohol_consumption: "not_reported",
    Tobacco_use: "not_reported",
    Secondhand_smoke_exposure: "not_reported",
    Overall_survival_in_days: "348-609 (25%-50%)",
    Recurrence_status: "No",
    Survival_status: "Dead",
    caseId: "17OV010",
    Immune_subtype: "CD8+/IFNG+",
  },
  {
    Tumor: "GBM",
    Sex: "Male",
    Age: "55-63 (25%-50%)",
    Participant_Country: "United States",
    Grade: "not_reported",
    Stage: "not_reported",
    BMI: ">=30",
    Alcohol_consumption: "non-drinker",
    Tobacco_use: "current smoker",
    Secondhand_smoke_exposure: "not_reported",
    Overall_survival_in_days: "348-609 (25%-50%)",
    Recurrence_status: "No",
    Survival_status: "Alive",
    caseId: "C3L-02970",
    Immune_subtype: "CD8-/IFNG-",
  },
  {
    Tumor: "BRCA",
    Sex: "Female",
    Age: "55-63 (25%-50%)",
    Participant_Country: "not_reported",
    Grade: "not_reported",
    Stage: "II",
    BMI: "not_reported",
    Alcohol_consumption: "not_reported",
    Tobacco_use: "not_reported",
    Secondhand_smoke_exposure: "not_reported",
    Overall_survival_in_days: "not_reported",
    Recurrence_status: "No",
    Survival_status: "not_reported",
    caseId: "05BR044",
    Immune_subtype: "CD8-/IFNG+",
  },
  {
    Tumor: "BRCA",
    Sex: "Female",
    Age: "63-70 (50%-75%)",
    Participant_Country: "not_reported",
    Grade: "not_reported",
    Stage: "not_reported",
    BMI: "not_reported",
    Alcohol_consumption: "not_reported",
    Tobacco_use: "not_reported",
    Secondhand_smoke_exposure: "not_reported",
    Overall_survival_in_days: "not_reported",
    Recurrence_status: "No",
    Survival_status: "not_reported",
    caseId: "01BR009",
    Immune_subtype: "not_reported",
  },
  {
    Tumor: "LUAD",
    Sex: "Male",
    Age: ">=70 (>=75%)",
    Participant_Country: "United States",
    Grade: "G1",
    Stage: "I",
    BMI: "25-30",
    Alcohol_consumption: "past-drinker",
    Tobacco_use: "past-smoker",
    Secondhand_smoke_exposure: "not_reported",
    Overall_survival_in_days: "<348 (25%)",
    Recurrence_status: "No",
    Survival_status: "Dead",
    caseId: "C3L-00893",
    Immune_subtype: "Eosinophils/Endothelial",
  },
  {
    Tumor: "HGSC",
    Sex: "Female",
    Age: "63-70 (50%-75%)",
    Participant_Country: "not_reported",
    Grade: "G3",
    Stage: "IV",
    BMI: "not_reported",
    Alcohol_consumption: "not_reported",
    Tobacco_use: "not_reported",
    Secondhand_smoke_exposure: "not_reported",
    Overall_survival_in_days: "348-609 (25%-50%)",
    Recurrence_status: "No",
    Survival_status: "Alive",
    caseId: "26OV008",
    Immune_subtype: "CD8-/IFNG-",
  },
  {
    Tumor: "COAD",
    Sex: "Male",
    Age: "63-70 (50%-75%)",
    Participant_Country: "not_reported",
    Grade: "not_reported",
    Stage: "II",
    BMI: "not_reported",
    Alcohol_consumption: "not_reported",
    Tobacco_use: "not_reported",
    Secondhand_smoke_exposure: "not_reported",
    Overall_survival_in_days: "348-609 (25%-50%)",
    Recurrence_status: "No",
    Survival_status: "Dead",
    caseId: "11CO042",
    Immune_subtype: "CD8-/IFNG-",
  },
  {
    Tumor: "LUAD",
    Sex: "Male",
    Age: "<55 (25%)",
    Participant_Country: "China",
    Grade: "G3",
    Stage: "II",
    BMI: "18.5-25",
    Alcohol_consumption: "non-drinker",
    Tobacco_use: "non-smoker",
    Secondhand_smoke_exposure: "not_reported",
    Overall_survival_in_days: ">=1016 (>=75%)",
    Recurrence_status: "No",
    Survival_status: "Dead",
    caseId: "C3N-02572",
    Immune_subtype: "CD8-/IFNG+",
  },
  {
    Tumor: "HNSCC",
    Sex: "Male",
    Age: "<55 (25%)",
    Participant_Country: "Poland",
    Grade: "G2",
    Stage: "IV",
    BMI: "18.5-25",
    Alcohol_consumption: "not_reported",
    Tobacco_use: "not_reported",
    Secondhand_smoke_exposure: "not_reported",
    Overall_survival_in_days: "609-1016 (50%-75%)",
    Recurrence_status: "Yes",
    Survival_status: "Alive",
    caseId: "C3N-00829",
    Immune_subtype: "Fibroblast/TGFBeta",
  },
  {
    Tumor: "LSCC",
    Sex: "Female",
    Age: "63-70 (50%-75%)",
    Participant_Country: "United States",
    Grade: "G2",
    Stage: "II",
    BMI: "18.5-25",
    Alcohol_consumption: "drinker",
    Tobacco_use: "past-smoker",
    Secondhand_smoke_exposure: "not_reported",
    Overall_survival_in_days: ">=1016 (>=75%)",
    Recurrence_status: "Yes",
    Survival_status: "Dead",
    caseId: "C3N-02435",
    Immune_subtype: "CD8-/IFNG-",
  },
  {
    Tumor: "PDAC",
    Sex: "Female",
    Age: "<55 (25%)",
    Participant_Country: "not_reported",
    Grade: "G2",
    Stage: "II",
    BMI: "18.5-25",
    Alcohol_consumption: "drinker",
    Tobacco_use: "current smoker",
    Secondhand_smoke_exposure: "not_reported",
    Overall_survival_in_days: ">=1016 (>=75%)",
    Recurrence_status: "Yes",
    Survival_status: "Alive",
    caseId: "C3L-00625",
    Immune_subtype: "Fibroblast/TGFBeta",
  },
  {
    Tumor: "COAD",
    Sex: "Female",
    Age: "55-63 (25%-50%)",
    Participant_Country: "not_reported",
    Grade: "not_reported",
    Stage: "III",
    BMI: "not_reported",
    Alcohol_consumption: "not_reported",
    Tobacco_use: "not_reported",
    Secondhand_smoke_exposure: "not_reported",
    Overall_survival_in_days: "348-609 (25%-50%)",
    Recurrence_status: "No",
    Survival_status: "Dead",
    caseId: "15CO001",
    Immune_subtype: "CD8-/IFNG-",
  },
  {
    Tumor: "ccRCC",
    Sex: "Male",
    Age: "<55 (25%)",
    Participant_Country: "United States",
    Grade: "G4",
    Stage: "IV",
    BMI: "25-30",
    Alcohol_consumption: "non-drinker",
    Tobacco_use: "non-smoker",
    Secondhand_smoke_exposure: "not_reported",
    Overall_survival_in_days: "<348 (25%)",
    Recurrence_status: "No",
    Survival_status: "Alive",
    caseId: "C3L-00096",
    Immune_subtype: "CD8-/IFNG+",
  },
  {
    Tumor: "BRCA",
    Sex: "Female",
    Age: "55-63 (25%-50%)",
    Participant_Country: "not_reported",
    Grade: "not_reported",
    Stage: "II",
    BMI: "not_reported",
    Alcohol_consumption: "not_reported",
    Tobacco_use: "not_reported",
    Secondhand_smoke_exposure: "not_reported",
    Overall_survival_in_days: "not_reported",
    Recurrence_status: "No",
    Survival_status: "not_reported",
    caseId: "01BR043",
    Immune_subtype: "CD8+/IFNG+",
  },
  {
    Tumor: "BRCA",
    Sex: "Female",
    Age: "<55 (25%)",
    Participant_Country: "not_reported",
    Grade: "not_reported",
    Stage: "III",
    BMI: "not_reported",
    Alcohol_consumption: "not_reported",
    Tobacco_use: "not_reported",
    Secondhand_smoke_exposure: "not_reported",
    Overall_survival_in_days: "348-609 (25%-50%)",
    Recurrence_status: "No",
    Survival_status: "Dead",
    caseId: "16BR012",
    Immune_subtype: "CD8-/IFNG-",
  },
  {
    Tumor: "HNSCC",
    Sex: "Male",
    Age: "<55 (25%)",
    Participant_Country: "not_reported",
    Grade: "G2",
    Stage: "IV",
    BMI: "18.5-25",
    Alcohol_consumption: "drinker",
    Tobacco_use: "non-smoker",
    Secondhand_smoke_exposure: "not_reported",
    Overall_survival_in_days: "609-1016 (50%-75%)",
    Recurrence_status: "No",
    Survival_status: "Dead",
    caseId: "C3N-04152",
    Immune_subtype: "CD8-/IFNG-",
  },
  {
    Tumor: "UCEC",
    Sex: "Female",
    Age: "55-63 (25%-50%)",
    Participant_Country: "Ukraine",
    Grade: "G2",
    Stage: "I",
    BMI: "<18.5",
    Alcohol_consumption: "non-drinker",
    Tobacco_use: "non-smoker",
    Secondhand_smoke_exposure: "not_reported",
    Overall_survival_in_days: "609-1016 (50%-75%)",
    Recurrence_status: "No",
    Survival_status: "Dead",
    caseId: "C3N-00389",
    Immune_subtype: "CD8-/IFNG+",
  },
  {
    Tumor: "HNSCC",
    Sex: "Male",
    Age: "63-70 (50%-75%)",
    Participant_Country: "Poland",
    Grade: "G2",
    Stage: "IV",
    BMI: ">=30",
    Alcohol_consumption: "not_reported",
    Tobacco_use: "not_reported",
    Secondhand_smoke_exposure: "not_reported",
    Overall_survival_in_days: ">=1016 (>=75%)",
    Recurrence_status: "No",
    Survival_status: "Dead",
    caseId: "C3N-02275",
    Immune_subtype: "CD8+/IFNG+",
  },
  {
    Tumor: "HNSCC",
    Sex: "Male",
    Age: "63-70 (50%-75%)",
    Participant_Country: "Poland",
    Grade: "G1",
    Stage: "II",
    BMI: ">=30",
    Alcohol_consumption: "drinker",
    Tobacco_use: "past-smoker",
    Secondhand_smoke_exposure: "No",
    Overall_survival_in_days: ">=1016 (>=75%)",
    Recurrence_status: "No",
    Survival_status: "Dead",
    caseId: "C3N-04280",
    Immune_subtype: "CD8-/IFNG-",
  },
  {
    Tumor: "BRCA",
    Sex: "Female",
    Age: "<55 (25%)",
    Participant_Country: "not_reported",
    Grade: "not_reported",
    Stage: "II",
    BMI: "not_reported",
    Alcohol_consumption: "not_reported",
    Tobacco_use: "not_reported",
    Secondhand_smoke_exposure: "not_reported",
    Overall_survival_in_days: "348-609 (25%-50%)",
    Recurrence_status: "No",
    Survival_status: "Dead",
    caseId: "01BR001",
    Immune_subtype: "Fibroblast/TGFBeta",
  },
  {
    Tumor: "LSCC",
    Sex: "Male",
    Age: ">=70 (>=75%)",
    Participant_Country: "United States",
    Grade: "G2",
    Stage: "I",
    BMI: ">=30",
    Alcohol_consumption: "drinker",
    Tobacco_use: "past-smoker",
    Secondhand_smoke_exposure: "not_reported",
    Overall_survival_in_days: ">=1016 (>=75%)",
    Recurrence_status: "No",
    Survival_status: "Dead",
    caseId: "C3N-02434",
    Immune_subtype: "CD8-/IFNG-",
  },
  {
    Tumor: "LSCC",
    Sex: "Female",
    Age: "63-70 (50%-75%)",
    Participant_Country: "United States",
    Grade: "G3",
    Stage: "II",
    BMI: "18.5-25",
    Alcohol_consumption: "non-drinker",
    Tobacco_use: "past-smoker",
    Secondhand_smoke_exposure: "not_reported",
    Overall_survival_in_days: ">=1016 (>=75%)",
    Recurrence_status: "No",
    Survival_status: "Dead",
    caseId: "C3L-02891",
    Immune_subtype: "CD8+/IFNG+",
  },
  {
    Tumor: "PDAC",
    Sex: "Male",
    Age: "55-63 (25%-50%)",
    Participant_Country: "Poland",
    Grade: "G2",
    Stage: "III",
    BMI: "18.5-25",
    Alcohol_consumption: "drinker",
    Tobacco_use: "not_reported",
    Secondhand_smoke_exposure: "not_reported",
    Overall_survival_in_days: "<348 (25%)",
    Recurrence_status: "No",
    Survival_status: "Alive",
    caseId: "C3N-03007",
    Immune_subtype: "Fibroblast/TGFBeta",
  },
  {
    Tumor: "LSCC",
    Sex: "Male",
    Age: "<55 (25%)",
    Participant_Country: "Ukraine",
    Grade: "G2",
    Stage: "II",
    BMI: "18.5-25",
    Alcohol_consumption: "non-drinker",
    Tobacco_use: "non-smoker",
    Secondhand_smoke_exposure: "not_reported",
    Overall_survival_in_days: "348-609 (25%-50%)",
    Recurrence_status: "No",
    Survival_status: "Dead",
    caseId: "C3N-04162",
    Immune_subtype: "CD8-/IFNG-",
  },
  {
    Tumor: "COAD",
    Sex: "Male",
    Age: "<55 (25%)",
    Participant_Country: "not_reported",
    Grade: "not_reported",
    Stage: "III",
    BMI: "not_reported",
    Alcohol_consumption: "not_reported",
    Tobacco_use: "not_reported",
    Secondhand_smoke_exposure: "not_reported",
    Overall_survival_in_days: "<348 (25%)",
    Recurrence_status: "No",
    Survival_status: "Dead",
    caseId: "20CO004",
    Immune_subtype: "CD8-/IFNG-",
  },
  {
    Tumor: "HGSC",
    Sex: "Female",
    Age: "<55 (25%)",
    Participant_Country: "not_reported",
    Grade: "G3",
    Stage: "IV",
    BMI: "not_reported",
    Alcohol_consumption: "not_reported",
    Tobacco_use: "not_reported",
    Secondhand_smoke_exposure: "not_reported",
    Overall_survival_in_days: "<348 (25%)",
    Recurrence_status: "No",
    Survival_status: "Dead",
    caseId: "04OV004",
    Immune_subtype: "CD8-/IFNG+",
  },
  {
    Tumor: "HGSC",
    Sex: "Female",
    Age: "63-70 (50%-75%)",
    Participant_Country: "not_reported",
    Grade: "G3",
    Stage: "III",
    BMI: "not_reported",
    Alcohol_consumption: "not_reported",
    Tobacco_use: "not_reported",
    Secondhand_smoke_exposure: "not_reported",
    Overall_survival_in_days: "<348 (25%)",
    Recurrence_status: "No",
    Survival_status: "Dead",
    caseId: "17OV025",
    Immune_subtype: "CD8-/IFNG+",
  },
  {
    Tumor: "BRCA",
    Sex: "Female",
    Age: "<55 (25%)",
    Participant_Country: "not_reported",
    Grade: "not_reported",
    Stage: "I",
    BMI: "not_reported",
    Alcohol_consumption: "not_reported",
    Tobacco_use: "not_reported",
    Secondhand_smoke_exposure: "not_reported",
    Overall_survival_in_days: "<348 (25%)",
    Recurrence_status: "No",
    Survival_status: "Dead",
    caseId: "05BR038",
    Immune_subtype: "Fibroblast/TGFBeta",
  },
  {
    Tumor: "ccRCC",
    Sex: "Male",
    Age: "55-63 (25%-50%)",
    Participant_Country: "United States",
    Grade: "G4",
    Stage: "III",
    BMI: ">=30",
    Alcohol_consumption: "past-drinker",
    Tobacco_use: "current smoker",
    Secondhand_smoke_exposure: "not_reported",
    Overall_survival_in_days: "348-609 (25%-50%)",
    Recurrence_status: "No",
    Survival_status: "Dead",
    caseId: "C3L-00416",
    Immune_subtype: "CD8+/IFNG+",
  },
  {
    Tumor: "BRCA",
    Sex: "Female",
    Age: "<55 (25%)",
    Participant_Country: "not_reported",
    Grade: "not_reported",
    Stage: "II",
    BMI: "not_reported",
    Alcohol_consumption: "not_reported",
    Tobacco_use: "not_reported",
    Secondhand_smoke_exposure: "not_reported",
    Overall_survival_in_days: "348-609 (25%-50%)",
    Recurrence_status: "No",
    Survival_status: "Dead",
    caseId: "11BR043",
    Immune_subtype: "CD8+/IFNG+",
  },
  {
    Tumor: "LUAD",
    Sex: "Female",
    Age: ">=70 (>=75%)",
    Participant_Country: "United States",
    Grade: "G2",
    Stage: "I",
    BMI: "25-30",
    Alcohol_consumption: "non-drinker",
    Tobacco_use: "past-smoker",
    Secondhand_smoke_exposure: "not_reported",
    Overall_survival_in_days: "348-609 (25%-50%)",
    Recurrence_status: "No",
    Survival_status: "Dead",
    caseId: "C3L-00279",
    Immune_subtype: "Eosinophils/Endothelial",
  },
  {
    Tumor: "LUAD",
    Sex: "Male",
    Age: "63-70 (50%-75%)",
    Participant_Country: "United States",
    Grade: "G1",
    Stage: "II",
    BMI: "25-30",
    Alcohol_consumption: "drinker",
    Tobacco_use: "current smoker",
    Secondhand_smoke_exposure: "not_reported",
    Overall_survival_in_days: "348-609 (25%-50%)",
    Recurrence_status: "Yes",
    Survival_status: "Alive",
    caseId: "C3L-02549",
    Immune_subtype: "Eosinophils/Endothelial",
  },
  {
    Tumor: "LSCC",
    Sex: "Female",
    Age: "63-70 (50%-75%)",
    Participant_Country: "Bulgaria",
    Grade: "G3",
    Stage: "I",
    BMI: "25-30",
    Alcohol_consumption: "non-drinker",
    Tobacco_use: "current smoker",
    Secondhand_smoke_exposure: "Yes",
    Overall_survival_in_days: ">=1016 (>=75%)",
    Recurrence_status: "No",
    Survival_status: "Dead",
    caseId: "C3L-02627",
    Immune_subtype: "CD8-/IFNG+",
  },
  {
    Tumor: "BRCA",
    Sex: "Female",
    Age: "<55 (25%)",
    Participant_Country: "not_reported",
    Grade: "not_reported",
    Stage: "III",
    BMI: "not_reported",
    Alcohol_consumption: "not_reported",
    Tobacco_use: "not_reported",
    Secondhand_smoke_exposure: "not_reported",
    Overall_survival_in_days: "348-609 (25%-50%)",
    Recurrence_status: "No",
    Survival_status: "Dead",
    caseId: "01BR032",
    Immune_subtype: "CD8-/IFNG-",
  },
  {
    Tumor: "UCEC",
    Sex: "Female",
    Age: ">=70 (>=75%)",
    Participant_Country: "United States",
    Grade: "G3",
    Stage: "I",
    BMI: "25-30",
    Alcohol_consumption: "drinker",
    Tobacco_use: "past-smoker",
    Secondhand_smoke_exposure: "not_reported",
    Overall_survival_in_days: "<348 (25%)",
    Recurrence_status: "No",
    Survival_status: "Dead",
    caseId: "C3L-00358",
    Immune_subtype: "CD8-/IFNG+",
  },
  {
    Tumor: "HNSCC",
    Sex: "Male",
    Age: "55-63 (25%-50%)",
    Participant_Country: "Poland",
    Grade: "G1",
    Stage: "II",
    BMI: "18.5-25",
    Alcohol_consumption: "drinker",
    Tobacco_use: "current smoker",
    Secondhand_smoke_exposure: "Yes",
    Overall_survival_in_days: ">=1016 (>=75%)",
    Recurrence_status: "No",
    Survival_status: "Dead",
    caseId: "C3N-04275",
    Immune_subtype: "Fibroblast/TGFBeta",
  },
  {
    Tumor: "ccRCC",
    Sex: "Male",
    Age: ">=70 (>=75%)",
    Participant_Country: "Poland",
    Grade: "G2",
    Stage: "III",
    BMI: "25-30",
    Alcohol_consumption: "not_reported",
    Tobacco_use: "past-smoker",
    Secondhand_smoke_exposure: "not_reported",
    Overall_survival_in_days: ">=1016 (>=75%)",
    Recurrence_status: "No",
    Survival_status: "Dead",
    caseId: "C3N-01179",
    Immune_subtype: "ccRCC/Endothelial",
  },
  {
    Tumor: "GBM",
    Sex: "Male",
    Age: "<55 (25%)",
    Participant_Country: "China",
    Grade: "not_reported",
    Stage: "not_reported",
    BMI: "18.5-25",
    Alcohol_consumption: "non-drinker",
    Tobacco_use: "non-smoker",
    Secondhand_smoke_exposure: "not_reported",
    Overall_survival_in_days: ">=1016 (>=75%)",
    Recurrence_status: "Yes",
    Survival_status: "Alive",
    caseId: "C3N-02769",
    Immune_subtype: "Fibroblast/TGFBeta",
  },
  {
    Tumor: "HGSC",
    Sex: "Female",
    Age: "55-63 (25%-50%)",
    Participant_Country: "not_reported",
    Grade: "G3",
    Stage: "III",
    BMI: "not_reported",
    Alcohol_consumption: "not_reported",
    Tobacco_use: "not_reported",
    Secondhand_smoke_exposure: "not_reported",
    Overall_survival_in_days: "<348 (25%)",
    Recurrence_status: "Yes",
    Survival_status: "Dead",
    caseId: "04OV044",
    Immune_subtype: "CD8-/IFNG+",
  },
  {
    Tumor: "COAD",
    Sex: "Male",
    Age: "<55 (25%)",
    Participant_Country: "not_reported",
    Grade: "not_reported",
    Stage: "II",
    BMI: "not_reported",
    Alcohol_consumption: "not_reported",
    Tobacco_use: "not_reported",
    Secondhand_smoke_exposure: "not_reported",
    Overall_survival_in_days: "<348 (25%)",
    Recurrence_status: "No",
    Survival_status: "Dead",
    caseId: "05CO037",
    Immune_subtype: "Fibroblast/TGFBeta",
  },
  {
    Tumor: "COAD",
    Sex: "Female",
    Age: ">=70 (>=75%)",
    Participant_Country: "not_reported",
    Grade: "not_reported",
    Stage: "III",
    BMI: "not_reported",
    Alcohol_consumption: "not_reported",
    Tobacco_use: "not_reported",
    Secondhand_smoke_exposure: "not_reported",
    Overall_survival_in_days: "348-609 (25%-50%)",
    Recurrence_status: "Yes",
    Survival_status: "Dead",
    caseId: "01CO006",
    Immune_subtype: "CD8+/IFNG+",
  },
  {
    Tumor: "BRCA",
    Sex: "Female",
    Age: ">=70 (>=75%)",
    Participant_Country: "not_reported",
    Grade: "not_reported",
    Stage: "II",
    BMI: "not_reported",
    Alcohol_consumption: "not_reported",
    Tobacco_use: "not_reported",
    Secondhand_smoke_exposure: "not_reported",
    Overall_survival_in_days: "<348 (25%)",
    Recurrence_status: "No",
    Survival_status: "Dead",
    caseId: "21BR010",
    Immune_subtype: "Fibroblast/TGFBeta",
  },
  {
    Tumor: "PDAC",
    Sex: "Female",
    Age: ">=70 (>=75%)",
    Participant_Country: "United States",
    Grade: "G1",
    Stage: "III",
    BMI: "25-30",
    Alcohol_consumption: "non-drinker",
    Tobacco_use: "non-smoker",
    Secondhand_smoke_exposure: "not_reported",
    Overall_survival_in_days: "<348 (25%)",
    Recurrence_status: "No",
    Survival_status: "Alive",
    caseId: "C3N-02971",
    Immune_subtype: "CD8+/IFNG+",
  },
  {
    Tumor: "GBM",
    Sex: "Female",
    Age: "<55 (25%)",
    Participant_Country: "Russia",
    Grade: "not_reported",
    Stage: "not_reported",
    BMI: "18.5-25",
    Alcohol_consumption: "non-drinker",
    Tobacco_use: "non-smoker",
    Secondhand_smoke_exposure: "No",
    Overall_survival_in_days: "<348 (25%)",
    Recurrence_status: "No",
    Survival_status: "Dead",
    caseId: "C3L-01142",
    Immune_subtype: "CD8+/IFNG+",
  },
  {
    Tumor: "LUAD",
    Sex: "Male",
    Age: ">=70 (>=75%)",
    Participant_Country: "United States",
    Grade: "G2",
    Stage: "I",
    BMI: "25-30",
    Alcohol_consumption: "drinker",
    Tobacco_use: "past-smoker",
    Secondhand_smoke_exposure: "not_reported",
    Overall_survival_in_days: ">=1016 (>=75%)",
    Recurrence_status: "No",
    Survival_status: "Dead",
    caseId: "C3L-00083",
    Immune_subtype: "CD8+/IFNG+",
  },
  {
    Tumor: "BRCA",
    Sex: "Female",
    Age: "63-70 (50%-75%)",
    Participant_Country: "not_reported",
    Grade: "not_reported",
    Stage: "II",
    BMI: "not_reported",
    Alcohol_consumption: "not_reported",
    Tobacco_use: "not_reported",
    Secondhand_smoke_exposure: "not_reported",
    Overall_survival_in_days: "348-609 (25%-50%)",
    Recurrence_status: "No",
    Survival_status: "Dead",
    caseId: "11BR058",
    Immune_subtype: "Fibroblast/TGFBeta",
  },
  {
    Tumor: "UCEC",
    Sex: "Female",
    Age: "63-70 (50%-75%)",
    Participant_Country: "United States",
    Grade: "G1",
    Stage: "I",
    BMI: ">=30",
    Alcohol_consumption: "non-drinker",
    Tobacco_use: "non-smoker",
    Secondhand_smoke_exposure: "not_reported",
    Overall_survival_in_days: ">=1016 (>=75%)",
    Recurrence_status: "No",
    Survival_status: "Dead",
    caseId: "C3L-01304",
    Immune_subtype: "Fibroblast/TGFBeta",
  },
  {
    Tumor: "PDAC",
    Sex: "Male",
    Age: "<55 (25%)",
    Participant_Country: "China",
    Grade: "G3",
    Stage: "II",
    BMI: "25-30",
    Alcohol_consumption: "non-drinker",
    Tobacco_use: "non-smoker",
    Secondhand_smoke_exposure: "not_reported",
    Overall_survival_in_days: "348-609 (25%-50%)",
    Recurrence_status: "No",
    Survival_status: "Alive",
    caseId: "C3N-02768",
    Immune_subtype: "CD8-/IFNG-",
  },
  {
    Tumor: "LSCC",
    Sex: "Male",
    Age: "<55 (25%)",
    Participant_Country: "China",
    Grade: "G3",
    Stage: "III",
    BMI: "25-30",
    Alcohol_consumption: "not_reported",
    Tobacco_use: "current smoker",
    Secondhand_smoke_exposure: "not_reported",
    Overall_survival_in_days: "609-1016 (50%-75%)",
    Recurrence_status: "No",
    Survival_status: "Dead",
    caseId: "C3N-03877",
    Immune_subtype: "CD8-/IFNG-",
  },
  {
    Tumor: "BRCA",
    Sex: "Female",
    Age: "<55 (25%)",
    Participant_Country: "not_reported",
    Grade: "not_reported",
    Stage: "II",
    BMI: "not_reported",
    Alcohol_consumption: "not_reported",
    Tobacco_use: "not_reported",
    Secondhand_smoke_exposure: "not_reported",
    Overall_survival_in_days: "348-609 (25%-50%)",
    Recurrence_status: "No",
    Survival_status: "Dead",
    caseId: "20BR005",
    Immune_subtype: "CD8-/IFNG-",
  },
  {
    Tumor: "BRCA",
    Sex: "Female",
    Age: "<55 (25%)",
    Participant_Country: "not_reported",
    Grade: "not_reported",
    Stage: "not_reported",
    BMI: "not_reported",
    Alcohol_consumption: "not_reported",
    Tobacco_use: "not_reported",
    Secondhand_smoke_exposure: "not_reported",
    Overall_survival_in_days: "<348 (25%)",
    Recurrence_status: "Yes",
    Survival_status: "Dead",
    caseId: "06BR006",
    Immune_subtype: "Fibroblast/TGFBeta",
  },
  {
    Tumor: "GBM",
    Sex: "Male",
    Age: "55-63 (25%-50%)",
    Participant_Country: "Russia",
    Grade: "not_reported",
    Stage: "not_reported",
    BMI: "18.5-25",
    Alcohol_consumption: "non-drinker",
    Tobacco_use: "non-smoker",
    Secondhand_smoke_exposure: "No",
    Overall_survival_in_days: "609-1016 (50%-75%)",
    Recurrence_status: "Yes",
    Survival_status: "Dead",
    caseId: "C3L-01048",
    Immune_subtype: "CD8-/IFNG-",
  },
  {
    Tumor: "LSCC",
    Sex: "Male",
    Age: "<55 (25%)",
    Participant_Country: "Vietnam",
    Grade: "G3",
    Stage: "II",
    BMI: "18.5-25",
    Alcohol_consumption: "non-drinker",
    Tobacco_use: "current smoker",
    Secondhand_smoke_exposure: "No",
    Overall_survival_in_days: "not_reported",
    Recurrence_status: "No",
    Survival_status: "Dead",
    caseId: "C3N-01025",
    Immune_subtype: "CD8-/IFNG-",
  },
  {
    Tumor: "LUAD",
    Sex: "Female",
    Age: "55-63 (25%-50%)",
    Participant_Country: "United States",
    Grade: "G2",
    Stage: "I",
    BMI: "18.5-25",
    Alcohol_consumption: "drinker",
    Tobacco_use: "past-smoker",
    Secondhand_smoke_exposure: "not_reported",
    Overall_survival_in_days: ">=1016 (>=75%)",
    Recurrence_status: "Yes",
    Survival_status: "Dead",
    caseId: "C3L-02345",
    Immune_subtype: "Fibroblast/TGFBeta",
  },
  {
    Tumor: "LSCC",
    Sex: "Male",
    Age: "<55 (25%)",
    Participant_Country: "United States",
    Grade: "G3",
    Stage: "III",
    BMI: ">=30",
    Alcohol_consumption: "drinker",
    Tobacco_use: "past-smoker",
    Secondhand_smoke_exposure: "Yes",
    Overall_survival_in_days: "348-609 (25%-50%)",
    Recurrence_status: "No",
    Survival_status: "Alive",
    caseId: "C3L-03678",
    Immune_subtype: "CD8-/IFNG-",
  },
  {
    Tumor: "BRCA",
    Sex: "Female",
    Age: "63-70 (50%-75%)",
    Participant_Country: "not_reported",
    Grade: "not_reported",
    Stage: "III",
    BMI: "not_reported",
    Alcohol_consumption: "not_reported",
    Tobacco_use: "not_reported",
    Secondhand_smoke_exposure: "not_reported",
    Overall_survival_in_days: "348-609 (25%-50%)",
    Recurrence_status: "No",
    Survival_status: "Dead",
    caseId: "11BR040",
    Immune_subtype: "Fibroblast/TGFBeta",
  },
  {
    Tumor: "GBM",
    Sex: "Female",
    Age: "63-70 (50%-75%)",
    Participant_Country: "Poland",
    Grade: "not_reported",
    Stage: "not_reported",
    BMI: "18.5-25",
    Alcohol_consumption: "non-drinker",
    Tobacco_use: "non-smoker",
    Secondhand_smoke_exposure: "not_reported",
    Overall_survival_in_days: "609-1016 (50%-75%)",
    Recurrence_status: "Yes",
    Survival_status: "Alive",
    caseId: "C3N-01857",
    Immune_subtype: "Brain/Neuro",
  },
  {
    Tumor: "COAD",
    Sex: "Male",
    Age: "<55 (25%)",
    Participant_Country: "not_reported",
    Grade: "not_reported",
    Stage: "II",
    BMI: "not_reported",
    Alcohol_consumption: "not_reported",
    Tobacco_use: "not_reported",
    Secondhand_smoke_exposure: "not_reported",
    Overall_survival_in_days: "348-609 (25%-50%)",
    Recurrence_status: "No",
    Survival_status: "Dead",
    caseId: "05CO011",
    Immune_subtype: "CD8-/IFNG-",
  },
  {
    Tumor: "BRCA",
    Sex: "Female",
    Age: "<55 (25%)",
    Participant_Country: "not_reported",
    Grade: "not_reported",
    Stage: "not_reported",
    BMI: "not_reported",
    Alcohol_consumption: "not_reported",
    Tobacco_use: "not_reported",
    Secondhand_smoke_exposure: "not_reported",
    Overall_survival_in_days: "not_reported",
    Recurrence_status: "No",
    Survival_status: "not_reported",
    caseId: "05BR004",
    Immune_subtype: "CD8-/IFNG-",
  },
  {
    Tumor: "LUAD",
    Sex: "Female",
    Age: "55-63 (25%-50%)",
    Participant_Country: "United States",
    Grade: "G3",
    Stage: "II",
    BMI: ">=30",
    Alcohol_consumption: "non-drinker",
    Tobacco_use: "past-smoker",
    Secondhand_smoke_exposure: "not_reported",
    Overall_survival_in_days: "609-1016 (50%-75%)",
    Recurrence_status: "Yes",
    Survival_status: "Alive",
    caseId: "C3L-01632",
    Immune_subtype: "CD8-/IFNG-",
  },
  {
    Tumor: "PDAC",
    Sex: "Male",
    Age: "63-70 (50%-75%)",
    Participant_Country: "not_reported",
    Grade: "G3",
    Stage: "II",
    BMI: "25-30",
    Alcohol_consumption: "drinker",
    Tobacco_use: "past-smoker",
    Secondhand_smoke_exposure: "not_reported",
    Overall_survival_in_days: ">=1016 (>=75%)",
    Recurrence_status: "Yes",
    Survival_status: "Dead",
    caseId: "C3L-04479",
    Immune_subtype: "CD8-/IFNG-",
  },
  {
    Tumor: "LUAD",
    Sex: "Male",
    Age: "55-63 (25%-50%)",
    Participant_Country: "Vietnam",
    Grade: "G3",
    Stage: "III",
    BMI: "18.5-25",
    Alcohol_consumption: "drinker",
    Tobacco_use: "non-smoker",
    Secondhand_smoke_exposure: "No",
    Overall_survival_in_days: "348-609 (25%-50%)",
    Recurrence_status: "No",
    Survival_status: "Dead",
    caseId: "C3N-01072",
    Immune_subtype: "CD8-/IFNG+",
  },
  {
    Tumor: "PDAC",
    Sex: "Female",
    Age: "63-70 (50%-75%)",
    Participant_Country: "China",
    Grade: "G2",
    Stage: "III",
    BMI: "18.5-25",
    Alcohol_consumption: "non-drinker",
    Tobacco_use: "non-smoker",
    Secondhand_smoke_exposure: "not_reported",
    Overall_survival_in_days: "609-1016 (50%-75%)",
    Recurrence_status: "Yes",
    Survival_status: "Alive",
    caseId: "C3N-03884",
    Immune_subtype: "CD8-/IFNG+",
  },
  {
    Tumor: "LUAD",
    Sex: "Male",
    Age: "55-63 (25%-50%)",
    Participant_Country: "China",
    Grade: "G3",
    Stage: "I",
    BMI: "18.5-25",
    Alcohol_consumption: "drinker",
    Tobacco_use: "current smoker",
    Secondhand_smoke_exposure: "not_reported",
    Overall_survival_in_days: ">=1016 (>=75%)",
    Recurrence_status: "No",
    Survival_status: "Dead",
    caseId: "C3N-02421",
    Immune_subtype: "Eosinophils/Endothelial",
  },
  {
    Tumor: "ccRCC",
    Sex: "Female",
    Age: "55-63 (25%-50%)",
    Participant_Country: "United States",
    Grade: "G2",
    Stage: "I",
    BMI: "18.5-25",
    Alcohol_consumption: "not_reported",
    Tobacco_use: "past-smoker",
    Secondhand_smoke_exposure: "not_reported",
    Overall_survival_in_days: ">=1016 (>=75%)",
    Recurrence_status: "No",
    Survival_status: "Dead",
    caseId: "C3L-00765",
    Immune_subtype: "ccRCC/Endothelial",
  },
  {
    Tumor: "GBM",
    Sex: "Male",
    Age: "<55 (25%)",
    Participant_Country: "United States",
    Grade: "not_reported",
    Stage: "not_reported",
    BMI: "18.5-25",
    Alcohol_consumption: "non-drinker",
    Tobacco_use: "current smoker",
    Secondhand_smoke_exposure: "not_reported",
    Overall_survival_in_days: ">=1016 (>=75%)",
    Recurrence_status: "No",
    Survival_status: "Alive",
    caseId: "C3L-02900",
    Immune_subtype: "Brain/Neuro",
  },
  {
    Tumor: "BRCA",
    Sex: "Female",
    Age: "63-70 (50%-75%)",
    Participant_Country: "not_reported",
    Grade: "not_reported",
    Stage: "III",
    BMI: "not_reported",
    Alcohol_consumption: "not_reported",
    Tobacco_use: "not_reported",
    Secondhand_smoke_exposure: "not_reported",
    Overall_survival_in_days: "<348 (25%)",
    Recurrence_status: "No",
    Survival_status: "Dead",
    caseId: "05BR009",
    Immune_subtype: "Fibroblast/TGFBeta",
  },
  {
    Tumor: "HNSCC",
    Sex: "Male",
    Age: ">=70 (>=75%)",
    Participant_Country: "Poland",
    Grade: "G2",
    Stage: "II",
    BMI: "18.5-25",
    Alcohol_consumption: "drinker",
    Tobacco_use: "past-smoker",
    Secondhand_smoke_exposure: "No",
    Overall_survival_in_days: ">=1016 (>=75%)",
    Recurrence_status: "No",
    Survival_status: "Dead",
    caseId: "C3N-03457",
    Immune_subtype: "Fibroblast/TGFBeta",
  },
  {
    Tumor: "LSCC",
    Sex: "Male",
    Age: "63-70 (50%-75%)",
    Participant_Country: "Bulgaria",
    Grade: "G2",
    Stage: "I",
    BMI: "25-30",
    Alcohol_consumption: "drinker",
    Tobacco_use: "current smoker",
    Secondhand_smoke_exposure: "Yes",
    Overall_survival_in_days: ">=1016 (>=75%)",
    Recurrence_status: "No",
    Survival_status: "Alive",
    caseId: "C3L-02130",
    Immune_subtype: "Fibroblast/TGFBeta",
  },
  {
    Tumor: "HNSCC",
    Sex: "Male",
    Age: "55-63 (25%-50%)",
    Participant_Country: "Ukraine",
    Grade: "G2",
    Stage: "IV",
    BMI: "<18.5",
    Alcohol_consumption: "drinker",
    Tobacco_use: "past-smoker",
    Secondhand_smoke_exposure: "not_reported",
    Overall_survival_in_days: "not_reported",
    Recurrence_status: "No",
    Survival_status: "Dead",
    caseId: "C3L-01237",
    Immune_subtype: "Fibroblast/TGFBeta",
  },
  {
    Tumor: "LSCC",
    Sex: "Male",
    Age: ">=70 (>=75%)",
    Participant_Country: "United States",
    Grade: "G2",
    Stage: "I",
    BMI: "25-30",
    Alcohol_consumption: "non-drinker",
    Tobacco_use: "current smoker",
    Secondhand_smoke_exposure: "not_reported",
    Overall_survival_in_days: ">=1016 (>=75%)",
    Recurrence_status: "Yes",
    Survival_status: "Alive",
    caseId: "C3L-01884",
    Immune_subtype: "CD8-/IFNG+",
  },
  {
    Tumor: "UCEC",
    Sex: "Female",
    Age: ">=70 (>=75%)",
    Participant_Country: "Ukraine",
    Grade: "G3",
    Stage: "I",
    BMI: "25-30",
    Alcohol_consumption: "non-drinker",
    Tobacco_use: "non-smoker",
    Secondhand_smoke_exposure: "not_reported",
    Overall_survival_in_days: "609-1016 (50%-75%)",
    Recurrence_status: "No",
    Survival_status: "Dead",
    caseId: "C3N-01521",
    Immune_subtype: "CD8-/IFNG+",
  },
  {
    Tumor: "LSCC",
    Sex: "Male",
    Age: "63-70 (50%-75%)",
    Participant_Country: "China",
    Grade: "G3",
    Stage: "II",
    BMI: "18.5-25",
    Alcohol_consumption: "non-drinker",
    Tobacco_use: "past-smoker",
    Secondhand_smoke_exposure: "not_reported",
    Overall_survival_in_days: "609-1016 (50%-75%)",
    Recurrence_status: "No",
    Survival_status: "Dead",
    caseId: "C3N-03882",
    Immune_subtype: "CD8-/IFNG-",
  },
  {
    Tumor: "PDAC",
    Sex: "Female",
    Age: ">=70 (>=75%)",
    Participant_Country: "Canada",
    Grade: "G2",
    Stage: "III",
    BMI: ">=30",
    Alcohol_consumption: "drinker",
    Tobacco_use: "non-smoker",
    Secondhand_smoke_exposure: "not_reported",
    Overall_survival_in_days: "<348 (25%)",
    Recurrence_status: "No",
    Survival_status: "Dead",
    caseId: "C3L-02109",
    Immune_subtype: "Fibroblast/TGFBeta",
  },
  {
    Tumor: "LSCC",
    Sex: "Male",
    Age: "63-70 (50%-75%)",
    Participant_Country: "United States",
    Grade: "G3",
    Stage: "I",
    BMI: ">=30",
    Alcohol_consumption: "drinker",
    Tobacco_use: "current smoker",
    Secondhand_smoke_exposure: "not_reported",
    Overall_survival_in_days: ">=1016 (>=75%)",
    Recurrence_status: "No",
    Survival_status: "Alive",
    caseId: "C3L-02552",
    Immune_subtype: "Eosinophils/Endothelial",
  },
  {
    Tumor: "LUAD",
    Sex: "Male",
    Age: ">=70 (>=75%)",
    Participant_Country: "China",
    Grade: "G3",
    Stage: "II",
    BMI: "18.5-25",
    Alcohol_consumption: "drinker",
    Tobacco_use: "past-smoker",
    Secondhand_smoke_exposure: "not_reported",
    Overall_survival_in_days: "609-1016 (50%-75%)",
    Recurrence_status: "No",
    Survival_status: "Alive",
    caseId: "C3N-02582",
    Immune_subtype: "CD8-/IFNG+",
  },
  {
    Tumor: "COAD",
    Sex: "Male",
    Age: "<55 (25%)",
    Participant_Country: "not_reported",
    Grade: "not_reported",
    Stage: "III",
    BMI: "not_reported",
    Alcohol_consumption: "not_reported",
    Tobacco_use: "not_reported",
    Secondhand_smoke_exposure: "not_reported",
    Overall_survival_in_days: "348-609 (25%-50%)",
    Recurrence_status: "No",
    Survival_status: "Dead",
    caseId: "11CO070",
    Immune_subtype: "CD8+/IFNG+",
  },
  {
    Tumor: "PDAC",
    Sex: "Female",
    Age: ">=70 (>=75%)",
    Participant_Country: "United States",
    Grade: "G2",
    Stage: "II",
    BMI: "25-30",
    Alcohol_consumption: "drinker",
    Tobacco_use: "past-smoker",
    Secondhand_smoke_exposure: "not_reported",
    Overall_survival_in_days: "609-1016 (50%-75%)",
    Recurrence_status: "Yes",
    Survival_status: "Alive",
    caseId: "C3L-01637",
    Immune_subtype: "Eosinophils/Endothelial",
  },
  {
    Tumor: "ccRCC",
    Sex: "Female",
    Age: "<55 (25%)",
    Participant_Country: "United States",
    Grade: "G3",
    Stage: "I",
    BMI: ">=30",
    Alcohol_consumption: "not_reported",
    Tobacco_use: "not_reported",
    Secondhand_smoke_exposure: "not_reported",
    Overall_survival_in_days: "<348 (25%)",
    Recurrence_status: "No",
    Survival_status: "Dead",
    caseId: "C3L-01560",
    Immune_subtype: "ccRCC/Endothelial",
  },
  {
    Tumor: "HNSCC",
    Sex: "Male",
    Age: "63-70 (50%-75%)",
    Participant_Country: "Bulgaria",
    Grade: "G3",
    Stage: "II",
    BMI: "18.5-25",
    Alcohol_consumption: "drinker",
    Tobacco_use: "current smoker",
    Secondhand_smoke_exposure: "Yes",
    Overall_survival_in_days: "609-1016 (50%-75%)",
    Recurrence_status: "No",
    Survival_status: "Dead",
    caseId: "C3L-02617",
    Immune_subtype: "CD8-/IFNG-",
  },
  {
    Tumor: "HGSC",
    Sex: "Female",
    Age: ">=70 (>=75%)",
    Participant_Country: "not_reported",
    Grade: "G3",
    Stage: "IV",
    BMI: "not_reported",
    Alcohol_consumption: "not_reported",
    Tobacco_use: "not_reported",
    Secondhand_smoke_exposure: "not_reported",
    Overall_survival_in_days: "348-609 (25%-50%)",
    Recurrence_status: "No",
    Survival_status: "Dead",
    caseId: "04OV005",
    Immune_subtype: "Fibroblast/TGFBeta",
  },
  {
    Tumor: "HNSCC",
    Sex: "Male",
    Age: "63-70 (50%-75%)",
    Participant_Country: "Poland",
    Grade: "G2",
    Stage: "IV",
    BMI: "18.5-25",
    Alcohol_consumption: "not_reported",
    Tobacco_use: "not_reported",
    Secondhand_smoke_exposure: "not_reported",
    Overall_survival_in_days: "609-1016 (50%-75%)",
    Recurrence_status: "No",
    Survival_status: "Alive",
    caseId: "C3N-00857",
    Immune_subtype: "CD8-/IFNG-",
  },
  {
    Tumor: "UCEC",
    Sex: "Female",
    Age: "<55 (25%)",
    Participant_Country: "United States",
    Grade: "G2",
    Stage: "II",
    BMI: ">=30",
    Alcohol_consumption: "non-drinker",
    Tobacco_use: "non-smoker",
    Secondhand_smoke_exposure: "not_reported",
    Overall_survival_in_days: ">=1016 (>=75%)",
    Recurrence_status: "No",
    Survival_status: "Dead",
    caseId: "C3N-01510",
    Immune_subtype: "CD8-/IFNG-",
  },
  {
    Tumor: "HNSCC",
    Sex: "Female",
    Age: "55-63 (25%-50%)",
    Participant_Country: "not_reported",
    Grade: "G2",
    Stage: "IV",
    BMI: "18.5-25",
    Alcohol_consumption: "past-drinker",
    Tobacco_use: "current smoker",
    Secondhand_smoke_exposure: "not_reported",
    Overall_survival_in_days: ">=1016 (>=75%)",
    Recurrence_status: "Yes",
    Survival_status: "Dead",
    caseId: "C3L-04844",
    Immune_subtype: "CD8-/IFNG-",
  },
  {
    Tumor: "COAD",
    Sex: "Male",
    Age: "63-70 (50%-75%)",
    Participant_Country: "not_reported",
    Grade: "not_reported",
    Stage: "II",
    BMI: "not_reported",
    Alcohol_consumption: "not_reported",
    Tobacco_use: "not_reported",
    Secondhand_smoke_exposure: "not_reported",
    Overall_survival_in_days: "348-609 (25%-50%)",
    Recurrence_status: "No",
    Survival_status: "Dead",
    caseId: "11CO048",
    Immune_subtype: "Fibroblast/TGFBeta",
  },
  {
    Tumor: "ccRCC",
    Sex: "Male",
    Age: "63-70 (50%-75%)",
    Participant_Country: "Ukraine",
    Grade: "G1",
    Stage: "I",
    BMI: "18.5-25",
    Alcohol_consumption: "non-drinker",
    Tobacco_use: "non-smoker",
    Secondhand_smoke_exposure: "not_reported",
    Overall_survival_in_days: "609-1016 (50%-75%)",
    Recurrence_status: "No",
    Survival_status: "Dead",
    caseId: "C3N-01261",
    Immune_subtype: "ccRCC/Endothelial",
  },
  {
    Tumor: "ccRCC",
    Sex: "Male",
    Age: "63-70 (50%-75%)",
    Participant_Country: "Poland",
    Grade: "G1",
    Stage: "I",
    BMI: ">=30",
    Alcohol_consumption: "drinker",
    Tobacco_use: "past-smoker",
    Secondhand_smoke_exposure: "not_reported",
    Overall_survival_in_days: ">=1016 (>=75%)",
    Recurrence_status: "No",
    Survival_status: "Dead",
    caseId: "C3N-00834",
    Immune_subtype: "ccRCC/Endothelial",
  },
  {
    Tumor: "PDAC",
    Sex: "Female",
    Age: ">=70 (>=75%)",
    Participant_Country: "Poland",
    Grade: "G2",
    Stage: "III",
    BMI: "25-30",
    Alcohol_consumption: "non-drinker",
    Tobacco_use: "non-smoker",
    Secondhand_smoke_exposure: "not_reported",
    Overall_survival_in_days: "609-1016 (50%-75%)",
    Recurrence_status: "Yes",
    Survival_status: "Alive",
    caseId: "C3N-01167",
    Immune_subtype: "CD8-/IFNG-",
  },
  {
    Tumor: "HNSCC",
    Sex: "Male",
    Age: "63-70 (50%-75%)",
    Participant_Country: "Poland",
    Grade: "G2",
    Stage: "III",
    BMI: "18.5-25",
    Alcohol_consumption: "drinker",
    Tobacco_use: "past-smoker",
    Secondhand_smoke_exposure: "Yes",
    Overall_survival_in_days: ">=1016 (>=75%)",
    Recurrence_status: "No",
    Survival_status: "Dead",
    caseId: "C3N-01755",
    Immune_subtype: "CD8-/IFNG+",
  },
  {
    Tumor: "LSCC",
    Sex: "Male",
    Age: "55-63 (25%-50%)",
    Participant_Country: "United States",
    Grade: "G2",
    Stage: "I",
    BMI: "not_reported",
    Alcohol_consumption: "not_reported",
    Tobacco_use: "not_reported",
    Secondhand_smoke_exposure: "not_reported",
    Overall_survival_in_days: "not_reported",
    Recurrence_status: "No",
    Survival_status: "not_reported",
    caseId: "C3N-02523",
    Immune_subtype: "CD8-/IFNG-",
  },
  {
    Tumor: "COAD",
    Sex: "Male",
    Age: "55-63 (25%-50%)",
    Participant_Country: "not_reported",
    Grade: "not_reported",
    Stage: "III",
    BMI: "not_reported",
    Alcohol_consumption: "not_reported",
    Tobacco_use: "not_reported",
    Secondhand_smoke_exposure: "not_reported",
    Overall_survival_in_days: "<348 (25%)",
    Recurrence_status: "Yes",
    Survival_status: "Dead",
    caseId: "05CO033",
    Immune_subtype: "CD8+/IFNG+",
  },
  {
    Tumor: "BRCA",
    Sex: "Female",
    Age: "<55 (25%)",
    Participant_Country: "not_reported",
    Grade: "not_reported",
    Stage: "II",
    BMI: "not_reported",
    Alcohol_consumption: "not_reported",
    Tobacco_use: "not_reported",
    Secondhand_smoke_exposure: "not_reported",
    Overall_survival_in_days: "348-609 (25%-50%)",
    Recurrence_status: "No",
    Survival_status: "Dead",
    caseId: "20BR007",
    Immune_subtype: "CD8-/IFNG+",
  },
  {
    Tumor: "PDAC",
    Sex: "Female",
    Age: "55-63 (25%-50%)",
    Participant_Country: "Poland",
    Grade: "G2",
    Stage: "II",
    BMI: ">=30",
    Alcohol_consumption: "non-drinker",
    Tobacco_use: "non-smoker",
    Secondhand_smoke_exposure: "No",
    Overall_survival_in_days: "<348 (25%)",
    Recurrence_status: "Yes",
    Survival_status: "Alive",
    caseId: "C3N-02592",
    Immune_subtype: "CD8+/IFNG+",
  },
  {
    Tumor: "ccRCC",
    Sex: "Male",
    Age: "55-63 (25%-50%)",
    Participant_Country: "Ukraine",
    Grade: "G2",
    Stage: "IV",
    BMI: "25-30",
    Alcohol_consumption: "non-drinker",
    Tobacco_use: "non-smoker",
    Secondhand_smoke_exposure: "not_reported",
    Overall_survival_in_days: "609-1016 (50%-75%)",
    Recurrence_status: "No",
    Survival_status: "Dead",
    caseId: "C3N-00390",
    Immune_subtype: "CD8+/IFNG+",
  },
  {
    Tumor: "LSCC",
    Sex: "Male",
    Age: ">=70 (>=75%)",
    Participant_Country: "Bulgaria",
    Grade: "G2",
    Stage: "II",
    BMI: "18.5-25",
    Alcohol_consumption: "drinker",
    Tobacco_use: "past-smoker",
    Secondhand_smoke_exposure: "Yes",
    Overall_survival_in_days: "609-1016 (50%-75%)",
    Recurrence_status: "No",
    Survival_status: "Alive",
    caseId: "C3L-02669",
    Immune_subtype: "Fibroblast/TGFBeta",
  },
  {
    Tumor: "GBM",
    Sex: "Female",
    Age: "55-63 (25%-50%)",
    Participant_Country: "United States",
    Grade: "not_reported",
    Stage: "not_reported",
    BMI: "25-30",
    Alcohol_consumption: "drinker",
    Tobacco_use: "not_reported",
    Secondhand_smoke_exposure: "not_reported",
    Overall_survival_in_days: "<348 (25%)",
    Recurrence_status: "No",
    Survival_status: "Alive",
    caseId: "C3L-02542",
    Immune_subtype: "CD8-/IFNG-",
  },
  {
    Tumor: "GBM",
    Sex: "Female",
    Age: "55-63 (25%-50%)",
    Participant_Country: "Poland",
    Grade: "not_reported",
    Stage: "not_reported",
    BMI: ">=30",
    Alcohol_consumption: "non-drinker",
    Tobacco_use: "non-smoker",
    Secondhand_smoke_exposure: "No",
    Overall_survival_in_days: ">=1016 (>=75%)",
    Recurrence_status: "Yes",
    Survival_status: "Alive",
    caseId: "C3N-01192",
    Immune_subtype: "Fibroblast/TGFBeta",
  },
  {
    Tumor: "LSCC",
    Sex: "Male",
    Age: "63-70 (50%-75%)",
    Participant_Country: "Bulgaria",
    Grade: "G3",
    Stage: "I",
    BMI: "18.5-25",
    Alcohol_consumption: "drinker",
    Tobacco_use: "current smoker",
    Secondhand_smoke_exposure: "Yes",
    Overall_survival_in_days: "<348 (25%)",
    Recurrence_status: "No",
    Survival_status: "Dead",
    caseId: "C3L-03962",
    Immune_subtype: "CD8-/IFNG-",
  },
  {
    Tumor: "HGSC",
    Sex: "Female",
    Age: "55-63 (25%-50%)",
    Participant_Country: "not_reported",
    Grade: "G3",
    Stage: "III",
    BMI: "not_reported",
    Alcohol_consumption: "not_reported",
    Tobacco_use: "not_reported",
    Secondhand_smoke_exposure: "not_reported",
    Overall_survival_in_days: "609-1016 (50%-75%)",
    Recurrence_status: "No",
    Survival_status: "Dead",
    caseId: "04OV057",
    Immune_subtype: "CD8-/IFNG+",
  },
  {
    Tumor: "ccRCC",
    Sex: "Male",
    Age: "<55 (25%)",
    Participant_Country: "United States",
    Grade: "G3",
    Stage: "I",
    BMI: ">=30",
    Alcohol_consumption: "not_reported",
    Tobacco_use: "current smoker",
    Secondhand_smoke_exposure: "not_reported",
    Overall_survival_in_days: "609-1016 (50%-75%)",
    Recurrence_status: "No",
    Survival_status: "Dead",
    caseId: "C3L-00010",
    Immune_subtype: "Fibroblast/TGFBeta",
  },
  {
    Tumor: "HGSC",
    Sex: "Female",
    Age: "63-70 (50%-75%)",
    Participant_Country: "not_reported",
    Grade: "G3",
    Stage: "IV",
    BMI: "not_reported",
    Alcohol_consumption: "not_reported",
    Tobacco_use: "not_reported",
    Secondhand_smoke_exposure: "not_reported",
    Overall_survival_in_days: "348-609 (25%-50%)",
    Recurrence_status: "No",
    Survival_status: "Dead",
    caseId: "01OV007",
    Immune_subtype: "Fibroblast/TGFBeta",
  },
  {
    Tumor: "HGSC",
    Sex: "Female",
    Age: "55-63 (25%-50%)",
    Participant_Country: "not_reported",
    Grade: "G3",
    Stage: "IV",
    BMI: "not_reported",
    Alcohol_consumption: "not_reported",
    Tobacco_use: "not_reported",
    Secondhand_smoke_exposure: "not_reported",
    Overall_survival_in_days: "348-609 (25%-50%)",
    Recurrence_status: "No",
    Survival_status: "Dead",
    caseId: "04OV033",
    Immune_subtype: "Fibroblast/TGFBeta",
  },
  {
    Tumor: "HGSC",
    Sex: "Female",
    Age: "55-63 (25%-50%)",
    Participant_Country: "not_reported",
    Grade: "G3",
    Stage: "III",
    BMI: "not_reported",
    Alcohol_consumption: "not_reported",
    Tobacco_use: "not_reported",
    Secondhand_smoke_exposure: "not_reported",
    Overall_survival_in_days: "<348 (25%)",
    Recurrence_status: "No",
    Survival_status: "Dead",
    caseId: "02OV046",
    Immune_subtype: "CD8-/IFNG+",
  },
  {
    Tumor: "LSCC",
    Sex: "Male",
    Age: "55-63 (25%-50%)",
    Participant_Country: "China",
    Grade: "G3",
    Stage: "I",
    BMI: "18.5-25",
    Alcohol_consumption: "non-drinker",
    Tobacco_use: "current smoker",
    Secondhand_smoke_exposure: "not_reported",
    Overall_survival_in_days: "609-1016 (50%-75%)",
    Recurrence_status: "No",
    Survival_status: "Dead",
    caseId: "C3N-04124",
    Immune_subtype: "CD8-/IFNG+",
  },
  {
    Tumor: "PDAC",
    Sex: "Male",
    Age: "63-70 (50%-75%)",
    Participant_Country: "United States",
    Grade: "G3",
    Stage: "II",
    BMI: "25-30",
    Alcohol_consumption: "non-drinker",
    Tobacco_use: "non-smoker",
    Secondhand_smoke_exposure: "Yes",
    Overall_survival_in_days: "<348 (25%)",
    Recurrence_status: "No",
    Survival_status: "Alive",
    caseId: "C3L-04080",
    Immune_subtype: "CD8-/IFNG-",
  },
  {
    Tumor: "LUAD",
    Sex: "Male",
    Age: ">=70 (>=75%)",
    Participant_Country: "United States",
    Grade: "G2",
    Stage: "III",
    BMI: "25-30",
    Alcohol_consumption: "not_reported",
    Tobacco_use: "past-smoker",
    Secondhand_smoke_exposure: "not_reported",
    Overall_survival_in_days: "348-609 (25%-50%)",
    Recurrence_status: "No",
    Survival_status: "Alive",
    caseId: "C3L-01862",
    Immune_subtype: "CD8-/IFNG+",
  },
  {
    Tumor: "HGSC",
    Sex: "Female",
    Age: "<55 (25%)",
    Participant_Country: "not_reported",
    Grade: "G3",
    Stage: "III",
    BMI: "not_reported",
    Alcohol_consumption: "not_reported",
    Tobacco_use: "not_reported",
    Secondhand_smoke_exposure: "not_reported",
    Overall_survival_in_days: "348-609 (25%-50%)",
    Recurrence_status: "No",
    Survival_status: "Dead",
    caseId: "04OV013",
    Immune_subtype: "Fibroblast/TGFBeta",
  },
  {
    Tumor: "LUAD",
    Sex: "Male",
    Age: "63-70 (50%-75%)",
    Participant_Country: "Ukraine",
    Grade: "G2",
    Stage: "I",
    BMI: ">=30",
    Alcohol_consumption: "drinker",
    Tobacco_use: "non-smoker",
    Secondhand_smoke_exposure: "not_reported",
    Overall_survival_in_days: "609-1016 (50%-75%)",
    Recurrence_status: "No",
    Survival_status: "Dead",
    caseId: "C3N-01842",
    Immune_subtype: "CD8-/IFNG-",
  },
  {
    Tumor: "COAD",
    Sex: "Female",
    Age: ">=70 (>=75%)",
    Participant_Country: "not_reported",
    Grade: "not_reported",
    Stage: "III",
    BMI: "not_reported",
    Alcohol_consumption: "not_reported",
    Tobacco_use: "not_reported",
    Secondhand_smoke_exposure: "not_reported",
    Overall_survival_in_days: "348-609 (25%-50%)",
    Recurrence_status: "No",
    Survival_status: "Dead",
    caseId: "05CO045",
    Immune_subtype: "CD8-/IFNG+",
  },
  {
    Tumor: "HGSC",
    Sex: "Female",
    Age: "<55 (25%)",
    Participant_Country: "not_reported",
    Grade: "G3",
    Stage: "IV",
    BMI: "not_reported",
    Alcohol_consumption: "not_reported",
    Tobacco_use: "not_reported",
    Secondhand_smoke_exposure: "not_reported",
    Overall_survival_in_days: "<348 (25%)",
    Recurrence_status: "Yes",
    Survival_status: "Alive",
    caseId: "04OV054",
    Immune_subtype: "CD8-/IFNG-",
  },
  {
    Tumor: "LUAD",
    Sex: "Female",
    Age: "63-70 (50%-75%)",
    Participant_Country: "United States",
    Grade: "G1",
    Stage: "I",
    BMI: "25-30",
    Alcohol_consumption: "drinker",
    Tobacco_use: "past-smoker",
    Secondhand_smoke_exposure: "not_reported",
    Overall_survival_in_days: "<348 (25%)",
    Recurrence_status: "No",
    Survival_status: "Dead",
    caseId: "C3L-00368",
    Immune_subtype: "Eosinophils/Endothelial",
  },
  {
    Tumor: "LSCC",
    Sex: "Male",
    Age: "55-63 (25%-50%)",
    Participant_Country: "China",
    Grade: "G3",
    Stage: "II",
    BMI: "18.5-25",
    Alcohol_consumption: "drinker",
    Tobacco_use: "current smoker",
    Secondhand_smoke_exposure: "not_reported",
    Overall_survival_in_days: ">=1016 (>=75%)",
    Recurrence_status: "No",
    Survival_status: "Dead",
    caseId: "C3N-02425",
    Immune_subtype: "CD8-/IFNG-",
  },
  {
    Tumor: "LSCC",
    Sex: "Male",
    Age: ">=70 (>=75%)",
    Participant_Country: "China",
    Grade: "G2",
    Stage: "II",
    BMI: "25-30",
    Alcohol_consumption: "non-drinker",
    Tobacco_use: "current smoker",
    Secondhand_smoke_exposure: "not_reported",
    Overall_survival_in_days: "609-1016 (50%-75%)",
    Recurrence_status: "No",
    Survival_status: "Dead",
    caseId: "C3N-03880",
    Immune_subtype: "CD8+/IFNG+",
  },
  {
    Tumor: "GBM",
    Sex: "Male",
    Age: "<55 (25%)",
    Participant_Country: "China",
    Grade: "not_reported",
    Stage: "not_reported",
    BMI: "25-30",
    Alcohol_consumption: "drinker",
    Tobacco_use: "current smoker",
    Secondhand_smoke_exposure: "No",
    Overall_survival_in_days: "348-609 (25%-50%)",
    Recurrence_status: "No",
    Survival_status: "Dead",
    caseId: "C3N-03184",
    Immune_subtype: "CD8-/IFNG-",
  },
  {
    Tumor: "COAD",
    Sex: "Female",
    Age: "63-70 (50%-75%)",
    Participant_Country: "not_reported",
    Grade: "not_reported",
    Stage: "III",
    BMI: "not_reported",
    Alcohol_consumption: "not_reported",
    Tobacco_use: "not_reported",
    Secondhand_smoke_exposure: "not_reported",
    Overall_survival_in_days: "<348 (25%)",
    Recurrence_status: "No",
    Survival_status: "Dead",
    caseId: "05CO050",
    Immune_subtype: "CD8-/IFNG-",
  },
  {
    Tumor: "ccRCC",
    Sex: "Male",
    Age: "63-70 (50%-75%)",
    Participant_Country: "United States",
    Grade: "G2",
    Stage: "I",
    BMI: ">=30",
    Alcohol_consumption: "non-drinker",
    Tobacco_use: "past-smoker",
    Secondhand_smoke_exposure: "not_reported",
    Overall_survival_in_days: ">=1016 (>=75%)",
    Recurrence_status: "No",
    Survival_status: "Dead",
    caseId: "C3N-00242",
    Immune_subtype: "CD8-/IFNG-",
  },
  {
    Tumor: "HNSCC",
    Sex: "Male",
    Age: ">=70 (>=75%)",
    Participant_Country: "Bulgaria",
    Grade: "G2",
    Stage: "II",
    BMI: "18.5-25",
    Alcohol_consumption: "drinker",
    Tobacco_use: "current smoker",
    Secondhand_smoke_exposure: "not_reported",
    Overall_survival_in_days: "<348 (25%)",
    Recurrence_status: "No",
    Survival_status: "Dead",
    caseId: "C3L-02651",
    Immune_subtype: "CD8+/IFNG+",
  },
  {
    Tumor: "ccRCC",
    Sex: "Male",
    Age: "<55 (25%)",
    Participant_Country: "Poland",
    Grade: "G2",
    Stage: "IV",
    BMI: ">=30",
    Alcohol_consumption: "drinker",
    Tobacco_use: "non-smoker",
    Secondhand_smoke_exposure: "not_reported",
    Overall_survival_in_days: "<348 (25%)",
    Recurrence_status: "No",
    Survival_status: "Alive",
    caseId: "C3N-00491",
    Immune_subtype: "CD8+/IFNG+",
  },
  {
    Tumor: "LSCC",
    Sex: "Male",
    Age: "<55 (25%)",
    Participant_Country: "Bulgaria",
    Grade: "G2",
    Stage: "II",
    BMI: "25-30",
    Alcohol_consumption: "not_reported",
    Tobacco_use: "current smoker",
    Secondhand_smoke_exposure: "not_reported",
    Overall_survival_in_days: "<348 (25%)",
    Recurrence_status: "No",
    Survival_status: "Dead",
    caseId: "C3L-02665",
    Immune_subtype: "CD8-/IFNG+",
  },
  {
    Tumor: "LSCC",
    Sex: "Male",
    Age: ">=70 (>=75%)",
    Participant_Country: "Ukraine",
    Grade: "G2",
    Stage: "II",
    BMI: ">=30",
    Alcohol_consumption: "non-drinker",
    Tobacco_use: "non-smoker",
    Secondhand_smoke_exposure: "not_reported",
    Overall_survival_in_days: "<348 (25%)",
    Recurrence_status: "No",
    Survival_status: "Dead",
    caseId: "C3N-02339",
    Immune_subtype: "Fibroblast/TGFBeta",
  },
  {
    Tumor: "GBM",
    Sex: "Male",
    Age: "<55 (25%)",
    Participant_Country: "Russia",
    Grade: "not_reported",
    Stage: "not_reported",
    BMI: ">=30",
    Alcohol_consumption: "non-drinker",
    Tobacco_use: "non-smoker",
    Secondhand_smoke_exposure: "No",
    Overall_survival_in_days: "609-1016 (50%-75%)",
    Recurrence_status: "Yes",
    Survival_status: "Dead",
    caseId: "C3L-01149",
    Immune_subtype: "Brain/Neuro",
  },
  {
    Tumor: "BRCA",
    Sex: "Female",
    Age: "<55 (25%)",
    Participant_Country: "not_reported",
    Grade: "not_reported",
    Stage: "II",
    BMI: "not_reported",
    Alcohol_consumption: "not_reported",
    Tobacco_use: "not_reported",
    Secondhand_smoke_exposure: "not_reported",
    Overall_survival_in_days: "<348 (25%)",
    Recurrence_status: "No",
    Survival_status: "Dead",
    caseId: "01BR015",
    Immune_subtype: "Fibroblast/TGFBeta",
  },
  {
    Tumor: "BRCA",
    Sex: "Female",
    Age: "<55 (25%)",
    Participant_Country: "not_reported",
    Grade: "not_reported",
    Stage: "II",
    BMI: "not_reported",
    Alcohol_consumption: "not_reported",
    Tobacco_use: "not_reported",
    Secondhand_smoke_exposure: "not_reported",
    Overall_survival_in_days: "not_reported",
    Recurrence_status: "No",
    Survival_status: "not_reported",
    caseId: "05BR043",
    Immune_subtype: "CD8+/IFNG+",
  },
  {
    Tumor: "PDAC",
    Sex: "Female",
    Age: "55-63 (25%-50%)",
    Participant_Country: "United States",
    Grade: "G2",
    Stage: "II",
    BMI: "25-30",
    Alcohol_consumption: "drinker",
    Tobacco_use: "non-smoker",
    Secondhand_smoke_exposure: "not_reported",
    Overall_survival_in_days: "<348 (25%)",
    Recurrence_status: "Yes",
    Survival_status: "Alive",
    caseId: "C3L-01662",
    Immune_subtype: "CD8-/IFNG-",
  },
  {
    Tumor: "ccRCC",
    Sex: "Male",
    Age: "55-63 (25%-50%)",
    Participant_Country: "Ukraine",
    Grade: "G3",
    Stage: "II",
    BMI: "25-30",
    Alcohol_consumption: "non-drinker",
    Tobacco_use: "non-smoker",
    Secondhand_smoke_exposure: "not_reported",
    Overall_survival_in_days: "609-1016 (50%-75%)",
    Recurrence_status: "No",
    Survival_status: "Dead",
    caseId: "C3N-01524",
    Immune_subtype: "ccRCC/Endothelial",
  },
  {
    Tumor: "ccRCC",
    Sex: "Male",
    Age: "55-63 (25%-50%)",
    Participant_Country: "United States",
    Grade: "G3",
    Stage: "III",
    BMI: "25-30",
    Alcohol_consumption: "drinker",
    Tobacco_use: "current smoker",
    Secondhand_smoke_exposure: "not_reported",
    Overall_survival_in_days: ">=1016 (>=75%)",
    Recurrence_status: "Yes",
    Survival_status: "Alive",
    caseId: "C3L-00103",
    Immune_subtype: "ccRCC/Endothelial",
  },
  {
    Tumor: "HNSCC",
    Sex: "Male",
    Age: "55-63 (25%-50%)",
    Participant_Country: "Poland",
    Grade: "G2",
    Stage: "IV",
    BMI: "<18.5",
    Alcohol_consumption: "not_reported",
    Tobacco_use: "not_reported",
    Secondhand_smoke_exposure: "not_reported",
    Overall_survival_in_days: "348-609 (25%-50%)",
    Recurrence_status: "No",
    Survival_status: "Alive",
    caseId: "C3N-00871",
    Immune_subtype: "CD8-/IFNG-",
  },
  {
    Tumor: "HNSCC",
    Sex: "Male",
    Age: "<55 (25%)",
    Participant_Country: "Ukraine",
    Grade: "G2",
    Stage: "III",
    BMI: "18.5-25",
    Alcohol_consumption: "drinker",
    Tobacco_use: "current smoker",
    Secondhand_smoke_exposure: "not_reported",
    Overall_survival_in_days: "not_reported",
    Recurrence_status: "No",
    Survival_status: "not_reported",
    caseId: "C3N-04611",
    Immune_subtype: "Fibroblast/TGFBeta",
  },
  {
    Tumor: "LUAD",
    Sex: "Male",
    Age: "63-70 (50%-75%)",
    Participant_Country: "United States",
    Grade: "G1",
    Stage: "I",
    BMI: "18.5-25",
    Alcohol_consumption: "drinker",
    Tobacco_use: "current smoker",
    Secondhand_smoke_exposure: "not_reported",
    Overall_survival_in_days: ">=1016 (>=75%)",
    Recurrence_status: "No",
    Survival_status: "Dead",
    caseId: "C3L-00140",
    Immune_subtype: "CD8-/IFNG+",
  },
  {
    Tumor: "PDAC",
    Sex: "Male",
    Age: ">=70 (>=75%)",
    Participant_Country: "United States",
    Grade: "G3",
    Stage: "II",
    BMI: "25-30",
    Alcohol_consumption: "drinker",
    Tobacco_use: "non-smoker",
    Secondhand_smoke_exposure: "not_reported",
    Overall_survival_in_days: "<348 (25%)",
    Recurrence_status: "Yes",
    Survival_status: "Dead",
    caseId: "C3L-01328",
    Immune_subtype: "Eosinophils/Endothelial",
  },
  {
    Tumor: "HNSCC",
    Sex: "Male",
    Age: "55-63 (25%-50%)",
    Participant_Country: "Poland",
    Grade: "G2",
    Stage: "IV",
    BMI: "18.5-25",
    Alcohol_consumption: "drinker",
    Tobacco_use: "current smoker",
    Secondhand_smoke_exposure: "No",
    Overall_survival_in_days: "609-1016 (50%-75%)",
    Recurrence_status: "No",
    Survival_status: "Dead",
    caseId: "C3N-03028",
    Immune_subtype: "CD8+/IFNG+",
  },
  {
    Tumor: "BRCA",
    Sex: "Female",
    Age: "<55 (25%)",
    Participant_Country: "not_reported",
    Grade: "not_reported",
    Stage: "II",
    BMI: "not_reported",
    Alcohol_consumption: "not_reported",
    Tobacco_use: "not_reported",
    Secondhand_smoke_exposure: "not_reported",
    Overall_survival_in_days: "not_reported",
    Recurrence_status: "No",
    Survival_status: "not_reported",
    caseId: "604",
    Immune_subtype: "not_reported",
  },
  {
    Tumor: "PDAC",
    Sex: "Female",
    Age: "<55 (25%)",
    Participant_Country: "China",
    Grade: "G2",
    Stage: "III",
    BMI: "25-30",
    Alcohol_consumption: "non-drinker",
    Tobacco_use: "non-smoker",
    Secondhand_smoke_exposure: "not_reported",
    Overall_survival_in_days: "348-609 (25%-50%)",
    Recurrence_status: "No",
    Survival_status: "Alive",
    caseId: "C3N-03853",
    Immune_subtype: "CD8-/IFNG-",
  },
  {
    Tumor: "HNSCC",
    Sex: "Male",
    Age: "63-70 (50%-75%)",
    Participant_Country: "Poland",
    Grade: "G2",
    Stage: "II",
    BMI: "18.5-25",
    Alcohol_consumption: "drinker",
    Tobacco_use: "current smoker",
    Secondhand_smoke_exposure: "No",
    Overall_survival_in_days: ">=1016 (>=75%)",
    Recurrence_status: "No",
    Survival_status: "Dead",
    caseId: "C3N-04279",
    Immune_subtype: "CD8-/IFNG-",
  },
  {
    Tumor: "ccRCC",
    Sex: "Male",
    Age: ">=70 (>=75%)",
    Participant_Country: "Poland",
    Grade: "G2",
    Stage: "III",
    BMI: "25-30",
    Alcohol_consumption: "drinker",
    Tobacco_use: "non-smoker",
    Secondhand_smoke_exposure: "not_reported",
    Overall_survival_in_days: ">=1016 (>=75%)",
    Recurrence_status: "No",
    Survival_status: "Dead",
    caseId: "C3N-01176",
    Immune_subtype: "ccRCC/Endothelial",
  },
  {
    Tumor: "HGSC",
    Sex: "Female",
    Age: "55-63 (25%-50%)",
    Participant_Country: "not_reported",
    Grade: "G3",
    Stage: "III",
    BMI: "not_reported",
    Alcohol_consumption: "not_reported",
    Tobacco_use: "not_reported",
    Secondhand_smoke_exposure: "not_reported",
    Overall_survival_in_days: "348-609 (25%-50%)",
    Recurrence_status: "No",
    Survival_status: "Dead",
    caseId: "01OV023",
    Immune_subtype: "Fibroblast/TGFBeta",
  },
  {
    Tumor: "HNSCC",
    Sex: "Male",
    Age: ">=70 (>=75%)",
    Participant_Country: "Poland",
    Grade: "G2",
    Stage: "III",
    BMI: "25-30",
    Alcohol_consumption: "not_reported",
    Tobacco_use: "not_reported",
    Secondhand_smoke_exposure: "not_reported",
    Overall_survival_in_days: "609-1016 (50%-75%)",
    Recurrence_status: "Yes",
    Survival_status: "Dead",
    caseId: "C3N-01645",
    Immune_subtype: "CD8+/IFNG+",
  },
  {
    Tumor: "HGSC",
    Sex: "Female",
    Age: "55-63 (25%-50%)",
    Participant_Country: "not_reported",
    Grade: "G2",
    Stage: "III",
    BMI: "not_reported",
    Alcohol_consumption: "not_reported",
    Tobacco_use: "not_reported",
    Secondhand_smoke_exposure: "not_reported",
    Overall_survival_in_days: "348-609 (25%-50%)",
    Recurrence_status: "No",
    Survival_status: "Dead",
    caseId: "17OV001",
    Immune_subtype: "Fibroblast/TGFBeta",
  },
  {
    Tumor: "ccRCC",
    Sex: "Male",
    Age: "<55 (25%)",
    Participant_Country: "United States",
    Grade: "G2",
    Stage: "I",
    BMI: ">=30",
    Alcohol_consumption: "not_reported",
    Tobacco_use: "current smoker",
    Secondhand_smoke_exposure: "not_reported",
    Overall_survival_in_days: "348-609 (25%-50%)",
    Recurrence_status: "No",
    Survival_status: "Dead",
    caseId: "C3L-01882",
    Immune_subtype: "ccRCC/Endothelial",
  },
  {
    Tumor: "UCEC",
    Sex: "Female",
    Age: "55-63 (25%-50%)",
    Participant_Country: "United States",
    Grade: "G2",
    Stage: "I",
    BMI: ">=30",
    Alcohol_consumption: "non-drinker",
    Tobacco_use: "non-smoker",
    Secondhand_smoke_exposure: "No",
    Overall_survival_in_days: "<348 (25%)",
    Recurrence_status: "No",
    Survival_status: "Dead",
    caseId: "C3L-00767",
    Immune_subtype: "CD8-/IFNG-",
  },
  {
    Tumor: "LUAD",
    Sex: "Female",
    Age: "63-70 (50%-75%)",
    Participant_Country: "United States",
    Grade: "G2",
    Stage: "I",
    BMI: ">=30",
    Alcohol_consumption: "past-drinker",
    Tobacco_use: "current smoker",
    Secondhand_smoke_exposure: "not_reported",
    Overall_survival_in_days: ">=1016 (>=75%)",
    Recurrence_status: "No",
    Survival_status: "Dead",
    caseId: "C3L-01924",
    Immune_subtype: "CD8+/IFNG+",
  },
  {
    Tumor: "BRCA",
    Sex: "Female",
    Age: "55-63 (25%-50%)",
    Participant_Country: "not_reported",
    Grade: "not_reported",
    Stage: "II",
    BMI: "not_reported",
    Alcohol_consumption: "not_reported",
    Tobacco_use: "not_reported",
    Secondhand_smoke_exposure: "not_reported",
    Overall_survival_in_days: "not_reported",
    Recurrence_status: "No",
    Survival_status: "not_reported",
    caseId: "05BR045",
    Immune_subtype: "CD8-/IFNG-",
  },
  {
    Tumor: "LSCC",
    Sex: "Female",
    Age: ">=70 (>=75%)",
    Participant_Country: "United States",
    Grade: "G2",
    Stage: "I",
    BMI: "<18.5",
    Alcohol_consumption: "drinker",
    Tobacco_use: "current smoker",
    Secondhand_smoke_exposure: "not_reported",
    Overall_survival_in_days: ">=1016 (>=75%)",
    Recurrence_status: "Yes",
    Survival_status: "Dead",
    caseId: "C3L-02349",
    Immune_subtype: "CD8-/IFNG-",
  },
  {
    Tumor: "ccRCC",
    Sex: "Female",
    Age: "63-70 (50%-75%)",
    Participant_Country: "United States",
    Grade: "G3",
    Stage: "I",
    BMI: "25-30",
    Alcohol_consumption: "non-drinker",
    Tobacco_use: "non-smoker",
    Secondhand_smoke_exposure: "not_reported",
    Overall_survival_in_days: ">=1016 (>=75%)",
    Recurrence_status: "No",
    Survival_status: "Dead",
    caseId: "C3L-00026",
    Immune_subtype: "CD8-/IFNG-",
  },
  {
    Tumor: "LUAD",
    Sex: "Male",
    Age: "55-63 (25%-50%)",
    Participant_Country: "United States",
    Grade: "G2",
    Stage: "I",
    BMI: "25-30",
    Alcohol_consumption: "drinker",
    Tobacco_use: "current smoker",
    Secondhand_smoke_exposure: "not_reported",
    Overall_survival_in_days: "348-609 (25%-50%)",
    Recurrence_status: "No",
    Survival_status: "Dead",
    caseId: "C3L-00144",
    Immune_subtype: "CD8+/IFNG+",
  },
  {
    Tumor: "PDAC",
    Sex: "Male",
    Age: "63-70 (50%-75%)",
    Participant_Country: "Russia",
    Grade: "G2",
    Stage: "III",
    BMI: "25-30",
    Alcohol_consumption: "non-drinker",
    Tobacco_use: "non-smoker",
    Secondhand_smoke_exposure: "No",
    Overall_survival_in_days: "<348 (25%)",
    Recurrence_status: "No",
    Survival_status: "Alive",
    caseId: "C3L-03388",
    Immune_subtype: "CD8-/IFNG+",
  },
  {
    Tumor: "HGSC",
    Sex: "Female",
    Age: ">=70 (>=75%)",
    Participant_Country: "not_reported",
    Grade: "G3",
    Stage: "IV",
    BMI: "not_reported",
    Alcohol_consumption: "not_reported",
    Tobacco_use: "not_reported",
    Secondhand_smoke_exposure: "not_reported",
    Overall_survival_in_days: "<348 (25%)",
    Recurrence_status: "No",
    Survival_status: "Dead",
    caseId: "04OV037",
    Immune_subtype: "CD8-/IFNG+",
  },
  {
    Tumor: "BRCA",
    Sex: "Female",
    Age: "55-63 (25%-50%)",
    Participant_Country: "not_reported",
    Grade: "not_reported",
    Stage: "II",
    BMI: "not_reported",
    Alcohol_consumption: "not_reported",
    Tobacco_use: "not_reported",
    Secondhand_smoke_exposure: "not_reported",
    Overall_survival_in_days: "348-609 (25%-50%)",
    Recurrence_status: "No",
    Survival_status: "Dead",
    caseId: "14BR014",
    Immune_subtype: "Fibroblast/TGFBeta",
  },
  {
    Tumor: "LUAD",
    Sex: "Male",
    Age: "55-63 (25%-50%)",
    Participant_Country: "Ukraine",
    Grade: "G3",
    Stage: "I",
    BMI: "25-30",
    Alcohol_consumption: "non-drinker",
    Tobacco_use: "non-smoker",
    Secondhand_smoke_exposure: "not_reported",
    Overall_survival_in_days: "<348 (25%)",
    Recurrence_status: "No",
    Survival_status: "Dead",
    caseId: "C3N-00433",
    Immune_subtype: "CD8+/IFNG+",
  },
  {
    Tumor: "ccRCC",
    Sex: "Male",
    Age: "63-70 (50%-75%)",
    Participant_Country: "United States",
    Grade: "G2",
    Stage: "I",
    BMI: "25-30",
    Alcohol_consumption: "not_reported",
    Tobacco_use: "past-smoker",
    Secondhand_smoke_exposure: "not_reported",
    Overall_survival_in_days: ">=1016 (>=75%)",
    Recurrence_status: "Yes",
    Survival_status: "Dead",
    caseId: "C3L-00448",
    Immune_subtype: "ccRCC/Endothelial",
  },
  {
    Tumor: "LUAD",
    Sex: "Male",
    Age: "63-70 (50%-75%)",
    Participant_Country: "Vietnam",
    Grade: "G3",
    Stage: "I",
    BMI: "<18.5",
    Alcohol_consumption: "non-drinker",
    Tobacco_use: "non-smoker",
    Secondhand_smoke_exposure: "No",
    Overall_survival_in_days: "348-609 (25%-50%)",
    Recurrence_status: "No",
    Survival_status: "Dead",
    caseId: "C3N-01489",
    Immune_subtype: "CD8+/IFNG+",
  },
  {
    Tumor: "PDAC",
    Sex: "Male",
    Age: "55-63 (25%-50%)",
    Participant_Country: "United States",
    Grade: "G2",
    Stage: "I",
    BMI: "18.5-25",
    Alcohol_consumption: "non-drinker",
    Tobacco_use: "current smoker",
    Secondhand_smoke_exposure: "not_reported",
    Overall_survival_in_days: "609-1016 (50%-75%)",
    Recurrence_status: "No",
    Survival_status: "Dead",
    caseId: "C3L-02463",
    Immune_subtype: "Eosinophils/Endothelial",
  },
  {
    Tumor: "COAD",
    Sex: "Male",
    Age: "<55 (25%)",
    Participant_Country: "not_reported",
    Grade: "not_reported",
    Stage: "II",
    BMI: "not_reported",
    Alcohol_consumption: "not_reported",
    Tobacco_use: "not_reported",
    Secondhand_smoke_exposure: "not_reported",
    Overall_survival_in_days: "<348 (25%)",
    Recurrence_status: "No",
    Survival_status: "Dead",
    caseId: "20CO007",
    Immune_subtype: "CD8-/IFNG-",
  },
  {
    Tumor: "ccRCC",
    Sex: "Male",
    Age: "<55 (25%)",
    Participant_Country: "Poland",
    Grade: "G2",
    Stage: "II",
    BMI: ">=30",
    Alcohol_consumption: "not_reported",
    Tobacco_use: "not_reported",
    Secondhand_smoke_exposure: "not_reported",
    Overall_survival_in_days: "348-609 (25%-50%)",
    Recurrence_status: "Yes",
    Survival_status: "Alive",
    caseId: "C3N-00831",
    Immune_subtype: "ccRCC/Endothelial",
  },
  {
    Tumor: "PDAC",
    Sex: "Female",
    Age: "63-70 (50%-75%)",
    Participant_Country: "Russia",
    Grade: "G2",
    Stage: "III",
    BMI: "25-30",
    Alcohol_consumption: "non-drinker",
    Tobacco_use: "non-smoker",
    Secondhand_smoke_exposure: "No",
    Overall_survival_in_days: "348-609 (25%-50%)",
    Recurrence_status: "No",
    Survival_status: "Dead",
    caseId: "C3L-01124",
    Immune_subtype: "CD8-/IFNG+",
  },
  {
    Tumor: "GBM",
    Sex: "Female",
    Age: "55-63 (25%-50%)",
    Participant_Country: "United States",
    Grade: "not_reported",
    Stage: "not_reported",
    BMI: "18.5-25",
    Alcohol_consumption: "not_reported",
    Tobacco_use: "not_reported",
    Secondhand_smoke_exposure: "not_reported",
    Overall_survival_in_days: "<348 (25%)",
    Recurrence_status: "No",
    Survival_status: "Alive",
    caseId: "C3L-04084",
    Immune_subtype: "CD8-/IFNG-",
  },
  {
    Tumor: "HGSC",
    Sex: "Female",
    Age: ">=70 (>=75%)",
    Participant_Country: "not_reported",
    Grade: "not_reported",
    Stage: "not_reported",
    BMI: "not_reported",
    Alcohol_consumption: "not_reported",
    Tobacco_use: "not_reported",
    Secondhand_smoke_exposure: "not_reported",
    Overall_survival_in_days: "<348 (25%)",
    Recurrence_status: "No",
    Survival_status: "Dead",
    caseId: "17OV011",
    Immune_subtype: "Fibroblast/TGFBeta",
  },
  {
    Tumor: "PDAC",
    Sex: "Male",
    Age: "55-63 (25%-50%)",
    Participant_Country: "Poland",
    Grade: "G2",
    Stage: "II",
    BMI: "18.5-25",
    Alcohol_consumption: "non-drinker",
    Tobacco_use: "non-smoker",
    Secondhand_smoke_exposure: "No",
    Overall_survival_in_days: "348-609 (25%-50%)",
    Recurrence_status: "Yes",
    Survival_status: "Alive",
    caseId: "C3N-01714",
    Immune_subtype: "Eosinophils/Endothelial",
  },
  {
    Tumor: "PDAC",
    Sex: "Male",
    Age: "<55 (25%)",
    Participant_Country: "United States",
    Grade: "G3",
    Stage: "III",
    BMI: "<18.5",
    Alcohol_consumption: "past-drinker",
    Tobacco_use: "current smoker",
    Secondhand_smoke_exposure: "No",
    Overall_survival_in_days: "<348 (25%)",
    Recurrence_status: "No",
    Survival_status: "Alive",
    caseId: "C3L-04072",
    Immune_subtype: "CD8-/IFNG-",
  },
  {
    Tumor: "PDAC",
    Sex: "Male",
    Age: "55-63 (25%-50%)",
    Participant_Country: "not_reported",
    Grade: "G2",
    Stage: "II",
    BMI: "25-30",
    Alcohol_consumption: "drinker",
    Tobacco_use: "past-smoker",
    Secondhand_smoke_exposure: "not_reported",
    Overall_survival_in_days: "348-609 (25%-50%)",
    Recurrence_status: "Yes",
    Survival_status: "Alive",
    caseId: "C3L-01689",
    Immune_subtype: "CD8-/IFNG-",
  },
  {
    Tumor: "GBM",
    Sex: "Female",
    Age: "55-63 (25%-50%)",
    Participant_Country: "China",
    Grade: "not_reported",
    Stage: "not_reported",
    BMI: "18.5-25",
    Alcohol_consumption: "non-drinker",
    Tobacco_use: "non-smoker",
    Secondhand_smoke_exposure: "No",
    Overall_survival_in_days: "<348 (25%)",
    Recurrence_status: "Yes",
    Survival_status: "Alive",
    caseId: "C3N-02183",
    Immune_subtype: "CD8+/IFNG+",
  },
  {
    Tumor: "ccRCC",
    Sex: "Male",
    Age: "55-63 (25%-50%)",
    Participant_Country: "Ukraine",
    Grade: "G3",
    Stage: "III",
    BMI: "25-30",
    Alcohol_consumption: "non-drinker",
    Tobacco_use: "non-smoker",
    Secondhand_smoke_exposure: "not_reported",
    Overall_survival_in_days: "609-1016 (50%-75%)",
    Recurrence_status: "No",
    Survival_status: "Dead",
    caseId: "C3N-00852",
    Immune_subtype: "CD8-/IFNG+",
  },
  {
    Tumor: "BRCA",
    Sex: "Female",
    Age: "63-70 (50%-75%)",
    Participant_Country: "not_reported",
    Grade: "not_reported",
    Stage: "II",
    BMI: "not_reported",
    Alcohol_consumption: "not_reported",
    Tobacco_use: "not_reported",
    Secondhand_smoke_exposure: "not_reported",
    Overall_survival_in_days: "348-609 (25%-50%)",
    Recurrence_status: "No",
    Survival_status: "Dead",
    caseId: "18BR003",
    Immune_subtype: "CD8+/IFNG+",
  },
  {
    Tumor: "UCEC",
    Sex: "Female",
    Age: "55-63 (25%-50%)",
    Participant_Country: "United States",
    Grade: "G2",
    Stage: "I",
    BMI: "25-30",
    Alcohol_consumption: "not_reported",
    Tobacco_use: "current smoker",
    Secondhand_smoke_exposure: "not_reported",
    Overall_survival_in_days: ">=1016 (>=75%)",
    Recurrence_status: "No",
    Survival_status: "Dead",
    caseId: "C3L-00449",
    Immune_subtype: "Fibroblast/TGFBeta",
  },
  {
    Tumor: "HNSCC",
    Sex: "Male",
    Age: "63-70 (50%-75%)",
    Participant_Country: "Poland",
    Grade: "G2",
    Stage: "II",
    BMI: "<18.5",
    Alcohol_consumption: "not_reported",
    Tobacco_use: "not_reported",
    Secondhand_smoke_exposure: "not_reported",
    Overall_survival_in_days: ">=1016 (>=75%)",
    Recurrence_status: "No",
    Survival_status: "Dead",
    caseId: "C3N-00297",
    Immune_subtype: "CD8-/IFNG-",
  },
  {
    Tumor: "BRCA",
    Sex: "Female",
    Age: "63-70 (50%-75%)",
    Participant_Country: "not_reported",
    Grade: "not_reported",
    Stage: "not_reported",
    BMI: "not_reported",
    Alcohol_consumption: "not_reported",
    Tobacco_use: "not_reported",
    Secondhand_smoke_exposure: "not_reported",
    Overall_survival_in_days: "not_reported",
    Recurrence_status: "No",
    Survival_status: "not_reported",
    caseId: "01BR010",
    Immune_subtype: "not_reported",
  },
  {
    Tumor: "LUAD",
    Sex: "Male",
    Age: "63-70 (50%-75%)",
    Participant_Country: "United States",
    Grade: "G2",
    Stage: "III",
    BMI: "25-30",
    Alcohol_consumption: "non-drinker",
    Tobacco_use: "past-smoker",
    Secondhand_smoke_exposure: "not_reported",
    Overall_survival_in_days: "<348 (25%)",
    Recurrence_status: "Yes",
    Survival_status: "Alive",
    caseId: "C3L-00412",
    Immune_subtype: "Eosinophils/Endothelial",
  },
  {
    Tumor: "HNSCC",
    Sex: "Male",
    Age: "63-70 (50%-75%)",
    Participant_Country: "Poland",
    Grade: "G2",
    Stage: "I",
    BMI: ">=30",
    Alcohol_consumption: "drinker",
    Tobacco_use: "current smoker",
    Secondhand_smoke_exposure: "Yes",
    Overall_survival_in_days: ">=1016 (>=75%)",
    Recurrence_status: "No",
    Survival_status: "Alive",
    caseId: "C3N-03785",
    Immune_subtype: "CD8-/IFNG-",
  },
  {
    Tumor: "COAD",
    Sex: "Male",
    Age: "63-70 (50%-75%)",
    Participant_Country: "not_reported",
    Grade: "not_reported",
    Stage: "IV",
    BMI: "not_reported",
    Alcohol_consumption: "not_reported",
    Tobacco_use: "not_reported",
    Secondhand_smoke_exposure: "not_reported",
    Overall_survival_in_days: "<348 (25%)",
    Recurrence_status: "No",
    Survival_status: "Dead",
    caseId: "16CO006",
    Immune_subtype: "Fibroblast/TGFBeta",
  },
  {
    Tumor: "PDAC",
    Sex: "Female",
    Age: ">=70 (>=75%)",
    Participant_Country: "Canada",
    Grade: "G2",
    Stage: "II",
    BMI: "18.5-25",
    Alcohol_consumption: "non-drinker",
    Tobacco_use: "non-smoker",
    Secondhand_smoke_exposure: "not_reported",
    Overall_survival_in_days: "<348 (25%)",
    Recurrence_status: "Yes",
    Survival_status: "Alive",
    caseId: "C3L-04853",
    Immune_subtype: "CD8-/IFNG+",
  },
  {
    Tumor: "LUAD",
    Sex: "Male",
    Age: "63-70 (50%-75%)",
    Participant_Country: "United States",
    Grade: "G3",
    Stage: "I",
    BMI: ">=30",
    Alcohol_consumption: "drinker",
    Tobacco_use: "past-smoker",
    Secondhand_smoke_exposure: "not_reported",
    Overall_survival_in_days: "609-1016 (50%-75%)",
    Recurrence_status: "Yes",
    Survival_status: "Alive",
    caseId: "C3L-00094",
    Immune_subtype: "CD8-/IFNG+",
  },
  {
    Tumor: "LSCC",
    Sex: "Male",
    Age: ">=70 (>=75%)",
    Participant_Country: "Bulgaria",
    Grade: "G2",
    Stage: "I",
    BMI: ">=30",
    Alcohol_consumption: "drinker",
    Tobacco_use: "past-smoker",
    Secondhand_smoke_exposure: "Yes",
    Overall_survival_in_days: "<348 (25%)",
    Recurrence_status: "No",
    Survival_status: "Dead",
    caseId: "C3L-02170",
    Immune_subtype: "Eosinophils/Endothelial",
  },
  {
    Tumor: "LSCC",
    Sex: "Male",
    Age: "55-63 (25%-50%)",
    Participant_Country: "China",
    Grade: "G3",
    Stage: "III",
    BMI: ">=30",
    Alcohol_consumption: "non-drinker",
    Tobacco_use: "past-smoker",
    Secondhand_smoke_exposure: "not_reported",
    Overall_survival_in_days: "<348 (25%)",
    Recurrence_status: "No",
    Survival_status: "Dead",
    caseId: "C3N-03072",
    Immune_subtype: "CD8-/IFNG-",
  },
  {
    Tumor: "HGSC",
    Sex: "Female",
    Age: "55-63 (25%-50%)",
    Participant_Country: "not_reported",
    Grade: "G3",
    Stage: "IV",
    BMI: "not_reported",
    Alcohol_consumption: "not_reported",
    Tobacco_use: "not_reported",
    Secondhand_smoke_exposure: "not_reported",
    Overall_survival_in_days: "348-609 (25%-50%)",
    Recurrence_status: "Yes",
    Survival_status: "Dead",
    caseId: "04OV028",
    Immune_subtype: "Fibroblast/TGFBeta",
  },
  {
    Tumor: "HNSCC",
    Sex: "Male",
    Age: "55-63 (25%-50%)",
    Participant_Country: "Poland",
    Grade: "G3",
    Stage: "IV",
    BMI: "18.5-25",
    Alcohol_consumption: "drinker",
    Tobacco_use: "past-smoker",
    Secondhand_smoke_exposure: "No",
    Overall_survival_in_days: "348-609 (25%-50%)",
    Recurrence_status: "Yes",
    Survival_status: "Alive",
    caseId: "C3N-03011",
    Immune_subtype: "CD8-/IFNG-",
  },
  {
    Tumor: "BRCA",
    Sex: "Female",
    Age: ">=70 (>=75%)",
    Participant_Country: "not_reported",
    Grade: "not_reported",
    Stage: "II",
    BMI: "not_reported",
    Alcohol_consumption: "not_reported",
    Tobacco_use: "not_reported",
    Secondhand_smoke_exposure: "not_reported",
    Overall_survival_in_days: "<348 (25%)",
    Recurrence_status: "No",
    Survival_status: "Dead",
    caseId: "20BR002",
    Immune_subtype: "CD8-/IFNG-",
  },
  {
    Tumor: "BRCA",
    Sex: "Female",
    Age: "55-63 (25%-50%)",
    Participant_Country: "not_reported",
    Grade: "not_reported",
    Stage: "III",
    BMI: "not_reported",
    Alcohol_consumption: "not_reported",
    Tobacco_use: "not_reported",
    Secondhand_smoke_exposure: "not_reported",
    Overall_survival_in_days: "348-609 (25%-50%)",
    Recurrence_status: "No",
    Survival_status: "Dead",
    caseId: "01BR031",
    Immune_subtype: "CD8-/IFNG-",
  },
  {
    Tumor: "GBM",
    Sex: "Male",
    Age: ">=70 (>=75%)",
    Participant_Country: "Poland",
    Grade: "not_reported",
    Stage: "not_reported",
    BMI: "18.5-25",
    Alcohol_consumption: "drinker",
    Tobacco_use: "not_reported",
    Secondhand_smoke_exposure: "No",
    Overall_survival_in_days: "<348 (25%)",
    Recurrence_status: "No",
    Survival_status: "Alive",
    caseId: "C3N-02255",
    Immune_subtype: "CD8-/IFNG-",
  },
  {
    Tumor: "UCEC",
    Sex: "Female",
    Age: ">=70 (>=75%)",
    Participant_Country: "United States",
    Grade: "G2",
    Stage: "I",
    BMI: "25-30",
    Alcohol_consumption: "non-drinker",
    Tobacco_use: "non-smoker",
    Secondhand_smoke_exposure: "not_reported",
    Overall_survival_in_days: "609-1016 (50%-75%)",
    Recurrence_status: "Yes",
    Survival_status: "Dead",
    caseId: "C3N-00729",
    Immune_subtype: "Fibroblast/TGFBeta",
  },
  {
    Tumor: "ccRCC",
    Sex: "Male",
    Age: "<55 (25%)",
    Participant_Country: "United States",
    Grade: "G3",
    Stage: "II",
    BMI: "18.5-25",
    Alcohol_consumption: "drinker",
    Tobacco_use: "non-smoker",
    Secondhand_smoke_exposure: "not_reported",
    Overall_survival_in_days: ">=1016 (>=75%)",
    Recurrence_status: "No",
    Survival_status: "Dead",
    caseId: "C3N-00953",
    Immune_subtype: "CD8+/IFNG+",
  },
  {
    Tumor: "HGSC",
    Sex: "Female",
    Age: "63-70 (50%-75%)",
    Participant_Country: "not_reported",
    Grade: "G3",
    Stage: "III",
    BMI: "not_reported",
    Alcohol_consumption: "not_reported",
    Tobacco_use: "not_reported",
    Secondhand_smoke_exposure: "not_reported",
    Overall_survival_in_days: "348-609 (25%-50%)",
    Recurrence_status: "Yes",
    Survival_status: "Dead",
    caseId: "04OV017",
    Immune_subtype: "CD8-/IFNG-",
  },
  {
    Tumor: "LSCC",
    Sex: "Male",
    Age: ">=70 (>=75%)",
    Participant_Country: "United States",
    Grade: "G3",
    Stage: "II",
    BMI: "25-30",
    Alcohol_consumption: "non-drinker",
    Tobacco_use: "past-smoker",
    Secondhand_smoke_exposure: "not_reported",
    Overall_survival_in_days: "<348 (25%)",
    Recurrence_status: "No",
    Survival_status: "Alive",
    caseId: "C3L-01455",
    Immune_subtype: "CD8+/IFNG+",
  },
  {
    Tumor: "BRCA",
    Sex: "Female",
    Age: "55-63 (25%-50%)",
    Participant_Country: "not_reported",
    Grade: "not_reported",
    Stage: "II",
    BMI: "not_reported",
    Alcohol_consumption: "not_reported",
    Tobacco_use: "not_reported",
    Secondhand_smoke_exposure: "not_reported",
    Overall_survival_in_days: "<348 (25%)",
    Recurrence_status: "No",
    Survival_status: "Dead",
    caseId: "06BR005",
    Immune_subtype: "CD8-/IFNG-",
  },
  {
    Tumor: "ccRCC",
    Sex: "Female",
    Age: ">=70 (>=75%)",
    Participant_Country: "United States",
    Grade: "G4",
    Stage: "III",
    BMI: ">=30",
    Alcohol_consumption: "non-drinker",
    Tobacco_use: "non-smoker",
    Secondhand_smoke_exposure: "not_reported",
    Overall_survival_in_days: "<348 (25%)",
    Recurrence_status: "No",
    Survival_status: "Alive",
    caseId: "C3L-01302",
    Immune_subtype: "CD8+/IFNG+",
  },
  {
    Tumor: "COAD",
    Sex: "Female",
    Age: "<55 (25%)",
    Participant_Country: "not_reported",
    Grade: "not_reported",
    Stage: "III",
    BMI: "not_reported",
    Alcohol_consumption: "not_reported",
    Tobacco_use: "not_reported",
    Secondhand_smoke_exposure: "not_reported",
    Overall_survival_in_days: "348-609 (25%-50%)",
    Recurrence_status: "No",
    Survival_status: "Dead",
    caseId: "11CO037",
    Immune_subtype: "Fibroblast/TGFBeta",
  },
  {
    Tumor: "HNSCC",
    Sex: "Male",
    Age: "<55 (25%)",
    Participant_Country: "Poland",
    Grade: "G2",
    Stage: "IV",
    BMI: "18.5-25",
    Alcohol_consumption: "drinker",
    Tobacco_use: "non-smoker",
    Secondhand_smoke_exposure: "No",
    Overall_survival_in_days: ">=1016 (>=75%)",
    Recurrence_status: "No",
    Survival_status: "Dead",
    caseId: "C3N-01947",
    Immune_subtype: "CD8+/IFNG+",
  },
  {
    Tumor: "LUAD",
    Sex: "Female",
    Age: "<55 (25%)",
    Participant_Country: "Vietnam",
    Grade: "G2",
    Stage: "II",
    BMI: "18.5-25",
    Alcohol_consumption: "non-drinker",
    Tobacco_use: "non-smoker",
    Secondhand_smoke_exposure: "No",
    Overall_survival_in_days: "348-609 (25%-50%)",
    Recurrence_status: "No",
    Survival_status: "Dead",
    caseId: "C3N-00550",
    Immune_subtype: "CD8-/IFNG+",
  },
  {
    Tumor: "BRCA",
    Sex: "Female",
    Age: "63-70 (50%-75%)",
    Participant_Country: "not_reported",
    Grade: "not_reported",
    Stage: "II",
    BMI: "not_reported",
    Alcohol_consumption: "not_reported",
    Tobacco_use: "not_reported",
    Secondhand_smoke_exposure: "not_reported",
    Overall_survival_in_days: "<348 (25%)",
    Recurrence_status: "No",
    Survival_status: "Dead",
    caseId: "20BR006",
    Immune_subtype: "CD8-/IFNG-",
  },
  {
    Tumor: "GBM",
    Sex: "Male",
    Age: ">=70 (>=75%)",
    Participant_Country: "United States",
    Grade: "not_reported",
    Stage: "not_reported",
    BMI: "25-30",
    Alcohol_consumption: "non-drinker",
    Tobacco_use: "non-smoker",
    Secondhand_smoke_exposure: "not_reported",
    Overall_survival_in_days: "<348 (25%)",
    Recurrence_status: "Yes",
    Survival_status: "Alive",
    caseId: "C3N-01505",
    Immune_subtype: "CD8+/IFNG+",
  },
  {
    Tumor: "ccRCC",
    Sex: "Female",
    Age: ">=70 (>=75%)",
    Participant_Country: "United States",
    Grade: "G3",
    Stage: "III",
    BMI: ">=30",
    Alcohol_consumption: "not_reported",
    Tobacco_use: "past-smoker",
    Secondhand_smoke_exposure: "not_reported",
    Overall_survival_in_days: ">=1016 (>=75%)",
    Recurrence_status: "Yes",
    Survival_status: "Alive",
    caseId: "C3L-00606",
    Immune_subtype: "ccRCC/Endothelial",
  },
  {
    Tumor: "COAD",
    Sex: "Female",
    Age: ">=70 (>=75%)",
    Participant_Country: "not_reported",
    Grade: "not_reported",
    Stage: "III",
    BMI: "not_reported",
    Alcohol_consumption: "not_reported",
    Tobacco_use: "not_reported",
    Secondhand_smoke_exposure: "not_reported",
    Overall_survival_in_days: "<348 (25%)",
    Recurrence_status: "No",
    Survival_status: "Dead",
    caseId: "05CO006",
    Immune_subtype: "CD8-/IFNG+",
  },
  {
    Tumor: "BRCA",
    Sex: "Female",
    Age: "55-63 (25%-50%)",
    Participant_Country: "not_reported",
    Grade: "not_reported",
    Stage: "II",
    BMI: "not_reported",
    Alcohol_consumption: "not_reported",
    Tobacco_use: "not_reported",
    Secondhand_smoke_exposure: "not_reported",
    Overall_survival_in_days: "348-609 (25%-50%)",
    Recurrence_status: "No",
    Survival_status: "Dead",
    caseId: "11BR047",
    Immune_subtype: "Fibroblast/TGFBeta",
  },
  {
    Tumor: "HGSC",
    Sex: "Female",
    Age: "55-63 (25%-50%)",
    Participant_Country: "not_reported",
    Grade: "not_reported",
    Stage: "III",
    BMI: "not_reported",
    Alcohol_consumption: "not_reported",
    Tobacco_use: "not_reported",
    Secondhand_smoke_exposure: "not_reported",
    Overall_survival_in_days: "348-609 (25%-50%)",
    Recurrence_status: "Yes",
    Survival_status: "Dead",
    caseId: "14OV011",
    Immune_subtype: "Fibroblast/TGFBeta",
  },
  {
    Tumor: "PDAC",
    Sex: "Female",
    Age: "<55 (25%)",
    Participant_Country: "Poland",
    Grade: "G2",
    Stage: "III",
    BMI: "18.5-25",
    Alcohol_consumption: "non-drinker",
    Tobacco_use: "current smoker",
    Secondhand_smoke_exposure: "No",
    Overall_survival_in_days: "348-609 (25%-50%)",
    Recurrence_status: "Yes",
    Survival_status: "Alive",
    caseId: "C3N-01381",
    Immune_subtype: "CD8-/IFNG-",
  },
  {
    Tumor: "LSCC",
    Sex: "Male",
    Age: ">=70 (>=75%)",
    Participant_Country: "Vietnam",
    Grade: "G3",
    Stage: "I",
    BMI: "18.5-25",
    Alcohol_consumption: "non-drinker",
    Tobacco_use: "non-smoker",
    Secondhand_smoke_exposure: "No",
    Overall_survival_in_days: "<348 (25%)",
    Recurrence_status: "No",
    Survival_status: "Alive",
    caseId: "C3N-01028",
    Immune_subtype: "CD8+/IFNG+",
  },
];
