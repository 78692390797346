<template>
  <v-app>
    <v-app-bar color="blue-grey lighten-3" dark app clipped-left>
      <app-bar-links />
    </v-app-bar>

    <v-main>
      <router-view />
    </v-main>

    <v-footer padless>
      <footer-affiliations />
    </v-footer>
  </v-app>
</template>

<script>
import AppBarLinks from './components/AppBarLinks.vue';
import FooterAffiliations from './components/FooterAffiliations.vue';

export default {
  components: { 
    AppBarLinks, 
    FooterAffiliations 
  },
  name: "App",

  data: () => ({
    //
  }),

  mounted() {
  },
};
</script>
