<template>
    <div class="landing-input-left">
      <v-textarea
        v-model="inputKinases"
        outlined
        name="input-7-4"
        label="Enter newline separated list of kinases"
        height="250"
        no-resize
      ></v-textarea>
      <v-autocomplete
        v-model="selectedKinaseList"
        :items="publishedKinaseLists"
        item-text="title"
        dense
        filled
        label="or use a published kinase list"
        :class="onHeatmapPage ? 'mt-n3' : ''"
      >
      </v-autocomplete>
    </div>
</template>

<script>
export default {
  name: "LandingInputLeft",

  props: ['onHeatmapPage'],

  data: () => ({
    selectedKinaseList: '',
  }),

  computed: {
    inputKinases: {
      get() { return this.$store.state.inputKinases.join('\n') },
      set(inputKinases) { this.$store.dispatch(
          'setInputKinases', 
          { inputKinases: inputKinases
              .split('\n')
              .filter(kinase => kinase.length > 0)
              .map(kinase => kinase.toUpperCase()) 
          }
        )
      },
    },
    publishedKinaseLists() { return this.$store.state.publishedKinaseLists },
  },

  watch: {
    selectedKinaseList() {
      this.inputKinases = this.publishedKinaseLists.find(o => o.title === this.selectedKinaseList).kinases.join('\n')
    }
  },
  
};
</script>

<style scoped>
.landing-input-left {
  width: 40%;
  margin: 10px;
}
</style>
