<template>
  <div class="landing-input-header">
      <!-- <div class="landing-input-header-title"> -->
        <img src="../assets/header.png" style="height: 110px; margin-top: -20px; margin-bottom: -20px;">
        <!-- <div class="landing-input-header-title-text">
          <h2>proKAP pancancer</h2>
          <hr style="width:50%;text-align:left;margin-left:0; margin-top: 10px;">
        </div>
        <img src="../assets/landing-header-1.png" style="height: 80px;"> -->
      <!-- </div> -->
      <!-- <h6>ProTrack Kinase Activity Portal</h6> -->
      <!-- <h6 style="margin-top: -6px;">for phenotype and kinase enrichment exploration</h6> -->
  </div>
</template>

<script>
export default {
  name: "LandingInputHeader",
};
</script>

<style scoped>
.landing-input-header {   
  width: 50%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.landing-input-header-title {
  display: flex;
  flex-direction: row;
}
</style>
